import { Deserializable } from "../Deserializable";

export class AnnouncementConfidentialAccess implements Deserializable {

  constructor(
    public userId: number,
    public confidentialCode: string
  ) {

  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
