import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RndtsType } from 'src/app/07-bordereaux/rndts-page/rndts-page.component';
import { BordereauService } from 'src/app/fr/brgm/common/http/bodereau/bordereau.service';
import { RndtsService } from 'src/app/fr/brgm/common/http/rndts/rndts.service';
import { Bordereau } from 'src/app/fr/brgm/common/model/bordereau.model';
import { RndtsResponse } from 'src/app/fr/brgm/common/model/rndts/rndtsResponse.model';
import { TerresSediments } from 'src/app/fr/brgm/common/model/rndts/terresSediments.model';
import { ModalRNDTSResponseComponent } from '../modal-rndts-response/modal-rndts-response.component';

@Component({
  selector: 'app-modal-rndts-confirm-declaration',
  templateUrl: './modal-rndts-confirm-declaration.component.html',
  styleUrls: ['./modal-rndts-confirm-declaration.component.scss']
})
export class ModalRndtsConfirmDeclarationComponent implements OnInit {

  @Input() type: RndtsType;

  RndtsType = RndtsType;

  constructor(
    public activeModal: NgbActiveModal
    ) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  submit() {
    this.activeModal.close(true);
  }

}
