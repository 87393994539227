<div class="chat">

    <div class="chat-body" *ngFor="let message of messages">
        <div class="chat-message" [ngClass]="{'chat-message--self': message.userFrom.id === currentUser.id}">
            <time class="chat-date"><b>Le {{message.sendingDate | date:'dd/MM/yyyy'}}</b> à {{message.sendingDate | date:'HH:mm:ss'}}, {{message.userFrom.firstname}} {{message.userFrom.name}} | {{message.userFrom.entityObject.socialReason}} a écrit :</time>
            <p class="chat-message-text">{{message.body}}</p>
        </div>
    </div>

    <form class="chat-footer" *ngIf="enabled">
        <input type="text" name="" placeholder="Écrivez votre message ici" class="chat-input" [(ngModel)]="newMessage.body" [ngModelOptions]="{standalone: true}">
        <button class="chat-send icon-paper-plane" (click)="sendMessage()"></button>
    </form>

</div>
