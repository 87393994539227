<div class="entry wrapper">
    <form action="" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="entry-content" style="width:100%;">
            <div class="entry-form-header">
                <h1 class="h1 entry-form-title">Modification de mot de passe</h1>
               
                <div class="form-group">
                    <label class="form-item form-item--12">
                        <span class="form-label">Nouveau mot de passe <span class="form-tooltip has-tooltip" ngbTooltip="Votre mot de passe doit contenir 8 caractères minimum, au moins 1 Majuscule, 1 chiffre & 1 caractère spécial @& »’=:,…"></span></span>
                        <span class="form-password">
                            <input type="password" formControlName="password" placeholder="Entrez votre mot de passe" class="form-password-input" [(ngModel)]="password">
                            <span class="form-password-toggle"></span>
                        </span>
                    </label>
                    
                    <label class="form-item form-item--12">
                        <span class="form-label">Confirmation du mot de passe</span>
                        <span class="form-password">
                            <input type="password" formControlName="confirmPassword" placeholder="Entrez votre mot de passe" class="form-password-input" [(ngModel)]="confirmation">
                            <span class="form-password-toggle"></span>
                        </span>
                    </label>
                </div>
    
                <div class="form-footer">   
                    <button type="submit" value="1" class="button button--green" [disabled]="registerForm.invalid">
                        <i class="button-icon icon-check"></i>
                        <span class="button-label">Confirmer</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>