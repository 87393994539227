import { Deserializable } from './Deserializable';
import { User } from './user.model';
import { Transaction } from './transaction.model';

export class Message implements Deserializable {
    constructor(
        public id?: number,
        public sendingDate?: Date,
        public body?: string,
        public answer?: string,
        public transaction?:Transaction,
        public userFrom?: User,
        public userTo?: User
    ) {}
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}