import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AccueilRoutingModule } from './accueil-routing.module';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '../00-shared/primeng/primeng.module';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { MapModule } from '../00-shared/map/map.module';
import { MessageModule } from '../00-shared/message/message.module';

@NgModule({
  declarations: [
    LandingPageComponent
  ],
  imports: [
    CommonModule,
    AccueilRoutingModule,
    FormsModule,
    PrimengModule,
    PaginationModule,
    MapModule,
    MessageModule
  ]
})
export class AccueilModule { }
