<div appUseParentForm #divToModalClickMessage>
  <div class="form-fieldset">
    <div class="form-fieldset-header">
      <h3 class="h3 form-fieldset-title">Substances</h3>
    </div>

    <div class="form-group">
      <div class="form-item form-item--12" *ngIf="!childIsOffer">
        <p class="form-message">
          <em class="blue"><b>Les offres de terres ne contenant pas de résultats d’analyse
              limitent les possibilités de valorisation.</b></em><br>
          Merci de saisir la valeur représentative des résultats d’analyses sur échantillon
          brut pour chacune des substances analysées.<br>
          Les résultats sur lixiviat peuvent être ajoutés en résultats complémentaires. Les
          valeurs sont représentatives du lot.
        </p>
      </div>

      <div class="form-group" *ngIf="!childIsOffer && analysis">

        <div class="form-item form-item--12">
          <ul class="form-list">
            <li class="form-list-item" *ngFor="let lexAnalysisType of lexAnalysisTypes">
              <label class="form-radio">
                <input type="radio" [value]="lexAnalysisType?.id" name="analysisType"
                  [(ngModel)]="currentLexAnalysisTypeIdValue" class="form-radio-input"
                  (change)="emitAnalysisDeclaration()">
                <span class="form-radio-label">{{ lexAnalysisType.label }}</span>
              </label>
            </li>
          </ul>
        </div>

        <!-- Valeurs de fond pédo-géochimique local disponibles

      -->
        <label *ngIf="currentLexAnalysisTypeIdValue === 2" class="form-item form-item--12">
          <span class="form-label">Quelle est la base de fond pédo-géochimique locale utilisée
            ? <span class="red">*</span></span>
            <span class="form-select">
              <select [(ngModel)]="idSelectedFond" required class="form-select-inner" id="idFondPedo" name="fondpedo"
                  #fondpedo="ngModel" [ngClass]="{'red-border-class': fondpedo.errors && parentFormSubmitted}"
                  (change)="emitAnalysisDeclaration()">
                  <option value="null" disabled selected>Sélectionner ou enregistrer un fond pédogéochimique </option>
                  <option *ngFor="let fond of fondpedogeochimiques" [ngValue]="fond?.id">{{fond.nom}}</option>
              </select>
          </span>
        </label>

        <label *ngIf="currentLexAnalysisTypeIdValue === 2" class="form-item form-item--12">
          <span class="form-label">Détails du type d'analyse</span>
          <textarea name="analysisTypeDetail" placeholder="Saisir le texte…" class="form-textarea"
            [(ngModel)]="analysis.analysisType.detail" (change)="emitAnalysisDeclaration()">
          </textarea>
        </label>

      </div>

      <div *ngIf="currentLexAnalysisTypeIdValue !== 3 || childIsOffer">
        <div class="form-item form-item--12">
          <p class="form-message black">Les substances indiquées dans le tableau suivant sont à analyser obligatoirement
            pour la valorisation des terres excavées issues de sites et sols pollués.
            Il est également possible d'ajouter d'autres substances selon l'historique du site.<br>
            Le renseignement des résultats d'analyses est laissé à votre libre choix mais il favorisera les échanges de terres.</p>
          <p class="form-message blue">Les unités des substances sont en mg/kg MS. Vérifier que
            tous les résultats d’analyse respectent bien cette unité.</p>
        </div>

        <div class="form-item form-item--12">

          <table class="form-table" *ngIf="defaultAnalysisResults" (change)="emitAnalysisResults()">
            <caption>Tableau d'analyse des substances</caption>
            <thead>
              <tr>
                <th scope="col">Substance (analyse en contenu total)</th>
                <th scope="col" class="form-table-centered">Valeur</th>
                <th scope="col" class="form-table-centered">
                  < LQ</th>
                <th scope="col" class="form-table-centered">LQ</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let result of defaultAnalysisResults; let i = index">

                <td>
                  {{result.lexMolecule?.name}}
                </td>
                <td class="form-table-centered">
                  <span>
                    <input class="form-number-input" [(ngModel)]="result.value"
                      name="resultValue{{result?.id?.moleculeId}}" pattern="^[+-]?\d+(\.\d+)?$">
                  </span>
                </td>
                <td class="form-table-centered">
                  <label class="form-checkbox">
                    <input type="checkbox" class="form-checkbox-input" [(ngModel)]="result.infQl"
                      name="resultInfQl{{result?.id?.moleculeId}}" (click)="clickOnInfQ1(result.infQl, result)">
                    <span class="form-checkbox-label"></span>
                  </label>
                </td>
                <td class="form-table-centered">
                  <span>
                    <input class="form-number-input" [disabled]="!result.infQl" [(ngModel)]="result.quantifyLimit"
                      name="resultQuantifyLimit{{result?.id?.moleculeId}}" pattern="^[+-]?\d+(\.\d+)?$">
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="currentLexAnalysisTypeIdValue === 2" class="form-item form-item--12">
          <label>
            <span><button (click)="saveFondPedogeochimiqueAnalysis()" type="button" class="button button--green">Sauvegarder le fond pédogéochimiques déclaré ci-dessus</button></span>
          </label>
          <label>
            <span><a href="https://assistance.brgm.fr/formulaire/posez-votre-question?tools=TERRASS" target="_blank" class="form-link">Nous contacter pour demander l’enregistrement d’un fond pédogéochimique visible par l’ensemble des utilisateurs</a></span>
          </label>
        </div>

        <hr class="form-separator">
        <div class="form-group">
          <div class="form-item form-item--4">
            <span>Rechercher une substance spécifique</span>
          </div>

          <div class="form-item form-item--6">
          </div>
        </div>

        <div class="form-group">
          <div class="form-item form-item--4">
            <p-autoComplete type="text" name="analysisMolecules" #analysisMolecules="ngModel"
              [(ngModel)]="selectedMoelecule" [suggestions]="filteredMolecules"
              (completeMethod)="filterMolecule($event)" (onSelect)="doOnSelectMolecule($event)" field="name"
              [minLength]="2" class="form-input">
              <!--[dropdown]="true" -->
            </p-autoComplete>
          </div>

          <div class="form-item form-item--6">
            <button (click)="addAnalysisResult()" type="button" class="button button--blue">
              <i class="button-icon icon-add"></i>
              <span class="button-label">Ajouter la substance</span>
            </button>
          </div>
        </div>

        <div *ngIf="analysisAlreadyExist != null" class="form-item form-item--4 alert alert-danger">
          La substance '{{analysisAlreadyExist}}' est déjà répertoriée
        </div>

        <div class="form-item form-item--12">
          <a href="https://assistance.brgm.fr/formulaire/posez-votre-question?tools=TERRASS" target="_blank" class="form-link">Si la
            substance est introuvable, contactez-nous.</a>
        </div>

      </div>

    </div>
  </div>

  <hr class="form-separator">


  <div class="form-fieldset" *ngIf="analysis">
    <div class="form-group">
      <label class="form-item form-item--12">
        <span class="form-label">Informations complémentaires</span>
        <textarea [(ngModel)]="analysis.infos" name="analysisDeclarationInfos" (change)="emitAnalysisDeclaration()"
          placeholder="Précisions sur les données fournies, modalités de calcul des concentrations représentatives déclarées…"
          class="form-textarea"></textarea>
      </label>
    </div>
  </div>
</div>
