import { Deserializable } from '../Deserializable';
import { LexPollutionGroup } from './lexpollutiongroup.model';

export class LexMolecule implements Deserializable {
    constructor(
        public id?: number,
        public active?: boolean,
        public codeSandre?:number,
        public defaultDisplayed?:boolean,
        public familyOrder?:number,
        public misc?:string,
        public name?:string,
        public unitId?:number,
        public lexpollutionGroup?:LexPollutionGroup
    ) {
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}