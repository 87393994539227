<form name="createSiteForm" #form="ngForm">
  <div class="process-body container">
    <div class="page-header">
      <h1 class="h1">Ajouter un transporteur</h1>
    </div>

    <div class="process-wrapper">
      <nav class="process-nav">
        <ol class="process-nav-list">
          <li class="process-nav-item" [class.process-nav-item--selected]="currentStep == 1">
            <a href="#01" class="process-nav-link">
              <i class="process-nav-icon icon-info"></i>
              <span class="process-nav-label">Informations générales</span>
            </a>
          </li>
          <li class="process-nav-item" [class.process-nav-item--selected]="currentStep == 2">
            <a href="#02" class="process-nav-link">
              <i class="process-nav-icon icon-pin"></i>
              <span class="process-nav-label">Localisation du transporteur</span>
            </a>
          </li>
        </ol>
      </nav>

      <div class="process-main">
        <fieldset id="01" class="process-section">
          <legend class="process-section-header">
            <i class="process-section-icon icon-add"></i>
            <span class="process-section-title" data-counter="01">Informations générales</span>
          </legend>
          <div class="process-section-body">
            <div class="form-fieldset">
              <div class="form-fieldset-header">
                <h3 class="h3 form-fieldset-title">Informations sur le transporteur</h3>
              </div>
              <div class="form-group">
                <div class="form-item form-item--6">
                  <span class="form-label">Quel est le / les mode(s) de transport ?</span>
                </div>
                <div class="form-item form-item--6">
                  <div class="form-group">

                    <div class="form-item" *ngFor="let mode of transportMode">
                      <label class="form-checkbox">
                        <input type="radio" name="mode.id" [value]="mode.id" [(ngModel)]="selectedTransportModeId"
                          (change)="getModeTransportId($event, mode.id)" class="form-checkbox-input">

                        <span class="form-checkbox-label">{{mode.label}}</span>

                      </label>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <hr class="form-separator">

            <div class="form-fieldset">
              <div class="form-group">

                <label class="form-item form-item--6">
                  <span class="form-label">Nom du transporteur <span class="red">*</span></span>
                  <input type="text" #transporterName="ngModel" name="transporterName" [(ngModel)]="transporter.name"
                    placeholder="Saisir le nom du transporteur" class="form-input form-control"
                    [ngClass]="{'red-border-class': transporterName.errors && form.submitted}" required>
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Numéro de SIRET <span class="red">*</span></span>
                  <input type="text" #transporterSiret="ngModel" name="transporterSiret" class="form-input form-control"
                    (change)="onSiretChange($event)" [(ngModel)]="transporter.siret" placeholder="Saisir le numéro de siret"
                    [ngClass]="{'red-border-class': isInvalidSiret(), 'green-border-class': siretAddress}"
                    pattern="^[0-9]{14}$" required>

                  <div *ngIf="getSiretFormControl() && isInvalidSiret()" class="alert alert-danger">
                    <div *ngIf="getSiretFormControl().errors?.required">
                      Numéro SIRET requis
                    </div>
                    <div *ngIf="getSiretFormControl().errors?.pattern">
                      Merci de renseigner un numéro de SIRET valide (14 chiffres)
                    </div>
                    <div *ngIf="invalidSiret">
                      Le numéro de SIRET renseigné est introuvable
                    </div>
                  </div>

                  <div *ngIf="siretAddress && !isInvalidSiret()" class="alert alert-success">
                    Raison sociale : {{ siretAddress.socialReason }}
                  </div>

                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Email<span class="red">*</span></span>
                  <input type="email" #transporterEmail="ngModel" name="transporterEmail" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    [(ngModel)]="transporter.email" placeholder="Saisir l'adresse email" class="form-input form-control"
                    [ngClass]="{'red-border-class': transporterEmail.errors && form.submitted}" required>
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Téléphone<span class="red">*</span></span>
                  <input type="tel" #transporterPhoneNumber="ngModel" name="transporterPhoneNumber"
                    [(ngModel)]="transporter.phoneNumber" placeholder="Saisir le numéro de téléphone" pattern="^\+?([0-9]\s*){10,14}$"
                    class="form-input form-control"
                    [ngClass]="{'red-border-class': transporterPhoneNumber.errors && form.submitted}" required>
                </label>

              </div>
            </div>
          </div>
        </fieldset>

        <fieldset id="02" class="process-section" [disabled]="currentStep < 2 || isInvalidSiret() || siretAddress == null">
          <legend class="process-section-header">
            <i class="process-section-icon icon-pin"></i>
            <span class="process-section-title" data-counter="02">Localisation du transporteur</span>
          </legend>
          <div class="process-section-body">
            <div class="form-fieldset">
              <div class="form-fieldset-header">
                <h3 class="h3 form-fieldset-title">Adresse</h3>
              </div>

              <div class="form-group">
                <label class="form-item form-item--6">
                  <span class="form-label">Rue<span class="red">*</span></span>
                  <input *ngIf="siretAddress" type="text" name="transporterAddress" #transporterAddress="ngModel"
                    [(ngModel)]="siretAddress.address.numberStreet" placeholder="Nom et numéro de la rue"
                    class="form-input" [ngClass]="{'red-border-class': transporterAddress.errors && form.submitted}"
                    required>
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Code postal<span class="red">*</span></span>
                  <input *ngIf="siretAddress" type="text" name="transporterPostalCode" #transporterPostalCode="ngModel" pattern="^[0-9]{5}$"
                    [(ngModel)]="siretAddress.address.zipCode" placeholder="Saisir le code postal" class="form-input"
                    [ngClass]="{'red-border-class': transporterPostalCode.errors && form.submitted}" required>
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Ville<span class="red">*</span></span>
                  <input *ngIf="siretAddress" type="text" name="transporterVille" #transporterVille="ngModel"
                    [(ngModel)]="siretAddress.address.foreignCity" placeholder="Saisir la ville" class="form-input"
                    [ngClass]="{'red-border-class': transporterVille.errors && form.submitted}" required>
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Pays</span>
                  <span class="form-select">
                    <select *ngIf="siretAddress" class="form-select-inner" [(ngModel)]="selectedCountryId" name="selectedCountryId">
                      <option *ngFor="let country of transporterCountry" [value]="country.id">{{country.name}}</option>
                    </select>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>

  <div class="process-footer">
    <div class="container">
      <div class="process-controls">
        <button type="button" class="button button--teal button--outline" disabled>
          <i class="button-icon icon-left"></i>
          <span class="button-label">Précédent</span>
        </button>

        <div class="process-steps">
          <span class="process-steps-current">Étape <span
              class="process-steps-current-number">{{this.currentStep}}</span></span>
          <span class="process-steps-total">/ 2</span>

          <h3 class="process-steps-title">Informations générales</h3>
        </div>

        <button type="submit" class="button button--teal" (click)="suivant()">
          <span class="button-label" [textContent]="currentStep == 2 ? 'Créer' : 'Suivant'"></span>
          <i class="button-icon icon-right"></i>
        </button>

        <div *ngIf="form.submitted">
          <div *ngIf="form.form.status == 'INVALID' && form.submitted" class="red">
            Veuillez compléter les champs obligatoires avec des valeurs valides avant de continuer
          </div>
        </div>

      </div>
    </div>

    <div class="process-progress">
      <div class="process-progress-bar" [style.width]="progress+'%'"></div>
      <span class="process-progress-label">{{progress}}%</span>
    </div>
  </div>
</form>
