import { Component, OnInit } from '@angular/core';
import { LexUserParam } from 'src/app/fr/brgm/common/model/lex/lexuserparam.model';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {

  params:LexUserParam[];

  userParams:number[];

  allChecked:Boolean = false;

  constructor(private lexService:LexiqueService, 
    private userService:UserService) {
      this.userParams = [];
     }

  ngOnInit() {
    this.lexService.getLexUserParam().subscribe((res) => this.params = res);
    this.userService.getUserParams().subscribe((res)=> {
      this.userParams = res;
      if(this.userParams.length === this.params.length) this.allChecked = true;
    });
  }

  change(id:number) {
    if(this.userParams.includes(id)) {
      this.userParams.splice(this.userParams.indexOf(id),1);
    } else {
      this.userParams.push(id);
    }
    this.userService.createUserParams(this.userParams).subscribe((res) => this.userParams = res);
  }

  changeAll() {
    this.allChecked = !this.allChecked;
    this.userParams = [];
    if(this.allChecked) {
      this.params.forEach(param => this.userParams.push(param.id));
    }
    this.userService.createUserParams(this.userParams).subscribe((res) => this.userParams = res);
  }

}
