import { Deserializable } from './Deserializable';
import { DocumentTerrass } from './document.model';
import { DTV } from './dtv.model';
import { LexMaterialType } from './lex/lexmaterialtype.model';
import { LexObject } from './lex/lexObject.model';
import { LexOfferClosureReason } from './lex/lexofferclosurereason.model';
import { LexOfferStatus } from './lex/lexofferstatus.model';
import { Site } from './site.model';
import { User } from './user.model';

export class OTV implements Deserializable {

  constructor(
      public id?: number,
      public availStartDate?: Date,
      public availEndDate?: Date,
      public closureDate?: Date,
      public creationDate?: Date,
      public publicationDate?: Date,
      public denomination?: string,
      public infos?: string,
      public bstvIcpe?:string,
      public public_?: boolean,
      public quantity?: number,
      public scanningDistance?: number,
      public scanningMode?: number,
      public lexMaterialType?: LexMaterialType,
      public lexCodeDechet?: LexObject,
      public lexOfferClosureReason?: LexOfferClosureReason,
      public lexOfferStatus?: LexOfferStatus,
      public site?: Site,
      public user?: User,
      public excludedDemand?: DTV[],
      public technicalDocument?: string[],
      public status?: string,
      public documents?: DocumentTerrass[],
      public isModifiable?: boolean,
      public isLinkToTransaction?: boolean,
      public currentUserCanUpdate?: boolean,
      public currentUserCanDuplicate?: boolean,
      public geometry?:any
  ) {

  }

  deserialize(input: any): this {
      Object.assign(this, input);
      return this;
  }
}
