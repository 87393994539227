import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Lot } from '../model/lot.model';
import { LotService } from '../services/lot.service';

@Component({
  selector: 'app-lot-edit',
  templateUrl: './lot-edit.component.html',
  styleUrls: ['./lot-edit.component.scss']
})
export class LotEditComponent implements OnInit {

  lot: Lot = null;
  
  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private lotService: LotService,
    private router: Router
  ) { }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => this.titleService.setTitle(data.title));
    this.activatedroute.params.subscribe( params => {
      this.lotService.getById(Number(params.idLot)).subscribe(res => {
        this.lot = res;
      });
    });

  }

}
