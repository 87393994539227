import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemandesRoutingModule } from './demandes-routing.module';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '../00-shared/primeng/primeng.module';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { DtvPageComponent } from './dtv-page/dtv-page.component';
import { EditDemandePageComponent } from './edit-demande-page/edit-demande-page.component';
import { ViewDemandePageComponent } from './view-demande-page/view-demande-page.component';
import { MessageModule } from '../00-shared/message/message.module';
import { MapModule } from '../00-shared/map/map.module';
import { AnnonceFormModule } from '../00-shared/annonce-form/annonce-form.module';
import { DuplicateDemandePageComponent } from './duplicate-demande-page/duplicate-demande-page.component';



@NgModule({
  declarations: [
    DtvPageComponent,
    EditDemandePageComponent,
    ViewDemandePageComponent,
    DuplicateDemandePageComponent
  ],
  imports: [
    CommonModule,
    DemandesRoutingModule,
    FormsModule,
    PrimengModule,
    PaginationModule,
    MapModule,
    MessageModule,
    AnnonceFormModule
  ],
  providers: []
})
export class DemandesModule { }
