import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { Bordereau } from '../../model/bordereau.model';
import { ListBordereau } from '../../model/listbordereau.model';
import { Criteria } from '../model/criteria.model';

@Injectable({
  providedIn: 'root'
})
export class BordereauService {

  private resourceUrl = environment.apiUrl + 'bordereau';
  private SEPARATOR = '/';

  constructor(private http: HttpClient) { }

  create(bordereau: Bordereau): Observable<Bordereau> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + 'create', bordereau).pipe(map((res: Bordereau) => res));
  }

  update(bordereau: Bordereau): Observable<Bordereau> {
    return this.http.put(this.resourceUrl + this.SEPARATOR + 'update', bordereau).pipe(map((res: Bordereau) => res));
  }

  close(bordereau: Bordereau): Observable<Bordereau> {
    return this.http.put(this.resourceUrl + this.SEPARATOR + 'close', bordereau).pipe(map((res: Bordereau) => res));
  }

  delete(bordereau: Bordereau) {
    return this.http.delete(this.resourceUrl + this.SEPARATOR + 'delete' + this.SEPARATOR + bordereau.id).pipe();
  }

  getForUser(criteria: Criteria, pageable?: any): Observable<ListBordereau> {
    return this.http.post(this.resourceUrl + "/findall", criteria, { params: pageable, observe: 'response' }).pipe(map((res) => {
      let listBordereau = new ListBordereau();
      listBordereau.bordereaux = this.convertBordereau(res.body);
      listBordereau.maxBordereau = Number(res.headers.get('X-Total-Count'));
      return listBordereau;
    }));
  }

  get(id: number): Observable<Bordereau> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + id).pipe(map((res: Bordereau) => res));
  }

  getPdfBordereau(id: number) {
    const url = this.resourceUrl + this.SEPARATOR + 'PDF' + this.SEPARATOR + id;
    return this.http.get(url, { responseType: 'blob' });
  }

  private convertBordereau(res): Bordereau[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
      result.push(new Bordereau().deserialize(res[i]));
    }
    return result;
  }

  getBstvs(id: number):Observable<Bordereau[]> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + 'site' + this.SEPARATOR + id).pipe(map((res: Bordereau[]) => this.convertBordereau(res)));
  }
}
