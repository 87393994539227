import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HomeOtvDtv } from '../../model/home-otv-dtv.model';
import { ListHomeOtvDtv } from '../../model/list-home-otv-dtv.component';
import { CriteriaHome } from '../model/criteria-home.model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private resourceUrl =  environment.apiUrl;
  private SEPARATOR =   '/';

  constructor(private http: HttpClient,) { }

  findAll(criteria: CriteriaHome, pageable?: any):Observable<ListHomeOtvDtv> {
    return this.http.post(this.resourceUrl+'demandoffer'+this.SEPARATOR+'findall',criteria, { params: pageable, observe: 'response' }).pipe(map((res)=> {
      let listOtv = new ListHomeOtvDtv();
      listOtv.odtv = this.convertResponse(res.body);
      listOtv.maxODTV = Number(res.headers.get('X-Total-Count'));
      return listOtv;
    }));
  }

  private convertResponse(res): HomeOtvDtv[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new HomeOtvDtv().deserialize(res[i]));
    }
    return result;
  }
}
