<div class="process-section-body" appUseParentForm>
    <div class="form-fieldset" *ngIf="isCreation">
        <div class="form-fieldset-header">
            <h3 class="h3 form-fieldset-title">Type d’annonce</h3>
        </div>

        <div class="form-group">
            <div class="form-item form-item--6">
                <span class="form-label">Choisissez quel type d’annonce vous allez créer.</span>
            </div>
            <div class="form-item form-item--6">

                <div class="form-group">
                  <div class="form-item">
                    <label class="form-radio">
                        <input type="radio" name="type" class="form-radio-input" [checked]="childIsOffer" (change)="onTypeChange(true)">
                        <span class="form-radio-label">Offre</span>
                    </label>
                  </div>

                  <div class="form-item">
                      <label class="form-radio">
                          <input type="radio" name="type" class="form-radio-input" [checked]="!childIsOffer" (change)="onTypeChange(false)">
                          <span class="form-radio-label">Demande</span>
                      </label>
                  </div>

                </div>
            </div>
        </div>
    </div>

    <hr class="form-separator" *ngIf="isCreation">

    <div *ngIf="annonce.isLinkToTransaction && !childIsDuplication" class="alert alert-info">
      {{childIsOffer? "L'offre" : "La demande"}} fait actuellement l’objet d’une transaction, il est uniquement possible de modifier la date de fin de disponibilité
    </div>

    <div class="form-fieldset">
        <div class="form-fieldset-header">
            <h3 class="h3 form-fieldset-title">PÉRIODE DE DISPONIBILITÉ</h3>
        </div>

        <div class="form-group">
            <div class="form-item form-item--6">
                <label>Date de début de disponibilité<span class="red">*</span>
                    <div [ngClass]="{'red-border': availStartDate.errors && parentFormSubmitted}">
                        <p-calendar [(ngModel)]="annonce.availStartDate" name="availStartDate" required
                            #availStartDate="ngModel" selectOtherMonths="true" monthNavigator="true"
                            yearNavigator="true" yearRange="2000:2099" dateFormat="dd/mm/yy" [disabled]="annonce.isLinkToTransaction && !childIsDuplication"
                            styleClass="form-date-input" [showButtonBar]="true" (onSelect)="onDateSelect()">
                        </p-calendar>
                    </div>
                </label>
                <div *ngIf="(availStartDate.errors && parentFormSubmitted) || (!availStartDate.pristine && !availStartDate.valid)" class="red">

                  <div *ngIf="availStartDate.hasError('required')">
                    La date de début est obligatoire
                  </div>

                  <div *ngIf="availStartDate.hasError('now-inferior')">
                    La date de début de disponibilité doit être postérieure ou égale à la date du jour
                  </div>

                  <div *ngIf="availStartDate.hasError('superior-end')">
                    La date de début ne peut pas être postérieure ou égale à la date de fin
                  </div>

                </div>
            </div>

            <div class="form-item form-item--6">
                <label>Date de fin de disponibilité<span class="red">*</span>
                    <div [ngClass]="{'red-border': availEndDate.errors && parentFormSubmitted}">
                        <p-calendar [(ngModel)]="annonce.availEndDate" name="availEndDate" required
                            #availEndDate="ngModel" selectOtherMonths="true" dateFormat="dd/mm/yy"
                            monthNavigator="true" yearNavigator="true" yearRange="2000:2099"
                            styleClass="form-date-input" [showButtonBar]="true" (onSelect)="onDateSelect()">
                        </p-calendar>
                    </div>
                </label>

                <div *ngIf="(availEndDate.errors && parentFormSubmitted) || (!availEndDate.pristine && !availEndDate.valid)" class="red">

                  <div *ngIf="availEndDate.hasError('required')">
                    La date de fin est obligatoire
                  </div>

                  <div *ngIf="availEndDate.hasError('inferior-start')">
                    La date de fin de disponibilité doit être postérieure, d’au moins un jour, à la date de début de disponibilité
                  </div>

                </div>
            </div>
        </div>
    </div>


    <hr class="form-separator">

    <div class="form-fieldset">
        <div class="form-fieldset-header">
            <h3 class="h3 form-fieldset-title">Confidentialité</h3>
        </div>

        <div class="form-group">
            <div class="form-item form-item--12">
                <p class="form-message">
                    Une {{childIsOffer? 'offre' : 'demande'}} publique sera visible par tous.<br>
                    Une {{childIsOffer? 'offre' : 'demande'}} confidentielle est uniquement consultable par vous.
                    <!-- les partenaires auxquels vous
                    transmettez la référence.-->
                </p>
            </div>
            <div class="form-item form-item--12">
                <label class="form-toggle">
                    <input type="checkbox" [(ngModel)]="annonce.public_" class="form-toggle-input" name="confidentialite" [disabled]="annonce.isLinkToTransaction && !childIsDuplication">
                    <span class="form-toggle-label">Confidentielle</span>
                    <span class="form-toggle-label">Publique</span>
                </label>
            </div>
        </div>
    </div>
</div>
