import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { MessageService } from "src/app/00-shared/message/message.service";
import { calendar_fr } from "src/app/app.constants";
import { LexiqueService } from "src/app/fr/brgm/common/http/lex/lexique.service";
import { Criteria } from "src/app/fr/brgm/common/http/model/criteria.model";
import { OtvService } from "src/app/fr/brgm/common/http/otv/otv.service";
import { UserService } from "src/app/fr/brgm/common/http/user/user.service";
import { LexOfferStatus } from "src/app/fr/brgm/common/model/lex/lexofferstatus.model";
import { OTV } from "src/app/fr/brgm/common/model/otv.model";
import { User } from "src/app/fr/brgm/common/model/user.model";



@Component({
  selector: "app-otv-page",
  templateUrl: "./otv-page.component.html",
  styleUrls: ["./otv-page.component.scss"],
})
export class OtvPageComponent implements OnInit {

  title = "Offre de terre valorisables";
  myOtv: OTV[];
  offerStatus: LexOfferStatus[];
  currentUser: User;

  totalOtvs: number;
  itemsPerPage: number;
  page: any;
  pageSizeOptions = [10, 20, 30, 40, 50];
  sortColumn: string;
  sortOrder: string;

  filterStatus: any[];
  filterStatusExpired: boolean;
  filterDate: Date[];
  dateDebut: Date;
  dateFin: Date;
  filterSearch: string;

  public otvList = [];

  fr: any = calendar_fr;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private lexiqueService: LexiqueService,
    private otvService: OtvService,
    private messageService: MessageService,
    private userService: UserService
  ) {
    this.currentUser = new User();
    this.totalOtvs = 0;
    this.page = 0;
    this.itemsPerPage = this.pageSizeOptions[0];
    this.sortColumn = "creationDate";
    this.offerStatus = [];
    this.filterStatusExpired = false;
    this.filterSearch = "";
    this.filterStatus = [];
  }

  ngOnInit() {
    this.activatedroute.data.subscribe((data) => this.titleService.setTitle(data.title));
    this.lexiqueService.getLexOfferStatus().subscribe((res) => (this.offerStatus = res));
    this.userService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  addFilterStatus(status: LexOfferStatus) {
    if (!_.includes(this.filterStatus, status.id)) {
      this.filterStatus.push(status.id);
    } else {
      let index = _.indexOf(this.filterStatus, status.id);
      if (index != -1) {
        this.filterStatus.splice(index, 1);
      }
    }
    this.search();
  }

  addExpired() {
    this.search();
  }

  loadLazy(event) {
    this.page = event.first / this.itemsPerPage;
    if (event.sortField) {
      this.sortColumn = event.sortField;
    }

    if (event.sortOrder === 1) {
      this.sortOrder = "ASC";
      this.page = 0;
    } else {
      this.sortOrder = "DESC";
      this.page = 0;
    }
    this.search();
  }

  search() {
    let criteria: Criteria = new Criteria();
    criteria.queryItem = this.filterSearch;
    criteria.statusIds = this.filterStatus;
    criteria.date = this.filterDate;
    criteria.isExpired = this.filterStatusExpired;
    this.otvService
      .getOtvs(criteria, {
        page: this.page,
        size: this.itemsPerPage,
        sort: this.sortColumn + "," + this.sortOrder,
      })
      .subscribe(
        (res) => this.onSuccess(res),
        (res) => this.onError(res)
      );
  }

  private onSuccess(data) {
    this.myOtv = data.otv;
    this.totalOtvs = data.maxOTV;
  }
  private onError(error: any) {
    this.messageService.error("otv", error);
  }

  load(event) {
    //this.router.navigate();
  }

  duplicate(id) {
    this.otvService.duplicate(id).subscribe((res) => {
      this.search();
    });
  }

  publishOTV(otv: OTV) {
    var tempOtv: OTV = otv;
    tempOtv.lexOfferStatus = this.offerStatus.find(
      (offer) => offer.label === "Publiée"
    );
    this.otvService.publishOTV(tempOtv).subscribe((res) => {
      this.search();
    });
  }

  closeOTV(otv: OTV) {
    var tempOtv: OTV = otv;
    tempOtv.lexOfferStatus = this.offerStatus.find(
      (offer) => offer.label === "Clôturée"
    );
    this.otvService.closeOTV(tempOtv).subscribe((res) => {
      this.search();
    });
  }

  pageChange(event) {
    this.page = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.search();
  }

  handleDate() {
    this.filterDate = [null, null];

    if (this.dateDebut) {
      this.filterDate[0] = this.dateDebut;
    }
    if (this.dateFin) {
      this.filterDate[1] = this.dateFin;
    }

    this.search();
  }

  isModifiable(offer): boolean {
    return (offer.lexOfferStatus.id != 3) && this.currentUserCanUpdate(offer);
  }

  isPubliable(offer): boolean {
    return (offer.lexOfferStatus.id == 1) && this.currentUserCanUpdate(offer);
  }

  isCloturable(offer): boolean {
    return (offer.lexOfferStatus.id != 3) && this.currentUserCanUpdate(offer);
  }

  isOwner(offer: OTV): boolean {
    return (this.currentUser.id === offer.user.id);
  }

  currentUserCanUpdate(offer: OTV): boolean {
    return offer.currentUserCanUpdate;
  }
}
