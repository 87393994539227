<div class="process-section-body" appUseParentForm>
    <div class="form-fieldset">
        <div class="form-fieldset-header">
            <h3 class="h3 form-fieldset-title">Site <span class="red">*</span></h3>
        </div>
        <div class="form-group">
            <!-- Site -->
            <label class="form-item form-item--6">
                <span class="form-label">Choisissez un site dans la liste ci-dessous</span>
                <span class="form-select">
                    <select type="number" [(ngModel)]="selectedSiteId" required class="form-select-inner" id="site" name="site"
                        #site="ngModel" [ngClass]="{'red-border-class': site.errors && parentFormSubmitted}" (ngModelChange)="toNumber()">
                        <option *ngFor="let site of sites" [ngValue]="site.id">{{site.name}},
                            {{site.address.numberStreet}}, {{site.address.zipCode}}
                            {{site.address.foreignCity}}</option>
                    </select>
                </span>
                <div *ngIf="(site.errors && parentFormSubmitted) || (!site.pristine && !site.valid)" class="red">
                    Le choix d'un site est obligatoire.
                </div>
            </label>

            <div class="form-item form-item--12">
                <div class="form-group">
                    <div class="form-item">
                        <span class="form-label">Venez-vous d’une ICPE ?</span>
                    </div>
                    <div class="form-item">
                        <label class="form-radio">
                            <input type="radio" name="icpe" class="form-radio-input" [(ngModel)]="isIcpe"
                                [value]="true">
                            <span class="form-radio-label">Oui</span>
                        </label>
                    </div>
                    <div class="form-item">
                        <label class="form-radio">
                            <input type="radio" name="icpe" class="form-radio-input" [(ngModel)]="isIcpe"
                                [value]="false">
                            <span class="form-radio-label">Non</span>
                        </label>
                    </div>
                </div>
            </div>

            <label class="form-item form-item--12" *ngIf="isIcpe">
                <span class="form-label">Entrez la référence sur l’ancienne installation (référence du
                    lot de terre ou du BSTV)<span class="red">*</span></span>
                <span class="form-group">
                    <span class="form-item form-item--6">
                        <input [(ngModel)]="annonce.bstvIcpe" required type="text" name="" placeholder="N° 00000078"
                            class="form-input" id="bstvIcpe" name="bstvIcpe" #bstvIcpe="ngModel"
                            [ngClass]="{'red-border-class': bstvIcpe.errors && parentFormSubmitted}">
                    </span>
                </span>
                <div *ngIf="(bstvIcpe.errors && parentFormSubmitted) || (!bstvIcpe.pristine && !bstvIcpe.valid)"
                    class="red">
                    La référence sur l'ancienne installation est obligatoire.
                </div>
            </label>

        </div>
    </div>
</div>