import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessageModule } from '../00-shared/message/message.module';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { PrimengModule } from '../00-shared/primeng/primeng.module';
import { EntityComponent } from './entity/entity.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { UsersRoutingModule } from './users-routing.module';
import { UserPasswordChangedComponent } from './user-password-changed/user-password-changed.component';

@NgModule({
  declarations: [
    ProfilePageComponent,
    SettingsPageComponent,
    EntityComponent,
    UserPasswordChangedComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    PaginationModule,
    MessageModule,
    NgbModule,
  ],
  providers: []
})
export class UsersModule { }
