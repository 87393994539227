import { Deserializable } from '../Deserializable';

export class FondPedogeochimique implements Deserializable {
    constructor(
        public id?: number,
        public nom?: string,
        public visibiliteSiren?: string
    ) {}
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}