import { DTV } from './dtv.model';
import { LexGuideDechet } from './lex/lexGuideDechet.model';
import { LexTransactionStatus } from './lex/lextransactionstatus.model';
import { LexTransportMode } from './lex/lextransportmode.model';
import { LexUsage } from './lex/lexusage.model';
import { OTV } from './otv.model';
import { Transporter } from './transporter.model';
import { User } from './user.model';

export class Transaction {

  constructor(
    public id?: number,
    public bstrStartDate?: Date,
    public bstrEndDate?: Date,
    public closureDate?: Date,
    public creationDate?: Date,
    public closureDetails?: string,
    public finalQuantity?: number,
    public hydrotexDocId?: number,
    public planBuiltDocId?: number,
    public publicationDate?: Date,
    public quantity?: number,
    public receptionStart?: Date,
    public receptionEnd?: Date,
    public recommendations?: string,
    public sanitaryDocId?: number,
    public transporterId?: number,
    public offer?: OTV,
    public demand?: DTV,
    public lexUsage?: LexUsage,
    public lexTransactionStatus?: LexTransactionStatus,
    public lexGuideDechet?: LexGuideDechet,
    public lexTransportMode?: LexTransportMode,
    public transporters?: Transporter[],
    public isCreateurTransaction?: boolean,
    public currentUserCanUpdate?: boolean,
    public currentUserCanView?: boolean,
    public currentUserCanCancel?: boolean,
    public currentUserCanCreateBordereau?: boolean,
    public currentUserCanAccepteOrRefuse?: boolean,
    public user?: User
  ) {}

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
