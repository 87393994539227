import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from '../fr/brgm/common/guards/is-signed-in.guard';
import { CreateTransactionPageComponent } from './create-transaction-page/create-transaction-page.component';
import { EditTransactionPageComponent } from './edit-transaction-page/edit-transaction-page.component';
import { TransactionsPageComponent } from './transactions-page/transactions-page.component';
import { ViewTransactionPageComponent } from './view-transaction-page/view-transaction-page.component';

const transactionsRoutes: Routes = [
    {path: 'transactions', component: TransactionsPageComponent, data: {title: APP_PREFIX  + 'Transactions'}, canActivate: [ IsSignedInGuard] },
    {path: 'transactions/create/:type/:id', component: CreateTransactionPageComponent, data: {title: APP_PREFIX  + 'Création de transaction'}, canActivate: [ IsSignedInGuard] },
    {path: 'transactions/details/:id', component: ViewTransactionPageComponent, data: {title: APP_PREFIX  + 'Transaction'}, canActivate: [ IsSignedInGuard] },
    {path: 'transactions/edit/:id', component: EditTransactionPageComponent, data: {title: APP_PREFIX  + 'Modification de transaction'}, canActivate: [ IsSignedInGuard] },
];

@NgModule({
    imports: [
        RouterModule.forChild(transactionsRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class TransactionsRoutingModule { }