import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Map, View } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry, Polygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Modify, Snap } from 'ol/interaction';
import { Group, Image, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { ImageWMS, OSM, Vector as VectorSource, WMTS } from 'ol/source';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import { getDistance } from 'ol/sphere';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { MapService } from 'src/app/00-shared/map/map.service';
import { Parcelle } from 'src/app/00-shared/map/parcelle.model';
import { Sis } from 'src/app/00-shared/map/sis.model';
import { ApiAdresseService } from 'src/app/fr/brgm/common/http/api-adresse/api-adresse.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { SiteService } from 'src/app/fr/brgm/common/http/site/site.service';
import { Address } from 'src/app/fr/brgm/common/model/address.model';
import { Featurecollection } from 'src/app/fr/brgm/common/model/featurecollection.model';
import { LexSiteStatus } from 'src/app/fr/brgm/common/model/lex/lexsitestatus.model';
import { LexSiteType } from 'src/app/fr/brgm/common/model/lex/lexsitetype.model';
import { Site } from 'src/app/fr/brgm/common/model/site.model';
import { ModalSiteInvalidParcelsComponent } from 'src/app/modal/modal-site-invalid-parcels/modal-site-invalid-parcels.component';

@Component({
  selector: "app-create-site-page",
  templateUrl: "./create-site-page.component.html",
  styleUrls: ["./create-site-page.component.scss"],
})
export class CreateSitePageComponent implements OnInit {
  @ViewChild("form", { static: true }) createSiteForm: NgForm;
  @ViewChild("divToModalClick") divToModalClick: ElementRef;

  currentStep: number;
  progress: number;
  site: Site = new Site();
  adresses: Featurecollection;
  requete: string;
  siteStatus: LexSiteStatus[];
  siteType: LexSiteType[];
  /**
   * Objet Ol Map
   */
  private _map: Map = null;
  pointsSource: VectorSource;
  source: VectorSource;
  vector: VectorLayer<VectorSource>;
  pointsLayer: VectorLayer<VectorSource>;
  draw: Draw;
  view: View = new View();
  pointCoordinates: Coordinate;
  polygonGeometry: Geometry;
  parcelles: Parcelle[] = [];
  sisIntersectes: Sis[] = [];

  isSisCodeLoaded = true;

  constructor(
    private siteService: SiteService,
    private lexService: LexiqueService,
    private apiAdresseService: ApiAdresseService,
    private router: Router,
    private mapService: MapService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.site.address = new Address();
    this.site.lexSiteStatus;
    this.progress = 0;
    this.currentStep = 1;
    this._createMap();
    this.view.setCenter([194455, 5916837]);
    this.view.setZoom(6);
    this.lexService
      .getLexSiteStatus()
      .subscribe((res) => (this.siteStatus = res));
    this.lexService.getLexSiteType().subscribe((res) => (this.siteType = res));
  }

  searchAdresse(event) {
    this.apiAdresseService
      .getAdresseByTexte(event.query)
      .subscribe((res) => (this.adresses = res));
  }

  doOnSelect(event) {
    this.site.address.numberStreet = event.properties.name;
    this.site.address.zipCode = event.properties.postcode;
    this.site.address.foreignCity = event.properties.city;
    let pointGeom: Geometry = new GeoJSON()
      .readGeometry(event.geometry)
      .transform("EPSG:4326", "EPSG:3857");
    this.pointCoordinates = event.geometry.coordinates;
    this.view.fit(pointGeom.getExtent());
    this.view.setZoom(18);
    this.view.setMaxZoom(20);
    this.view.setMinZoom(14);
  }

  _createMap() {
    var layer = new TileLayer({
      source: new OSM(),
    });

    this.source = new VectorSource();
    this.vector = new VectorLayer({
      source: this.source,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#0022ff",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#ffcc33",
          }),
        }),
      }),
    });

    this._map = new Map({
      //layers: [layer, this.vector],
      target: "map",
      view: this.view,
    });

    const osmMapStandard = new TileLayer({
      source: new OSM(),
      visible: true,
    });
    var resolutions = [
      156543.03392804103, 78271.5169640205, 39135.75848201024,
      19567.879241005125, 9783.939620502562, 4891.969810251281,
      2445.9849051256406, 1222.9924525628203, 611.4962262814101,
      305.74811314070485, 152.87405657035254, 76.43702828517625,
      38.218514142588134, 19.109257071294063, 9.554628535647034,
      4.777314267823517, 2.3886571339117584, 1.1943285669558792,
      0.5971642834779396, 0.29858214173896974, 0.14929107086948493,
      0.07464553543474241,
    ];

    const geoportailParcelles = new TileLayer({
      source: new WMTS({
        url: "https://wxs.ign.fr/parcellaire/geoportail/wmts",
        layer: "CADASTRALPARCELS.PARCELLAIRE_EXPRESS",
        matrixSet: "PM",
        format: "image/png",
        tileGrid: new WMTSTileGrid({
          origin: [-20037508, 20037508],
          resolutions: resolutions,
          matrixIds: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
        }),
        style: "PCI vecteur",
      }),
      visible: true,
    });

    const sisInfosols = new Image({
      source: new ImageWMS({
        params: {
          LAYERS: "ETAT_SIS",
        },
        url: "https://www.georisques.gouv.fr/services?request=GetCapabilities",
      }),
      visible: true,
      opacity: 0.5,
    });

    // Layer Group
    const baseLayeGroup = new Group({
      layers: [osmMapStandard, geoportailParcelles, sisInfosols, this.vector],
    });

    this._map.addLayer(baseLayeGroup);

    var modify = new Modify({ source: this.source });
    this._map.addInteraction(modify);
    this.addInteractions();
  }

  addInteractions() {
    this.draw = new Draw({
      source: this.source,
      type: GeometryType.POLYGON,
    });
    this.draw.on("drawend", (event) => this.onDrawPoint(event));
    var modifyDraw = new Modify({
      source: this.source,
    });
    modifyDraw.on("modifyend", (event) => this.onModifyPolygon(event));
    this._map.addInteraction(this.draw);
    this._map.addInteraction(modifyDraw);
    let snap = new Snap({ source: this.source });
    this._map.addInteraction(snap);
  }

  onModifyPolygon(event) {
    if (event.features.array_[0]) {
      let geom3857: Geometry = new Polygon(
        event.features.array_[0].getGeometry().getCoordinates()
      );
      let geom4326 = geom3857.transform("EPSG:3857", "EPSG:4326");
      this.site.geometry = new GeoJSON().writeGeometryObject(geom4326);
      this.polygonGeometry = geom4326;
      this.getParcelles();
      this.getSis();
    }
  }

  onDrawPoint(event) {
    if (event.feature) {
      let geom3857: Geometry = new Polygon(
        event.feature.getGeometry().getCoordinates()
      );
      let geom4326 = geom3857.transform("EPSG:3857", "EPSG:4326");
      this.site.geometry = new GeoJSON().writeGeometryObject(geom4326);
      this.polygonGeometry = geom4326;
      this.getParcelles();
      this.getSis();

      this._map.removeInteraction(this.draw);
    }
  }

  getParcelles() {
    this.mapService.getParcelles(this.site).subscribe((res: Parcelle[]) => {
      if (res) {
        this.parcelles = res;
      } else {
        this.parcelles = [];
      }
    });
  }

  getSis() {
    this.isSisCodeLoaded = false;
    this.mapService.getSis(this.site).subscribe((sisArray: Sis[]) => {
      this.isSisCodeLoaded = true;
      if (sisArray) {
        this.sisIntersectes = sisArray;
      } else {
        this.sisIntersectes = [];
      }
    });
  }

  resetDraw() {
    this.parcelles = [];
    this.sisIntersectes = [];
    this.site.geometry = undefined;
    this.vector.getSource().clear();

    var modify = new Modify({ source: this.source });
    this._map.addInteraction(modify);
    this.addInteractions();
  }

  suivant(): void {
    if (this.currentStep < 2) {
      this.currentStep++;
      this.progress = 50;
    }
  }

  createSite(): void {
    if (this.createSiteForm.form.status == "VALID" && this.site.geometry && this.isSisCodeLoaded) {
      this.site.parcelles = this.parcelles;
      this.site.sisIntersectes = this.sisIntersectes;
      if (this.checkPolygonConsistency()) { // On vérifie que le polygone est cohérent avec l'adresse renseignée par l'utilisateur
        this.siteService.create(this.site).subscribe(
          (_res) => this.router.navigate(["sites"]),
          (error) => console.error(error)
        );
      } else { // Le polygone n'est pas cohérent avec l'adresse renseignée, on demande à l'utilisateur si c'est voulu
        const modalRef = this.modalService.open(
          ModalSiteInvalidParcelsComponent,
          { size: "sm" }
        );
        modalRef.componentInstance.site = this.site;
        modalRef.componentInstance.isCreation = true;

        this.divToModalClick.nativeElement.click(); // Permet de forcer l'affichage de la modal

        modalRef.result
          .then((_result) => this.router.navigate(["/sites"]))
          .catch((error) => console.error(error));
      }
    }
  }

  private checkPolygonConsistency(): boolean {
    let polygonIsValid = this.polygonGeometry.intersectsCoordinate(this.pointCoordinates); // détermine si l’adresse renseignée est comprise dans le polygone

    if (!polygonIsValid) {
      console.info("L’adresse renseignée n'est pas comprise dans le polygone de base => on vérifie si le centre du polygone n'est pas à plus de 250m de l'adresse");

      let polygonCenter = getCenter(this.polygonGeometry.getExtent());
      let polygonCenterToAddressDistance = getDistance(polygonCenter, this.pointCoordinates);
      let metterDistance = Math.round(polygonCenterToAddressDistance * 100) / 100;

      console.info(`Distance entre le centre du polygone et l'adresse renseignée : ${metterDistance}m`);
      polygonIsValid = (metterDistance < 251);
    }

    return polygonIsValid;
  }

  deleteParcelle(parcel: Parcelle) {
    const index: number = this.parcelles.indexOf(parcel);
    if (index !== -1) {
      this.parcelles.splice(index, 1);
    }
  }

  deleteSisIntersectes(sis: Sis) {
    const index: number = this.sisIntersectes.indexOf(sis);
    if (index !== -1) {
      this.sisIntersectes.splice(index, 1);
    }
  }
}
