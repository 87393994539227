<br/>
<div class="form-fieldset">
    <div *ngIf="!readOnly" class="form-fieldset-header">
        <h3 class="h4 form-fieldset-title">{{fieldsetTitle}}</h3>
        <hr class="form-fieldset-line">
        <label [ngClass]="disabled || (_files.length >= fileLimit) ? 'opacite button button--grey' : 'button button--blue'">
            <i class="button-icon icon-add" aria-hidden="true"></i>
            <span class="button-label">Ajouter un document</span>
            <input [disabled]="disabled || (_files.length >= fileLimit)" type="file" accept=".pdf,.jpg,.jpeg,.bmp,.png" id="{{uploadFileId}}" class="button-file" (change)="selectFile($event.target.files)" multiple>
        </label>
    </div>

    <div *ngIf="_files.length >= fileLimit" class="alert alert-info">
      Vous avez atteint la limite de document possible à uploader ({{ fileLimit }} fichiers au maximum sont autorisés pour cette section)
    </div>

    <div *ngIf="fileTypeErrors.length > 0 || fileSizeErrors.length > 0" class="alert alert-danger">
      <div *ngFor="let fileName of fileTypeErrors">
        {{fileName}} : l'application n'autorise que les fichiers pdf, jpeg, bmp ou png
      </div>
      <div *ngFor="let fileName of fileSizeErrors">
        {{fileName}} : le fichier est trop volumineux (taille maximale autorisée : 10 Mo)
      </div>
    </div>

    <div class="form-group" *ngIf="_files.length !== 0">
        <div class="form-item form-item--6">
            <span class="form-document" *ngFor="let fic of _files; let i = index">
                <a (click)="downloadFile(fic.name)" download class="details-value"><i class="icon-pdf"></i>
                    {{fic.name}}</a>
                <button *ngIf="!readOnly" type="button" class="form-document-delete icon-trash-can-alt" (click)="removeFile(i)"></button>
            </span>
        </div>
    </div>

</div>
