import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const dateDebutGelValidator = (dateFinG: string, dateDebG: string): ValidatorFn =>

(control: AbstractControl): ValidationErrors | null => {

  if (control.get(dateFinG).value && control.get(dateDebG).value) {
    const dateFG: Date = new Date(control.get(dateFinG).value);
    dateFG.setHours(0,0,0,0);
    const dateDG: Date = new Date(control.get(dateDebG).value);
    dateDG.setHours(0,0,0,1);
    if(dateDG > dateFG) {
        return {dateDebutGelInvalide: true};
    }
  }

  return null;
};