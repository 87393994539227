import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from '../fr/brgm/common/guards/is-signed-in.guard';
import { CreateSitePageComponent } from './create-site-page/create-site-page.component';
import { DelegationCreatePageComponent } from "./delegations/delegation-create-page/delegation-create-page.component";
import { DelegationUpdatePageComponent } from "./delegations/delegation-update-page/delegation-update-page.component";
import { EditSitePageComponent } from './edit-site-page/edit-site-page.component';
import { SitesDetailsComponent } from './sites-details/sites-details.component';
import { SitesPageComponent } from './sites-page/sites-page.component';

const sitesRoutes: Routes = [
    { path: 'sites', component: SitesPageComponent, data: {title: APP_PREFIX  + 'sites'}, canActivate: [ IsSignedInGuard] },
    { path: 'sites/create', component: CreateSitePageComponent, data: {title: APP_PREFIX  + 'Création de site'}, canActivate: [ IsSignedInGuard] },
    { path: 'sites/details/:id', component: SitesDetailsComponent, data: {title: APP_PREFIX  + 'Consultation de site'}, canActivate: [ IsSignedInGuard] },
    { path: 'sites/edit/:id', component: EditSitePageComponent, data: {title: APP_PREFIX  + 'Modification de site'}, canActivate: [ IsSignedInGuard] },
    { path: 'sites/delegations/create/:idSite', component: DelegationCreatePageComponent, data: {title: APP_PREFIX  + 'Mise en délégations 2'}, canActivate: [ IsSignedInGuard] },
    { path: 'sites/delegation/update/:delegationId', component: DelegationUpdatePageComponent, data: {title: APP_PREFIX  + 'Modifier une délégation 2'}, canActivate: [ IsSignedInGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(sitesRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SitesRoutingModule { }
