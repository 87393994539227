<div class="container" *ngIf="site">

  <div class="page-sticky">
    <a [routerLink]="'/sites'" class="button button--outline button--teal">
      <i class="button-icon icon-left" aria-hidden="true"></i>
      <span class="button-label">Retour</span>
    </a>

    <div class="page-header">
      <h1 class="h1">Consultation du site</h1>

      <ul class="page-actions">
        <li class="page-actions-item">
          <a class="button button--red" style="color: #FFF"
            *ngIf="site.lexSiteStatus?.label === 'Ouvert' && !site.hasOfferDemandNotClosed && site.currentUserCanCloseOrReopen"
            (click)="fermer(site)">
            <i class="button-icon icon-open-folder" aria-hidden="true"></i>
            <span class="button-label">Fermer</span>
          </a>
          <a class="button button--green" style="color: #FFF"
            *ngIf="site.lexSiteStatus?.label === 'Fermé' && site.currentUserCanCloseOrReopen" (click)="rouvrir(site)">
            <i class="button-icon icon-open-folder" aria-hidden="true"></i>
            <span class="button-label">Rouvrir</span>
          </a>
        </li>

        <li class="page-actions-item"
          *ngIf="site.lexSiteStatus?.label === 'Ouvert' && !site.hasOfferDemandNotClosed && site.currentUserCanUpdate">
          <a [routerLink]="['/sites/edit/',site.id]" class="button button--orange">
            <i class="button-icon icon-edit" aria-hidden="true"></i>
            <span class="button-label">Modifier</span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="details">
    <div class="details-header">
      <h2 class="details-title h1">{{site.name}}</h2>
    </div>

    <div class="details-body">
      <div class="details-section details-section--opened">
        <div class="details-section-header">
          <i class="details-section-icon icon-pin" aria-hidden="true"></i>
          <h3 class="details-section-title">Localisation du site</h3>
        </div>
        <div class="details-section-body">
          <div class="form-group">
            <div class="form-item form-item--12">
              <div class="details-contact">
                <h3 class="details-contact-title">{{site.name}}</h3>
                <p class="details-contact-line">{{site.address?.numberStreet}}, {{site.address?.zipCode}}
                  {{site.address?.foreignCity}}</p>
              </div>
            </div>

            <div class="form-item form-item--12">
              <app-map-view [site]="site"></app-map-view>
            </div>

            <div class="form-item form-item--12" *ngIf="site.parcelles?.length !== 0">
              <p class="form-label">Parcelles intersectées par ce site</p>
              <table class="table">
                <thead>
                  <th>ID</th>
                  <th>Depart.</th>
                  <th>Code</th>
                  <th>Ville</th>
                  <th>Section</th>
                  <th>Numero</th>
                  <th>Feuille</th>
                </thead>
                <tbody>
                  <tr style="text-align: center;" *ngFor="let parcel of site.parcelles">
                    <td>{{parcel.identifiant}}</td>
                    <td>{{parcel.codeDepartement}}</td>
                    <td>{{parcel.codeCommune}}</td>
                    <td>{{parcel.nomCommune}}</td>
                    <td>{{parcel.section}}</td>
                    <td>{{parcel.numero}}</td>
                    <td>{{parcel.feuille}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="form-item form-item--12" *ngIf="site.sisIntersectes?.length !== 0">
              <p class="form-label">SIS intersectés par ce site</p>
              <table class="table">
                <thead>
                  <th>Code</th>
                </thead>
                <tbody>
                  <tr style="text-align: center;" *ngFor="let sis of site.sisIntersectes">
                    <td>{{sis.code}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Informations complémentaires -->
            <div class="form-item form-item--9" *ngIf="site.comment">
              <span class="details-label">Informations complémentaires</span>
              <p class="details-value">{{ site.comment }}</p>
            </div>

          </div>
        </div>
      </div>

      <div class="details-section details-section--opened">
        <div class="details-section-header">
          <i class="details-section-icon icon-folder" aria-hidden="true"></i>
          <h3 class="details-section-title">Offres liées à ce site</h3>
        </div>
        <div class="details-section-body" *ngIf="otvs?.maxOTV === 0">
          <p>Aucune offre n'est liée à ce site.</p>
        </div>
        <div class="details-section-body" *ngIf="otvs?.maxOTV !== 0">
          <table class="table">
            <thead>
              <tr>
                <th class="table-icon"></th>
                <th>OTV</th>
                <th>Date de création</th>
                <th>Quantité (t)</th>
                <th>Disponibilité</th>
                <th>Statut</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let otv of otvs?.otv">

                <td class="table-icon">
                  <i [class]="!otv.public_?'red icon-padlock has-tooltip':'green icon-open-padlock has-tooltip'" aria-hidden="true">
                    <span [class]="!otv.public_?'tooltip tooltip--red':'tooltip tooltip--green'">{{!otv.public_ ?
                      "Demande Privée":"Demande Publique"}}</span>
                  </i>
                </td>
                <td>
                  <a [routerLink]="['/offres/details',otv.id]" routerLinkActive="active"
                    class="table-link">{{otv?.id}}</a>
                </td>
                <td>{{otv?.creationDate|date:'dd/MM/yy'}}</td>
                <td>{{otv?.quantity}}</td>
                <td>
                  <time datetime="" class="table-tag">{{otv?.availStartDate|date:'dd/MM/yy'}} -
                    {{otv?.availEndDate|date:'dd/MM/yy'}}</time>
                </td>
                <td>
                  <b [ngClass]="{
                                        'teal': otv?.lexOfferStatus.label.toLowerCase() === 'en brouillon',
                                        'green': otv?.lexOfferStatus.label.toLowerCase() === 'publiée',
                                        'orange': otv?.lexOfferStatus.label.toLowerCase() === 'échue',
                                        'black': otv?.lexOfferStatus.label.toLowerCase() === 'clôturée'
                                    }">{{otv?.lexOfferStatus.label}}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="details-section details-section--opened">
        <div class="details-section-header">
          <i class="details-section-icon icon-folder" aria-hidden="true"></i>
          <h3 class="details-section-title">Demandes liées à ce site</h3>
        </div>
        <div class="details-section-body" *ngIf="dtvs?.maxDTV === 0">
          <p>Aucune demande n'est liée à ce site.</p>
        </div>

        <div class="details-section-body" *ngIf="dtvs?.maxDTV !== 0">
          <table class="table">
            <thead>
              <tr>
                <th class="table-icon"></th>
                <th>DTV</th>
                <th>Date de création</th>
                <th>Quantité (t)</th>
                <th>Disponibilité</th>
                <th>Statut</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dtv of dtvs?.dtv">

                <td class="table-icon">
                  <i [class]="!dtv.public_?'red icon-padlock has-tooltip':'green icon-open-padlock has-tooltip'" aria-hidden="true">
                    <span [class]="!dtv.public_?'tooltip tooltip--red':'tooltip tooltip--green'">{{!dtv.public_ ?
                      "Demande Privée":"Demande Publique"}}</span>
                  </i>
                </td>
                <td>
                  <a [routerLink]="['/demandes/details',dtv?.id]" routerLinkActive="active"
                    class="table-link">{{dtv?.id}}</a>
                </td>
                <td>{{dtv?.creationDate|date:'dd/MM/yy'}}</td>
                <td>{{dtv?.quantity}}</td>
                <td>
                  <time datetime="" class="table-tag">{{dtv?.availStartDate|date:'dd/MM/yy'}} -
                    {{dtv?.availEndDate|date:'dd/MM/yy'}}</time>
                </td>
                <td>
                  <b [ngClass]="{
                                        'teal': dtv?.lexDemandStatus.label.toLowerCase() === 'en brouillon',
                                        'green': dtv?.lexDemandStatus.label.toLowerCase() === 'publiée',
                                        'orange': dtv?.lexDemandStatus.label.toLowerCase() === 'échue',
                                        'black': dtv?.lexDemandStatus.label.toLowerCase() === 'clôturée'
                                    }">{{dtv?.lexDemandStatus.label}}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="details-section details-section--opened">
        <div class="details-section-header">
          <i class="details-section-icon icon-file" aria-hidden="true"></i>
          <h3 class="details-section-title">BSTV liés à ce site</h3>
        </div>
        <div class="details-section-body" *ngIf="bstvs?.length === 0">
          <p>Aucun BSTV n'est liée à ce site.</p>
        </div>
        <div class="details-section-body" *ngIf="bstvs?.length !== 0">
          <table class="table">
            <thead>
              <tr>
                <th>Bordereau</th>
                <th class="table-sortable">Date de création</th>
                <th class="table-sortable">DTV</th>
                <th class="table-sortable">Site receveur</th>
                <th class="table-sortable">OTV</th>
                <th class="table-sortable">Site producteur</th>
                <th>Statut</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bstv of bstvs">
                <td>
                  <a [routerLink]="['/bordereaux/details',bstv?.id]" routerLinkActive="active"
                    class="table-link">{{bstv?.id}}</a>
                </td>
                <td>{{bstv?.dateCreation | date:'dd/MM/yy'}}</td>
                <td>
                  <a [routerLink]="['/demandes/details',bstv?.transaction?.demand?.id]" routerLinkActive="active"
                    class="table-link">{{bstv?.transaction?.demand?.id}}</a>
                </td>
                <td>
                  <p>{{bstv?.demandeurNomSite}}</p>
                </td>
                <td>
                  <a [routerLink]="['/offres/details',bstv?.transaction?.offer?.id]" routerLinkActive="active"
                    class="table-link">{{bstv?.transaction?.offer?.id}}</a>
                </td>
                <td>
                  <p>{{bstv?.offreurNomSite}}</p>
                </td>
                <td>
                  <b [ngClass]="{'orange': bstv?.lexBordereauStatus.label.toLowerCase() === 'en cours',
                                        'black': bstv?.lexBordereauStatus.label.toLowerCase() === 'clôturée'}">
                    {{bstv?.lexBordereauStatus.label}}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="details-section details-section--opened" *ngIf="delegations">
        <div class="details-section-header">
          <i class="details-section-icon icon-folder" aria-hidden="true"></i>
          <h3 class="details-section-title">Délégations</h3>
        </div>
        <div class="details-section-body">

          <div *ngIf="site.lexSiteStatus?.label === 'Ouvert' && site.currentUserCanDelegate" class="form-group">
            <div class="form-item form-item--12">
              <a [routerLink]="['/sites/delegations/create/', site.id]" class="button button--green" [class.disabled]="!site.currentUserCanDelegate">
                <i class="button-icon icon-add" aria-hidden="true"></i>
                <span class="button-label">Ajouter une délégation</span>
              </a>
              <span *ngIf="!site.currentUserCanDelegate">&nbsp;Vous êtes en délégation sur ce site, vous ne pouvez pas le déléguer à votre tour</span>
            </div>
          </div>

          <br>

          <p *ngIf="delegations.length === 0">
            Aucune délégation n'est liée à ce site.
          </p>

          <table *ngIf="delegations.length !== 0" class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Siren</th>
                <th>Raison sociale</th>
                <th>Personnes bénéficiaires</th>
                <th>Date de début</th>
                <th>Date d'expiration</th>
                <th>Date début gel</th>
                <th>Date fin gel</th>
                <th>Statut</th>
                <th *ngIf="hasDelegateActions()">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let delegation of delegations">

                <td>{{ delegation.id }}</td>
                <td>{{ delegation.entityObject?.siret }}</td>
                <td>{{ delegation.entityObject?.socialReason }}</td>
                <td>{{ delegation.refDelegation }}</td>
                <td>{{ delegation.startDate | date:'dd/MM/yy' }}</td>
                <td>{{ delegation.expirationDate | date:'dd/MM/yy' }}</td>
                <td>{{ delegation.dateDebutGel | date:'dd/MM/yy' }}</td>
                <td>{{ delegation.dateFinGel | date:'dd/MM/yy' }}</td>
                <td>{{ delegation.lexDelegationStatus?.label }}</td>
                <td *ngIf="hasDelegateActions()">
                  <div *ngIf="delegation.lexDelegationStatus?.id === 1 || delegation.lexDelegationStatus?.id === 2" class="table-actions">
                    <input type="checkbox" id="table-actions" class="table-actions-input">
                    <label for="table-actions" class="table-actions-toggle"></label>
                    <ul class="table-actions-list">
                      <li class="table-actions-item">
                        <a [routerLink]="['/sites/delegation/update/', delegation.id]" class="table-actions-link orange">
                          <i class="table-actions-icon icon-edit" aria-hidden="true"></i>
                          <span class="table-actions-label">Modifier</span>
                        </a>
                      </li>
                      <li class="table-actions-item">
                        <a class="table-actions-link black" (click)="closeDelegation(delegation)">
                          <i class="table-actions-icon icon-open-folder" aria-hidden="true"></i>
                          <span class="table-actions-label">Clôturer</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
