<div class="table-controls">
    <div class="table-controls-item">
        <label class="table-results">
            <span class="table-results-label">Résultats par page</span>
            <span class="table-results-wrapper">
                <select (change)="changeSize($event)" class="table-results-select">
                    <option *ngFor="let option of pageSizeOptions" [value]="option">{{ option }}</option>
                </select>
            </span>
        </label>
    </div>

    <div class="table-controls-item">
        <div class="table-pagination">
            <button class="table-pagination-button" [disabled]="index + 1 === 1 || pages.length === 1" (click)="changePage(index - 1)">Précédent</button>
            <button class="table-pagination-button" [ngClass]="{ 'table-pagination-button--current': index + 1 === pages[0] }" (click)="changePage(pages[0] - 1)">{{ pages[0] }}</button>
            <button class="table-pagination-button" [ngClass]="{ 'table-pagination-button--current': index + 1 === pages[1] }" (click)="changePage(pages[1] - 1)" *ngIf="pageNumber > 1">{{ pages[1] }}</button>
            <button class="table-pagination-button" [ngClass]="{ 'table-pagination-button--current': index + 1 === pages[2] }" (click)="changePage(pages[2] - 1)" *ngIf="pageNumber > 2">{{ pages[2] }}</button>
            <button class="table-pagination-button" [ngClass]="{ 'table-pagination-button--current': index + 1 === pages[3] }" (click)="changePage(pages[3] - 1)" *ngIf="pageNumber > 3">{{ pages[3] }}</button>
            <button class="table-pagination-button" [ngClass]="{ 'table-pagination-button--current': index + 1 === pages[4] }" (click)="changePage(pages[4] - 1)" *ngIf="pageNumber > 4">{{ pages[4] }}</button>
            <button class="table-pagination-button" [disabled]="index + 1 === pageNumber" (click)="changePage(index + 1)">Suivant</button>
        </div>
    </div>
</div>