import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityObject } from 'src/app/fr/brgm/common/model/entityobject.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntityObjectService {

  private entityUrl =  environment.apiUrl + 'entity';
  private SEPARATOR =   '/';

  constructor(private http: HttpClient) { }

  getEntityObject(siren: string): Observable<EntityObject> {
    return this.http.get( this.entityUrl + this.SEPARATOR + 'findsiren' + this.SEPARATOR + siren).pipe(map((res:EntityObject) => res));
  }

  updateEntityObject(entityobject: EntityObject): Observable<EntityObject> {
    return this.http.put(`${this.entityUrl}/update`, entityobject).pipe(map((entity: EntityObject) => entity));
  }

  getBySiretStartsWith(siret: string): Observable<EntityObject[]> {
    return this.http.get(this.entityUrl + this.SEPARATOR + 'getBySiretStartsWith' + this.SEPARATOR + siret).pipe(map((res:EntityObject[])=> res));
  }

}
