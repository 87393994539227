import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Criteria } from 'src/app/fr/brgm/common/http/model/criteria.model';
import { SiteService } from 'src/app/fr/brgm/common/http/site/site.service';
import { ListSite } from 'src/app/fr/brgm/common/model/listsite.model';
import { OTV } from 'src/app/fr/brgm/common/model/otv.model';
import { Site } from 'src/app/fr/brgm/common/model/site.model';

@Component({
  selector: 'app-localisation',
  templateUrl: './localisation.component.html',
  styleUrls: ['./localisation.component.scss']
})
export class LocalisationComponent implements OnInit {

  @Input() annonce: OTV;
  @Output() onSiteChange = new EventEmitter<any>();
  sites: Site[];
  isIcpe: boolean;

  @Input() parentFormSubmitted: boolean;

  selectedSiteId: number;


  constructor(private siteService: SiteService) { }

  ngOnInit() { 
    this.selectedSiteId = this.annonce.site?.id;
    this.isIcpe = false;
    this.siteService.getAll(new Criteria(), {
			page: 0,
			sort: "name,ASC"
		}).subscribe(
			(res: ListSite) => this.sites = res.site
		);
  }

  
  toNumber () {
    this.selectedSiteId = +this.selectedSiteId;
    let selectedSite = this.sites.find(s => s.id === this.selectedSiteId);
    this.annonce.site = selectedSite;
    this.onSiteChange.emit(selectedSite);
  }

}
