import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapModule } from '../00-shared/map/map.module';
import { MessageModule } from '../00-shared/message/message.module';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { PrimengModule } from '../00-shared/primeng/primeng.module';
import { CreateSitePageComponent } from './create-site-page/create-site-page.component';
import { EditSitePageComponent } from './edit-site-page/edit-site-page.component';
import { SitesDetailsComponent } from './sites-details/sites-details.component';
import { SitesPageComponent } from './sites-page/sites-page.component';
import { SitesRoutingModule } from './sites-routing.module';
import { DelegationFormComponent } from './delegations/delegation-form/delegation-form.component';
import { DelegationCreatePageComponent } from './delegations/delegation-create-page/delegation-create-page.component';
import { DelegationUpdatePageComponent } from './delegations/delegation-update-page/delegation-update-page.component';


@NgModule({
  declarations: [
    SitesPageComponent,
    CreateSitePageComponent,
    EditSitePageComponent,
    SitesDetailsComponent,
    DelegationFormComponent,
    DelegationCreatePageComponent,
    DelegationUpdatePageComponent
  ],
  imports: [
    CommonModule,
    SitesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    MapModule,
    PrimengModule,
    MessageModule
  ],
  providers: []
})
export class SitesModule { }
