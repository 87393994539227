<div class="container">
    <div class="page-header">
        <h1 class="h1">Mes Offres de Terres Valorisables</h1>
    </div>
    <div class="table-wrapper">
        <div class="table-filters">
            <span class="table-filters-results">
                <i class="table-filters-results-icon icon-folder" aria-hidden="true"></i>
                {{totalOtvs}} Offres
            </span>

            <ul class="table-filters-list">
                <li class="table-filters-item" *ngFor="let status of offerStatus">
                    <label class="table-filters-button has-tooltip">
                        <input type="checkbox" class="table-filters-input" (change)="addFilterStatus(status)">
                        <span class="table-filters-label">{{status.label}}</span>

                        <span class="tooltip tooltip--center">Filtrer par offres {{status.label}}</span>
                    </label>
                </li>
            </ul>

            <div class="table-filters-search">
                <label class="table-filters-search-item">
                    <p-calendar [(ngModel)]="dateDebut" dateFormat="dd/mm/yy"
                        [showTransitionOptions]="'5ms'" [hideTransitionOptions]="'5ms'" [showIcon]="true"
                        [showButtonBar]="true" (ngModelChange)="handleDate()" styleClass="table-filters-search-input"
                        placeholder="Date début" >
                    </p-calendar>
                </label>
                <label class="table-filters-search-item">
                    <p-calendar [(ngModel)]="dateFin" dateFormat="dd/mm/yy"  [showTransitionOptions]="'5ms'"
                        [hideTransitionOptions]="'5ms'" [showIcon]="true" [showButtonBar]="true"
                        (ngModelChange)="handleDate()" styleClass="table-filters-search-input" placeholder="Date fin">
                    </p-calendar>
                </label>

                <label class="table-filters-search-item">
                    <input type="text" placeholder="Rechercher une référence, un site…"
                        class="table-filters-search-input" [(ngModel)]="filterSearch" (blur)="search()"
                        (keyup.enter)="search()">
                    <i class="table-filters-search-icon icon-search" aria-hidden="true"></i>
                </label>
            </div>
        </div>

        <p-table tableStyleClass="table" [resetPageOnSort]="false" [sortOrder]="2" [value]="myOtv"
            (onLazyLoad)="loadLazy($event)" [paginator]="false" [lazy]="true" [totalRecords]="totalOtvs" dataKey="id"
            [tableStyle]="{'table-layout':'auto'}">
            <ng-template pTemplate="header">
                <tr>
                    <th class="table-icon" scope="col"></th>
                    <th [pSortableColumn]="'id'" class="table-sortable" scope="col">OTV</th>
                    <th [pSortableColumn]="'creationDate'" class="table-sortable" scope="col">Date de création</th>
                    <th [pSortableColumn]="'site.name'" class="table-sortable" scope="col">Site</th>
                    <th [pSortableColumn]="'quantity'" class="table-sortable" scope="col">Quantité (t)</th>
                    <th [pSortableColumn]="'availEndDate'" class="table-sortable" scope="col">Disponibilité</th>
                    <th [pSortableColumn]="'lexOfferStatus.label'" class="table-sortable" scope="col">Statut</th>
                    <th scope="col">Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-otv>
                <tr>
                    <td class="table-icon">
                        <i [class]="!otv.public_?'red icon-padlock has-tooltip':'green icon-open-padlock has-tooltip'" aria-hidden="true">
                            <span
                                [class]="!otv.public_?'tooltip tooltip--red':'tooltip tooltip--green'">{{!otv.public_ ? "Demande Privée":"Demande Publique"}}</span>
                        </i>
                    </td>
                    <td>
                        <a [routerLink]="['/offres/details',otv.id]" routerLinkActive="active"
                            class="table-link">{{otv.id}}</a>
                        <!--<span class="beacon beacon--lightblue has-tooltip">
                            <span class="tooltip tooltip--lightblue">Une proposition de transaction a été faite sur
                                cette demande.</span>
                        </span>-->
                    </td>
                    <td>{{otv.creationDate|date:'dd/MM/yy'}}</td>
                    <td>
                        <h3 class="h3">{{otv.site.name}}</h3>
                        <p>{{otv.site.address.numberStreet}}, {{otv.site.address.cityInsee}}</p>
                    </td>
                    <td>{{otv.quantity}} t</td>
                    <td>
                        <time datetime="" class="table-tag">{{otv.availStartDate|date:'dd MMM yyyy'}} -
                            {{otv.availEndDate|date:'dd MMM yyyy'}}</time>
                    </td>
                    <td>
                        <strong [ngClass]="{
                            'teal': otv.lexOfferStatus.label.toLowerCase() === 'en brouillon',
                            'green': otv.lexOfferStatus.label.toLowerCase() === 'publiée',
                            'orange': otv.lexOfferStatus.label.toLowerCase() === 'échue',
                            'black': otv.lexOfferStatus.label.toLowerCase() === 'clôturée'
                        }">
                        {{otv.lexOfferStatus.label}}
                      </strong>
                    </td>
                    <td>
                        <div class="table-actions">
                            <input type="checkbox" [id]="'table-actions-'+ otv.id" class="table-actions-input">
                            <label [for]="'table-actions-'+ otv.id" class="table-actions-toggle"></label>
                            <ul class="table-actions-list">
                                <li class="table-actions-item">
                                    <a [routerLink]="['/offres/details',otv.id]" routerLinkActive="active"
                                        class="table-actions-link blue">
                                        <i class="table-actions-icon icon-eye" aria-hidden="true"></i>
                                        <span class="table-actions-label">Consulter</span>
                                    </a>
                                </li>
                                <li class="table-actions-item" *ngIf="isModifiable(otv)">
                                    <a [routerLink]="['/offres/edit',otv.id]" routerLinkActive="active"
                                        class="table-actions-link orange">
                                        <i class="table-actions-icon icon-edit" aria-hidden="true"></i>
                                        <span class="table-actions-label">Modifier</span>
                                    </a>
                                </li>
                                <li class="table-actions-item" *ngIf="isPubliable(otv)">
                                    <a class="table-actions-link red" (click)="publishOTV(otv)">
                                        <i class="table-actions-icon icon-edit" aria-hidden="true"></i>
                                        <span class="table-actions-label">Publier</span>
                                    </a>
                                </li>

                                <li class="table-actions-item" *ngIf="isCloturable(otv)">
                                    <a class="table-actions-link red" (click)="closeOTV(otv)">
                                        <i class="table-actions-icon icon-open-folder" aria-hidden="true"></i>
                                        <span class="table-actions-label">Clôturer</span>
                                    </a>
                                </li>
                                <li class="table-actions-item" *ngIf="otv.currentUserCanDuplicate">
                                    <a [routerLink]="['/offres/duplicate',otv.id]" routerLinkActive="active"
                                        class="table-actions-link orange">
                                        <i class="table-actions-icon icon-edit" aria-hidden="true"></i>
                                        <span class="table-actions-label">Dupliquer</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">
                        Aucune offre.
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <app-pagination [length]="totalOtvs" [index]="page" [size]="itemsPerPage" [pageSizeOptions]="pageSizeOptions"
            (page)="pageChange($event)"></app-pagination>

    </div>
</div>
