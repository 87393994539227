import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AnnouncementConfidentialAccess } from "../../model/announcement/announcement-confidential-access.model";
import { Announcement } from "../../model/announcement/announcement.model";
import { AnnouncementType } from "../../model/enums/announcement-type.enum";

@Injectable({
  providedIn: "root",
})
export class AnnouncementService {

  private announcementBaseUrl = environment.apiUrl + "announcement";

  constructor(private http: HttpClient) {}

  getConfidentialCode(announcementType: AnnouncementType, announcementId: number): Observable<string> {
    return this.http.get(`${this.announcementBaseUrl}/${announcementType}/${announcementId}/confidential-code`, {responseType: 'text'})
              .pipe(map((confidentialCode: string) => confidentialCode));
  }

  getConfidentialAccess(announcementConfidentialAccess: AnnouncementConfidentialAccess): Observable<Announcement> {
    return this.http.post(`${this.announcementBaseUrl}/confidential/access`, announcementConfidentialAccess).pipe(map((announcement: Announcement) => announcement));
  }

}
