import { Deserializable } from './Deserializable';

export class Password implements Deserializable {
    constructor(
        public password?: string,
    ) {}
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
