import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup} from '@angular/forms';
import { RndtsType } from 'src/app/07-bordereaux/rndts-page/rndts-page.component';
import { RndtsResponse } from 'src/app/fr/brgm/common/model/rndts/rndtsResponse.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-rndts-response',
  templateUrl: './modal-rndts-response.component.html',
  styleUrls: ['./modal-rndts-response.component.scss']
})
export class ModalRNDTSResponseComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  RndtsType = RndtsType;
  typeSelected: RndtsType;
  response : RndtsResponse;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router
    ) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  returnToBSTV(){
    this.activeModal.close();
    this.router.navigate(['/bordereaux']);
  }
}
