import { Deserializable } from '../Deserializable';

export class LexSiteType implements Deserializable {
    constructor(
        public id?: number,
        public label?: string,
    ) {}
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}