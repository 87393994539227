import { Deserializable } from "../Deserializable";

export class LexUserStatus implements Deserializable {

  static readonly PENDING = "EN ATTENTE";

  static readonly COLLABORATOR = "COLLABORATEUR";

  static readonly ADMIN = "ADMINISTRATEUR";

  static readonly SUPER_ADMIN = "GESTIONNAIRE";

  static readonly DISABLED = "DESACTIVE";

  constructor(public id?: number, public label?: string) {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
