import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import Group from 'ol/layer/Group';

import { Router } from '@angular/router';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry, Polygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Modify, Snap } from 'ol/interaction';
import { Image, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { ImageWMS, OSM, Vector as VectorSource, WMTS } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import View from 'ol/View';
import { Site } from 'src/app/fr/brgm/common/model/site.model';
import { MapService } from '../map.service';
import { Parcelle } from '../parcelle.model';
import { Sis } from '../sis.model';

@Component({
  selector: "app-map-edit-polygon",
  templateUrl: "./map-edit-polygon.component.html",
  styleUrls: ["./map-edit-polygon.component.scss"],
})
export class MapEditPolygonComponent implements OnInit {
  @Input() site: Site;

  @Output() onPolygonGeometryChange = new EventEmitter<any>();
  @Output() onPointCoordinatesChange = new EventEmitter<any>();
  @Output() onSisCodeLoadedChange = new EventEmitter<boolean>();

  vectorSource: VectorSource;
  vectorLayer: VectorLayer<VectorSource>;
  map: Map;
  draw: Draw;
  view: View = new View();
  pointCoordinates: Coordinate;

  isSisCodeLoaded = true;

  constructor(private router: Router, private mapService: MapService) {}

  ngOnInit() {
    this.initMap();
    this.getParcelles();
    this.getSis();
  }

  initMap() {
    var styles = {
      Polygon: new Style({
        stroke: new Stroke({
          color: "rgba(237,131,77,1)",
          width: 3,
        }),
        fill: new Fill({
          color: "rgba(237,131,77,.5)",
        }),
      }),
    };

    var styleFunction = function (feature) {
      return styles[feature.getGeometry().getType()];
    };

    var vectorFeature = new Feature();
    var geometry = new GeoJSON()
      .readGeometry(this.site.geometry)
      .transform("EPSG:4326", "EPSG:3857");
    vectorFeature.setGeometry(geometry);

    this.vectorSource = new VectorSource({
      features: [vectorFeature],
    });

    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
      style: styleFunction,
    });

    this.pointCoordinates = this._getCenterOfExtent(
      vectorFeature.getGeometry().getExtent()
    );

    this.view = new View({
      center: this.pointCoordinates,
      zoom: 18,
    });

    this.map = new Map({
      view: this.view,
      target: "site_edit_map",
    });

    const osmStandardLayer = new TileLayer({
      source: new OSM(),
      visible: true,
    });

    var resolutions = [
      156543.03392804103, 78271.5169640205, 39135.75848201024,
      19567.879241005125, 9783.939620502562, 4891.969810251281,
      2445.9849051256406, 1222.9924525628203, 611.4962262814101,
      305.74811314070485, 152.87405657035254, 76.43702828517625,
      38.218514142588134, 19.109257071294063, 9.554628535647034,
      4.777314267823517, 2.3886571339117584, 1.1943285669558792,
      0.5971642834779396, 0.29858214173896974, 0.14929107086948493,
      0.07464553543474241,
    ];

    const geoportailParcelles = new TileLayer({
      source: new WMTS({
        url: "https://wxs.ign.fr/parcellaire/geoportail/wmts",
        layer: "CADASTRALPARCELS.PARCELLAIRE_EXPRESS",
        matrixSet: "PM",
        format: "image/png",
        tileGrid: new WMTSTileGrid({
          origin: [-20037508, 20037508],
          resolutions: resolutions,
          matrixIds: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
        }),
        style: "PCI vecteur",
      }),
      visible: true,
    });

    const sisInfosols = new Image({
      source: new ImageWMS({
        params: {
          LAYERS: "ETAT_SIS",
        },
        url: "https://www.georisques.gouv.fr/services?request=GetCapabilities",
      }),
      visible: true,
      opacity: 0.5,
    });

    const baseLayeGroup = new Group({
      layers: [osmStandardLayer, geoportailParcelles, sisInfosols],
    });

    this.map.addLayer(baseLayeGroup);
    this.map.addLayer(this.vectorLayer);
    //this.map.getView().fit(vectorFeature.getGeometry().getExtent(), {padding: [100, 50, 50, 100]});

    var modify = new Modify({ source: this.vectorSource });
    this.map.addInteraction(modify);
    this.addInteractions();
  }

  onModifyPolygon(event) {
    if (event.features.array_[0]) {
      let geom3857: Geometry = new Polygon(
        event.features.array_[0].getGeometry().getCoordinates()
      );
      let geom4326 = geom3857.transform("EPSG:3857", "EPSG:4326");
      this.onPolygonGeometryChange.emit(geom4326);
      this.site.geometry = new GeoJSON().writeGeometryObject(geom4326);
      this.getParcelles();
      this.getSis();
    }
  }

  onDrawPoint(event) {
    if (event.feature) {
      let geom3857: Geometry = new Polygon(
        event.feature.getGeometry().getCoordinates()
      );
      let geom4326 = geom3857.transform("EPSG:3857", "EPSG:4326");
      this.site.geometry = new GeoJSON().writeGeometryObject(geom4326);
      this.onPolygonGeometryChange.emit(geom4326);
      this.getParcelles();
      this.getSis();
      this.map.removeInteraction(this.draw);
    }
  }

  resetDraw() {
    this.site.geometry = undefined;
    this.site.parcelles = [];
    this.site.sisIntersectes = [];
    this.vectorLayer.getSource().clear();
    var modify = new Modify({ source: this.vectorSource });
    this.map.addInteraction(modify);
    this.addInteractions();
  }

  addInteractions() {
    this.draw = new Draw({
      source: this.vectorSource,
      type: GeometryType.POLYGON,
    });
    this.draw.on("drawend", (event) => this.onDrawPoint(event));
    var modifyDraw = new Modify({
      source: this.vectorSource,
    });
    modifyDraw.on("modifyend", (event) => this.onModifyPolygon(event));
    this.map.addInteraction(this.draw);
    this.map.addInteraction(modifyDraw);
    let snap = new Snap({ source: this.vectorSource });
    this.map.addInteraction(snap);
  }

  _getCenterOfExtent(extent) {
    var X = (extent[0] + extent[2]) / 2;
    var Y = (extent[1] + extent[3]) / 2;
    return [X, Y];
  }

  getParcelles() {
    this.mapService.getParcelles(this.site).subscribe((res: Parcelle[]) => {
      if (res) {
        this.site.parcelles = res;
      } else {
        this.site.parcelles = [];
      }
    });
  }

  getSis() {
    this.isSisCodeLoaded = false;
    this.emitSisCodeLoaded();
    this.mapService.getSis(this.site).subscribe((sisArray: Sis[]) => {
      this.isSisCodeLoaded = true;
      this.emitSisCodeLoaded();
      if (sisArray) {
        this.site.sisIntersectes = sisArray;
      } else {
        this.site.sisIntersectes = [];
      }
    });
  }

  private emitSisCodeLoaded(): void {
    this.onSisCodeLoadedChange.emit(this.isSisCodeLoaded);
  }

  deleteParcelle(parcel: Parcelle) {
    const index: number = this.site.parcelles.indexOf(parcel);
    if (index !== -1) {
      this.site.parcelles.splice(index, 1);
    }
  }

  onAddressChange(event) {
    let pointGeom: Geometry = new GeoJSON()
      .readGeometry(event.geometry)
      .transform("EPSG:4326", "EPSG:3857");
    this.pointCoordinates = this._getCenterOfExtent(pointGeom.getExtent());
    this.onPointCoordinatesChange.emit(this.pointCoordinates);
    this.view.fit(pointGeom.getExtent());
    this.view.setZoom(18);
    this.view.setMaxZoom(20);
    this.view.setMinZoom(14);
  }

  deleteSisIntersectes(sis: Sis) {
    const index: number = this.site.sisIntersectes.indexOf(sis);
    if (index !== -1) {
      this.site.sisIntersectes.splice(index, 1);
    }
  }
}
