<div class="entry wrapper">
    <img src="assets/images/placeholders/login.png" alt="" class="entry-image">
    <div id="login-block" class="entry-content">
        <form class="entry-form" [formGroup]="loginForm" (ngSubmit)="seConnecter()" >

            <div class="entry-form-header">
                <h1 class="h1 entry-form-title">Se connecter</h1>
                <p>
                    Bienvenue sur l’outil TERRASS.<br>
                    Connectez-vous pour accéder à votre compte.
                </p>
            </div>

            <div class="entry-form-body">
              <div class="form-group">
                <label class="form-item form-item--12">
                  <span class="form-label">Email <span class="red">*</span></span>
                  <input formControlName="email" type="email" placeholder="Entrez votre adresse mail" class="form-input"
                    [ngClass]="{'invalid-input' : isInvalid('email') || mailError}">
                  <div *ngIf="isInvalid('email') || mailError" class="alert alert-danger">
                    <div *ngIf="getFormControl('email').errors?.required">
                      Adresse mail requise
                    </div>
                    <div *ngIf="getFormControl('email').errors?.email">
                      Merci de renseigner une adresse mail valide
                    </div>
                    <div *ngIf="mailError">
                      Cette adresse mail est associée à aucun compte
                    </div>
                  </div>
                </label>

                <label class="form-item form-item--12">
                  <span class="form-label">Mot de passe <span class="red">*</span></span>
                  <span class="form-password">
                    <input type="password" formControlName="password" placeholder="Entrez votre mot de passe"
                      class="form-password-input" [ngClass]="{'invalid-input' : isInvalid('password') || mailError}">
                    <span class="form-password-toggle"></span>
                  </span>
                  <div *ngIf="isInvalid('password') || passwordError" class="alert alert-danger">
                    <div *ngIf="getFormControl('password').errors?.required">
                      Mot de passe requis
                    </div>
                    <div *ngIf="passwordError">
                      Mot de passe erroné
                    </div>
                  </div>
                </label>

              </div>
            </div>

            <div class="entry-form-footer">
              <button class="button button--fullwidth button--blue">
                <i class="button-icon icon-silhouette" aria-hidden="true"></i>
                <span class="button-label">Se connecter</span>
              </button>
            </div>
        </form>

        <div class="entry-footer">
          <!-- Message pour un compte en attente de validation ou désactivé -->
          <div *ngIf="userStatusError" class="alert alert-warning">
            Votre compte est en attente de validation ou il a été désactivé par un administrateur
          </div>

          <!-- Messages pour l'utilisation d'un code d'activation-->
          <div *ngIf="userActivationSuccess" class="alert alert-success">
            Votre compte a bien été activé, vous pouvez désormais vous connecter avec vos identifiants
          </div>

          <div *ngIf="userActivationCodeError" class="alert alert-warning">
            Le lien d'activation a déjà été utilisé ou il ne correspond à aucun compte utilisateur, merci d'essayer de vous connecter avec vos identifiants
          </div>

          <div *ngIf="userActivationError" class="alert alert-danger">
            Une erreur interne est survenue suite à l'utilisation du lien d'activation, merci de réessayer ultérieurement
          </div>

          <!-- Mot de passe oublié / création de compte -->
          <p>
              <button class="entry-link" (click)="forgottenPassword()">Mot de passe oublié ?</button>
          </p>
          <p>
              Pas encore de compte ? <a [routerLink]="'/register'" routerLinkActive="active" class="entry-link">Inscrivez-vous</a>
          </p>
        </div>
    </div>
</div>
