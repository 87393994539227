import { Component, OnInit } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TransporterService } from 'src/app/00-shared/services/transporter.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { SireneService } from 'src/app/fr/brgm/common/http/sirene/sirene.service';
import { LexCountry } from 'src/app/fr/brgm/common/model/lex/lexcountry.model';
import { LexTransportMode } from 'src/app/fr/brgm/common/model/lex/lextransportmode.model';
import { SiretAddress } from 'src/app/fr/brgm/common/model/siret-address';
import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';

@Component({
  selector: 'app-edit-carrier-page',
  templateUrl: './edit-carrier-page.component.html',
  styleUrls: ['./edit-carrier-page.component.scss']
})
export class EditCarrierPageComponent implements OnInit {

  transporter: Transporter;
  transportMode: LexTransportMode[];
  transporterCountry: LexCountry[];

  oldTransporterName: string;
  oldTransportModeId: number;

  selectedCountryId: number;

  siretAddress: SiretAddress;
  invalidSiret: boolean;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private transporterService: TransporterService,
    private lexiqueService: LexiqueService,
    private sireneService: SireneService,
    private router: Router
  ) {
    this.invalidSiret = false;
    this.siretAddress = null;
   }

  ngOnInit() {
    this.activatedRoute.data.subscribe( data => this.titleService.setTitle(data.title));
    this.activatedRoute.params.subscribe( params => {
      this.transporterService.get(Number(params.id)).subscribe((res:Transporter)=> {
        this.transporter = res;
        this.oldTransporterName = this.transporter.name;
        if(this.transporter.lexTransportMode) {
          this.oldTransportModeId = this.transporter.lexTransportMode.id;
        }
        this.selectedCountryId = this.transporter.address.lexCountry.id;
        this.setAddress();
      });
    });

    this.lexiqueService.getLexTransportMode().subscribe((res) => this.transportMode = res);
    this.lexiqueService.getLexCountry().subscribe((res) => this.transporterCountry = res);
  }

  updateTransporter(form: NgForm) {
    if (form.form.status == "VALID" && !this.invalidSiret) {
      this.transporter.address = this.siretAddress.address;
      this.transporter.address.lexCountry = this.transporterCountry[this.selectedCountryId - 1];
      this.transporterService.update(this.transporter).subscribe((res:Transporter)=> {
        this.transporter = res;
        this.oldTransporterName = this.transporter.name;
        this.router.navigate(['/carriers/details/'+this.transporter.id])
      });
    }
  }

  setModeTransport(id){
    this.transporter.lexTransportMode = this.transportMode[id -1];
  }

  onSiretChange(event){
    this.transporter.siret = event.target.value.replace(/\s/g, "");
    if(this.transporter.siret != null && this.transporter.siret.length === 14){
      this.invalidSiret = true;
      this.setAddress();
    }else{
      this.siretAddress = null;
    }
  }

  setAddress(): void {
    this.sireneService.getSiretAddress(this.transporter.siret).subscribe(
      (siretAddress) => {
        if (siretAddress == null) {
          this.siretAddress = null;
          this.invalidSiret = true;
        } else {
          this.siretAddress = siretAddress;
          this.siretAddress.address = this.transporter.address;
          this.invalidSiret = false;
        }
      },
      (err) => this.invalidSiret = true
    );
  }

  getSiretFormControl(form: NgForm): AbstractControl {
    return form.controls['transporterSiret'];
  }

  isInvalidSiret(form: NgForm): boolean {
    let control = this.getSiretFormControl(form);
    if (control != null) {
      return (control.invalid || this.invalidSiret);
    }
    return false;
  }

}
