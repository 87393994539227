import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from 'src/app/fr/brgm/common/http/site/site.service';
import { Delegation } from 'src/app/fr/brgm/common/model/delegation.model';
import { Site } from 'src/app/fr/brgm/common/model/site.model';

@Component({
  selector: 'app-delegation-create-page',
  templateUrl: './delegation-create-page.component.html',
  styleUrls: ['./delegation-create-page.component.scss']
})
export class DelegationCreatePageComponent implements OnInit {

  delegation: Delegation;

  constructor(
    private activatedroute: ActivatedRoute,
    private siteService: SiteService
  ) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => this.getSite(params.idSite));
  }

  getSite(id) {
    this.siteService.get(Number(id)).subscribe((res: Site) => {
      this.delegation = new Delegation();
      this.delegation.id = null;
      this.delegation.site = res;
    });
  }

}
