import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Feature, Map, View } from 'ol';
import { Geometry, MultiPolygon, Polygon } from 'ol/geom';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import { Draw, Snap } from 'ol/interaction';
import { Coordinate } from 'ol/coordinate';
import { Annonce } from '../../annonce-form/annonce.model';
import { Group } from 'ol/layer';
import GeometryType from 'ol/geom/GeometryType';
import { transform } from 'ol/proj';

@Component({
  selector: 'app-map-edit-multipolygon',
  templateUrl: './map-edit-multipolygon.component.html',
  styleUrls: ['./map-edit-multipolygon.component.scss']
})
export class MapEditMultipolygonComponent implements OnInit {

  @Input() mapcenter: Coordinate;
  @Input() annonce: Annonce;
  @Input() editMode: boolean = true;


  vectorSource: VectorSource = new VectorSource();
  vectorLayer: VectorLayer<VectorSource> = new VectorLayer();
  map: Map;
  draw: Draw;
  view: View = new View();

  coordinateList = [];

  constructor() { }

  ngOnInit() {
    this.initMap();
  }

  ngOnChanges() {
    this.vectorLayer.getSource()?.clear();
    this.coordinateList = [];
    this.view.setCenter(this.mapcenter);
    this.view.setZoom(18);
  }

  initMap() {
    if (this.annonce.geometry != null) {
        this.annonce.geometry.coordinates.forEach(coord4326 => {
          let coord3857 = [];
          let newContent = [];
          coord4326[0].forEach(c => {
            newContent.push(transform(c, 'EPSG:4326', 'EPSG:3857'));
          });
          coord3857.push(newContent);
          
          this.coordinateList.push(coord3857);
          let poly4326: Geometry = new Polygon(coord4326);
          let poly3857: Geometry = poly4326.transform('EPSG:4326', 'EPSG:3857');
          
          let vectorFeature = new Feature();
          vectorFeature.setGeometry(poly3857);
          this.vectorSource.addFeature(vectorFeature);
        });
    }

    let style =  new Style({
        stroke: new Stroke({
          color: 'rgba(237,131,77,1)',
          width: 3
        }),
        fill: new Fill({
          color: 'rgba(237,131,77,.5)',
        })
    });

    this.vectorLayer.setSource(this.vectorSource);
    this.vectorLayer.setStyle(style);

    this.view = new View({
      center: this.mapcenter,
      zoom: 18
    });

    this.map = new Map({
      view: this.view,
      target: "annonce_edit_map"
    });

    const osmStandardLayer = new TileLayer({
      source: new OSM(),
      visible: true
    });

    const baseLayeGroup = new Group({
      layers: [
        osmStandardLayer
      ]
    });

    this.map.addLayer(baseLayeGroup);
    this.map.addLayer(this.vectorLayer);

    if(this.editMode){
      this.addInteractions();
    }
  }
  
  onDrawPolygon(event) {
    if (event.feature) {
      this.coordinateList.push(event.feature.getGeometry().getCoordinates());
      this.updateZoneGeometry();
    }
  }

  updateZoneGeometry() {
    let polyList = [];

    this.coordinateList.forEach(co => {
      let poly = new Polygon(co);
      polyList.push(poly);
    });

    let multipolygonGeom3857 = new MultiPolygon(polyList);
    let multipolygonGeom4326 = multipolygonGeom3857.transform('EPSG:3857', 'EPSG:4326');
    
    let thegeometry = new GeoJSON().writeGeometryObject(multipolygonGeom4326);
    this.annonce.geometry = thegeometry;
  }

  resetDraw() {
    this.annonce.geometry = undefined;
    this.vectorLayer.getSource().clear();
    this.coordinateList = [];
    this.map.removeInteraction(this.draw);
    this.addInteractions();
  }

  addInteractions() {
    this.draw = new Draw({
      source: this.vectorLayer.getSource(),
      type: GeometryType.POLYGON
    });
    this.draw.on('drawend', event => this.onDrawPolygon(event));
    this.map.addInteraction(this.draw);
    let snap = new Snap({ source: this.vectorSource });
    this.map.addInteraction(snap);
  }

  _getCenterOfExtent(extent) {
    let X = (extent[0] + extent[2])/2;
    let Y = (extent[1] + extent[3])/2;
    return [X, Y];
  }

}
