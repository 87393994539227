import { AbstractControl, ValidationErrors } from "@angular/forms";

export const siteExitDateValidator = (control: AbstractControl): ValidationErrors | null => {
    
    if(control.value) {
        const selectedDate: Date = new Date(control.value);
        const currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        selectedDate.setHours(0,0,0,0);
        if(selectedDate > currentDate) {
            return {siteExitDateInvalide: true};
        }
    }    
    return null;
}