<form [formGroup]="lotFormGroup" (ngSubmit)="onSubmitLot()" *ngIf="lotFormGroup">
  <div class="page-sticky">

    <div class="page-header">
      <a (click)="goBack()" class="button button--outline button--teal">
        <i class="button-icon icon-left" aria-hidden="true"></i>
        <span class="button-label">Retour</span>
      </a>

      <ul class="page-actions">
        <li class="page-actions-item">
          <a (click)="goBack()" class="button button--red">
            <i class="button-icon icon-cancel" aria-hidden="true"></i>
            <span class="button-label">Annuler</span>
          </a>
        </li>

        <li class="page-actions-item">
          <button type="submit" class="button button--green">
            <i class="button-icon icon-check" aria-hidden="true"></i>
            <span *ngIf="isCreateForm" class="button-label">Créer</span>
            <span *ngIf="!isCreateForm" class="button-label">Mettre à jour</span>
          </button>
        </li>

      </ul>
    </div>
  </div>

  <div class="details">

    <div class="details-header">
      <h2 *ngIf="isCreateForm" class="details-title h1">Ajout d'un bon de transport</h2>
      <h2 *ngIf="!isCreateForm" class="details-title h1">Modification d'un bon de transport</h2>
    </div>

    <div class="details-body">


      <div class="details-section details-section--opened">
        <div class="details-section-header">
          <i class="details-section-icon icon-design-tools" aria-hidden="true"></i>
          <h3 class="details-section-title">lot numéro #{{lotFormGroup.get('lot').value}}</h3>
        </div>
        <div class="details-section-body">
          <div class="form-fieldset">
            <div class="form-group">

              <div class="form-group">

                <div class="form-item form-item--12 lot-date">

                  <label class="form-label">Date de sortie du site <span class="red">*</span></label>
                  
                  <div class="form-date lot-input">
                    <p-calendar 
                      [ngClass]="{'red-border-class': lotFormGroup.controls.siteExitDate.invalid}"
                      hideOnDateTimeSelect="true" selectOtherMonths="true"
                      dateFormat="dd/mm/yy" monthNavigator="true" yearNavigator="true" showTime="true" hourFormat="24"
                      class="form-input" formControlName="siteExitDate">
                    </p-calendar>
                    
                  </div>
                  <div *ngIf="lotFormGroup.controls.siteExitDate.invalid" class="red">
                    La date de sortie du lot ne peut pas être postérieure à la date du jour. La date ne peut être que celle du jour ou antérieure.
                  </div>
                  <br>
                </div>

              </div>

              <div class="form-group">

                <div class="form-item form-item--6">
                  <label class="form-label">Quantité en tonne <span class="red">*</span></label>
                  <input type="text" class="form-input" formControlName="quantite" />
                </div>

                <div class="form-item form-item--6">
                  <label class="form-label">Volume en m3 <span class="red">*</span></label>
                  <input type="text" class="form-input" formControlName="volume"/>
                </div>
                
              </div>
              <div class="form-group">
                <div class="form-item form-item--6">
                  <label class="form-label">Transporteur <span class="red">*</span></label>
                  <div class="form-select" [ngClass]="{'red-border-class': isSubmited && lotFormGroup.controls.transporteur.invalid}">
                    <select class="form-select-inner" formControlName="transporteur">
                      <option *ngFor="let transporter of transporters" [ngValue]="transporter.id">{{transporter.name}}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="isSubmited && lotFormGroup.controls.transporteur.invalid" class="red">
                Au moins un transporteur est obligatoire pour créer un lot
              </div>

            </div>

            <a routerLink="/carriers/create" routerLinkActive="active" class="button button--blue mt-5">
              <i class="button-icon icon-add" aria-hidden="true"></i>
              <span class="button-label" style="color: #FFF">Ajouter un nouveau transporteur</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
