<div class="modal"><!-- Ajouter modal--opened pour ouvrir -->
    <form action="" class="modal-inner" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">
            <i class="modal-icon modal-icon--blue icon-padlock"></i>

            <h2 class="h1 modal-title" *ngIf="!user.cleEtablissement">Saisir ma clé RNDTS et d'établissement</h2>
            <h2 class="h1 modal-title" *ngIf="user.cleEtablissement">Modifier ma clé RNDTS et d'établissement</h2>

            <div class="form-group">
                <label class="form-item form-item--12">
                    <span class="form-label">Nouvelle clé RNDTS <span class="form-tooltip has-tooltip" ngbTooltip="Veuillez entrer ici votre clé d’identification liée au registre national des terres et sédiments. Attention, cette clé est personnelle et ne doit pas être partagée."></span></span>
                    <span class="form-password">
                        <input type="password" formControlName="cleRndts" placeholder="Entrez votre clé RNDTS" class="form-password-input">
                        <span class="form-password-toggle"></span>
                    </span>
                </label>
                
                <label class="form-item form-item--12">
                    <span class="form-label">Clé d'établissement <span class="form-tooltip has-tooltip" ngbTooltip="La clé de l’établissement correspond au SIRET de votre entreprise. Veuillez entrer les 5 derniers chiffres du SIRET."></span></span>
                    <span class="form-input">
                        <span>{{user.entityObject.siret}} </span><input type="text" formControlName="cleEtablissement" placeholder="+ clé d'établissement" [(ngModel)]="user.cleEtablissement">
                    </span>
                </label>
            </div>

            <div class="form-footer">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="submit" value="1" class="button button--green" [disabled]="registerForm.invalid">
                    <i class="button-icon icon-check"></i>
                    <span class="button-label">Confirmer</span>
                </button>
            </div>
        </div>
    </form>
</div>