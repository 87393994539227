import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FondPedogeochimiqueAnalysis } from '../../model/fondpedogeochimiqueanalysis.model';
import { FondPedogeochimique } from '../../model/lex/fondpedogeochimique.model';

@Injectable({
  providedIn: 'root'
})
export class FondPedogeochimiqueService {

  private resourceFPGUrl =  environment.apiUrl + 'fondPedogeochimique';
  private resourceFPGAnalysisUrl =  environment.apiUrl + 'fondPedogeochimiqueAnalysis';

  private SEPARATOR =   '/';

  constructor(private http: HttpClient,) { }

  createFondPedogeochimique(analysis:FondPedogeochimique):Observable<FondPedogeochimique> {
    return this.http.post(this.resourceFPGUrl + this.SEPARATOR + "create", analysis).pipe(map((res:FondPedogeochimique) => res));
  }

  createFondPedogeochimiqueAnalysis(analysis:FondPedogeochimiqueAnalysis[]):Observable<FondPedogeochimiqueAnalysis[]> {
    return this.http.post(this.resourceFPGAnalysisUrl + this.SEPARATOR + "create", analysis).pipe(map((res:FondPedogeochimiqueAnalysis[]) => res));
  }

  getByIdFondPedogeochimique(idFond: number):Observable<FondPedogeochimiqueAnalysis[]> {
    return this.http.get(this.resourceFPGAnalysisUrl + this.SEPARATOR + idFond).pipe(map((res) => { return this.convertFondPedogeochimiqueAnalysis(res)} ));
  }

  private convertFondPedogeochimiqueAnalysis(res): FondPedogeochimiqueAnalysis[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new FondPedogeochimiqueAnalysis().deserialize(res[i]));
    }
    return result;
  }
}
