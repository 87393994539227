<div class="modal"><!-- Ajouter modal--opened pour ouvrir -->
    <form action="" class="modal-inner">
        <div class="modal-body">
            <i class="modal-icon modal-icon--blue icon-external"></i>

            <h2 class="h1 modal-title">Choix du registre à alimenter</h2>

            <label class="form-radio">
                <input type="radio" [value]="RndtsType.ENTRANT" name="type"
                    [(ngModel)]="typeSelected" class="form-radio-input"
                    (change)="close()">
                <span class="form-radio-label">Registre des déchets terres et sédiment entrants </span>
            </label>
            <label class="form-radio">
                <input type="radio" [value]="RndtsType.SORTANT" name="type"
                    [(ngModel)]="typeSelected" class="form-radio-input"
                    (change)="close()">
                <span class="form-radio-label">Registre des déchets terres et sédiment sortants </span>
            </label>
        </div>
    </form>
</div>