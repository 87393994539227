import { Deserializable } from './Deserializable';
import { LexDocumentType } from './lex/lexdocumenttype.model';

export class DocumentTerrass implements Deserializable {
    constructor(
        public id?: number,
        public filename?: string,
        public originalFilename?: string,
        public lexDocumentType?: LexDocumentType,
    ) {}
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}