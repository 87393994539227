<div class="container" *ngIf="loaded">
    <div class="page-sticky">
        <a (click)="onReturnClick()" routerLinkActive="active" class="button button--outline button--teal">
            <i class="button-icon icon-left" aria-hidden="true"></i>
            <span class="button-label">Retour</span>
        </a>

        <div class="page-header">
            <h1 class="h1">Consultation de l'offre</h1>

            <ul class="page-actions">

                <li class="page-actions-item" *ngIf="isPubliable(otv)">
                    <a routerLink="close" routerLinkActive="active" class="button button--green"
                        (click)="publishOTV(otv)">
                        <i class="button-icon icon-check" aria-hidden="true"></i>
                        <span class="button-label">Publier</span>
                    </a>
                </li>

                <li class="page-actions-item" *ngIf="isCloturable(otv)">
                    <a routerLink="close" routerLinkActive="active" class="button button--black"
                        (click)="closeOTV(otv)">
                        <i class="button-icon icon-open-folder" aria-hidden="true"></i>
                        <span class="button-label">Clôturer</span>
                    </a>
                </li>

                <li class="page-actions-item" *ngIf="isModifiable(otv)">
                    <a routerLink="/offres/edit/{{otv.id}}" routerLinkActive="active" class="button button--orange">
                        <i class="button-icon icon-edit" aria-hidden="true"></i>
                        <span class="button-label">Modifier</span>
                    </a>
                </li>

                <li class="page-actions-item" *ngIf="otv.currentUserCanDuplicate">
                    <a routerLink="/offres/duplicate/{{otv.id}}" routerLinkActive="active" class="button button--orange">
                        <i class="button-icon icon-edit" aria-hidden="true"></i>
                        <span class="button-label">Dupliquer</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="details" *ngIf="otv" #divToModalClick>

        <div class="details-header">
            <span class="details-type orange">Offre</span>

            <h2 class="details-title h1">{{otv.id}}</h2>
            <span class="details-status" [ngClass]="{
                'details-status--draft': otv.lexOfferStatus.label.toLowerCase() === 'en brouillon',
                'details-status--proposed': otv.lexOfferStatus.label.toLowerCase() === 'publiée',
                'details-status--pending': otv.lexOfferStatus.label.toLowerCase() === 'échue',
                'details-status--archived': otv.lexOfferStatus.label.toLowerCase() === 'clôturée'
            }">{{otv.lexOfferStatus.label}}</span>

            <span *ngIf="!otv.public_" class="details-visibility">
              <span *ngIf="isOwner()">
                <a id="confidential-code" class="black" (click)="onGenereteConfidentialCodeClick()">Générer code de partage</a>
              </span>
              <span class="red">
                <i class="icon-padlock" aria-hidden="true"></i> Confidentielle
              </span>
            </span>
            <span *ngIf="otv.public_" class="details-visibility green"><i class="icon-open-padlock" aria-hidden="true"></i>Publique</span>
        </div>

        <div class="details-body">
            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-info" aria-hidden="true"></i>
                    <h3 class="details-section-title">Informations générales</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--6">
                            <p class="details-label">Période de disponibilité</p>
                            <p>Du &nbsp; <span class="details-value">{{otv.availStartDate | date :'d/MM/y'}}</span>
                                &nbsp; &nbsp; Au &nbsp; <span
                                    class="details-value">{{otv.availEndDate|date:'d/MM/y'}}</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-pin" aria-hidden="true"></i>
                    <h3 class="details-section-title">Localisation du site</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--12">
                            <div class="details-contact">
                                <h3 class="details-contact-title">{{otv.site.name}}</h3>

                                <p class="details-contact-line">{{otv.site.address.numberStreet}},
                                    {{otv.site.address.zipCode}}</p>

                                <em class="teal">{{otv.site.lexSiteType?.label}}</em>
                            </div>
                        </div>

                        <div class="form-item form-item--12" *ngIf="otv.site">
                            <app-map-edit-multipolygon [mapcenter]="pointCoordinates" [annonce]="otv" [editMode]="false"></app-map-edit-multipolygon>
                        </div>
                    </div>
                    <hr class="form-separator">

                    <div class="form-group" *ngIf="planOfferDocs.length > 0">
                        <div class="form-item form-item--12">
                            <p class="details-label">Plan de la zone d'extraction des terres ou localisation de l'andain</p>

                            <div class="form-group">
                                <div *ngFor="let doc of planOfferDocs" class="form-item form-item--3">
                                    <a [routerLink]="" (click)="downloadFile(doc.filename, doc.originalFilename)" download class="details-value"><i class="icon-pdf"></i>
                                        {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-design-tools" aria-hidden="true"></i>
                    <h3 class="details-section-title">Caractérisation</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--3">
                            <p class="details-label">Quantité à valoriser (t)</p>
                            <p class="details-value">{{otv.quantity}}</p>
                        </div>
                        <div class="form-item form-item--3">
                            <p class="details-label">Volume à valoriser (m3)</p>
                            <p class="details-value">{{getVolume(otv.quantity)}}</p>
                        </div>
                        <div class="form-item form-item--3">
                            <p class="details-label">Type de matériaux (classement GTR)</p>
                            <p class="details-value">{{otv.lexMaterialType?.label}}</p>
                        </div>

                        <div class="form-item form-item--3">
                            <p class="details-label">Code déchet</p>
                            <p class="details-value">{{otv.lexCodeDechet?.label}}</p>
                        </div>

                        <div class="form-item form-item--9">
                            <p class="details-label">Informations complémentaires</p>
                            <p class="details-value">{{otv.infos}}</p>
                        </div>
                    </div>

                    <hr class="form-separator">

                    <div class="form-group">
                        <div class="form-item form-item--12" *ngIf="docTechniques.length > 0">
                            <p class="details-label">Documents techniques</p>

                            <div class="form-group">
                                <div *ngFor="let doc of docTechniques" class="form-item form-item--3">
                                    <a *ngIf="doc.lexDocumentType.id === 1" [routerLink]="" (click)="downloadFile(doc.filename, doc.originalFilename)" download class="details-value"><i class="icon-pdf"></i>
                                        {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-flasks" aria-hidden="true"></i>
                    <h3 class="details-section-title">Déclaration d’analyse</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--12" *ngIf="otvAnalysisResults !== null && otvAnalysisResults.length !== 0">

                            <p class="details-label">Tableau de substances</p>

                            <table class="details-table" aria-hidden="true">
                                <thead>
                                    <tr>
                                        <th>Substance (analyse en contenu total)</th>
                                        <th class="details-table-centered">Valeur</th>
                                        <th class="details-table-centered">
                                            < LQ</th>
                                        <th class="details-table-centered">LQ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let elemResult of otvAnalysisResults">
                                        <td>{{elemResult?.lexMolecule?.name}}</td>
                                        <td class="details-table-centered blue">
                                            {{elemResult.infQl?'-':elemResult.value}}</td>
                                        <td class="details-table-centered blue"><i class="icon-check"
                                                *ngIf="elemResult.infQl"></i></td>
                                        <td class="details-table-centered blue">{{elemResult?.quantifyLimit}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-item form-item--9" *ngIf="otvAnalysis">
                            <p class="details-label">Informations complémentaires</p>
                            <p class="details-value">{{otvAnalysis.infos}}</p>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="otvAnalysisResults == null || otvAnalysisResults.length === 0">
                        <div class="form-item form-item--12">
                            <p class="black">Aucune analyse n’a été déclarée.</p>
                        </div>
                    </div>

                    <hr class="form-separator">

                    <div class="form-group">
                        <div class="form-item form-item--12" *ngIf="bordereauxAnalyses.length > 0">
                            <p class="details-label">Bordereau(x) d'analyses</p>

                            <div class="form-group">
                                <div *ngFor="let doc of bordereauxAnalyses" class="form-item form-item--3">
                                    <a *ngIf="doc.lexDocumentType.id === 2" [routerLink]="" (click)="downloadFile(doc.filename, doc.originalFilename)" download class="details-value"><i class="icon-pdf"></i>
                                        {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-item form-item--12" *ngIf="bordereauxAnalysesSurLuxiviat.length > 0">
                            <p class="details-label">Bordereau(x) d'analyses sur lixiviat</p>

                            <div class="form-group">
                                <div *ngFor="let doc of bordereauxAnalysesSurLuxiviat" class="form-item form-item--3">
                                    <a *ngIf="doc.lexDocumentType.id === 3" [routerLink]="" (click)="downloadFile(doc.filename, doc.originalFilename)" download class="details-value"><i class="icon-pdf"></i>
                                        {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
