import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { User } from 'src/app/fr/brgm/common/model/user.model';

@Component({
  selector: 'app-modal-change-rndts-key',
  templateUrl: './modal-change-rndts-key.component.html',
  styleUrls: ['./modal-change-rndts-key.component.scss']
})
export class ModalChangeRNDTSKeyComponent implements OnInit {

  @Input() user: User;
  registerForm: FormGroup;
  submitted = false;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder, 
    private userService:UserService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      cleRndts: ['', [Validators.pattern('^$|^[a-zA-Z0-9]{64}$')]],
      cleEtablissement: ['', [Validators.pattern('^$|^[0-9]{5}$')]]
  });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  onSubmit() {
    this.user.cleRndts = this.registerForm.value.cleRndts;
    this.user.cleEtablissement = this.registerForm.value.cleEtablissement;
    this.userService.updateRndts(this.user).subscribe((res) => this.activeModal.close());
  }
}
