import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Annonce } from 'src/app/00-shared/annonce-form/annonce.model';
import { AnalysisService } from 'src/app/fr/brgm/common/http/analysis/analysis.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { OtvService } from 'src/app/fr/brgm/common/http/otv/otv.service';


@Component({
  selector: 'app-edit-offre-page',
  templateUrl: './edit-offre-page.component.html',
  styleUrls: ['./edit-offre-page.component.scss']
})
export class EditOffrePageComponent implements OnInit {

  id: number;
  annonce: Annonce = null;
  isCreation: boolean = false;
  isOffer: boolean = true;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private otvService: OtvService,
    private analysisService: AnalysisService,
    private lexiqueService: LexiqueService
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    });
    this.activatedRoute.params.subscribe(params => {
      this.otvService.get(Number(params.id)).subscribe(otv => {

        this.annonce = new Annonce();

        this.annonce.id = otv.id;
        this.annonce.availStartDate =  new Date(otv.availStartDate);
        this.annonce.availEndDate =  new Date(otv.availEndDate);
        this.annonce.bstvIcpe = otv.bstvIcpe;
        this.annonce.infos = otv.infos;
        this.annonce.lexMaterialType = otv.lexMaterialType;
        this.annonce.public_ = otv.public_;
        this.annonce.quantity = otv.quantity;
        this.annonce.site = otv.site;
        this.annonce.user = otv.user;
        this.annonce.lexOfferStatus = otv.lexOfferStatus;
        this.annonce.lexCodeDechet = otv.lexCodeDechet;
        this.annonce.documents = otv.documents;
        this.annonce.isLinkToTransaction = otv.isLinkToTransaction;
        this.annonce.geometry = otv.geometry;
      });
    });
  }
}
