<footer class="footer">

    <a target="_blank" href="https://www.ecologie.gouv.fr/sites-et-sols-pollues">
        <img src="assets/images/logos/mtes.png" alt="Ministère de la Transition écologique et solidaire"
            class="footer-logo">
    </a><p style="color: #2c5574;">.</p>
    <a target="_blank" href="https://infoterre.brgm.fr">
        <img src="assets/images/logos/brgm-white.png" alt="brgm - Géosciences pour une Terre durable"
            class="footer-logo">
    </a>

    <div class="footer-info">
        <h3 class="footer-info-title">Terrass</h3>
        <p class="footer-info-description">Plateforme de gestion des terres excavées</p>
        <p class="footer-info-version">Version 2.0</p>
    </div>

    <nav class="footer-nav">
        <ul class="footer-nav-list">
            <li class="footer-nav-item">
                <a target="_blank" href="https://tex-infoterre.brgm.fr/fr/protection-des-donnees-personnelles"
                    class="footer-nav-link">Protection des Données Personnelles</a>
            </li>
            <li class="footer-nav-item">
                <a target="_blank" href="https://assistance.brgm.fr/formulaire/posez-votre-question?tools=TERRASS"
                    class="footer-nav-link">Besoin d’aide ?</a>
            </li>
            <li class="footer-nav-item">
                <a (click)="onClick('RetourEnHaut')" class="footer-nav-link">Retour en haut de page &nbsp;<i class="footer-nav-button-icon icon-up"></i></a>
            </li>
        </ul>
    </nav>
</footer>
