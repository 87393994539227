import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransporteursRoutingModule } from './transporteurs-routing.module';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '../00-shared/primeng/primeng.module';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { CarriersPageComponent } from './carriers-page/carriers-page.component';
import { CreateCarrierPageComponent } from './create-carrier-page/create-carrier-page.component';
import { EditCarrierPageComponent } from './edit-carrier-page/edit-carrier-page.component';
import { ViewCarrierPageComponent } from './view-carrier-page/view-carrier-page.component';

@NgModule({
  declarations: [
    CarriersPageComponent,
    CreateCarrierPageComponent,
    EditCarrierPageComponent,
    ViewCarrierPageComponent
  ],
  imports: [
    CommonModule,
    TransporteursRoutingModule,
    FormsModule,
    PrimengModule,
    PaginationModule
  ],
  providers: []
})
export class TransporteursModule { }
