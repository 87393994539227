<div class="modal">
  <form #form="ngForm" class="modal-inner" (ngSubmit)="onSubmit()">

    <button type="button" class="modal-close icon-close" (click)="onClose()"></button>

    <div class="modal-body">

      <h2 class="h1 modal-title">Code de partage d'une annonce</h2>

      <p class="modal-text">
        Après avoir renseigné le code confidentiel d'une annonce, cette dernière sera accessible définitivement pour vous dans les listes d'annonces TERRASS.
      </p>

      <div class="form-group">
        <label class="form-item form-item--12">
            <span class="form-label">Code confidentiel de l'annonce<span class="red">*</span></span>
            <input type="text" class="form-input" [(ngModel)]="confidentialCode" placeholder="Code à 15 caractères"
                #announcementConfidentialCode="ngModel" name="announcementConfidentialCode" minlength="15" maxlength="15" size="15"
                [ngClass]="{'red-border-class': announcementConfidentialCode.errors && form.submitted}"
                required
            />
        </label>
      </div>

      <div class="form-footer">
        <button type="submit" class="button button--green">
            <i class="button-icon icon-search" aria-hidden="true"></i>
            <span class="button-label">Rechercher l'annonce confidentielle</span>
        </button>

        <div class="green" *ngIf="announcementUrl">
          <strong>L'annonce confidentielle vous est désormais accessible. Vous pouvez y accéder directement via ce
            <a [routerLink]="announcementUrl" (click)="onClose()">lien</a>
          </strong>
        </div>

        <div class="orange" *ngIf="confidentialAccessNotFound">
          <strong>Le code renseigné ne correspond à aucune annonce confidentielle</strong>
        </div>

        <div class="red" *ngIf="confidentialAccessError">
          <strong>Une erreur est survenue, merci de réessayer ultérieurement</strong>
        </div>

    </div>

    </div>
  </form>
</div>
