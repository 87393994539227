<div class="modal">
    <form action="" #form="ngForm" class="modal-inner" (ngSubmit)="onSubmit()">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">

            <h2 class="h1 modal-title">Attention</h2>

            <div *ngIf="error" class="row alert alert-danger">
                {{ error }}
            </div>

            <p class="modal-text">
              L’adresse saisie n’est pas comprise dans le polygone dessiné et est à plus de 250m du centre de celui-ci.
              Souhaitez-vous poursuivre avec la création du site ?
            </p>

            <div class="form-footer">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close" aria-hidden="true"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="submit" value="1" class="button button--green">
                    <i class="button-icon icon-check" aria-hidden="true"></i>
                    <span class="button-label">Confirmer</span>
                </button>
            </div>
        </div>
    </form>
</div>
