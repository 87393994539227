<div class="wrapper">

  <div class="container">

    <div class="page-header">
      <h1 class="h1">Mon entité</h1>
    </div>

    <!-- Section entreprise -->

    <div class="details">

      <div class="details-header">
        <h2 class="details-title h1">Entreprise</h2>
      </div>

      <div class="form-group">

        <div class="form-item form-item--12">
          <span class="form-label">SIREN : {{ currentUser?.entityObject?.siret }}</span>
        </div>

        <div class="form-item form-item--12">
          <span class="form-label">Raison sociale : {{ currentEntityUser?.socialReason }}</span>
        </div>

        <div class="form-item form-item--end form-item--3" *ngIf="currentUserIsAdmin()">
          <button type="button" class="button button--fullwidth button--blue" (click)="onSocialReasonEdit()">
            <i class="button-icon icon-edit" aria-hidden="true"></i>
            <span class="button-label">Modifier la raison social</span>
          </button>
        </div>

      </div>

    </div>

    <!-- Section personnes -->
    <div class="details" *ngIf="currentUserIsAdmin()" style="margin-top: 2em;">

      <div class="details-header">
        <h2 class="details-title h1">Utilisateurs</h2>
      </div>

      <div *ngIf="toManyAdminError" class="alert alert-warning text-center">
        Une entité ne peut pas disposer de plus de 5 administrateurs
      </div>

      <div>

        <table class="table">
          <caption>Tableau des utilisateurs associés à votre entité</caption>
          <thead>
            <tr>
              <th scope="col">Prénom</th>
              <th scope="col">Nom</th>
              <th scope="col">Mail</th>
              <th scope="col">Rôle</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let user of entityUsers">

              <td>{{ user.firstname }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td *ngIf="user.lexUserStatus.id === 1" style="color:red;">{{ user.lexUserStatus.label }}</td>
              <td *ngIf="user.lexUserStatus.id != 1">{{ user.lexUserStatus.label }}</td>
              <td>
                <div class="table-actions">
                  <input type="checkbox" id="table-actions" class="table-actions-input">
                  <label for="table-actions" class="table-actions-toggle"></label>

                  <ul class="table-actions-list" *ngIf="currentUser.id !== user.id">

                    <li class="table-actions-item" *ngIf="displayValidateRequest(user)">
                      <a (click)="onValideRequestClick(user)" class="table-actions-link green">
                          <i class="table-actions-icon icon-check" aria-hidden="true"></i>
                          <span class="table-actions-label">Valider la demande</span>
                      </a>
                    </li>

                    <li class="table-actions-item" *ngIf="displayRefuseRequest(user)">
                      <a (click)="onRefuseRequestClick(user)" class="table-actions-link red">
                          <i class="table-actions-icon icon-close" aria-hidden="true"></i>
                          <span class="table-actions-label">Refuser la demande</span>
                      </a>
                    </li>

                    <li class="table-actions-item" *ngIf="displayAppointAdmin(user)">
                      <a (click)="onAppointAdminClick(user)" class="table-actions-link blue">
                          <i class="table-actions-icon icon-up" aria-hidden="true"></i>
                          <span class="table-actions-label">Nommer administrateur</span>
                      </a>
                    </li>

                    <li class="table-actions-item" *ngIf="displayDisableUser(user)">
                      <a (click)="onDisableUserClick(user)" class="table-actions-link red">
                          <i class="table-actions-icon icon-close" aria-hidden="true"></i>
                          <span class="table-actions-label">Désactiver l'utilisateur</span>
                      </a>
                    </li>

                  </ul>

                  <ul class="table-actions-list" *ngIf="currentUser.id === user.id">
                    <li class="table-actions-item">
                      <a class="table-actions-link orange">
                          <i class="table-actions-icon icon-close" aria-hidden="true"></i>
                          <span class="table-actions-label">Vous ne pouvez pas désactiver votre propre compte</span>
                      </a>
                    </li>
                  </ul>

                </div>
              </td>
            </tr>
          </tbody>

        </table>

      </div>

    </div>

  </div>

</div>
