import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Delegation } from 'src/app/fr/brgm/common/model/delegation.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DelegationService {

  private resourceUrl =  environment.apiUrl + 'delegation';
  private SEPARATOR =   '/';

  constructor(private http: HttpClient) { }

  create(delegation: Delegation): Observable<Delegation> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + 'create', delegation).pipe(map((res: Delegation)=> res));
  }

  createDelegations(delegations: Delegation[]): Observable<Delegation[]> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + 'createlist', delegations).pipe(map((res: Delegation[])=> res));
  }

  update(delegation: Delegation): Observable<Delegation> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + 'update', delegation).pipe(map((res: Delegation)=> res));
  }

  get(delegationId: number): Observable<Delegation> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + delegationId).pipe(map((res: Delegation)=> res));
  }

  getBySite(id: number): Observable<Delegation[]>{
    const url = this.resourceUrl + this.SEPARATOR + 'findBySite' + this.SEPARATOR + id;
    return this.http.get(url).pipe(map((res: Delegation[]) => res));
  }

  getNumberOfActiveDelegations(delegations: Array<Delegation>): number {
    return delegations.filter(delegation => delegation.lexDelegationStatus.id == 1).length;
  }

}
