import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from 'src/app/fr/brgm/common/http/user/user.service';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss']
})
export class ModalChangePasswordComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private userService:UserService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[\\d])(?=.*[^a-zA-Z\\d\\s]).{8,}')]],
      confirmPassword: ['', Validators.required]
  }, {
      validator: this.mustMatch('password', 'confirmPassword')
  });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

  onSubmit() {
    this.userService.updatePassword(this.registerForm.value.password).subscribe((res) => this.activeModal.close());
  }
}
