import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeojsonService {

  private resourceUrl = environment.apiUrl + 'geojson';
  private SEPARATOR = '/';
  constructor(private http: HttpClient) { }

  getReunionSecteurGeographique(): Observable<string> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + 'reunion/secteursgeographique').pipe(map((res:string) => res));
  }

  getReunionPedologieSol(): Observable<string> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + 'reunion/pedologiesol').pipe(map((res:string) => res));
  }

  getReunionPedologieNonSol(): Observable<string> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + 'reunion/pedologienonsol').pipe(map((res:string) => res));
  }

}
