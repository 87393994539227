<div class="container">

  <form [formGroup]="delegationFormGroup" (ngSubmit)="onSubmitDelegation()" *ngIf="delegationFormGroup">
    <div class="page">
      <a (click)="onReturnClick()" routerLinkActive="active" class="button button--outline button--teal">
        <i class="button-icon icon-left" aria-hidden="true"></i>
        <span class="button-label">Retour</span>
      </a>

      <div class="page-header">
        <h1 *ngIf="isCreation" class="h1">Mettre en délégation le site {{ _delegation.site?.name }}<span
            *ngIf="_delegation.site?.name"></span></h1>
        <h1 *ngIf="!isCreation" class="h1">Modifier la délégation du site {{ _delegation.site?.name }}</h1>
      </div>
    </div>
    <br>
    <div class="form-group" *ngIf="isCreation">
      <div class="form-item form-item--6">
        <label class="form-label">Recherche par SIREN de l'entité à laquelle vous voulez déléguer votre site</label>
        <div class="fromt-input">
          <p-autoComplete #autoComplete type="text" [suggestions]="entities" (onSelect)="doOnSelect($event)"
            (completeMethod)="searchEntities($event)" (onKeyUp)="sirenOnChange()" field="siret" [minLength]="3"
            class="form-search form-search-input delegation-input">
            <ng-template let-entity pTemplate="item">
              <span>{{ entity.siret }} - {{ entity.socialReason }}</span>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="!isCreation">
      <div class="form-item form-item--6">
        <label class="form-label">Entité qui a reçu la délégation : {{ _delegation.entityObject?.socialReason }}</label>
        <input type="text" name="siren" class="form-input delegation-input" [value]="_delegation.entityObject?.siret"
          disabled>
      </div>
    </div>
    <br>
    <div class="form-group" *ngIf="isCreation">
      <div class="form-item form-item--6">
        <div [ngClass]="{'red': sirenError}">
          <label for="form-label">Raison sociale :&nbsp;</label>
          <span *ngIf="selectedEntity?.socialReason">{{ selectedEntity.socialReason }}</span>
          <span *ngIf="!selectedEntity?.socialReason" class="red">aucun compte TERRASS ne possède ce SIREN</span>
        </div>
        <div *ngIf="sirenError" class="alert alert-danger delegation-input">
          {{ sirenError }}
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="isCreation">
      <div class="form-item form-item--6">
        <label class="form-label">Selectionner les utilisateurs recevant la délégation<span class="red">*</span></label>
        <label class="form-checkbox">
          <input type="checkbox" class="form-checkbox-input" [checked]="allChecked" (change)="changeAll()"
            [disabled]="disableAllCheck">
          <span class="form-checkbox-label">Toutes les personnes</span>
        </label>

      </div>
    </div>
    <div class="form-group" *ngIf="isCreation">
      <div class="form-item form-item--6">

        <div style="overflow-y: auto; width: 250px;height: 150px; border: 2px solid lightgrey;">
          <div *ngFor="let u of users">
            <label class="form-checkbox form-checkbox--margin">
              <input type="checkbox" class="form-checkbox-input" name="u.id" [value]="u.id" [checked]="isChecked(u.id)"
                (change)="getSelectedUsers($event, u)">
              <span class="form-checkbox-label">{{u.name}} {{u.firstname}}</span>
            </label>
          </div>
        </div>
        <span *ngIf="delegationFormGroup.valid && users?.length > 0 && selectedUsers.length === 0"
          class="red">Selectionner au moins un utilisateur</span>
      </div>
    </div>

    <div class="form-group" *ngIf="!isCreation">
      <div class="form-item form-item--3">
        <label class="form-label">Utilisateur qui a reçu la délégation : {{ _delegation.refDelegation }}</label>
      </div>
    </div>

    <br>

    <div class="form-group">
      <div class="form-item form-item--6 delegation-date">
        <label>Sélectionner une date de début de délégation</label>
        <div class="form-date delegation-input" id="idStartDate" (click)="onClickStartDate()">
          <p-calendar formControlName="startDate" class="form-input"
            [ngClass]="{'red-border-class': delegationFormGroup.controls.startDate.invalid && delegationFormGroup.controls.startDate.touched}"
            hideOnDateTimeSelect="true" selectOtherMonths="true" dateFormat="dd/mm/yy" monthNavigator="true"
            yearNavigator="true" yearRange="2000:2099" [showIcon]="true" [style]="{'max-width':'250px'}"
            [inputStyle]="{'max-width':'250px'}" [showButtonBar]="true">
          </p-calendar>
        </div>
      </div>

      <div class="form-item form-item--6 delegation-date">
        <label>Sélectionner une date de fin de délégation</label>
        <div class="form-date delegation-input" id="idExpirationDate" (click)="onClickExpirationDate()">
          <p-calendar class="form-input"
            [ngClass]="{'red-border-class': delegationFormGroup.controls.expirationDate.invalid && delegationFormGroup.controls.expirationDate.touched}"
            hideOnDateTimeSelect="true" selectOtherMonths="true" dateFormat="dd/mm/yy" monthNavigator="true"
            yearNavigator="true" yearRange="2000:2099" formControlName="expirationDate" [showIcon]="true"
            [style]="{'max-width':'250px'}" [inputStyle]="{'max-width':'250px'}" [showButtonBar]="true">
          </p-calendar>
        </div>
      </div>
    </div>

    <br>

    <div class="form-group">
      <div class="form-item form-item--6 delegation-date" id="idDateDebutGel" (click)="onClickDateDebutGel()">
        <label>Sélectionner une date de début de gel</label>
        <div class="form-date delegation-input">
          <p-calendar formControlName="dateDebutGel" class="form-input" [ngClass]="{'red-border-class': delegationFormGroup.controls.dateDebutGel.invalid
            && delegationFormGroup.controls.dateDebutGel.touched
            || delegationFormGroup.hasError('dateDebutGelInvalide')}" hideOnDateTimeSelect="true"
            selectOtherMonths="true" dateFormat="dd/mm/yy" monthNavigator="true" yearNavigator="true"
            yearRange="2000:2099" [showIcon]="true" [style]="{'max-width':'250px'}" [inputStyle]="{'max-width':'250px'}" [showButtonBar]="true"
            (onSelect)="onFrostStartDateChange()" (onBlur)="onFrostStartDateChange()" (onClear)="onFrostStartDateChange()" (onClearClick)="onFrostStartDateChange()">
          </p-calendar>
        </div>
        <div *ngIf="frostStartDateError" class="alert alert-danger delegation-input">
          <p>La date de début de gel ne peut pas être antérieure à la date de début de délégation</p>
        </div>
        <div *ngIf="delegationFormGroup.hasError('dateDebutGelInvalide')" class="alert alert-danger delegation-input">
          <p>La date de début de gel ne peut pas être postérieure ou égale à la date de fin de gel</p>
        </div>
        <div *ngIf="frostDatesEmptiesError && (delegationFormGroup.get('dateDebutGel').value == null)" class="alert alert-danger delegation-input">
          <p>Une date de fin de gel doit posséder une date de début de gel</p>
        </div>
      </div>

      <div class="form-item form-item--6 delegation-date">
        <label>Sélectionner une date de fin de gel</label>
        <div class="form-date delegation-input" id="idDateFinGel" (click)="onClickDateFinGel()">
          <p-calendar class="form-input" [ngClass]="{'red-border-class': (delegationFormGroup.controls.dateFinGel.invalid
            && delegationFormGroup.controls.dateFinGel.touched)
            || delegationFormGroup.hasError('dateFinGelInvalide')}" hideOnDateTimeSelect="true"
            selectOtherMonths="true" dateFormat="dd/mm/yy" monthNavigator="true" yearNavigator="true"
            yearRange="2000:2099" formControlName="dateFinGel" [showIcon]="true" [style]="{'max-width':'250px'}"
            [inputStyle]="{'max-width':'250px'}" [showButtonBar]="true">
          </p-calendar>
        </div>
        <div *ngIf="delegationFormGroup.hasError('dateFinGelInvalide')" class="alert alert-danger delegation-input">
          <p>La date de fin de gel ne peut pas être postérieure à la date de fin de délégation</p>
        </div>
        <div *ngIf="frostDatesEmptiesError && (delegationFormGroup.get('dateFinGel').value == null)" class="alert alert-danger delegation-input">
          <p>Une date de début de gel doit posséder une date de fin de gel</p>
        </div>
      </div>
    </div>

    <br>

    <div>
      <button type="submit" class="button button--green"
        [disabled]="!delegationFormGroup.valid || selectedUsers.length === 0">
        <span class="button-label">Valider</span>
      </button>
    </div>

  </form>

</div>
