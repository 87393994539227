import { Deserializable } from './Deserializable';

export class FondPedogeochimiqueAnalysisPK implements Deserializable {
    constructor(public moleculeId?: number,
        public fondPedogeochimiqueId?: number) {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}