<div id="sites_map" class="map-container"></div>

<div id="popup" class="ol-popup">
    <a href="#" id="popup-closer" class="ol-popup-closer"></a>
    <div id="popup-content">
        <div *ngFor="let annonce of selectedOtvDtvList" class="card mt-2">
            <div class="card-body">
                <ul>
                    <li>Type d'annonce :
                        <b class="orange" *ngIf="annonce.type === 'O'">OTV</b>
                        <b class="lightblue" *ngIf="annonce.type === 'D'">DTV</b>
                    </li>
                    <li>Identifiant : {{annonce.id}}</li>
                    <li>Date de création : {{annonce.creationDate | date:'dd MMM yyyy'}}</li>
                    <li>Site : {{annonce.site.name}}</li>
                    <li>Quantité : {{annonce.quantity}} t</li>
                    <li>Disponibilité : {{annonce.availStartDate | date:'dd MMM yyyy'}} - {{annonce.availEndDate|
                        date:'dd MMM yyyy'}}</li>
                    <li>Statut :
                        <span [ngSwitch]="annonce.statusId">
                            <b *ngSwitchCase="1" class="teal">En brouillon</b>
                            <b *ngSwitchCase="2" class="green">Publiée</b>
                            <b *ngSwitchCase="3" class="orange">Echue</b>
                            <b *ngSwitchCase="4" class="black">Clôturée</b>
                        </span>
                    </li>
                </ul>
                <a id="otv-dtv-link" [routerLink]="getConsultationLink(annonce)"
                    class="button button--blue">Consulter</a>
            </div>
        </div>
    </div>
</div>

<div class="ol-checkbox ol-unselectable ol-control p-2">
    <button style="background-color: rgba(0,60,136,0.5); width: 100%;" (click)="afficherCouches()" class="button button-icon">
      <span *ngIf="showLayers">Masquer les couches</span>
      <span *ngIf="!showLayers">Afficher les couches</span>
    </button>
    <div *ngIf="showLayers">
        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isOtvDtvLayerVisible" type="checkbox" class="form-checkbox-input"
                        (change)="onOtvDtvLayerCheckboxClicked()" [(ngModel)]="isOtvDtvLayerVisible">
                    <span class="form-checkbox-label">Afficher les OTV / DTV</span>
                </label>
            </div>
        </div>
        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isGeochimieLayerVisible" type="checkbox" class="form-checkbox-input"
                        (change)="onGeochimieLayerCheckboxClicked()" [(ngModel)]="isGeochimieLayerVisible">
                    <span class="form-checkbox-label">Anomalies géochimiques(<a target="_blank"
                            href="http://ficheinfoterre.brgm.fr/terrass/Notice_Infoterre_Carte_Anomalies_geochimiques.pdf">légende</a>)</span>
                </label>
            </div>
        </div>

        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isSoilSiteNaturelUniqueLayerVisible" type="checkbox"
                        class="form-checkbox-input" (change)="onSoilSiteNaturelUniqueCheckboxClicked()"
                        [(ngModel)]="isSoilSiteNaturelUniqueLayerVisible">
                    <span class="form-checkbox-label">Sous-zone de fond pédogéochimique naturel (Bassin Parisien) (<a
                            target="_blank"
                            href="assets/pdf/GeoBaPa_Notice_Utilisation_Infoterre_V1-2.pdf">légende</a>)</span>
                </label>
            </div>
        </div>
        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isSoilSiteRemblaisLayerVisible" type="checkbox" class="form-checkbox-input"
                        (change)="onSoilSiteRemblaisCheckboxClicked()" [(ngModel)]="isSoilSiteRemblaisLayerVisible">
                    <span class="form-checkbox-label">Sous-zone de fond pédogéochimique remblai (Bassin Parisien) (<a
                            target="_blank"
                            href="assets/pdf/GeoBaPa_Notice_Utilisation_Infoterre_V1-2.pdf">légende</a>)</span>
                </label>
            </div>
        </div>

        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isReunionPedologieLayerVisible" type="checkbox" class="form-checkbox-input"
                        (change)="onReunionPedologieCheckboxClicked()" [(ngModel)]="isReunionPedologieLayerVisible">
                    <span class="form-checkbox-label">Pédologie simplifiée de La Réunion (<a target="_blank"
                            href="assets/pdf/Notice_Carte_pedologique_simplifiee_Reunion.pdf">légende</a>)</span>
                </label>
            </div>
        </div>
        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isReunionSecteurGeogLayerVisible" type="checkbox" class="form-checkbox-input"
                        (change)="onReunionSecteurGeogCheckboxClicked()" [(ngModel)]="isReunionSecteurGeogLayerVisible">
                    <span class="form-checkbox-label">Grands secteurs géographiques de l’île de La Réunion</span>
                </label>
            </div>
        </div>
        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isReunionEnjeuInvasionLayerVisible" type="checkbox"
                        class="form-checkbox-input" (change)="onReunionEnjeuInvasionCheckboxClicked()"
                        [(ngModel)]="isReunionEnjeuInvasionLayerVisible">
                    <span class="form-checkbox-label">Niveaux d’invasion par les plantes exotiques envahissantes sur
                        l'île de La Réunion (<a target="_blank"
                            href="https://carmen.developpement-durable.gouv.fr/IHM/metadata/REU/Publication/METADONNEES_00_DEAL_REUNION/enjeu_invasion_actions_metadonnees_complet.pdf">légende</a>)</span>
                </label>
            </div>
        </div>
        <div class="form-group mb-0">
            <div class="form-item form-item--12 mb-0">
                <label class="form-checkbox" class="form-checkbox">
                    <input required name="isMartiniqueLayerVisible" type="checkbox" class="form-checkbox-input"
                        (change)="onMartiniqueCheckboxClicked()" [(ngModel)]="isMartiniqueLayerVisible">
                    <span class="form-checkbox-label">Parcelles analysées pour la chlordécone en Martinique (<a
                            target="_blank"
                            href="https://www.geomartinique.fr/accueil/ressources/pollution_des_sols_par_la_chlordecone_en_martinique">légende</a>)</span>
                </label>
            </div>
        </div>
    </div>
</div>
