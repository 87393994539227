<div class="search-form-wrapper">
    <div class="search">
        <form action="" method="" class="search-form">
            <div id="bourse-aux-terres" class="search-form-wrapper">
              <h2>Bourse aux terres</h2>
                <span class="search-form-label" style="font-size: large;">Recherche des annonces (OTV/DTV) à partir d'un site</span>
                <div class="search-form-body">
                    <label class="search-form-item">
                        <span class="search-form-search">
                            <input [(ngModel)]="queryItem" [ngModelOptions]="{standalone: true}" type="text"
                                placeholder="Rechercher par nom" class="search-form-search-input">
                        </span>
                    </label>
                    <label class="search-form-item" style="justify-content: center;">
                        OU
                    </label>

                    <label class="search-form-item">
                        <span class="search-form-location">
                            <p-autoComplete type="text" name="locAddress" #locAddress="ngModel" [(ngModel)]="requete"
                                [suggestions]="adresses?.features" (onSelect)="doOnSelect($event)"
                                (completeMethod)="searchAdresse($event)" field="properties.label" [minLength]="3"
                                class="search-form-location-input" [style]="{'width':'100%'}"
                                [inputStyle]="{'width':'100%'}" placeholder="Rechercher par adresse"></p-autoComplete>
                        </span>
                    </label>

                    <label class="search-form-item">
                        <span class="search-form-label">Dans un rayon de</span>
                        <span class="search-form-select">
                            <select [(ngModel)]="rayon" [ngModelOptions]="{standalone: true}"
                                class="search-form-select-inner">
                                <option value="5" style="color: #000">5 kilomètres</option>
                                <option value="10" style="color: #000">10 kilomètres</option>
                                <option value="30" style="color: #000">30 kilomètres</option>
                                <option value="50" style="color: #000">50 kilomètres</option>
                                <option value="100" style="color: #000">100 kilomètres</option>
                            </select>
                        </span>
                    </label>

                    <hr class="search-form-separator">

                    <span class="search-form-label" style="font-size: large;">Recherche par annonce</span>
                    <label class="search-form-item">
                        <span class="search-form-select">
                            <select [(ngModel)]="annonceTypeSearch" [ngModelOptions]="{standalone: true}"
                                class="search-form-select-inner">
                                <option class="black" value="">Type d'annonce</option>
                                <option class="black" value="O">Offre</option>
                                <option class="black" value="D">Demande</option>
                            </select>
                        </span>
                    </label>

                    <label class="search-form-item">
                        <span class="search-form-select">
                            <select class="form-select-inner" [(ngModel)]="idSelectedMaterialType" id="lexMaterialType"
                            name="materialType" #materialType="ngModel">
                            <option class="black" value="null">Type de matériau classement (GTR)</option>
                            <option class="black" *ngFor="let matType of materialTypes" [ngValue]="matType.id">
                                {{matType.label}}</option>
                            </select>
                        </span>
                    </label>

                    <div class="search-form-item">
                        <span class="search-form-number">
                            <input type="text" [(ngModel)]="quantity" [ngModelOptions]="{standalone: true}"
                                placeholder="Quantité en tonne supérieure à" class="search-form-number-input">
                        </span>
                    </div>

                    <div class="form-group">
                        <div class="search-form-item">
                            <div class="form-item form-item--6">
                                <label>Date de début</label>
                                <span class="search-form-date">
                                    <p-calendar [(ngModel)]="availStartDate" #startDate="ngModel" name="startDate"
                                        selectOtherMonths="true" dateFormat="dd/mm/yy"  monthNavigator="true"
                                        yearNavigator="true" yearRange="2000:2099" styleClass="search-form-date-input">
                                    </p-calendar>
                                </span>
                            </div>

                            <div class="form-item form-item--6">
                                <label>Date de fin</label>
                                <span class="search-form-date">
                                    <p-calendar [(ngModel)]="availEndDate" #endDate="ngModel" name="endDate"
                                        selectOtherMonths="true" dateFormat="dd/mm/yy"  monthNavigator="true"
                                        yearNavigator="true" yearRange="2000:2099" styleClass="search-form-date-input">
                                    </p-calendar>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="search-form-footer">
                    <button type="submit" class="button button--fullwidth button--white" (click)="search(true)">
                        <i class="button-icon icon-search" aria-hidden="true"></i>
                        <span class="button-label">Rechercher <span *ngIf="totalOtvDtv">({{totalOtvDtv}}
                                RÉSULTATS)</span></span>

                    </button>
                </div>

                <div class="search-form-footer">
                    <button type="submit" class="button button--fullwidth button--blue" (click)="reinitialiser()">
                        <i class="button-icon icon-search" aria-hidden="true"></i>
                        <span class="button-label">Reinitialiser</span>

                    </button>
                </div>
                <p id="result-warning" class="text-center">
                  <small>
                    Les résultats de la recherche sont des offres ou des demandes correspondant aux critères sélectionnés.
                    Si vous avez fait une recherche par site, seuls les sites qui disposent d'offres ou de demandes seront affichés.
                  </small>
                </p>
            </div>
        </form>

        <div class="search-map map">
            <app-map-view-points [sites]="sites" (selectedOtvDtv)="selectedOtvDtvChange($event)" (onZoomChangeEventEmitter)="onZoomChangeInChild($event)"></app-map-view-points>
        </div>
    </div>
</div>
<div class="table-wrapper" *ngIf="otvDtvList">
    <p-table tableStyleClass="table home-table" [value]="otvDtvList"
    [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,20,30]" pageLinks="3">

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="table-icon" scope="col"></th>
                <th scope="col">Type d'annonce</th>
                <th scope="col">Identifiant</th>
                <th scope="col">Date de création</th>
                <th scope="col">Site</th>
                <th scope="col">Quantité (t)</th>
                <th scope="col">Disponibilité</th>
                <th scope="col">Statut</th>
                <th style="text-align: center;" scope="col">Actions</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
          <tr [ngClass]="{'select-background-color': selectedOtvDtvIds?.includes(rowData['id'])}">

            <td class="table-icon" (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">
              <i [class]="rowData['public_'] ? 'green icon-padlock': 'red icon-padlock'" aria-hidden="true"></i>
            </td>

            <td (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">
              <b class="orange" *ngIf="rowData['type'] === 'O'">OTV</b>
              <b class="lightblue" *ngIf="rowData['type'] === 'D'">DTV</b>
            </td>

            <td (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">
              <a class="table-link" [routerLink]="['/offres/details', rowData['id']]"
                *ngIf="rowData['type'] ==='O'">{{rowData['id']}}</a>
              <a class="table-link" [routerLink]="['/demandes/details', rowData['id']]"
                *ngIf="rowData['type'] ==='D'">{{rowData['id']}}</a>
            </td>

            <td (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">{{rowData['creationDate'] | date:'dd MMM yyyy'}}</td>

            <td (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">
              <h3 class="h3">{{rowData['site'].name}}</h3>
              <p>{{rowData['site'].address.numberStreet}}, {{rowData['site'].address.cityInsee}}
                {{rowData['site'].address.foreignCity}}</p>
            </td>

            <td (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">{{rowData['quantity']}} t</td>

            <td (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">
              <time datetime="" class="table-tag">{{rowData['availStartDate'] | date:'dd MMM yyyy'}} -
                {{rowData['availEndDate'] | date:'dd MMM yyyy'}}</time>
            </td>

            <td [ngSwitch]="rowData['statusId']" (click)="showOtvDtvOnMap(rowData)" class="pointer-cursor">
              <b *ngSwitchCase="1" class="teal">En brouillon</b>
              <b *ngSwitchCase="2" class="green">Publiée</b>
              <b *ngSwitchCase="3" class="orange">Echue</b>
              <b *ngSwitchCase="4" class="black">Clôturée</b>
            </td>

            <td>
              <div class="table-actions">
                <button type="button" class="table-actions-toggle"></button>
                <ul class="table-actions-list">
                  <li class="table-actions-item">
                    <a (click)="showOtvDtv(rowData)" class="table-actions-link blue">
                      <i class="table-actions-icon icon-eye" aria-hidden="true"></i>
                      <span class="table-actions-label">Consulter</span>
                    </a>
                  </li>
                  <li class="table-actions-item">
                    <a (click)="createTransaction(rowData['type'], rowData['id'])" class="table-actions-link orange">
                      <i class="table-actions-icon icon-swap" aria-hidden="true"></i>
                      <span class="table-actions-label">Proposer une transaction</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </ng-template>
    </p-table>

</div>
