<div class="modal">
    <form action="" class="modal-inner">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">
            <h2 class="h1 modal-title">Souhaitez vous déclarer ce bordereau au registre RNDTS suivant : {{RndtsType[type]}} ?</h2>

            <p class="modal-text">Attention, vous vous apprêtez à déclarer un flux de terres dans le registre national électronique. Veuillez-vous assurer que la clé RNDTS entrée dans l’onglet profil correspond bien à l’entreprise pour laquelle vous allez réaliser la déclaration.</p>
            
            <div class="form-footer mt-5">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="button" value="1" class="button button--green" (click)="submit()">
                    <i class="button-icon icon-check"></i>
                    <span class="button-label">Confirmer</span>
                </button>
            </div>
        </div>
    </form>
</div>