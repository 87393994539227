import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() pageSizeOptions: number[];
  @Output() page = new EventEmitter<any>();

  _length: number;
  _size: number;
  _index: number;

  pageNumber: number;
  pages: number[];

  constructor() { }

  ngOnInit() {
    this.pages = [1, 2, 3, 4, 5];
  }

  get length() { return this._length; }
  @Input() set length(length: number) {
    this._length = length;
    if (this._length && this._size ) {
      this.pageNumber = Math.ceil(this._length / this._size);      
    }
  }

  get size() { return this._size; }
  @Input() set size(size: number) {
    this._size = size;
    if (this._length && this._size ) {
      this.pageNumber = Math.ceil(this._length / this._size);
    }
  }

  get index() { return this._index; }
  @Input() set index(index: number) {
    this._index = index;
    this.updatePages(index);
  }

  changeSize(event) {
    this.pageNumber = Math.ceil(this._length / event.target.value);
    this.updatePages(0);
    this.page.emit({
      pageIndex: 0,
      pageSize: event.target.value
    });
  }

  changePage(index: number) {
    if (index >= 0 && index < this.pageNumber) {
      this.updatePages(index);
      this.page.emit({
        pageIndex: index,
        pageSize: this._size
      });
    }
  }

  updatePages(index) {
    let aroundPages:number[] = [];

    if (index > 1 && index < this.pageNumber - 2) {
      aroundPages = [
        index - 1,
        index,
        index + 1,
        index + 2,
        index + 3
      ];
    } else if (index <= 1) {
      aroundPages = [1, 2, 3, 4, 5];
    } else {
      aroundPages = [
        this.pageNumber - 4,
        this.pageNumber - 3,
        this.pageNumber - 2,
        this.pageNumber - 1,
        this.pageNumber
      ];
    }

    this.pages = [];

    _.each(aroundPages, p => {
      if (p > 0) {
        this.pages.push(p);
      }
    });
  }

}