import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from 'src/app/fr/brgm/common/http/site/site.service';
import { Site } from 'src/app/fr/brgm/common/model/site.model';

@Component({
  selector: 'app-modal-site-invalid-parcels',
  templateUrl: './modal-site-invalid-parcels.component.html',
  styleUrls: ['./modal-site-invalid-parcels.component.scss']
})
export class ModalSiteInvalidParcelsComponent {

  @Input() site: Site;
  @Input() isCreation: boolean;

  error: string;

  constructor(
    public activeModal: NgbActiveModal,
    private siteService: SiteService,
    private router: Router,
    private modalService: NgbModal
    ) { }

  cancel() {
    this.activeModal.dismiss();
  }

  onSubmit() {

    if(this.isCreation){
      this.siteService.create(this.site).subscribe((res) => {
        this.activeModal.dismiss();
        this.router.navigate(['sites']);
      }, _error => {
          this.error = 'Une erreur s\'est produite lors de la création du site.';
          setTimeout(()=> this.error = null,4000);
      });
    }
    else{
      this.siteService.update(this.site.id, this.site).subscribe((res:Site)=> {
        this.site = res;
        this.activeModal.dismiss();
        this.router.navigate(['/sites/details/'+this.site.id]);
      }, _error => {
          this.error = 'Une erreur s\'est produite lors de la mise à jour du site.';
          setTimeout(()=> this.error = null,4000);
      });

    }

  }

}
