import { Deserializable } from '../../model/Deserializable';

export class Criteria implements Deserializable{ 
    constructor(
        public queryItem?: string,
        public date?:Date[],
        public statusIds?:number[],
        public isExpired?:boolean,
        public siteId?:number,
        public canCreateTransaction?: boolean
    ) {}

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}