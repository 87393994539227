import { Deserializable } from 'src/app/fr/brgm/common/model/Deserializable';
import { Site } from 'src/app/fr/brgm/common/model/site.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { AnalysisDeclaration } from 'src/app/fr/brgm/common/model/analysisdeclaration.model';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { DocumentTerrass } from 'src/app/fr/brgm/common/model/document.model';
import { LexDemandStatus } from 'src/app/fr/brgm/common/model/lex/lexdemandstatus.model';
import { LexMaterialType } from 'src/app/fr/brgm/common/model/lex/lexmaterialtype.model';
import { LexObject } from 'src/app/fr/brgm/common/model/lex/lexObject.model';
import { LexOfferStatus } from 'src/app/fr/brgm/common/model/lex/lexofferstatus.model';

export class Annonce implements Deserializable {

    constructor(
        public id?: number,
        public availStartDate?: Date,
        public availEndDate?: Date,
        public infos?: string,
        public bstvIcpe?:string,
        public public_?: boolean,
        public quantity?: number,
        public lexMaterialType?: LexMaterialType,
        public lexCodeDechet?: LexObject,
        public site?: Site,
        public user?: User,
        public lexOfferStatus?: LexOfferStatus,
        public lexDemandStatus?: LexDemandStatus,
        public documents?: DocumentTerrass[],
        public analysisDeclaration?: AnalysisDeclaration,
        public analysisResults?: AnalysisElemResult[],
        public isLinkToTransaction?: boolean,
        public confidentialCode?: string,
        public geometry?:any
    ) {}

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
