import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LexCountry } from '../../model/lex/lexcountry.model';
import { LexDemandStatus } from '../../model/lex/lexdemandstatus.model';
import { FondPedogeochimique } from '../../model/lex/fondpedogeochimique.model';
import { LexGuideDechet } from '../../model/lex/lexGuideDechet.model';
import { LexMaterialType } from '../../model/lex/lexmaterialtype.model';
import { LexMolecule } from '../../model/lex/lexmolecule.model';
import { LexObject } from '../../model/lex/lexObject.model';
import { LexOfferStatus } from '../../model/lex/lexofferstatus.model';
import { LexSiteStatus } from '../../model/lex/lexsitestatus.model';
import { LexSiteType } from '../../model/lex/lexsitetype.model';
import { LexTransactionStatus } from '../../model/lex/lextransactionstatus.model';
import { LexTransporterStatus } from '../../model/lex/lextransporterstatus.model';
import { LexTransportMode } from '../../model/lex/lextransportmode.model';
import { LexUserParam } from '../../model/lex/lexuserparam.model';
import { LexUserStatus } from '../../model/lex/lexuserstatus.model';
import { LexRndtsTypePersonne } from '../../model/lex/lexrndtstypepersonne.model';
import { LexRndtsCodeTraitement } from '../../model/lex/lexrndtscodetraitement.model';
import { LexRndtsCodeQualification } from '../../model/lex/lexrndtscodequalification.model';

@Injectable({
  providedIn: 'root'
})
export class LexiqueService {

  private resourceUrl =  environment.apiUrl;
  private SEPARATOR =   '/';

  constructor(private http: HttpClient,) { }

  getLexCodeDechets():Observable<LexObject[]> {
    return this.http.get(this.resourceUrl + 'lexCodeDechet' + this.SEPARATOR + "findall").pipe(map((res => { return this.convertLexiqueObject(res)})));
  }

  getLexMaterialTypes():Observable<LexMaterialType[]> {
    return this.http.get(this.resourceUrl + 'lexMaterialType' + this.SEPARATOR + "findall").pipe(map((res => { return this.convertMaterial(res)})));
  }

  getLexDemandStatus():Observable<LexDemandStatus[]> {
    return this.http.get(this.resourceUrl + 'lexDemandStatus' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertDemandStatus(res)}));
  }

  getLexOfferStatus():Observable<LexOfferStatus[]> {
    return this.http.get(this.resourceUrl + 'lexOfferStatus' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertOfferStatus(res)}));
  }

  getLexRndtsTypePersonne():Observable<LexRndtsTypePersonne[]> {
    return this.http.get(this.resourceUrl + 'lexRndtsTypePersonne' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertOfferStatus(res)}));
  }

  getLexRndtsCodeTraitement():Observable<LexRndtsCodeTraitement[]> {
    return this.http.get(this.resourceUrl + 'lexRndtsCodeTraitement' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertOfferStatus(res)}));
  }

  getLexRndtsCodeQualification():Observable<LexRndtsCodeQualification[]> {
    return this.http.get(this.resourceUrl + 'lexRndtsCodeQualification' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertOfferStatus(res)}));
  }

  getLexMolecules():Observable<LexMolecule[]> {
    return this.http.get(this.resourceUrl + 'lexMolecule' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertMolecule(res)}));
  }

  getLexTransactionStatus(): Observable<LexTransactionStatus[]> {
    return this.http.get(this.resourceUrl + 'lexTransactionStatus' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertTransactionStatus(res)}));
  }

  getLexTransporterStatus():Observable<LexTransporterStatus[]> {
    return this.http.get(this.resourceUrl + 'lexTransporterStatus' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertTransporterStatus(res)}));
  }

  getLexBordereauStatus():Observable<LexObject[]> {
    return this.http.get(this.resourceUrl + 'lexBordereauStatus' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertLexiqueObject(res)}));
  }

  getLexSiteStatus():Observable<LexSiteStatus[]> {
    return this.http.get(this.resourceUrl + 'lexSiteStatus' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertSiteStatus(res)}));
  }

  getLexSiteType():Observable<LexSiteType[]> {
    return this.http.get(this.resourceUrl + 'lexSiteType' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertSiteType(res)}));
  }

  getLexUserParam():Observable<LexUserParam[]> {
    return this.http.get(this.resourceUrl + 'lexUserParam' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertUserParam(res)}));
  }

  getLexUserStatus():Observable<LexUserStatus[]> {
    return this.http.get(this.resourceUrl + 'lexUserStatus' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertUserStatus(res)}));
  }

  getLexTransportMode():Observable<LexTransportMode[]> {
    return this.http.get(this.resourceUrl + 'lexTransportMode' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertTransportMode(res)}));
  }

  getLexCountry():Observable<LexCountry[]> {
    return this.http.get(this.resourceUrl + 'lexCountry' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertCountry(res)}));
  }

  getLexGuideDechet():Observable<LexGuideDechet[]> {
    return this.http.get(this.resourceUrl + 'lexGuideDechet' + this.SEPARATOR + "findall").pipe(map((res)=> { return this.convertGuideDechet(res)}));
  }

  getLexFondPedogeochimique():Observable<FondPedogeochimique[]> {
    return this.http.get(this.resourceUrl + 'fondPedogeochimique' + this.SEPARATOR + "findall").pipe(map((res => { return this.convertLexFondPedogeochimique(res)})));
  }

  private convertMaterial(res): LexMaterialType[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexMaterialType().deserialize(res[i]));
    }
    return result;
  }

  private convertDemandStatus(res): LexDemandStatus[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexDemandStatus().deserialize(res[i]));
    }
    return result;
  }

  private convertOfferStatus(res): LexOfferStatus[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexOfferStatus().deserialize(res[i]));
    }
    return result;
  }

  private convertMolecule(res): LexMolecule[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexMolecule().deserialize(res[i]));
    }
    return result;
  }

  private convertTransactionStatus(res): LexTransactionStatus[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexTransactionStatus().deserialize(res[i]));
    }
    return result;
  }

  private convertTransporterStatus(res): LexTransporterStatus[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexTransporterStatus().deserialize(res[i]));
    }
    return result;
  }

  private convertLexiqueObject(res): LexObject[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexObject().deserialize(res[i]));
    }
    return result;
  }

  private convertSiteStatus(res): LexSiteStatus[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexSiteStatus().deserialize(res[i]));
    }
    return result;
  }

  private convertSiteType(res): LexSiteType[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexSiteType().deserialize(res[i]));
    }
    return result;
  }

  private convertUserParam(res): LexUserParam[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexUserParam().deserialize(res[i]));
    }
    return result;
  }

  private convertUserStatus(res): LexUserStatus[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexUserStatus().deserialize(res[i]));
    }
    return result;
  }

  private convertTransportMode(res): LexTransportMode[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexTransportMode().deserialize(res[i]));
    }
    return result;
  }

  private convertCountry(res): LexCountry[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexCountry().deserialize(res[i]));
    }
    return result;
  }

  private convertGuideDechet(res): LexGuideDechet[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new LexGuideDechet().deserialize(res[i]));
    }
    return result;
  }

  private convertLexFondPedogeochimique(res): FondPedogeochimique[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new FondPedogeochimique().deserialize(res[i]));
    }
    return result;
  }
}
