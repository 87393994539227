import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from './app.constants';
import { IsSignedInGuard } from './fr/brgm/common/guards/is-signed-in.guard';
import { LoginPageComponent } from './public/login-page/login-page.component';
import { RegisterComponent } from './public/register/register.component';

const routes: Routes = [
  // Rediriger vers l'accueil par défaut
  //{path: '', redirectTo: '/accueil', pathMatch: 'full', canActivate: [ IsSignedInGuard] }, Ne fonctionne pas avec optimization: false
  {path: '', pathMatch: 'full', canActivate: [ IsSignedInGuard], children: [{path:'', redirectTo: '/accueil', pathMatch: 'full'}] },
  // Login page routing
  {path: 'login', component: LoginPageComponent, data: {title: APP_PREFIX  + 'Login'}},
  // Register page routing
  {path: 'register', component: RegisterComponent, data: {title: APP_PREFIX  + 'Création d\'utilisateur'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() { }
}
