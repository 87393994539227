import { Deserializable } from '../Deserializable';

export class LexObject implements Deserializable {

  constructor(
    public id?: number,
    public label?: string,
    public code?: string,
    public simplifiedLabel?: string
  ) {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

}
