import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from '../fr/brgm/common/guards/is-signed-in.guard';
import { CarriersPageComponent } from './carriers-page/carriers-page.component';
import { CreateCarrierPageComponent } from './create-carrier-page/create-carrier-page.component';
import { EditCarrierPageComponent } from './edit-carrier-page/edit-carrier-page.component';
import { ViewCarrierPageComponent } from './view-carrier-page/view-carrier-page.component';

const carriersRoutes: Routes = [
    { path: 'carriers', component: CarriersPageComponent, data: {title: APP_PREFIX  + 'Transporteur'}, canActivate: [ IsSignedInGuard] },
    { path: 'carriers/details/:id', component: ViewCarrierPageComponent, data: {title: APP_PREFIX  + 'Détail d\' un transporteur'}, canActivate: [ IsSignedInGuard] },
    { path: 'carriers/edit/:id', component: EditCarrierPageComponent, data: {title: APP_PREFIX  + 'Modifier un transporteur'}, canActivate: [ IsSignedInGuard] },
    { path: 'carriers/create', component: CreateCarrierPageComponent, data: {title: APP_PREFIX + 'Créer un transporteur'}, canActivate: [IsSignedInGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(carriersRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class TransporteursRoutingModule { }