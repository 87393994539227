<div class="wrapper">
    <div class="container" *ngIf="loaded">
        <div class="page-sticky">
            <a routerLink="/carriers" routerLinkActive="active" class="button button--outline button--teal">
                <i class="button-icon icon-left"></i>
                <span class="button-label">Retour</span>
            </a>
    
            <div class="page-header">
                <h1 class="h1">Consultation du transporteur</h1>
    
                <ul class="page-actions">
                    <li class="page-actions-item">
                        <a class="button button--black" style="color: #FFF" *ngIf="transporter.lexTransporterStatus?.label === 'Actif'" (click)="archiver()">
                            <i class="button-icon icon-open-folder"></i>
                            <span class="button-label">Archiver</span>
                        </a>
                        <a class="button button--green" style="color: #FFF" *ngIf="transporter.lexTransporterStatus?.label === 'Archivé'" (click)="activer()">
                            <i class="button-icon icon-open-folder"></i>
                            <span class="button-label">Activer</span>
                        </a>
                    </li>
    
                    <li class="page-actions-item">
                        <a [routerLink]="['/carriers/edit',transporter?.id]" routerLinkActive="active" class="button button--orange" 
                        *ngIf="transporter.lexTransporterStatus?.label != 'Archivé'">
                            <i class="button-icon icon-edit"></i>
                            <span class="button-label">Modifier</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    
        <div class="details">
            <span class="details-tag details-tag--teal">{{transporter?.lexTransportMode?.label}}</span>
    
            <div class="details-header">
                <h2 class="details-title h1">{{transporter?.name}}</h2>
            </div>
    
            <div class="details-body">
                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-info"></i>
                        <h3 class="details-section-title">Informations générales</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-group">
                            <div class="form-item form-item--12">
                                <p class="details-label">N° de SIRET</p>
                                <p class="details-value">{{transporter?.siret}}</p>
                            </div>
                            <div class="form-item">
                                <p class="details-label">Email</p>
                                <p class="details-value">{{transporter?.email}}</p>
                            </div>
                            <div class="form-item">
                                <p class="details-label">Téléphone</p>
                                <p class="details-value">{{transporter?.phoneNumber}}</p>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-pin"></i>
                        <h3 class="details-section-title">Localisation du transporteur</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-group">
                            <div class="form-item form-item--12">
                                <p class="details-label">Adresse</p>
                                <p class="details-value">{{transporter?.address?.numberStreet}}, {{transporter?.address?.zipCode}} {{transporter?.address?.foreignCity}}, {{transporter?.address?.lexCountry?.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
