import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from '../fr/brgm/common/guards/is-signed-in.guard';
import { BstvCreatePageComponent } from './bstv-create-page/bstv-create-page.component';
import { BstvPageComponent } from './bstv-page/bstv-page.component';
import { LotCreateComponent } from './lot-create/lot-create.component';
import { LotEditComponent } from './lot-edit/lot-edit.component';
import { RndtsPageComponent } from "./rndts-page/rndts-page.component";
import { ViewBstvPageComponent } from './view-bstv-page/view-bstv-page.component';

const bordereauxRoutes: Routes = [
    { path: 'bordereaux', component: BstvPageComponent, data: {title: APP_PREFIX  + 'Bordereaux'}, canActivate: [ IsSignedInGuard] },
    { path: 'bordereaux/rndts/:id', component: RndtsPageComponent, data: {title: APP_PREFIX  + 'Déclaration au RNDTS'}, canActivate: [ IsSignedInGuard] },
    { path: 'bordereaux/details/:id', component: ViewBstvPageComponent, data: {title: APP_PREFIX  + 'Détail d\' un bordereau'}, canActivate: [ IsSignedInGuard] },
    { path: 'bordereaux/edit/:id', component: BstvCreatePageComponent, data: {title: APP_PREFIX  + 'Modifier un bordereau'}, canActivate: [ IsSignedInGuard] },
    { path: 'bordereaux/create/:idTransaction', component: BstvCreatePageComponent, data: {title: APP_PREFIX + 'Créer un bordereau'}, canActivate: [IsSignedInGuard] },
    { path: 'bordereaux/lot/create/:idBordereau', component: LotCreateComponent, data: {title: APP_PREFIX + 'Créer un lot'}, canActivate: [IsSignedInGuard] },
    { path: 'bordereaux/lot/edit/:idBordereau/:idLot', component: LotEditComponent, data: {title: APP_PREFIX + 'Editer un lot'}, canActivate: [IsSignedInGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(bordereauxRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class BordereauxRoutingModule { }