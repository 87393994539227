import { Deserializable } from './Deserializable';
import { DocumentTerrass } from './document.model';
import { LexDemandClosureReason } from './lex/lexdemandclosurereason.model';
import { LexDemandStatus } from './lex/lexdemandstatus.model';
import { LexMaterialType } from './lex/lexmaterialtype.model';
import { LexUsage } from './lex/lexusage.model';
import { OTV } from './otv.model';
import { Site } from './site.model';
import { User } from './user.model';

export class DTV implements Deserializable {
    constructor(
        public id?: number,
        public availStartDate?:Date,
        public availEndDate?:Date,
        public closureDate?:Date,
        public creationDate?:Date,
        public publicationDate?:Date,
        public denomination?:string,
        public infos?:string,
        public bstvIcpe?:string,
        public public_?:boolean,
        public quantity?:number,
        public scanningDistance?:number,
        public scanningMode?:number,
        public lexMaterialType?:LexMaterialType,
        public lexDemandClosureReason?:LexDemandClosureReason,
        public lexDemandStatus?:LexDemandStatus,
        public lexUsage?:LexUsage,
        public site?:Site,
        public user?:User,
        public excludedOffer?:OTV[],
        public documents?:DocumentTerrass[],
        public isModifiable?:boolean,
        public isLinkToTransaction?: boolean,
        public currentUserCanUpdate?: boolean,
        public currentUserCanDuplicate?: boolean,
        public geometry?:any
    ) {}
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
