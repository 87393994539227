import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { Lot } from '../model/lot.model';

@Injectable({
  providedIn: 'root'
})
export class LotService {

  private resourceUrl =  environment.apiUrl + 'lotbordereau';
  private SEPARATOR =   '/';
  constructor(private http: HttpClient) { }

  getByBordereauId(id: number): Observable<Lot[]> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + 'findByIdBordereau' + this.SEPARATOR + id).pipe(map((res:Lot[])=> res));
  }

  create(lot: Lot): Observable<Lot> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + 'create', lot).pipe(map((res: Lot) => res));
  }

  getById(id: number): Observable<Lot>{
    return this.http.get(this.resourceUrl + this.SEPARATOR + id).pipe(map((res: Lot) => res));
  }

  updateLot(lot: Lot): Observable<Lot>{
    return this.http.put(this.resourceUrl + this.SEPARATOR + 'update', lot).pipe(map((res: Lot) => res));
  }

  delete(lot: Lot) {
    return this.http.delete(this.resourceUrl + this.SEPARATOR + 'delete' + this.SEPARATOR + lot.id).pipe();
  }
}
