import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { EntityObjectService } from "src/app/00-shared/services/entity-object.service";
import { ApiAdresseService } from "src/app/fr/brgm/common/http/api-adresse/api-adresse.service";
import { BordereauService } from "src/app/fr/brgm/common/http/bodereau/bordereau.service";
import { LexiqueService } from "src/app/fr/brgm/common/http/lex/lexique.service";
import { RndtsService } from "src/app/fr/brgm/common/http/rndts/rndts.service";
import { UserService } from "src/app/fr/brgm/common/http/user/user.service";
import { Bordereau } from "src/app/fr/brgm/common/model/bordereau.model";
import { EntityObject } from "src/app/fr/brgm/common/model/entityobject.model";
import { Featurecollection } from "src/app/fr/brgm/common/model/featurecollection.model";
import { LexRndtsCodeQualification } from "src/app/fr/brgm/common/model/lex/lexrndtscodequalification.model";
import { LexRndtsCodeTraitement } from "src/app/fr/brgm/common/model/lex/lexrndtscodetraitement.model";
import { LexRndtsTypePersonne } from "src/app/fr/brgm/common/model/lex/lexrndtstypepersonne.model";
import { RndtsResponse } from "src/app/fr/brgm/common/model/rndts/rndtsResponse.model";
import { TerresSedimentsEntrants } from "src/app/fr/brgm/common/model/rndts/terresSedimentsEntrants.model";
import { TerresSedimentsSortants } from "src/app/fr/brgm/common/model/rndts/terresSedimentsSortants.model";
import { User } from "src/app/fr/brgm/common/model/user.model";
import { ModalChooseRNDTSTypeComponent } from "src/app/modal/modal-choose-rndts-type/modal-choose-rndts-type.component";
import { ModalRndtsConfirmDeclarationComponent } from "src/app/modal/modal-rndts-confirm-declaration/modal-rndts-confirm-declaration.component";
import { ModalRNDTSResponseComponent } from "src/app/modal/modal-rndts-response/modal-rndts-response.component";

export enum RndtsType{
  ENTRANT,
  SORTANT
}

@Component({
  selector: "app-profile-page",
  templateUrl: "./rndts-page.component.html",
  styleUrls: ["./rndts-page.component.scss"],
})
export class RndtsPageComponent implements OnInit {
  @ViewChild("divToModalClick") divToModalClick: ElementRef;
  
  currentUser: User;
  bordereau: Bordereau;
  userStatus: number;
  typesPersonne: LexRndtsTypePersonne[];
  codesTraitement: LexRndtsCodeTraitement[];
  codesQualification: LexRndtsCodeQualification[];
  RndtsType = RndtsType;
  type: RndtsType;
  terresSedimentsEntrants: TerresSedimentsEntrants;
  terresSedimentsSortants: TerresSedimentsSortants;
  dateError: string;
  unknownSiren = "inconnue";
  invalidSiren: boolean = false;
  adresses: Featurecollection;
  adressesTransporteur: Featurecollection;
  requete: string;
  requeteTransporteur: string;
  denominationUsuelleEmpty: boolean = true;
  raisonSocialeTransporteurEmpty: boolean = true;

  constructor(
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute,
    private userService: UserService,
    private bordereauService: BordereauService,
    private lexiqueService: LexiqueService,
    private rndtsService: RndtsService,
    private entityObjectService: EntityObjectService,
    private apiAdresseService: ApiAdresseService,
  ) {
    
    this.currentUser = new User();
    this.currentUser.entityObject = new EntityObject();
    this.typesPersonne = [];
    this.codesTraitement = [];
    this.codesQualification = [];
  }

  ngOnInit() {
    this.initUser();
    this.initLexiques();
    this.activatedroute.params.subscribe((params) => {
      this.initBordereau(Number(params.id));
      });
  }

  initBordereau(bordereauId : number){
    this.bordereauService.get(bordereauId).subscribe((bordereau: Bordereau) => {
      this.bordereau = bordereau;
      if(bordereau.currentUserCanDeclareToRndtsSortants && !bordereau.currentUserCanDeclareToRndtsEntrants){
        this.type = RndtsType.SORTANT;
        this.rndtsService.getSortantsPreFilled(bordereauId).subscribe((response: RndtsResponse) => {
          this.terresSedimentsSortants = response.terresSediments;
          if(this.terresSedimentsSortants.denominationUsuelle != null){
            this.denominationUsuelleEmpty = false;
          }
          if(this.terresSedimentsSortants.transporteurs[0].raisonSociale != null){
            this.raisonSocialeTransporteurEmpty = false
          }
        });
      } else if(bordereau.currentUserCanDeclareToRndtsEntrants && !bordereau.currentUserCanDeclareToRndtsSortants){
        this.type = RndtsType.ENTRANT;
        this.rndtsService.getEntrantsPreFilled(bordereauId).subscribe((response: RndtsResponse) => {
          this.terresSedimentsEntrants = response.terresSediments;
          if(this.terresSedimentsEntrants.denominationUsuelle != null){
            this.denominationUsuelleEmpty = false;
          }
          if(this.terresSedimentsEntrants.transporteurs[0].raisonSociale != null){
            this.raisonSocialeTransporteurEmpty = false
          }
        });
      } else if(bordereau.currentUserCanDeclareToRndtsEntrants && bordereau.currentUserCanDeclareToRndtsSortants){
        // Ouverture Modal pour choix du type
        const modalRef = this.modalService.open(ModalChooseRNDTSTypeComponent);
        this.divToModalClick.nativeElement.click(); // Permet de forcer l'affichage de la modal
        modalRef.result.then((result) => {
          if(result == RndtsType.ENTRANT){
            this.type = RndtsType.ENTRANT;
            this.rndtsService.getEntrantsPreFilled(bordereauId).subscribe((response: RndtsResponse) => {
              this.terresSedimentsEntrants = response.terresSediments;
              if(this.terresSedimentsEntrants.denominationUsuelle != null){
                this.denominationUsuelleEmpty = false;
              }
              if(this.terresSedimentsEntrants.transporteurs[0].raisonSociale != null){
                this.raisonSocialeTransporteurEmpty = false
              }
            });
          } else{
            this.type = RndtsType.SORTANT;
            this.rndtsService.getSortantsPreFilled(bordereauId).subscribe((response: RndtsResponse) => {
              this.terresSedimentsSortants = response.terresSediments;
              if(this.terresSedimentsSortants.denominationUsuelle != null){
                this.denominationUsuelleEmpty = false;
              }
              if(this.terresSedimentsSortants.transporteurs[0].raisonSociale != null){
                this.raisonSocialeTransporteurEmpty = false
              }
            });
          }
        }).catch((error) => {});
      }
    });
  }

  initLexiques() {
    this.lexiqueService.getLexRndtsTypePersonne().subscribe((typesPersonne: LexRndtsTypePersonne[]) => {
      this.typesPersonne = typesPersonne;
    });
    this.lexiqueService.getLexRndtsCodeTraitement().subscribe((codesTraitement: LexRndtsCodeTraitement[]) => {
      this.codesTraitement = codesTraitement;
    });
    this.lexiqueService.getLexRndtsCodeQualification().subscribe((codesQualification: LexRndtsCodeQualification[]) => {
      this.codesQualification = codesQualification;
    });
  }

  initUser() {
    this.userService.getCurrentUser().subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  confirmDeclaration(form: NgForm) {
    if (form.form.status == "VALID") {
       // Ouverture Modal pour confirmation de la déclaration
       const modalRef = this.modalService.open(ModalRndtsConfirmDeclarationComponent);
       this.divToModalClick.nativeElement.click(); // Permet de forcer l'affichage de la modal
       modalRef.componentInstance.type = this.type;
       modalRef.result.then((result) => {
         if(result){
          let ngbModalOptions: NgbModalOptions = {
            backdrop : 'static',
            keyboard : false
          };
          if(this.type == RndtsType.ENTRANT){
              this.rndtsService.sendEntrants(this.terresSedimentsEntrants).subscribe((result: RndtsResponse) => {
                const modalRef = this.modalService.open(ModalRNDTSResponseComponent, ngbModalOptions);
                this.divToModalClick.nativeElement.click();
                modalRef.componentInstance.response = result;
              },
              (error: any) => {
                const modalRef = this.modalService.open(ModalRNDTSResponseComponent, ngbModalOptions);
                this.divToModalClick.nativeElement.click();
                modalRef.componentInstance.response = error.error;
              });
            } else if(this.type == RndtsType.SORTANT){
              this.rndtsService.sendSortants(this.terresSedimentsSortants).subscribe((result: RndtsResponse) => {
                const modalRef = this.modalService.open(ModalRNDTSResponseComponent, ngbModalOptions);
                this.divToModalClick.nativeElement.click();
                modalRef.componentInstance.response = result;
              },
              (error: any) => {
                const modalRef = this.modalService.open(ModalRNDTSResponseComponent, ngbModalOptions);
                this.divToModalClick.nativeElement.click();
                modalRef.componentInstance.response = error.error;
              });
            }
        }
       }).catch((error) => {});
    }
  }

  onDateSelect(): void {
    this.dateError = null;
    this.isValideDate();
  }

  isValideDate(): boolean {
    switch(this.type){
      case RndtsType.ENTRANT:
        if (this.terresSedimentsEntrants.dateReception > new Date()) {
          this.dateError = "La date de réception ne peut pas être dans le futur.";
          return false;
        }
        break;
      case RndtsType.SORTANT:
        if (this.terresSedimentsSortants.dateExpedition > new Date()) {
          this.dateError = "La date d'expédition ne peut pas être dans le futur.";
          return false;
        }
        break;
    }
    return true;
  }

  getSiren(e) {
    switch(this.type){
      case RndtsType.ENTRANT:
        this.terresSedimentsEntrants.expediteur.numeroIdentification = this.terresSedimentsEntrants.expediteur.numeroIdentification.replace(/\s/g, '');
        if (this.terresSedimentsEntrants.expediteur.numeroIdentification.length == 9) {
          this.entityObjectService.getEntityObject(this.terresSedimentsEntrants.expediteur.numeroIdentification).subscribe(
            res => {
              this.terresSedimentsEntrants.expediteur.raisonSociale = res.socialReason;
              this.invalidSiren = false;
            },
            err => {
              /*this.terresSedimentsEntrants.expediteur.raisonSociale = this.unknownSiren;
              this.invalidSiren = true;*/
            }
          );
        }
        break;
      case RndtsType.SORTANT:
        this.terresSedimentsSortants.destinataire.numeroIdentification = this.terresSedimentsSortants.destinataire.numeroIdentification.replace(/\s/g, '');
        if (this.terresSedimentsSortants.destinataire.numeroIdentification.length == 9) {
          this.entityObjectService.getEntityObject(this.terresSedimentsSortants.destinataire.numeroIdentification).subscribe(
            res => {
              this.terresSedimentsSortants.destinataire.raisonSociale = res.socialReason;
              this.invalidSiren = false;
            },
            err => {
              /*this.terresSedimentsSortants.destinataire.raisonSociale = this.unknownSiren;
              this.invalidSiren = true;*/
            }
          );
        }
        break;
    }
  }

  doOnSelectAdresse(event) {
    switch(this.type){
      case RndtsType.ENTRANT:
        this.terresSedimentsEntrants.expediteur.adresse.libelle = event.properties.name;
        this.terresSedimentsEntrants.expediteur.adresse.codePostal = event.properties.postcode;
        this.terresSedimentsEntrants.expediteur.adresse.commune = event.properties.city;
        break;
      case RndtsType.SORTANT:
        this.terresSedimentsSortants.destinataire.adresse.libelle = event.properties.name;
        this.terresSedimentsSortants.destinataire.adresse.codePostal = event.properties.postcode;
        this.terresSedimentsSortants.destinataire.adresse.commune = event.properties.city;
        break;
    }
  }

  doOnSelectAdresseTransporteur(event) {
    switch(this.type){
      case RndtsType.ENTRANT:
        this.terresSedimentsEntrants.transporteurs[0].adresse.libelle = event.properties.name;
        this.terresSedimentsEntrants.transporteurs[0].adresse.codePostal = event.properties.postcode;
        this.terresSedimentsEntrants.transporteurs[0].adresse.commune = event.properties.city;
        break;
      case RndtsType.SORTANT:
        this.terresSedimentsSortants.transporteurs[0].adresse.libelle = event.properties.name;
        this.terresSedimentsSortants.transporteurs[0].adresse.codePostal = event.properties.postcode;
        this.terresSedimentsSortants.transporteurs[0].adresse.commune = event.properties.city;
        break;
    }
  }

  searchAdresse(event) {
    this.apiAdresseService
      .getAdresseByTexte(event.query)
      .subscribe((res) => (this.adresses = res));
  }

  searchAdresseTransporteur(event) {
    this.apiAdresseService
      .getAdresseByTexte(event.query)
      .subscribe((res) => (this.adressesTransporteur = res));
  }
}
