import { Injectable, Injector } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { UserService } from './user/user.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router, private userService: UserService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      setHeaders: {
        'Authorization': 'Bearer ' + localStorage.getItem('Bearer'),
        'Access-Control-Allow-Origin': '*'
      }
    });
    //req.headers.set('Authorization', 'Bearer ' +localStorage.getItem('Bearer')).append('Access-Control-Allow-Origin', '*');
    return next.handle(authReq);
  }
}