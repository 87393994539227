import { Deserializable } from './Deserializable';
import { LexAnalysisDeclStatus } from './lex/lexanalysisdeclstatus.model';
import { LexSamplingMode } from './lex/lexsamplingmode.model';
import { AnalysisElemResult } from './analysiselemresult.model';
import { EnvQuality } from './envquality.model';
import { AnalysisType } from './analysis-type.model';
import { FondPedogeochimique } from './lex/fondpedogeochimique.model';

export class AnalysisDeclaration implements Deserializable {
    constructor(
        public id?: number,
        public creationDate?:Date,
        public infos?:string,
        public nbSamples?:number,
        public lexAnalysisDeclStatus?:LexAnalysisDeclStatus,
        public lexSamplingMode?:LexSamplingMode,
        public analysisElemResults?:AnalysisElemResult[],
        public envQuality?: EnvQuality,
        public analysisType?: AnalysisType,
        public fondPedogeochimique?: FondPedogeochimique
    ) {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
