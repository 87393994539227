<div class="modal"><!-- Ajouter modal--opened pour ouvrir -->
  <form action="" class="modal-inner">
      <button type="button" class="modal-close icon-close" (click)="onClose()"></button>

      <div class="modal-body">

          <h2 class="h1 modal-title">Création de compte</h2>


          <p *ngIf="!firstSubscriber" class="modal-text">Votre compte TERRASS a bien été créé et est en attente de validation. Merci de vérifier vos mails pour plus d'informations.</p>
          <p *ngIf="firstSubscriber" class="modal-text">Votre compte TERRASS a bien été créé. Vous êtes maintenant administrateur pour votre société.</p>

          <div class="form-footer">
            <button type="submit" class="button button--blue" (click)="onSubmit()">
              <i class="button-icon icon-right" aria-hidden="true"></i>
              <span class="button-label">Accéder à la page de connexion</span>
            </button>
          </div>
      </div>
  </form>
</div>
