<div class="modal">
  <div class="modal-inner">

    <button type="button" class="modal-close icon-close" (click)="onClose()"></button>

    <div class="modal-body">

      <h2 class="h1 modal-title">Code de partage de l'annonce</h2>

      <br>

      <div class="form-group form-item-inner">
        <div class="form-item">
          <input type="text" class="form-input" size="15" [value]="confidentialCode" readonly />
        </div>
        <div class="form-item">
          <a id="copy" class="button button--green" (click)="onCopy()">Copier</a>
        </div>
      </div>

      <br>

      <p class="modal-text">
        <strong>Attention</strong>, ne divulguez votre code qu'à des personnes de confiance. Une fois ce code partagé, il ne vous sera
        plus possible de rendre l'annonce confidentielle.
      </p>

    </div>
  </div>
