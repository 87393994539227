import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Coordinate } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import { calendar_fr } from 'src/app/app.constants';
import { DtvService } from 'src/app/fr/brgm/common/http/dtv/dtv.service';
import { OtvService } from 'src/app/fr/brgm/common/http/otv/otv.service';
import { AnalysisDeclaration } from 'src/app/fr/brgm/common/model/analysisdeclaration.model';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { DocumentTerrass } from 'src/app/fr/brgm/common/model/document.model';
import { DTV } from 'src/app/fr/brgm/common/model/dtv.model';
import { LexDemandStatus } from 'src/app/fr/brgm/common/model/lex/lexdemandstatus.model';
import { LexDocumentType } from 'src/app/fr/brgm/common/model/lex/lexdocumenttype.model';
import { LexOfferStatus } from 'src/app/fr/brgm/common/model/lex/lexofferstatus.model';
import { OTV } from 'src/app/fr/brgm/common/model/otv.model';
import { ModalAnnouncementStatusChoiceComponent } from 'src/app/modal/modal-announcement-status-choice/modal-announcement-status-choice.component';
import { v4 as uuidv4 } from 'uuid';
import { UploadFichierService } from '../upload-file/upload-fichier.service';
import { Annonce } from './annonce.model';

@Component({
	selector: 'annonce-form',
	templateUrl: './annonce-form.component.html',
	styleUrls: ['./annonce-form.component.css']
})
export class AnnonceFormComponent implements OnInit {

  @ViewChild('divToModalClick') divToModalClick: ElementRef;

	@Input() isCreation: boolean;
	@Input() annonceInput: Annonce;
	@Input() isDuplication: boolean;

  @Input() isOffer: boolean;

	_isOffer: boolean;
	annonce: Annonce = null;

	etapeActive: number;
	progression: number;

	fr: any = calendar_fr;

	private defaultAnalysisResults: AnalysisElemResult[] = [];

	analysis: AnalysisDeclaration = new AnalysisDeclaration();

	techniquesFiles: File[];
	analysesFiles: File[];
	analysesSurLixiviatFiles: File[];
	planOfferFiles: File[];
	planDemandFiles: File[];
	private myDocs: DocumentTerrass[] = [];

	fieldsetTitle1 = "Documents techniques";
	fieldsetTitle2 = "Bordereau(x) analyses";
	fieldsetTitle3 = "Bordereau(x) analyses sur lixiviat";
	fieldsetTitle4 = "Plan de la zone d'extraction des terres ou localisation de l'andain";
	fieldsetTitle5 = "Plan de la zone de valorisation des terres";
	uploadFileTechId1 = "uploadFileTechId1";
	uploadFileAnasId2 = "uploadFileAnasId2";
	uploadFileLixiId3 = "uploadFileLixiId3";
	uploadFilePlanOfferId4 = "uploadFilePlanOfferId4";
	uploadFilePlanDemandId5 = "uploadFilePlanDemandId5";

	pointCoordinates: Coordinate;

	constructor(
		private otvService: OtvService,
		private dtvService: DtvService,
		private vps: ViewportScroller,
		private uploadFichierService: UploadFichierService,
		private router: Router,
    private modalService: NgbModal,
  ) { }

	ngOnInit() {

		this._isOffer = this.isOffer;

		if (this.isCreation) {
			this.annonce = new Annonce();
			this.annonce.public_ = true;
		}

		if (!this.isCreation) {
			this.annonce = this.annonceInput;
		}

		this.etapeActive = 1;
		this.progression = 25;

		if (this.annonce?.site?.geometry) {
			var geometry = new GeoJSON().readGeometry(this.annonce.site.geometry).transform('EPSG:4326', 'EPSG:3857');
			this.pointCoordinates = this.getCenterOfExtent(geometry.getExtent());
		}


	}

	typeChangeInChild(event) {
		this._isOffer = event;
	}

	createOffer() {
		let offre = new OTV();

		offre.availStartDate = this.annonce.availStartDate;
		offre.availEndDate = this.annonce.availEndDate;
		offre.infos = this.annonce.infos;
		offre.bstvIcpe = this.annonce.bstvIcpe;
		offre.public_ = this.annonce.public_;
		offre.quantity = this.annonce.quantity;
		offre.lexMaterialType = this.annonce.lexMaterialType;
		offre.lexCodeDechet = this.annonce.lexCodeDechet;
		offre.site = this.annonce.site;
		offre.documents = this.myDocs;
		offre.geometry = this.annonce.geometry;

		this.otvService.create(offre).subscribe((createdOffer) => {
			offre = createdOffer;

			this.otvService.createAnalysis(createdOffer.id, this.analysis).subscribe((createdAD: AnalysisDeclaration) => {
				var idAnalysis = createdAD.id;
				var tempDefaultAnalysisResults: AnalysisElemResult[] = [];
				this.defaultAnalysisResults.forEach(element => {
					if (element.quantifyLimit || element.value) {
						element.id.analysisDeclId = idAnalysis;
						tempDefaultAnalysisResults.push(element);
					}
				});

				this.otvService.addAnalysisResults(tempDefaultAnalysisResults).subscribe((createdAR) => {
					this.defaultAnalysisResults = createdAR;
					this.router.navigate(['/offres/details/' + createdOffer.id]);
				});
			});
		});
	}

	updateOffer() {
		let offre = new OTV();
		offre.id = this.annonce.id;
		offre.availStartDate = this.annonce.availStartDate;
		offre.availEndDate = this.annonce.availEndDate;
		offre.infos = this.annonce.infos;
		offre.bstvIcpe = this.annonce.bstvIcpe;
		offre.public_ = this.annonce.public_;
		offre.quantity = this.annonce.quantity;
		offre.lexMaterialType = this.annonce.lexMaterialType;
		offre.lexCodeDechet = this.annonce.lexCodeDechet;
		offre.site = this.annonce.site;
		offre.user = this.annonce.user;
		offre.documents = this.myDocs;
		offre.geometry = this.annonce.geometry;

    if (this.annonce.lexOfferStatus?.id === 4 && !this.annonce.isLinkToTransaction) { // annonce échue qui n'est pas liée à une transaction
      const modalRef = this.modalService.open(ModalAnnouncementStatusChoiceComponent, { size: 'sm' });
      modalRef.componentInstance.otv = offre;
      modalRef.componentInstance.defaultAnalysisResults = this.defaultAnalysisResults;
      modalRef.componentInstance.analysis = this.analysis;
      this.divToModalClick.nativeElement.click(); // Forcer un click permet d'ouvrir instantanément la modal (bug : la modal ne s'ouvre pas sans click)
    } else {
      // Dans le cas d'une modification d'une offre échue lié à une transaction, on repasse l'annonce à publiée, sinon on garde le statut d'origine
      offre.lexOfferStatus = (this.annonce.lexOfferStatus?.id === 4) ? new LexOfferStatus(2) : this.annonce.lexOfferStatus;

      this.otvService.updateOTV(offre).subscribe((res) => {
		if (this.analysis.id) {
			this.otvService.updateAnalysisDeclarationOnOffer(res.id, this.analysis).subscribe((updatedAD: AnalysisDeclaration) => {
				this.analysis = updatedAD;

				this.otvService.updateAnalysisResults(res.id, this.defaultAnalysisResults).subscribe((updatedAR) => {
				  this.defaultAnalysisResults = updatedAR;
				});
			});
		}
        this.router.navigate(['/offres/details/' + res.id]);
      });
    }
	}

	createDemand() {
		let demand = new DTV();
		demand.availStartDate = this.annonce.availStartDate;
		demand.availEndDate = this.annonce.availEndDate;
		demand.infos = this.annonce.infos;
		demand.bstvIcpe = this.annonce.bstvIcpe;
		demand.public_ = this.annonce.public_;
		demand.quantity = this.annonce.quantity;
		demand.lexMaterialType = this.annonce.lexMaterialType;
		demand.site = this.annonce.site;
		demand.documents = this.myDocs;
		demand.geometry = this.annonce.geometry;

		this.dtvService.create(demand).subscribe((createdDemand) => {
			demand = createdDemand;

			this.dtvService.createAnalysis(createdDemand.id, this.analysis).subscribe((createdAD: AnalysisDeclaration) => {
				var idAnalysis = createdAD.id;
				var tempDefaultAnalysisResults: AnalysisElemResult[] = [];
				this.defaultAnalysisResults.forEach(element => {
					if (element.quantifyLimit || element.value) {
						element.id.analysisDeclId = idAnalysis;
						tempDefaultAnalysisResults.push(element);
					}
				});

				this.otvService.addAnalysisResults(tempDefaultAnalysisResults).subscribe((createdAR) => {
					this.defaultAnalysisResults = createdAR;
					this.router.navigate(['/demandes/details/' + createdDemand.id]);
				});
			});
		});
	}

	updateDemand() {
		let demand = new DTV();
		demand.id = this.annonce.id;
		demand.availStartDate = this.annonce.availStartDate;
		demand.availEndDate = this.annonce.availEndDate;
		demand.infos = this.annonce.infos;
		demand.bstvIcpe = this.annonce.bstvIcpe;
		demand.public_ = this.annonce.public_;
		demand.quantity = this.annonce.quantity;
		demand.lexMaterialType = this.annonce.lexMaterialType;
		demand.site = this.annonce.site;
		demand.user = this.annonce.user;
		demand.documents = this.myDocs;
		demand.geometry = this.annonce.geometry;

    if (this.annonce.lexDemandStatus?.id === 4 && !this.annonce.isLinkToTransaction) { // annonce échue qui n'est pas liée à une transaction
      const modalRef = this.modalService.open(ModalAnnouncementStatusChoiceComponent, { size: 'sm' });
      modalRef.componentInstance.dtv = demand;
      modalRef.componentInstance.defaultAnalysisResults = this.defaultAnalysisResults;
      modalRef.componentInstance.analysis = this.analysis;
      this.divToModalClick.nativeElement.click(); // Forcer un click permet d'ouvrir instantanément la modal (bug : la modal ne s'ouvre pas sans click)
    } else {
      // Dans le cas d'une modification d'une demande échue, on repasse l'annonce à publiée
      demand.lexDemandStatus = (this.annonce.lexDemandStatus?.id === 4) ? new LexDemandStatus(2) : this.annonce.lexDemandStatus;

      this.dtvService.updateDTV(demand).subscribe((res) => {
		if (this.analysis.id) {
			this.dtvService.updateAnalysisDeclarationOnDemand(res.id, this.analysis).subscribe((updatedAD: AnalysisDeclaration) => {
				this.analysis = updatedAD;
				this.dtvService.updateAnalysisResults(res.id, this.defaultAnalysisResults).subscribe((updatedAR) => {
				  this.defaultAnalysisResults = updatedAR;
				  this.router.navigate(['/demandes/details/' + res.id]);
				});
			});
		}
        //this.router.navigate(['/demandes/details/' + res.id]);
      });
    }
	}

	saveDocuments() {
		if (this.techniquesFiles && this.techniquesFiles.length != 0) {
			this.techniquesFiles.forEach(file => {
				const docu:DocumentTerrass = this.initializeNewDocumentTerrass(file.name, 1);
				this.myDocs.push(docu);
				this.uploadFichierService.sendFile(file, docu.filename).subscribe((res: string) => { });
			});
		}

		if (this.analysesFiles && this.analysesFiles.length != 0) {
			this.analysesFiles.forEach(file => {
				const docu:DocumentTerrass = this.initializeNewDocumentTerrass(file.name, 2);
				this.myDocs.push(docu);
				this.uploadFichierService.sendFile(file, docu.filename).subscribe((res: string) => { });
			});
		}

		if (this.analysesSurLixiviatFiles && this.analysesSurLixiviatFiles.length != 0) {
			this.analysesSurLixiviatFiles.forEach(file => {
				const docu:DocumentTerrass = this.initializeNewDocumentTerrass(file.name, 3);
				this.myDocs.push(docu);
				this.uploadFichierService.sendFile(file, docu.filename).subscribe((res: string) => { });
			});
		}

		if (this.planOfferFiles && this.planOfferFiles.length != 0) {
			this.planOfferFiles.forEach(file => {
				const docu:DocumentTerrass = this.initializeNewDocumentTerrass(file.name, 4);
				this.myDocs.push(docu);
				this.uploadFichierService.sendFile(file, docu.filename).subscribe((res: string) => { });
			});
		}

		if (this.planDemandFiles && this.planDemandFiles.length != 0) {
			this.planDemandFiles.forEach(file => {
				const docu:DocumentTerrass = this.initializeNewDocumentTerrass(file.name, 5);
				this.myDocs.push(docu);
				this.uploadFichierService.sendFile(file, docu.filename).subscribe((res: string) => { });
			});
		}
	}

	initializeNewDocumentTerrass(origFicName: string, typeDocId: number): DocumentTerrass {
		const uuidficname: string = uuidv4();
		let theDoc: DocumentTerrass = new DocumentTerrass();
		theDoc.filename = uuidficname;
		theDoc.originalFilename = origFicName;
		let typeDoc: LexDocumentType = new LexDocumentType();
		typeDoc.id = typeDocId;
		theDoc.lexDocumentType = typeDoc;
		return theDoc;
	}

	onSubmit(annonceForm: NgForm): void {
		if (annonceForm.form.status === "VALID") {
			if (this.isCreation) {
				this.saveDocuments();
				if (this._isOffer) {
					this.createOffer();
				} else {
					this.createDemand();
				}
			} else if (this.isDuplication) {
				this.saveDocuments();
				this.analysis.id = null;
				if (this._isOffer) {
					this.createOffer();
				} else {
					this.createDemand();
				}
			} else {
				this.saveDocuments();
				if (this._isOffer) {
					this.updateOffer();
				} else {
					this.updateDemand();
				}
			}

		}
	}

	setEtapeActive(id: number) {
		this.etapeActive = id;
		this.progression = (this.etapeActive * 20);
	}

	updateEtapeSuivant() {
		if (this.etapeActive < 5) {
			this.setEtapeActive(this.etapeActive);
			this.etapeActive++;
			this.vps.setOffset([0, 160]);
			this.vps.scrollToAnchor("0" + this.etapeActive);
			this.progression = (this.etapeActive * 20);
		}
	}

	updateEtapePrecedent() {
		if (this.etapeActive > 1) {
			this.etapeActive--;
			this.vps.setOffset([0, 160]);
			this.vps.scrollToAnchor("0" + this.etapeActive);
			this.progression = (this.etapeActive * 20);
		}
	}

	getAnalysisElemResult(event) {
		this.defaultAnalysisResults = event;
	}

	getAnalysisDeclaration(event) {
		this.analysis = event;
	}

	movePageViewTo(id) {
		var top = document.getElementById(id).offsetTop;
		window.scrollTo(0, top);
	}

	getTechniquesFiles(event) {
		this.techniquesFiles = event;
	}

	getAnalysesFiles(event) {
		this.analysesFiles = event;
	}

	getAnalysesSurLixiviatFiles(event) {
		this.analysesSurLixiviatFiles = event;
	}

	getPlanOfferFiles(event) {
		this.planOfferFiles = event;
	}

	getPlanDemandFiles(event) {
		this.planDemandFiles = event;
	}

	onSiteChange(event) {
		var geometry = new GeoJSON().readGeometry(event.geometry).transform('EPSG:4326', 'EPSG:3857');
		this.pointCoordinates = this.getCenterOfExtent(geometry.getExtent());
	}

	getCenterOfExtent(extent){
		var X = (extent[0] + extent[2])/2;
		var Y = (extent[1] + extent[3])/2;
		return [X, Y];
	}

}
