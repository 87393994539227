import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from '../fr/brgm/common/guards/is-signed-in.guard';
import { CreateOffrePageComponent } from './create-offre-page/create-offre-page.component';
import { DuplicateOffrePageComponent } from "./duplicate-offre-page/duplicate-offre-page.component";
import { EditOffrePageComponent } from './edit-offre-page/edit-offre-page.component';
import { OtvPageComponent } from './otv-page/otv-page.component';
import { ViewOffrePageComponent } from './view-offre-page/view-offre-page.component';

const offresRoutes: Routes = [
    {path: 'offres', component: OtvPageComponent, data: {title: APP_PREFIX + 'Offres'}, canActivate: [ IsSignedInGuard] },
    {path: 'annonce/create', component: CreateOffrePageComponent, data: {title: APP_PREFIX  + 'Création d\'annonce'}, canActivate: [ IsSignedInGuard] },
    {path: 'offres/details/:id', component: ViewOffrePageComponent, data: {title: APP_PREFIX  + 'Offre'}, canActivate: [ IsSignedInGuard] },
    {path: 'offres/edit/:id', component: EditOffrePageComponent, data: {title: APP_PREFIX  + 'Modification d\'offre'}, canActivate: [ IsSignedInGuard] },
    {path: 'offres/duplicate/:id', component: DuplicateOffrePageComponent, data: {title: APP_PREFIX + 'Duplication d\'offre'}, canActivate: [IsSignedInGuard]}
];

@NgModule({
    imports: [
        RouterModule.forChild(offresRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class OffresRoutingModule { }