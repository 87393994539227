import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { calendar_fr } from 'src/app/app.constants';
import { BordereauService } from 'src/app/fr/brgm/common/http/bodereau/bordereau.service';
import { TransactionService } from 'src/app/fr/brgm/common/http/transaction/transaction.service';
import { Bordereau } from 'src/app/fr/brgm/common/model/bordereau.model';

@Component({
  selector: 'app-bstv-create-page',
  templateUrl: './bstv-create-page.component.html',
  styleUrls: ['./bstv-create-page.component.scss']
})
export class BstvCreatePageComponent implements OnInit {

  bordereau: Bordereau;
  verificationCheckbox: boolean = false;
  verificationBCheckbox: boolean = false;
  isModification: boolean = false;
  fr: any = calendar_fr;

  volume = 0.0;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private bordereauService: BordereauService,
    private transactionService: TransactionService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => this.titleService.setTitle(data.title));
    this.activatedroute.params.subscribe(params => {
      if(params.idTransaction){
        this.fillNewBordereau(params.idTransaction);
      }

      if(params.id){
        this.bordereauService.get(params.id).subscribe((res) => this.bordereau = res);
        this.isModification = true;
        this.verificationCheckbox = true;
        this.verificationBCheckbox = true;
      }

    });
  }

  fillNewBordereau(idTransaction: number){
    this.transactionService.findById(idTransaction).subscribe(res => {

      this.bordereau = new Bordereau();

      this.bordereau.lot = 1;
      this.bordereau.transactionId = res.id;
      this.bordereau.nomRepresentant = '';
      this.bordereau.emailRepresentant = '';
      if(res.lexGuideDechet){
        this.bordereau.guideDechet = res.lexGuideDechet.label;
      }

      this.bordereau.offreurSiret = res.offer.user.entityObject.siret;
      this.bordereau.offreurRaisonSociale = res.offer.user.entityObject.socialReason;
      this.bordereau.offreurNomSite = res.offer.site.name;
      this.bordereau.offreurAdresse = res.offer.site.address.numberStreet;
      this.bordereau.offreurVille = res.offer.site.address.foreignCity;
      this.bordereau.offreurCodePostal = res.offer.site.address.zipCode;

      this.bordereau.demandeurSiret = res.demand.user.entityObject.siret;
      this.bordereau.demandeurRaisonSociale = res.demand.user.entityObject.socialReason;
      this.bordereau.demandeurNomSite = res.demand.site.name;
      this.bordereau.demandeurAdresse = res.demand.site.address.numberStreet;
      this.bordereau.demandeurVille = res.demand.site.address.foreignCity;
      this.bordereau.demandeurCodePostal = res.demand.site.address.zipCode;
      this.bordereau.transaction = res;
      this.bordereau.dateLivraison = new Date();
      this.bordereau.documents = [];

      this.updateVolume();
    });
  }

  createBordereau(form: NgForm){
    if(form.form.status === "VALID" && !this.isModification){
      this.bordereauService.create(this.bordereau).subscribe((res: Bordereau) => this.router.navigate(['/bordereaux']));

    }
    if(form.form.status === "VALID" && this.isModification){
      this.bordereauService.update(this.bordereau).subscribe((res: Bordereau) => this.router.navigate(['/bordereaux']));
    }
  }

  checkVerificationCheckBox(event) {
    this.verificationCheckbox = event.target.checked;
  }

  checkVerificationBCheckBox(event) {
    this.verificationBCheckbox = event.target.checked;
  }

  updateVolume(): void {
    this.volume = Number(
      (this.bordereau.transaction.quantity / 1.3).toFixed(1)
    );
  }

  cancel(){
    this.location.back();
  }
}
