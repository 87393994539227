<div class="modal"><!-- Ajouter modal--opened pour ouvrir -->
    <form action="" class="modal-inner">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">
            <i class="modal-icon modal-icon--blue icon-padlock"></i>

            <h2 class="h1 modal-title">Mot de passe oublié</h2>

            <p class="modal-text">Entrez votre adresse mail, nous vous enverrons un mail de réinitialisation de mot de passe</p>

            <div class="form-group">
                <label class="form-item form-item--12">
                    <span class="form-label">Email</span>
                    <input type="email" name="" placeholder="Entrez votre email" class="form-input" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
                </label>
            </div>

            <div class="form-footer">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="submit" value="1" class="button button--green" (click)="validate()">
                    <i class="button-icon icon-check"></i>
                    <span class="button-label">Envoyer</span>
                </button>
            </div>
        </div>
    </form>
</div>
