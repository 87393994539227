import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BordereauxRoutingModule } from './bordereaux-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { PrimengModule } from '../00-shared/primeng/primeng.module';

import { BstvCreatePageComponent } from './bstv-create-page/bstv-create-page.component';
import { BstvPageComponent } from './bstv-page/bstv-page.component';
import { EditBstvPageComponent } from './edit-bstv-page/edit-bstv-page.component';
import { ViewBstvPageComponent } from './view-bstv-page/view-bstv-page.component';
import { LotCreateComponent } from './lot-create/lot-create.component';
import { LotEditComponent } from './lot-edit/lot-edit.component';
import { LotFormComponent } from './lot-form/lot-form.component';
import { UploadFichierModule } from '../00-shared/upload-file/upload-fichier.module';
import { RndtsPageComponent } from './rndts-page/rndts-page.component';

@NgModule({
    imports: [
        CommonModule,
        BordereauxRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        PrimengModule,
        PaginationModule,
        UploadFichierModule
    ],
    declarations: [
        BstvCreatePageComponent,
        BstvPageComponent,
        EditBstvPageComponent,
        ViewBstvPageComponent,
        LotCreateComponent,
        LotEditComponent,
        LotFormComponent,
        RndtsPageComponent
    ],
    providers: []
})
export class BordereauxModule {}