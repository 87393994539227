import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Coordinate } from "ol/coordinate";
import { getCenter } from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import { getDistance } from "ol/sphere";
import { MapEditPolygonComponent } from "src/app/00-shared/map/map-edit-polygon/map-edit-polygon.component";
import { ApiAdresseService } from "src/app/fr/brgm/common/http/api-adresse/api-adresse.service";
import { SiteService } from "src/app/fr/brgm/common/http/site/site.service";
import { Featurecollection } from "src/app/fr/brgm/common/model/featurecollection.model";
import { Site } from "src/app/fr/brgm/common/model/site.model";
import { ModalSiteInvalidParcelsComponent } from "src/app/modal/modal-site-invalid-parcels/modal-site-invalid-parcels.component";

@Component({
  selector: "app-edit-site",
  templateUrl: "./edit-site-page.component.html",
  styleUrls: ["./edit-site-page.component.scss"],
})
export class EditSitePageComponent implements OnInit {
  @ViewChild(MapEditPolygonComponent, { static: false })
  mapEditPolygon: MapEditPolygonComponent;

  site: Site;
  oldSiteName: string;
  polygonGeometry: Geometry;
  pointCoordinates: Coordinate;
  requete: string;
  adresses: Featurecollection;

  isSisCodeLoaded = true;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private siteService: SiteService,
    private router: Router,
    private modalService: NgbModal,
    private apiAdresseService: ApiAdresseService
  ) {}

  ngOnInit() {
    this.activatedroute.data.subscribe((data) => {
      this.titleService.setTitle(data.title);
    });
    this.activatedroute.params.subscribe((params) => {
      this.siteService.get(Number(params.id)).subscribe((res: Site) => {
        console.log("Site : ", res);
        this.site = res;
        var geometry = new GeoJSON()
          .readGeometry(this.site.geometry)
          .transform("EPSG:4326", "EPSG:3857");
        this.polygonGeometry = geometry;
        this.pointCoordinates = getCenter(geometry.getExtent());
        this.oldSiteName = this.site.name;
      });
    });
  }

  searchAdresse(event) {
    this.apiAdresseService
      .getAdresseByTexte(event.query)
      .subscribe((res) => (this.adresses = res));
  }

  doOnSelect(event) {
    this.site.address.numberStreet = event.properties.name;
    this.site.address.zipCode = event.properties.postcode;
    this.site.address.foreignCity = event.properties.city;

    this.pointCoordinates = event.geometry.coordinates;

    this.mapEditPolygon.onAddressChange(event);
  }

  updateSite(form: NgForm) {
    if (form.form.status === "VALID" && this.site.geometry && this.polygonGeometry && this.pointCoordinates && this.isSisCodeLoaded) {
      this.siteService
        .update(this.site.id, this.site)
        .subscribe((res: Site) => {
          this.site = res;
          this.oldSiteName = this.site.name;
          this.router.navigate(["/sites/details/" + this.site.id]);
        });
    }
  }

  /**
   * On ne vérifie plus si le polygone est cohérent avec l'adresse du site (c.-à-d. afficher une pop-up d'avertissement)
   * car ça ne fonctionne pas lors de la modification d'un site.
   *
   * De plus, si on se réfère aux spécifications, la pop-up d'avertissement doit uniquement s'afficher lors de la création d'un site.
   *
   * Source potentiel du problème : this.pointCoordinates doit contenir des données fausses. En effet, lors du calcul de la distance entre le centre du polygone
   * et l'adresse du site contenu dans this.pointCoordinates, on obtient des distances aberrante (plus de 10 000km de distance).
   */
  updateSiteWithBrokenCheck(form: NgForm) {
    if (form.form.status === "VALID" && this.site.geometry && this.polygonGeometry && this.pointCoordinates && this.isSisCodeLoaded) {
      if (this.checkPolygonConsistency()) { // On vérifie que le polygone est cohérent avec l'adresse renseignée par l'utilisateur

        this.siteService
          .update(this.site.id, this.site)
          .subscribe((res: Site) => {
            this.site = res;
            this.oldSiteName = this.site.name;
            this.router.navigate(["/sites/details/" + this.site.id]);
          });
      } else { // Le polygone n'est pas cohérent avec l'adresse renseignée, on demande à l'utilisateur si c'est voulu
        const modalRef = this.modalService.open(
          ModalSiteInvalidParcelsComponent,
          { size: "sm" }
        );
        modalRef.componentInstance.site = this.site;
        modalRef.componentInstance.isCreation = false;

        modalRef.result
          .then((res) => {
            this.site = res;
            this.oldSiteName = this.site.name;
            this.router.navigate(["/sites/details/" + this.site.id]);
          })
          .catch((error) => {});
      }
    }
  }

  private checkPolygonConsistency(): boolean {
    let polygonIsValid = this.polygonGeometry.intersectsCoordinate(this.pointCoordinates); // détermine si l’adresse renseignée est comprise dans le polygone

    if (!polygonIsValid) {
      console.info(
        "L’adresse renseignée n'est pas comprise dans le polygone de base => on vérifie si le centre du polygone n'est pas à plus de 250m de l'adresse"
      );

      let polygonCenter = getCenter(this.polygonGeometry.getExtent());
      let polygonCenterToAddressDistance = getDistance(polygonCenter, this.pointCoordinates);
      let metterDistance = Math.round(polygonCenterToAddressDistance * 100) / 100;

      console.info(
        `Distance entre le centre du polygone et l'adresse renseignée : ${metterDistance}m`
      );
      polygonIsValid = metterDistance < 251;
    }

    return polygonIsValid;
  }

  onPolygonGeometryChange(event) {
    this.polygonGeometry = event;
  }

  onPointCoordinatesChange(event) {
    this.pointCoordinates = event;
  }

  onSisCodeLoadedChange(sisCodeLoadedEvent: boolean) {
    this.isSisCodeLoaded = sisCodeLoadedEvent;
  }
}
