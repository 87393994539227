<div class="modal">
    <form action="" #form="ngForm" class="modal-inner" (ngSubmit)="onSubmit()">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">

            <h2 class="h1 modal-title">Clôturer un bordereau</h2>

            <div *ngIf="closingError" class="row alert alert-danger">
                {{ closingError }}
            </div>

            <div class="form-group">
                <label class="form-item form-item--12">
                    <span class="form-label">Prénom et nom du représentant qui clôture le bordereau<span class="red">*</span></span>
                    <input type="text" class="form-input" [(ngModel)]="bordereau.prenomNomCloture"
                        #demandeurNomSite="ngModel" name="demandeurNomSite" minlength="1" maxlength="100"
                        [ngClass]="{'red-border-class': demandeurNomSite.errors && form.submitted}"
                        required />
                </label>
            </div>
            <br>
            <div class="form-group">
                <div class="form-item form-item--12">
                    <label class="form-checkbox" class="form-checkbox"
                        [ngClass]="{'red-border-class': !verificationCheckbox && form.submitted}">
                        <input required name="verificationCheckbox" [(ngModel)]="verificationCheckbox"
                            type="checkbox" class="form-checkbox-input"
                            (click)=checkVerificationCheckBox($event)>
                        <span class="form-checkbox-label">Je, soussigné, certifie avoir reçu les lots</span>
                    </label>
                </div>
            </div>

            <div class="form-group" *ngIf="bordereau.guideDechet">
                <div class="form-item form-item--12">
                    <label class="form-checkbox" class="form-checkbox"
                        [ngClass]="{'red-border-class': !verificationSSDCheckbox && form.submitted}">
                        <input required name="verificationSSDCheckbox" [(ngModel)]="verificationSSDCheckbox"
                            type="checkbox" class="form-checkbox-input"
                            (click)=checkVerificationSSDCheckBox($event)>
                        <span class="form-checkbox-label">
                            Je, soussigné, certifie que les renseignements ci-dessus sont exacts et établis de
                            bonne foi et que les présents lots de terres excavées ont été préparés conformément
                            aux exigences définies à l’arrêté ministériel du 04/06/2021 relatif à la sortie de
                            statut de déchet des terres excavées et sédiments ayant fait l’objet d’une préparation
                            en vue d’une utilisation en génie civil ou en aménagement
                        </span>
                    </label>
                </div>
            </div>

            <div class="form-footer">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close" aria-hidden="true"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="submit" value="1" class="button button--green">
                    <i class="button-icon icon-check" aria-hidden="true"></i>
                    <span class="button-label">Confirmer</span>
                </button>
            </div>
        </div>
    </form>
</div>
