import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MapEditPolygonComponent } from './map-edit-polygon/map-edit-polygon.component';
import { MapViewPointsComponent } from './map-view-points/map-view-points.component';
import { MapViewComponent } from './map-view/map-view.component';
import { MapEditMultipolygonComponent } from './map-edit-multipolygon/map-edit-multipolygon.component';

@NgModule({
    imports : [
        CommonModule,
        RouterModule,
        FormsModule
    ],
    declarations: [
        MapEditPolygonComponent,
        MapViewComponent,
        MapViewPointsComponent,
        MapEditMultipolygonComponent
    ],
    exports: [
        MapEditPolygonComponent,
        MapViewComponent,
        MapViewPointsComponent,
        MapEditMultipolygonComponent
    ]
})
export class MapModule {}