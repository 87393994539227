import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { MessageService } from 'src/app/00-shared/message/message.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { Criteria } from 'src/app/fr/brgm/common/http/model/criteria.model';
import { SiteService } from 'src/app/fr/brgm/common/http/site/site.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { LexSiteStatus } from 'src/app/fr/brgm/common/model/lex/lexsitestatus.model';
import { ListSite } from 'src/app/fr/brgm/common/model/listsite.model';
import { Site } from 'src/app/fr/brgm/common/model/site.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { DelegationService } from '../services/delegation.service';



@Component({
  selector: 'app-sites-page',
  templateUrl: './sites-page.component.html',
  styleUrls: ['./sites-page.component.scss']
})
export class SitesPageComponent implements OnInit {

  defaultSorting = ['lastModification,DESC', 'id,DESC'];

  mySites: Site[];

  totalSites: number;
  itemsPerPage: number;
  page: any;
  pageSizeOptions = [10, 20, 30, 40, 50];
  sortColumn: string;
  sortOrder: string;

  currentUser: User;

  filterStatus: any[];
  filterStatusExpired: boolean;
  filterDate: Date[];
  filterSearch: string;
  siteStatus: LexSiteStatus[];
  public sitesList = [];

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private messageService: MessageService,
    private siteService: SiteService,
    private router: Router,
    private lexService: LexiqueService,
    private userService: UserService,
    private delegationService: DelegationService
  )
  {
    this.totalSites = 0;
    this.page = 0;
    this.itemsPerPage = this.pageSizeOptions[0];
    this.sortColumn = null;
    this.filterStatusExpired = false;
    this.filterSearch = "";
    this.filterStatus = [];
  }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => this.titleService.setTitle(data.title));
    this.lexService.getLexSiteStatus().subscribe((res) => this.siteStatus = res);
    let criteria: Criteria = new Criteria();

    this.siteService.getAll(criteria,
      {
        page: 0,
        size: this.pageSizeOptions[0],
        sort: this.defaultSorting
      }
    ).subscribe((res: ListSite) => this.mySites = res.site);

    this.userService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  addFilterStatus(status: LexSiteStatus) {
    if (!_.includes(this.filterStatus, status.id)) {
      this.filterStatus.push(status.id);
    } else {
      let index = _.indexOf(this.filterStatus, status.id);
      if (index != -1) {
        this.filterStatus.splice(index, 1);
      }
    }
    this.page = 0;
    this.search();
  }

  addExpired() {
    this.search();
  }

  loadLazy(event) {
    this.page = event.first / this.itemsPerPage;
    if (event.sortField) {
      this.sortColumn = event.sortField;
    }

    if (event.sortOrder === 1) {
      this.sortOrder = 'ASC';
      this.page = 0;
    } else {
      this.sortOrder = 'DESC';
      this.page = 0;
    }
    this.search();
  }

  search() {
    let criteria: Criteria = new Criteria();
    criteria.queryItem = this.filterSearch;
    criteria.statusIds = this.filterStatus;
    criteria.date = this.filterDate;
    criteria.isExpired = this.filterStatusExpired;
    this.siteService.getAll(criteria,
      {
        page: this.page,
        size: this.itemsPerPage,
        sort: (this.sortColumn == null) ? this.defaultSorting : this.sortColumn + "," + this.sortOrder
      }
    ).subscribe(
      (res) => this.onSuccess(res),
      (res) => this.onError(res)
    );
  }

  private onSuccess(data) {
    this.mySites = data.site;
    if (data.maxSite) {
      this.totalSites = data.maxSite;
    }
  }
  private onError(error: any) {
    this.messageService.error('error', error);
  }

  rouvrir(site: Site) {
    let tempSite = site;
    tempSite.lexSiteStatus = this.siteStatus.find(status => status.label.toLowerCase() == "ouvert");
    this.siteService.update(tempSite.id, tempSite).subscribe((res) => this.search());
  }

  fermer(site: Site) {
    let tempSite = site;
    tempSite.lexSiteStatus = this.siteStatus.find(status => status.label.toLowerCase() == "fermé");
    this.siteService.update(tempSite.id, tempSite).subscribe((res) => this.search());
  }

  load(event) {
    this.router.navigate(['sites/details/' + event])
  }

  pageChange(event) {
    this.page = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.search();
  }

  getNumberOfActiveDelegations(site: Site): number {
    return this.delegationService.getNumberOfActiveDelegations(site.delegations);
  }

}
