<div class="modal">
  <div class="modal-inner">

    <button type="button" class="modal-close icon-close" (click)="onClose()"></button>

    <div class="modal-body">

      <h2 class="h1 modal-title">Votre fond pédogéochimique est enregistré</h2>

      <br>

      <p class="modal-text">
        Le fond pédogéochimique <strong>{{ message }}</strong> a bien été enregistré.
      </p>

      <div class="form-footer">
        <button type="submit" class="button button--green" (click)="onClose()">
          <span class="button-label">Ok</span>
        </button>
      </div>

    </div>
  </div>
</div>
