import { Parcelle } from "src/app/00-shared/map/parcelle.model";
import { Sis } from "src/app/00-shared/map/sis.model";
import { Address } from "./address.model";
import { Delegation } from "./delegation.model";
import { Deserializable } from "./Deserializable";
import { HomeOtvDtv } from "./home-otv-dtv.model";
import { LexSiteStatus } from "./lex/lexsitestatus.model";
import { LexSiteType } from "./lex/lexsitetype.model";
import { User } from "./user.model";

export class Site implements Deserializable {

  constructor(
    public id?: number,
    public icpeInfos?: string,
    public name?: string,
    public lastModification?: string,
    public comment?: string,
    public address?: Address,
    public lexSiteStatus?: LexSiteStatus,
    public lexSiteType?: LexSiteType,
    public user?: User,
    public longitude?: string,
    public latitude?: string,
    public geometry?: any,
    public hasOfferDemand?: boolean,
    public hasOfferDemandNotClosed?: boolean,
    public currentUserCanUpdate?: boolean,
    public currentUserCanCloseOrReopen?: boolean,
    public currentUserCanDelegate?: boolean,
    public parcelles?: Parcelle[],
    public sisIntersectes?: Sis[],
    public otvDtvList?: HomeOtvDtv[],
    public delegations?: Delegation[]
  ) {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
