<div class="form-fieldset" appUseParentForm>
    <div class="form-fieldset-header">
        <h3 class="h3 form-fieldset-title">Caractérisation du lot de terres</h3>
    </div>
    <div class="form-group">

        <!-- Quantité à valoriser -->
        <label class="form-item form-item--6">
            <span class="form-label">Quantité (t) <span class="red">*</span></span>
            <input class="form-input" [(ngModel)]="annonce.quantity" required pattern="^[+-]?\d+(\.\d+)?$" id="quantity"
                name="quantity" #quantity="ngModel"
                [ngClass]="{'red-border-class': quantity.errors && parentFormSubmitted}">
            <span *ngIf="(quantity.errors && parentFormSubmitted) || (!quantity.pristine && !quantity.valid)"
                class="red">
                La quantité à valoriser est obligatoire.
            </span>
        </label>

        <hr class="form-break">

        <!-- Type de matériau (classement GTR) -->
        <label class="form-item form-item--6" *ngIf="annonce.lexMaterialType">
            <span class="form-label">Type de matériau (classement GTR)
                <a target="_blank"
                    href="https://www.techniques-ingenieur.fr/base-documentaire/construction-et-travaux-publics-th3/terrassement-et-geomembranes-42233210/classification-des-materiaux-c5361/figures/ul-c5361niv10003/1/sl4324127-web.gif"><i
                        class="process-nav-icon icon-info"></i></a>
            </span>
            <span class="form-select">
                <select class="form-select-inner" [(ngModel)]="annonce.lexMaterialType.id" id="lexMaterialType"
                    name="materialType" #materialType="ngModel">
                    <option *ngFor="let matType of materialTypes" [ngValue]="matType.id">
                        {{matType.label}}</option>
                </select>
            </span>
        </label>
        <label class="form-item form-item--6" *ngIf="!annonce.lexMaterialType">
            <span class="form-label">Type de matériau (classement GTR)
                <a target="_blank"
                    href="https://www.techniques-ingenieur.fr/base-documentaire/construction-et-travaux-publics-th3/terrassement-et-geomembranes-42233210/classification-des-materiaux-c5361/figures/ul-c5361niv10003/1/sl4324127-web.gif"><i
                        class="process-nav-icon icon-info" aria-hidden="true"></i></a>
            </span>
            <span class="form-select">
                <select class="form-select-inner" [(ngModel)]="annonce.lexMaterialType" id="lexMaterialType"
                    name="materialType" #materialType="ngModel">
                    <option *ngFor="let matType of materialTypes" [ngValue]="matType">
                        {{matType.label}}</option>
                </select>
            </span>
        </label>

        <!-- Code Dechet -->
        <label class="form-item form-item--6" *ngIf="childIsOffer && annonce.lexCodeDechet">
            <span class="form-label">Code Dechet
                <a target="_blank" href="https://aida.ineris.fr/consultation_document/10327"><i class="process-nav-icon icon-info" aria-hidden="true"></i></a>
            </span>
            <span class="form-select">
                <select class="form-select-inner" [(ngModel)]="annonce.lexCodeDechet.id" id="lexCodeDechet" name="codeDechet" #codeDechet="ngModel">
                    <option *ngFor="let codeDechet of codeDechets" [ngValue]="codeDechet.id" title="{{codeDechet.label}}">
                      {{ codeDechet.simplifiedLabel }}
                    </option>
                </select>
            </span>
        </label>
        <label class="form-item form-item--6" *ngIf="childIsOffer && !annonce.lexCodeDechet">
            <span class="form-label">Code Dechet
                <a target="_blank" href="https://aida.ineris.fr/consultation_document/10327"><i class="process-nav-icon icon-info" aria-hidden="true"></i></a>
            </span>
            <span class="form-select">
                <select class="form-select-inner" [(ngModel)]="annonce.lexCodeDechet" id="lexCodeDechet" name="codeDechet" #codeDechet="ngModel">
                    <option *ngFor="let codeDechet of codeDechets" [ngValue]="codeDechet" title="{{codeDechet.label}}">
                      {{ codeDechet.simplifiedLabel }}
                    </option>
                </select>
            </span>
        </label>

        <!-- Informations complémentaires -->
        <label class="form-item form-item--12">
            <span class="form-label">Informations complémentaires</span>
            <textarea class="form-textarea" [(ngModel)]="annonce.infos" id="infos" name="infos" #infos="ngModel"
                placeholder="Informations géotechniques, sur les espèces protégées ou invasives…"></textarea>
        </label>

    </div>
</div>
