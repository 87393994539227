import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DtvService } from 'src/app/fr/brgm/common/http/dtv/dtv.service';
import { OtvService } from 'src/app/fr/brgm/common/http/otv/otv.service';
import { AnalysisDeclaration } from 'src/app/fr/brgm/common/model/analysisdeclaration.model';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { DTV } from 'src/app/fr/brgm/common/model/dtv.model';
import { LexDemandStatus } from 'src/app/fr/brgm/common/model/lex/lexdemandstatus.model';
import { LexOfferStatus } from 'src/app/fr/brgm/common/model/lex/lexofferstatus.model';
import { OTV } from 'src/app/fr/brgm/common/model/otv.model';

@Component({
  selector: 'app-modal-announcement-status-choice',
  templateUrl: './modal-announcement-status-choice.component.html',
  styleUrls: ['./modal-announcement-status-choice.component.scss']
})
export class ModalAnnouncementStatusChoiceComponent implements OnInit {;

  @Input() otv: OTV;
  @Input() dtv: DTV;

  @Input() defaultAnalysisResults: AnalysisElemResult[];
  @Input() analysis: AnalysisDeclaration;

  isOffer: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private otvService: OtvService,
    private dtvService: DtvService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isOffer = (this.dtv == null);
  }

  onClose(): void {
    this.activeModal.close();
  }

  onDraftClick(): void {
    if (this.isOffer) {
      this.otv.lexOfferStatus = new LexOfferStatus(1);
      this.updateOffer();
    } else {
      this.dtv.lexDemandStatus = new LexDemandStatus(1);
      this.updateDemand();
    }
    this.onClose();
  }

  onPublishedClick(): void {
    if (this.isOffer) {
      this.otv.lexOfferStatus = new LexOfferStatus(2);
      this.updateOffer();
    } else {
      this.dtv.lexDemandStatus = new LexDemandStatus(2);
      this.updateDemand();
    }
    this.onClose();
  }

  private updateOffer(): void {
    this.otvService.updateOTV(this.otv).subscribe((res) => {
      this.otvService.updateAnalysisDeclarationOnOffer(res.id, this.analysis).subscribe((updatedAD: AnalysisDeclaration) => {
        this.analysis = updatedAD;

        this.otvService.updateAnalysisResults(res.id, this.defaultAnalysisResults).subscribe((updatedAR) => {
          this.defaultAnalysisResults = updatedAR;
        });
      });
      this.router.navigate(['/offres/details/' + res.id]);
    });
  }

  private updateDemand(): void {
    this.dtvService.updateDTV(this.dtv).subscribe((res) => {
			this.dtvService.updateAnalysisDeclarationOnDemand(res.id, this.analysis).subscribe((updatedAD: AnalysisDeclaration) => {
				this.analysis = updatedAD;

				this.dtvService.updateAnalysisResults(res.id, this.defaultAnalysisResults).subscribe((updatedAR) => {
					this.defaultAnalysisResults = updatedAR;
				});
			});
			this.router.navigate(['/demandes/details/' + res.id]);
		});
  }

}
