<form name="createSiteForm" #form="ngForm" (ngSubmit)="createSite()">
    <div class="process-body container">
        <div class="page-header">
            <h1 class="h1">Ajouter un site</h1>
        </div>

        <div class="process-wrapper" #divToModalClick>
            <nav class="process-nav">
                <ol class="process-nav-list">
                    <li class="process-nav-item" [class.process-nav-item--selected]="currentStep === 1">
                        <a href="#01" class="process-nav-link">
                            <i class="process-nav-icon icon-info" aria-hidden="true"></i>
                            <span class="process-nav-label">Informations générales</span>
                        </a>
                    </li>
                    <li class="process-nav-item" [class.process-nav-item--selected]="currentStep === 2">
                        <a href="#02" class="process-nav-link">
                            <i class="process-nav-icon icon-pin" aria-hidden="true"></i>
                            <span class="process-nav-label">Localisation du site</span>
                        </a>
                    </li>
                </ol>
            </nav>

            <div class="process-main">
                <fieldset id="01" class="process-section">
                    <legend class="process-section-header">
                        <i class="process-section-icon icon-add" aria-hidden="true"></i>
                        <span class="process-section-title" data-counter="01">Informations générales</span>
                    </legend>
                    <div class="process-section-body">
                        <div class="form-fieldset">
                            <div class="form-fieldset-header">
                                <h3 class="h3 form-fieldset-title">Nom du site</h3>
                            </div>
                            <div class="form-group">
                                <label class="form-item form-item--6">
                                    <span class="form-label">Définissez le nom usuel du site <span
                                            class="red">*</span></span>
                                    <input type="text" #siteName="ngModel" name="siteName" [(ngModel)]="site.name"
                                        required placeholder="Entrer le nom du site" class="form-input form-control"
                                        [ngClass]="{'red-border-class': siteName.errors && form.submitted}">
                                </label>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset id="02" class="process-section" [disabled]="currentStep < 2">
                    <legend class="process-section-header">
                        <i class="process-section-icon icon-pin" aria-hidden="true"></i>
                        <span class="process-section-title" data-counter="02">Localisation du site</span>
                    </legend>
                    <div class="process-section-body">
                        <div class="form-fieldset">
                            <div class="form-fieldset-header">
                                <h3 class="h3 form-fieldset-title">Adresse</h3>
                            </div>
                            <div class="form-group">
                                <label class="form-item form-item--12">
                                    <span class="form-label">Rechercher une adresse</span>
                                    <p-autoComplete type="text" name="siteAddress" #siteAddress="ngModel"
                                        [(ngModel)]="requete" [suggestions]="adresses?.features"
                                        (onSelect)="doOnSelect($event)" (completeMethod)="searchAdresse($event)"
                                        field="properties.label" [minLength]="3" class="form-search form-search-input">
                                    </p-autoComplete>

                                </label>

                                <label class="form-item form-item--6">
                                    <span class="form-label">Rue <span class="red">*</span></span>

                                    <input type="text" name="siteAddress" #siteAddress="ngModel" required
                                        [(ngModel)]="site.address.numberStreet" placeholder="Numéro et nom de la rue"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': siteAddress.errors && form.submitted}">
                                </label>

                                <label class="form-item form-item--3">
                                    <span class="form-label">Code postal <span class="red">*</span></span>

                                    <input type="text" name="sitePostalCode" #sitePostalCode="ngModel" required
                                        [(ngModel)]="site.address.zipCode" placeholder="Exemple: 75013" pattern="[0-9]+"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': sitePostalCode.errors && form.submitted}">
                                    <span class="red"
                                        *ngIf="sitePostalCode.status === 'INVALID' && form.submitted">Veuillez saisir un
                                        code postal valide</span>
                                </label>

                                <label class="form-item form-item--3">
                                    <span class="form-label">Ville <span class="red">*</span></span>

                                    <input type="text" name="siteCity" #siteCity="ngModel" required
                                        [(ngModel)]="site.address.foreignCity" placeholder="Exemple: Paris"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': siteCity.errors && form.submitted}">
                                </label>
                            </div>
                        </div>

                        <hr class="form-separator">

                        <div class="form-fieldset">
                            <div class="form-group">

                                <div class="form-item form-item--12">
                                    <p class="form-message">
                                        Dessiner votre site (polygone) sur la carte.<br>
                                        <b>Attention : Le site correspond à la parcelle cadastrale du site émetteur ou
                                            receveur et non pas au plan des travaux.</b>
                                    </p>
                                </div>

                                <div class="form-item form-item--12">
                                    <div class="form-advice">
                                        <i class="form-advice-icon icon-advice" aria-hidden="true"></i>
                                        <p class="form-advice-label">Vous ne pourrez dessiner votre site qu’à partir
                                            d’un
                                            certain niveau de zoom</p>
                                    </div>
                                    <label class="button button--blue col-xs-12">
                                        <i class="button-icon" aria-hidden="true"></i>
                                        <span class="button-label">Réinitialiser la carte</span>
                                        <input type="button" (click)="resetDraw()">
                                    </label>
                                </div>

                                <div class="form-item form-item--12">
                                    <div class="details-map map">
                                        <div id="map" class="map-container"></div>
                                    </div>
                                </div>

                                <div class="form-item form-item--12" *ngIf="parcelles.length > 0">
                                    <p class="form-label">Parcelles intersectées par ce site</p>
                                    <table class="table">
                                        <thead>
                                            <th>ID</th>
                                            <th>Depart.</th>
                                            <th>Code</th>
                                            <th>Ville</th>
                                            <th>Section</th>
                                            <th>Numero</th>
                                            <th>Feuille</th>
                                            <th>Opération</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let parcel of parcelles">
                                                <td>{{parcel.identifiant}}</td>
                                                <td>{{parcel.codeDepartement}}</td>
                                                <td>{{parcel.codeCommune}}</td>
                                                <td>{{parcel.nomCommune}}</td>
                                                <td>{{parcel.section}}</td>
                                                <td>{{parcel.numero}}</td>
                                                <td>{{parcel.feuille}}</td>
                                                <td>
                                                    <a [routerLink]="" (click)="deleteParcelle(parcel)">
                                                        <i class="table-actions-icon icon-cancel"
                                                            aria-hidden="true"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div class="form-item form-item--12" *ngIf="isSisCodeLoaded && (sisIntersectes.length > 0)">
                                    <p class="form-label">SIS intersectés par ce site</p>
                                    <table class="table">
                                        <thead>
                                            <th>Code</th>
                                            <th>Opération</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let sis of sisIntersectes">
                                                <td>{{sis.code}}</td>
                                                <td>
                                                    <a [routerLink]="" (click)="deleteSisIntersectes(sis)">
                                                        <i class="table-actions-icon icon-cancel"
                                                            aria-hidden="true"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <p class="form-message"> Attention, sur un terrain répertorié sur un SIS,
                                        le maître d’ouvrage doit fournir dans le dossier de demande de permis de
                                        construire ou d’aménager une attestation,
                                        réalisée par un bureau d’étude certifié dans le domaine des sites et sols
                                        pollués ou équivalent,
                                        garantissant la réalisation d’une étude des sols et de sa prise en compte dans
                                        la conception du projet de construction ou de lotissement (cf. L.556-2 du code
                                        de l’environnement).
                                        Cette attestation garantit la réalisation d’une étude de sol ainsi que la prise
                                        en compte des préconisations
                                        de cette étude pour assurer la compatibilité entre l'état des sols et l'usage
                                        futur du site dans la conception du projet d'aménagement.</p>
                                </div>

                                <div class="form-item form-item--12" *ngIf="isSisCodeLoaded && (!sisIntersectes || (sisIntersectes.length === 0))">
                                    <p class="form-message">Aucun SIS intersecté par le polygone</p>
                                </div>

                                <div class="form-item form-item--12"
                                    *ngIf="!isSisCodeLoaded">
                                    <p class="form-message">Récupération des codes SIS en cours, merci de patienter...</p>
                                </div>

                                <!-- Informations complémentaires -->
                                <div class="form-item form-item--12">
                                  <span class="form-label">Informations complémentaires</span>
                                  <textarea class="form-textarea" [(ngModel)]="site.comment" id="comment" name="comment" #comment="ngModel"
                                      placeholder="Commentaires…"
                                      >
                                  </textarea>
                                </div>

                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>

    <div class="process-footer">
        <div class="container">
            <div class="process-controls">
                <button type="button" class="button button--teal button--outline" disabled>
                    <i class="button-icon icon-left" aria-hidden="true"></i>
                    <span class="button-label">Précédent</span>
                </button>

                <div class="process-steps">
                    <span class="process-steps-current">Étape <span
                            class="process-steps-current-number">{{this.currentStep}}</span></span>
                    <span class="process-steps-total">/ 2</span>

                    <h3 class="process-steps-title">Informations générales</h3>
                </div>

                <button class="button button--teal" (click)="suivant()" *ngIf="currentStep < 2">
                    <span class="button-label">Suivant</span>
                    <i class="button-icon icon-right" aria-hidden="true"></i>
                </button>
                <button type="submit" class="button button--teal" *ngIf="currentStep === 2">
                    <span class="button-label" *ngIf="currentStep === 2">Créer</span>
                    <i class="button-icon icon-right" aria-hidden="true"></i>
                </button>
                <div *ngIf="form.submitted">
                  <div *ngIf="!site.geometry && form.submitted" class="red">
                    Veuillez dessiner un polygone avant de continuer
                  </div>
                  <div *ngIf="form.form.status === 'INVALID' && form.submitted" class="red">
                    Veuillez compléter les champs obligatoires avant de continuer
                  </div>
                  <div *ngIf="!isSisCodeLoaded" class="red">
                    Merci d'attendre que les codes SIS soit récupérés avant de continuer
                  </div>
                </div>
            </div>
        </div>

        <div class="process-progress">
            <div class="process-progress-bar" [style.width]="progress+'%'"></div>
            <span class="process-progress-label">{{progress}}%</span>
        </div>
    </div>
</form>
