<div class="container" *ngIf="transporter">
  <form name="modifTransporterForm" #form="ngForm" (ngSubmit)="updateTransporter(form)">
    <div class="page-sticky">
      <a routerLink="/carriers" routerLinkActive="active" class="button button--outline button--teal">
        <i class="button-icon icon-left"></i>
        <span class="button-label">Retour</span>
      </a>

      <div class="page-header">
        <h1 class="h1">Modification du transporteur</h1>
        <ul class="page-actions">
          <li class="page-actions-item">
            <button type="submit" class="button button--green">
              <i class="button-icon icon-check"></i>
              <span class="button-label">Mettre à jour</span>
            </button>
            <div *ngIf="form.submitted">
              <div *ngIf="form.status == 'INVALID' && form.submitted" class="red">
                Veuillez compléter les champs obligatoires avec des valeurs valides avant de continuer
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="details">
      <div class="details-header">
        <h2 class="details-title h1">{{oldTransporterName}}</h2>
      </div>

      <div class="details-body">
        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-info"></i>
            <h3 class="details-section-title" data-counter="01">Informations générales</h3>
          </div>
          <div class="details-section-body">
            <div class="form-fieldset">
              <div class="form-group">
                <div class="form-item form-item--12">
                  <span class="form-label">Quel est le / les mode(s) de transport ?</span>

                  <div class="form-group">

                    <div class="form-item" *ngFor="let mode of transportMode">
                      <label class="form-checkbox">
                        <input type="radio" name="mode.id" [value]="mode.id" [(ngModel)]="oldTransportModeId"
                          (change)="setModeTransport(mode.id)" class="form-checkbox-input">

                        <span class="form-checkbox-label">{{mode.label}}</span>

                      </label>
                    </div>

                  </div>
                </div>

                <label class="form-item form-item--6">
                  <span class="form-label">Nom du transporteur<span class="red">*</span></span>
                  <input type="text" name="transporterName" #transporterName="ngModel" required
                    [(ngModel)]="transporter.name" placeholder="Entrer le nom du transporteur" class="form-input"
                    [ngClass]="{'red-border-class': transporterName.errors && form.submitted}">
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Numéro de SIRET<span class="red">*</span></span>
                  <input type="text" #transporterSiret="ngModel" name="transporterSiret" class="form-input form-control"
                    (change)="onSiretChange($event)" [(ngModel)]="transporter.siret" placeholder="Saisir le numéro de siret"
                    [ngClass]="{'red-border-class': isInvalidSiret(form), 'green-border-class': siretAddress}"
                    pattern="^[0-9]{14}$" required>

                  <div *ngIf="getSiretFormControl(form) && isInvalidSiret(form)" class="alert alert-danger">
                    <div *ngIf="getSiretFormControl(form).errors?.required">
                      Numéro SIRET requis
                    </div>
                    <div *ngIf="getSiretFormControl(form).errors?.pattern">
                      Merci de renseigner un numéro de SIRET valide (14 chiffres)
                    </div>
                    <div *ngIf="invalidSiret">
                      Le numéro de SIRET renseigné est introuvable
                    </div>
                  </div>

                  <div *ngIf="siretAddress && !isInvalidSiret(form)" class="alert alert-success">
                    Raison sociale : {{ siretAddress.socialReason }}
                  </div>
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Email<span class="red">*</span></span>
                  <input type="email" name="transporterEmail" #transporterEmail="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    [(ngModel)]="transporter.email" placeholder="Entrer email" class="form-input" required
                    [ngClass]="{'red-border-class': transporterEmail.errors && form.submitted}">
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Téléphone<span class="red">*</span></span>
                  <input type="tel" name="transporterPhoneNumber" #transporterPhoneNumber="ngModel" pattern="^\+?([0-9]\s*){10,14}$"
                    [(ngModel)]="transporter.phoneNumber" placeholder="Entrer le numéro de téléphone" class="form-input"
                    [ngClass]="{'red-border-class': transporterPhoneNumber.errors && form.submitted}" required>
                </label>

              </div>
            </div>
          </div>
        </div>

        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-pin"></i>
            <h3 class="details-section-title" data-counter="02">Localisation du transporteur</h3>
          </div>
          <div class="details-section-body">
            <div class="form-fieldset">
              <div class="form-group">
                <label class="form-item form-item--6">
                  <span class="form-label">Rue<span class="red">*</span></span>
                  <input type="text" name="transporterNumberStreet" #transporterNumberStreet="ngModel" required
                    [(ngModel)]="transporter.address.numberStreet" placeholder="Nom et numéro de la rue"
                    class="form-input"
                    [ngClass]="{'red-border-class': transporterNumberStreet.errors && form.submitted}">
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Ville<span class="red">*</span></span>
                  <input type="text" name="transporterCity" #transporterCity="ngModel" placeholder="Exemple: Paris"
                    required [(ngModel)]="transporter.address.foreignCity" placeholder="Exemple: Paris"
                    class="form-input" [ngClass]="{'red-border-class': transporterCity.errors && form.submitted}">
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Code postal<span class="red">*</span></span>
                  <input type="text" name="transporterPostalCode" #transporterPostalCode="ngModel"
                    placeholder="Exemple: 95123" required [(ngModel)]="transporter.address.zipCode"
                    placeholder="Exemple: 75013" pattern="^[0-9]{5}$" class="form-input"
                    [ngClass]="{'red-border-class': transporterPostalCode.errors && form.submitted}">
                  <span class="red" *ngIf="transporterPostalCode.status == 'INVALID' && form.submitted">Veuillez
                    saisir un code postal valide</span>
                </label>

                <label class="form-item form-item--6">
                  <span class="form-label">Pays</span>
                  <span class="form-select">
                    <select class="form-select-inner" [(ngModel)]="selectedCountryId" name="selectedCountryId">
                      <option *ngFor="let country of transporterCountry" [value]="country.id">{{country.name}}</option>
                    </select>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>
