import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from '../fr/brgm/common/guards/is-signed-in.guard';
import { LandingPageComponent } from './landing-page/landing-page.component';

const accueilRoutes: Routes = [
    {path: 'accueil', component: LandingPageComponent, data: {title: APP_PREFIX  + 'Accueil'}, canActivate: [ IsSignedInGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(accueilRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AccueilRoutingModule { }