<div class="modal">
  <form action="" #form="ngForm" class="modal-inner" (ngSubmit)="onSubmit()">

    <button type="button" class="modal-close icon-close" (click)="onClose()"></button>

    <div class="modal-body">

      <h1 class="h1 modal-title">Changer la raison social de mon entreprise</h1>

      <div *ngIf="internalError" class="row alert alert-danger">
        Une erreur interne est survenue, merci de réessayer ultérieurement
      </div>

      <div class="form-group">
        <label class="form-item form-item--12">
          <span class="form-label">Raison sociale<span class="red">*</span></span>
          <input type="text" class="form-input" [(ngModel)]="entity.socialReason" #socialReason="ngModel"
            name="socialReason" [ngClass]="{'red-border-class': socialReason.errors && form.submitted}"
            required />
        </label>
      </div>

      <div class="form-footer">
        <button type="button" class="button button--red" (click)="onClose()">
          <i class="button-icon icon-close" aria-hidden="true"></i>
          <span class="button-label">Annuler</span>
        </button>

        <button type="submit" class="button button--green">
          <i class="button-icon icon-check" aria-hidden="true"></i>
          <span class="button-label">Confirmer</span>
        </button>
      </div>
    </div>
  </form>
</div>
