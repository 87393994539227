import { Deserializable } from './Deserializable';
import { Address } from './address.model';
import { LexEntityNature } from './lex/lexentitynature.model';
import { LexEntityActivity } from './lex/lexentityactivity.model';
import { DocumentTerrass } from './document.model';
import { LexEntityStatus } from './lex/lexentitystatus.model';

export class EntityObject implements Deserializable {
    constructor(
        public id?: number,
        public headChief?: string,
        public name?: string,
        public sigle?: string,
        public siret?: string,
        public socialReason?: string,
        public tvaIntra?: string,
        public address?: Address,
        public kbisDocument?: DocumentTerrass,
        public respEngagementDocument?: DocumentTerrass,
        public lexEntityNature?: LexEntityNature,
        public lexEntityStatus?: LexEntityStatus,
        public lexEntityActivites?: LexEntityActivity[],
    ) {}
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}