import { Deserializable } from './Deserializable';
import { EntityObject } from './entityobject.model';
import { LexCountry } from './lex/lexcountry.model';
import { LexObject } from './lex/lexObject.model';
import { Site } from './site.model';
import { User } from './user.model';

export class Delegation implements Deserializable {
    constructor(
        public id?: number,
        public expirationDate?: Date,
        public handlerId?: number,
        public refDelegation?: string,
        public startDate?: Date,
        public dateDebutGel?: Date,
        public dateFinGel?: Date,
        public entityObject?: EntityObject,
        public lexDelegationStatus?: LexObject,
        public site?: Site,
        public user?: User
    ) {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}