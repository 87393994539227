import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalysisDeclaration } from '../../model/analysisdeclaration.model';
import { AnalysisElemResult } from '../../model/analysiselemresult.model';
import { LexAnalysisType } from '../../model/lex/lex-analysis-type.model';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  private resourceResultUrl =  environment.apiUrl + 'analysisElemResult';
  private resourceUrl =  environment.apiUrl + 'analysisDeclaration';
  private lexAnalysisTypeUrl =  environment.apiUrl + 'lexAnalysisType';

  private SEPARATOR =   '/';

  constructor(private http: HttpClient,) { }

  getAnalysis(id: number, type:string): Observable<AnalysisDeclaration> {
    return this.http.get( this.resourceUrl + this.SEPARATOR + type + this.SEPARATOR + id).pipe(map((res:AnalysisDeclaration)=> res));
  }

  createAnalysis(analysis:AnalysisDeclaration, id:number, type:string):Observable<AnalysisDeclaration> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + "create" + this.SEPARATOR + type + this.SEPARATOR + id, analysis).pipe(map((res:AnalysisDeclaration) => res));
  }

  updateAnalysis(analysis:AnalysisDeclaration, id:number, type:string):Observable<AnalysisDeclaration> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + "update" + this.SEPARATOR + type + this.SEPARATOR +id, analysis).pipe(map((res:AnalysisDeclaration) => res));
  }

  getAnalysisResults(id: number, type:string): Observable<AnalysisElemResult[]> {
    return this.http.get( this.resourceResultUrl + this.SEPARATOR + type + this.SEPARATOR + id).pipe(map((res:AnalysisElemResult[])=> res));
  }

  createAnalysisResults(analysis:AnalysisElemResult[], id:number):Observable<AnalysisElemResult[]> {
    return this.http.post(this.resourceResultUrl + this.SEPARATOR + "create" + this.SEPARATOR + id, analysis).pipe(map((res:AnalysisElemResult[]) => res));
  }

  updateAnalysisResults(analysis:AnalysisElemResult[], id:number, type:string):Observable<AnalysisElemResult[]> {
    return this.http.post(this.resourceResultUrl + this.SEPARATOR + "update" + this.SEPARATOR + type + this.SEPARATOR +id, analysis).pipe(map((res:AnalysisElemResult[]) => res));
  }

  getAllLexAnalysisDetail(): Observable<LexAnalysisType[]> {
    return this.http.get(this.lexAnalysisTypeUrl + this.SEPARATOR + "all").pipe(map((res:LexAnalysisType[]) => res));
  }
}
