<div class="wrapper">
    <div #divToModalClick></div>
    <form action="declareBordereauToRndtsForm" #form="ngForm" (ngSubmit)="confirmDeclaration(form)">
        <div class="page-header">
            <h1 class="h1">Déclaration au RNDTS Terres et sédiments</h1>
        </div>

        <div class="details" *ngIf="type == RndtsType.ENTRANT && bordereau?.dateDeclarationToRndtsEntrants">
            <div class="details-header">
                <h2 class="details-title h1">Ce bordereau a déjà été déclaré le {{bordereau.dateDeclarationToRndtsEntrants | date:'dd/MM/yyyy \'à\' HH:mm' }} au registre {{RndtsType[type]}} du RNDTS : {{bordereau.idRndtsEntrants}}</h2>
            </div>
        </div>
        <div class="details" *ngIf="type == RndtsType.SORTANT && bordereau?.dateDeclarationToRndtsSortants">
            <div class="details-header">
                <h2 class="details-title h1">Ce bordereau a déjà été déclaré le {{bordereau.dateDeclarationToRndtsSortants | date:'dd/MM/yyyy \'à\' HH:mm' }} au registre {{RndtsType[type]}} du RNDTS : {{bordereau.idRndtsSortants}}</h2>
            </div>
        </div>
        <div class="details" *ngIf="(bordereau?.currentUserCanDeclareToRndtsEntrants || bordereau?.currentUserCanDeclareToRndtsSortants) && !currentUser?.cleEtablissement">
            <div class="details-header">
                <h2 class="details-title h1">Pour activer cette fonction, merci de déclarer une clé RNDTS dans votre profil.</h2>
            </div>
        </div>
        <div class="details" *ngIf="(bordereau?.currentUserCanDeclareToRndtsEntrants || bordereau?.currentUserCanDeclareToRndtsSortants) && type != null && !(bordereau?.hasLot)">
            <div class="details-header">
                <h2 class="details-title h1">Pour effectuer une déclaration, merci d'ajouter au moins un lot dont la quantité est supérieure à 0.</h2>
            </div>
        </div>
        <div class="details" *ngIf="(bordereau?.currentUserCanDeclareToRndtsEntrants || bordereau?.currentUserCanDeclareToRndtsSortants) && type != null && bordereau?.hasLot">
            <div class="details-header">
                <h2 class="details-title h1">Déclaration du Bordereau N°{{bordereau?.id}} au registre : {{RndtsType[type]}}</h2>
            </div>

            <div class="form-fieldset">
                <div class="form-group">
                    <div class="form-fieldset-header">
                        <h3 class="h3 form-fieldset-title">Informations générales</h3>
                    </div>

                    <label class="form-item form-item--3" *ngIf="type == RndtsType.ENTRANT && denominationUsuelleEmpty">
                        <span class="form-label">Dénomination usuelle <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsEntrants.denominationUsuelle" name="denominationUsuelle" required 
                        #denominationUsuelle="ngModel" [ngClass]="{'red-border-class': denominationUsuelle.errors && form.submitted}">
                    </label>
                    <label class="form-item form-item--3" *ngIf="type == RndtsType.SORTANT && denominationUsuelleEmpty">
                        <span class="form-label">Dénomination usuelle <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsSortants.denominationUsuelle" name="denominationUsuelle" required 
                        #denominationUsuelle="ngModel" [ngClass]="{'red-border-class': denominationUsuelle.errors && form.submitted}">
                    </label>
            
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Date de réception <span class="red">*</span></span>
                        <span class="form-date">
                            <p-calendar [(ngModel)]="terresSedimentsEntrants.dateReception" name="dateReception"
                              selectOtherMonths="true" dateFormat="dd/mm/yy" class="form-input" [showButtonBar]="true" required 
                              #dateReception="ngModel" [ngClass]="{'red-border-class': (dateReception.errors && form.submitted) || dateError}"
                              (onSelect)="onDateSelect()">
                            </p-calendar>
                        </span>
                        <div *ngIf="dateError" class="alert alert-danger">
                            {{ dateError }}
                        </div>
                    </label>
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Date d'expédition <span class="red">*</span></span>
                        <span class="form-date">
                            <p-calendar [(ngModel)]="terresSedimentsSortants.dateExpedition" name="dateExpedition"
                              selectOtherMonths="true" dateFormat="dd/mm/yy" class="form-input" [showButtonBar]="true" required 
                              #dateExpedition="ngModel" [ngClass]="{'red-border-class': (dateExpedition.errors && form.submitted) || dateError}"
                              (onSelect)="onDateSelect()">
                            </p-calendar>
                        </span>
                        <div *ngIf="dateError" class="alert alert-danger">
                            {{ dateError }}
                        </div>
                    </label>

                    <div class="form-fieldset-header"></div>

                    <div class="form-item form-item--4" *ngIf="(type == RndtsType.ENTRANT)">
                        <label class="form-label">Terre valorisée en remblayage<span class="red">*</span></label>
                        <label class="form-checkbox">
                            <input type="checkbox" class="form-checkbox-input" [(ngModel)]="terresSedimentsEntrants.isTerreValorisee" name="isTerreValorisee"/>
                            <span class="form-checkbox-label"></span>
                        </label>
                    </div>
                    <div class="form-item form-item--4" *ngIf="(type == RndtsType.SORTANT)">
                        <label class="form-label">Terre valorisée en remblayage<span class="red">*</span></label>
                        <label class="form-checkbox">
                            <input type="checkbox" class="form-checkbox-input" [(ngModel)]="terresSedimentsSortants.isTerreValorisee" name="isTerreValorisee"/>
                            <span class="form-checkbox-label"></span>
                        </label>
                    </div>

                    <div class="form-fieldset-header"></div>
            
                    <div class="form-item form-item--4" *ngIf="(type == RndtsType.ENTRANT)">
                        <label class="form-label">Contient des polluants organiques persistants<span class="red">*</span></label>
                        <span style="color:red;">Attention, vous ne devez pas déclarer de terres dangereuses au Registre. Si les terres produites ou reçues sont dangereuses, vous devez les déclarer via Trackdéchets.</span>
                        <br><br>
                        <label class="form-checkbox">
                            <input type="checkbox" class="form-checkbox-input" [(ngModel)]="terresSedimentsEntrants.texPOP" name="texPOP"/>
                            <span class="form-checkbox-label"></span>
                        </label>
                    </div>
                    <div class="form-item form-item--4" *ngIf="(type == RndtsType.SORTANT)">
                        <label class="form-label">Contient des polluants organiques persistants<span class="red">*</span></label>
                        <span style="color:red;">Attention, vous ne devez pas déclarer de terres dangereuses au Registre. Si les terres produites ou reçues sont dangereuses, vous devez les déclarer via Trackdéchets.</span>
                        <br><br>
                        <label class="form-checkbox">
                            <input type="checkbox" class="form-checkbox-input" [(ngModel)]="terresSedimentsSortants.texPOP" name="texPOP"/>
                            <span class="form-checkbox-label"></span>
                        </label>
                    </div>

                    <div class="form-fieldset-header"></div>

                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Code de qualification <span class="red">*</span></span>
                        <span class="form-select">
                            <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsSortants.codeQualification" name="codeQualification" required #codeQualification="ngModel" [ngClass]="{'red-border-class': codeQualification.errors && form.submitted}">
                                <option *ngFor="let codeQualification of codesQualification" [value]="codeQualification.code">{{codeQualification.label}}</option>
                            </select>
                        </span>
                    </label>
            
                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Code du traitement appliqué <span class="red">*</span></span>
                        <span class="form-select">
                            <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsEntrants.codeTraitement" name="codeTraitement" required #codeTraitement="ngModel" [ngClass]="{'red-border-class': codeTraitement.errors && form.submitted}">
                                <option *ngFor="let codeTraitement of codesTraitement" [value]="codeTraitement.code">{{codeTraitement.code}} : {{codeTraitement.label}}</option>
                            </select>
                        </span>
                    </label>
                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Code du traitement appliqué <span class="red">*</span></span>
                        <span class="form-select">
                            <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsSortants.codeTraitement" name="codeTraitement" required #codeTraitement="ngModel" [ngClass]="{'red-border-class': codeTraitement.errors && form.submitted}">
                                <option *ngFor="let codeTraitement of codesTraitement" [value]="codeTraitement.code">{{codeTraitement.code}} : {{codeTraitement.label}}</option>
                            </select>
                        </span>
                    </label>
            
                    <div class="form-fieldset-header"></div>

                    <div class="form-fieldset-header">
                        <h3 class="h3 form-fieldset-title">Producteur</h3>
                    </div>
            
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Type d'organisme <span class="red">*</span></span>
                        <span class="form-select">
                            <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsEntrants.producteur.type" name="producteurType" required #producteurType="ngModel" [ngClass]="{'red-border-class': producteurType.errors && form.submitted}">
                                <option *ngFor="let typePersonne of typesPersonne" [value]="typePersonne.label">{{typePersonne.label}}</option>
                            </select>
                        </span>
                    </label>
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Type d'organisme <span class="red">*</span></span>
                        <span class="form-select">
                            <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsSortants.producteur.type" name="producteurType" required #producteurType="ngModel" [ngClass]="{'red-border-class': producteurType.errors && form.submitted}">
                                <option *ngFor="let typePersonne of typesPersonne" [value]="typePersonne.label">{{typePersonne.label}}</option>
                            </select>
                        </span>
                    </label>

                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsEntrants.producteur.numeroIdentification" name="producteurNumeroIdentification" required #producteurNumeroIdentification="ngModel" [ngClass]="{'red-border-class': producteurNumeroIdentification.errors && form.submitted}">
                    </label>
                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsSortants.producteur.numeroIdentification" name="producteurNumeroIdentification" required #producteurNumeroIdentification="ngModel" [ngClass]="{'red-border-class': producteurNumeroIdentification.errors && form.submitted}">
                    </label>
            
                    <div class="form-fieldset-header"></div>
            
                    <div class="form-fieldset-header" *ngIf="(type == RndtsType.ENTRANT)">
                        <h3 class="h3 form-fieldset-title">Expéditeur </h3>
                    </div>
                    <div class="form-fieldset-header" *ngIf="(type == RndtsType.ENTRANT)">L'expéditeur est l'entreprise qui assure l'expédition des terres sur un centre de tri ou de traitement : entreprise de travaux, ...</div>
                    <div class="form-fieldset-header" *ngIf="(type == RndtsType.SORTANT)">
                        <h3 class="h3 form-fieldset-title">Destinataire</h3>
                    </div>

                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Type d'organisme <span class="red">*</span></span>
                        <span class="form-select">
                            <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsEntrants.expediteur.type" name="expediteurType" required #expediteurType="ngModel" [ngClass]="{'red-border-class': expediteurType.errors && form.submitted}">
                                <option *ngFor="let typePersonne of typesPersonne" [value]="typePersonne.label">{{typePersonne.label}}</option>
                            </select>
                        </span>
                    </label>
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Type d'organisme <span class="red">*</span></span>
                        <span class="form-select">
                            <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsSortants.destinataire.type" name="destinataireType" required #destinataireType="ngModel" [ngClass]="{'red-border-class': destinataireType.errors && form.submitted}">
                                <option *ngFor="let typePersonne of typesPersonne" [value]="typePersonne.label">{{typePersonne.label}}</option>
                            </select>
                        </span>
                    </label>

                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsEntrants.expediteur.numeroIdentification" name="expediteurNumeroIdentification" required #expediteurNumeroIdentification="ngModel" [ngClass]="{'red-border-class': expediteurNumeroIdentification.errors && form.submitted}">
                    </label>
                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsSortants.destinataire.numeroIdentification" name="destinataireNumeroIdentification" required #destinataireNumeroIdentification="ngModel" [ngClass]="{'red-border-class': destinataireNumeroIdentification.errors && form.submitted}">
                    </label>

                    <!--<label class="form-item form-item--2" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                        <input type="text" placeholder="123456789" class="form-input" (change)="getSiren($event)" 
                            name="numeroIdentification" required pattern="^[0-9]{9}$" #numeroIdentification="ngModel" 
                            [ngClass]="{'red-border-class': (numeroIdentification.errors && form.submitted) || invalidSiren}" 
                            [(ngModel)]="terresSedimentsEntrants.expediteur.numeroIdentification">
                        <div *ngIf="(numeroIdentification.errors && form.submitted) || invalidSiren" class="alert alert-danger">
                            <div *ngIf="terresSedimentsEntrants.expediteur.numeroIdentification?.length != 9">
                                Merci de renseigner un numéro de SIREN valide (9 chiffres)
                            </div>
                            <div *ngIf="invalidSiren">
                                Le numéro de SIREN renseigné est introuvable
                            </div>
                        </div>
                    </label>
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                        <input type="text" placeholder="123456789" class="form-input" (change)="getSiren($event)" 
                            name="numeroIdentification" required pattern="^[0-9]{9}$" #numeroIdentification="ngModel" 
                            [ngClass]="{'red-border-class': (numeroIdentification.errors && form.submitted) || invalidSiren}" 
                            [(ngModel)]="terresSedimentsSortants.destinataire.numeroIdentification">
                        <div *ngIf="(numeroIdentification.errors && form.submitted) || invalidSiren" class="alert alert-danger">
                          <div *ngIf="terresSedimentsSortants.destinataire.numeroIdentification?.length != 9">
                            Merci de renseigner un numéro de SIREN valide (9 chiffres)
                          </div>
                          <div *ngIf="invalidSiren">
                            Le numéro de SIREN renseigné est introuvable
                          </div>
                        </div>
                    </label>-->
            
                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Raison sociale <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsEntrants.expediteur.raisonSociale" name="expediteurRaisonSociale" required #expediteurRaisonSociale="ngModel" [ngClass]="{'red-border-class': expediteurRaisonSociale.errors && form.submitted}">
                    </label>
                    <label class="form-item form-item--3" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Raison sociale <span class="red">*</span></span>
                        <input class="form-input" [(ngModel)]="terresSedimentsSortants.destinataire.raisonSociale" name="destinataireRaisonSociale" required #destinataireRaisonSociale="ngModel" [ngClass]="{'red-border-class': destinataireRaisonSociale.errors && form.submitted}" disabled>
                    </label>

                    <label class="form-item form-item--6" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Rechercher une adresse</span>
                        <p-autoComplete type="text" name="searchAddress" #siteAddress="ngModel"
                            [(ngModel)]="requete" [suggestions]="adresses?.features"
                            (onSelect)="doOnSelectAdresse($event)" (completeMethod)="searchAdresse($event)"
                            field="properties.label" [minLength]="3" class="form-search form-search-input">
                        </p-autoComplete>
                    </label>

                    <div class="form-fieldset-header"></div>

                    <label class="form-item form-item--4" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Adresse <span class="red">*</span></span>
                        <input type="text" name="expediteurAddress" #expediteurAddress="ngModel" required
                            [(ngModel)]="terresSedimentsEntrants.expediteur.adresse.libelle" placeholder="Numéro et nom de la rue"
                            class="form-input"
                            [ngClass]="{'red-border-class': expediteurAddress.errors && form.submitted}">
                    </label>
                    <label class="form-item form-item--4" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Adresse <span class="red">*</span></span>
                        <input type="text" name="destinataireAddress" #destinataireAddress="ngModel" required
                            [(ngModel)]="terresSedimentsSortants.destinataire.adresse.libelle" placeholder="Numéro et nom de la rue"
                            class="form-input"
                            [ngClass]="{'red-border-class': destinataireAddress.errors && form.submitted}" disabled>
                    </label>

                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Code postal <span class="red">*</span></span>
                        <input type="text" name="expediteurPostalCode" #expediteurPostalCode="ngModel" required
                            [(ngModel)]="terresSedimentsEntrants.expediteur.adresse.codePostal" placeholder="Exemple: 75013" pattern="[0-9]+"
                            class="form-input"
                            [ngClass]="{'red-border-class': expediteurPostalCode.errors && form.submitted}">
                        <span class="red"
                            *ngIf="expediteurPostalCode.status === 'INVALID' && form.submitted">Veuillez saisir un
                            code postal valide</span>
                    </label>
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Code postal <span class="red">*</span></span>
                        <input type="text" name="destinatairePostalCode" #destinatairePostalCode="ngModel" required
                            [(ngModel)]="terresSedimentsSortants.destinataire.adresse.codePostal" placeholder="Exemple: 75013" pattern="[0-9]+"
                            class="form-input"
                            [ngClass]="{'red-border-class': destinatairePostalCode.errors && form.submitted}" disabled>
                        <div *ngIf="destinatairePostalCode.status === 'INVALID' && form.submitted" class="alert alert-danger">
                            Veuillez saisir un code postal valide
                        </div>
                    </label>

                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.ENTRANT)">
                        <span class="form-label">Ville <span class="red">*</span></span>
                        <input type="text" name="expediteurCity" #expediteurCity="ngModel" required
                            [(ngModel)]="terresSedimentsEntrants.expediteur.adresse.commune" placeholder="Exemple: Paris"
                            class="form-input"
                            [ngClass]="{'red-border-class': expediteurCity.errors && form.submitted}">
                    </label>
                    <label class="form-item form-item--2" *ngIf="(type == RndtsType.SORTANT)">
                        <span class="form-label">Ville <span class="red">*</span></span>
                        <input type="text" name="destinataireCity" #destinataireCity="ngModel" required
                            [(ngModel)]="terresSedimentsSortants.destinataire.adresse.commune" placeholder="Exemple: Paris"
                            class="form-input"
                            [ngClass]="{'red-border-class': destinataireCity.errors && form.submitted}" disabled>
                    </label>
                    
                    <div class="form-fieldset-header"></div>

                    <div class="form-fieldset-header">
                        <h3 class="h3 form-fieldset-title">Transporteur(s)</h3>
                    </div>
            
                    <div *ngIf="(type == RndtsType.ENTRANT)" style="width:100%;">
                        <div *ngFor="let transporteur of terresSedimentsEntrants.transporteurs; let i = index">
                            <div class="form-fieldset-header" *ngIf="!raisonSocialeTransporteurEmpty">
                                <h4 class="h4 form-fieldset-title">{{i+1}} : {{terresSedimentsEntrants.transporteurs[i].raisonSociale}}</h4>
                            </div>
                            <div style="display:flex;flex-direction:row;">
                                <label class="form-item form-item--3" *ngIf="raisonSocialeTransporteurEmpty">
                                    <span class="form-label">Raison Sociale <span class="red">*</span></span>
                                    <input class="form-input" [(ngModel)]="terresSedimentsEntrants.transporteurs[i].raisonSociale" name="transporteurRaisonSociale{{i}}" required 
                                    #transporteurRaisonSociale="ngModel" [ngClass]="{'red-border-class': transporteurRaisonSociale.errors && form.submitted}">
                                </label>
                                <label class="form-item form-item--2">
                                    <span class="form-label">Type d'organisme <span class="red">*</span></span>
                                    <span class="form-select">
                                        <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsEntrants.transporteurs[i].type" name="transporteurType{{i}}" required #transporteurType="ngModel" [ngClass]="{'red-border-class': transporteurType.errors && form.submitted}">
                                            <option *ngFor="let typePersonne of typesPersonne" [value]="typePersonne.label">{{typePersonne.label}}</option>
                                        </select>
                                    </span>
                                </label>
                                <label class="form-item form-item--3">
                                    <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                                    <input class="form-input" [(ngModel)]="terresSedimentsEntrants.transporteurs[i].numeroIdentification" name="transporteurNumeroIdentification{{i}}" required #transporteurNumeroIdentification="ngModel" [ngClass]="{'red-border-class': transporteurNumeroIdentification.errors && form.submitted}">
                                </label>
                            </div>
                            <label class="form-item form-item--6" *ngIf="raisonSocialeTransporteurEmpty">
                                <span class="form-label">Rechercher une adresse</span>
                                <p-autoComplete type="text" name="searchAddressTransporteur" #transporteurAddress="ngModel"
                                    [(ngModel)]="requeteTransporteur" [suggestions]="adressesTransporteur?.features"
                                    (onSelect)="doOnSelectAdresseTransporteur($event)" (completeMethod)="searchAdresseTransporteur($event)"
                                    field="properties.label" [minLength]="3" class="form-search form-search-input">
                                </p-autoComplete>
                            </label>
                            <div style="display:flex;flex-direction:row;" *ngIf="raisonSocialeTransporteurEmpty">
                                <label class="form-item form-item--4">
                                    <span class="form-label">Adresse <span class="red">*</span></span>
                                    <input type="text" name="transporteurAddress" #transporteurAddress="ngModel" required
                                        [(ngModel)]="terresSedimentsEntrants.transporteurs[i].adresse.libelle" placeholder="Numéro et nom de la rue"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': transporteurAddress.errors && form.submitted}">
                                </label>
                                <label class="form-item form-item--2">
                                    <span class="form-label">Code postal <span class="red">*</span></span>
                                    <input type="text" name="transporteurPostalCode" #transporteurPostalCode="ngModel" required
                                        [(ngModel)]="terresSedimentsEntrants.transporteurs[i].adresse.codePostal" placeholder="Exemple: 75013" pattern="[0-9]+"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': transporteurPostalCode.errors && form.submitted}">
                                    <span class="red"
                                        *ngIf="transporteurPostalCode.status === 'INVALID' && form.submitted">Veuillez saisir un
                                        code postal valide</span>
                                </label>
                                <label class="form-item form-item--2">
                                    <span class="form-label">Ville <span class="red">*</span></span>
                                    <input type="text" name="transporteurCity" #transporteurCity="ngModel" required
                                        [(ngModel)]="terresSedimentsEntrants.transporteurs[i].adresse.commune" placeholder="Exemple: Paris"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': transporteurCity.errors && form.submitted}">
                                </label>
                            </div>
                        </div>                         
                    </div>
                    <div *ngIf="(type == RndtsType.SORTANT)" style="width:100%;">
                        <div *ngFor="let transporteur of terresSedimentsSortants.transporteurs; let i = index">
                            <div class="form-fieldset-header" *ngIf="!raisonSocialeTransporteurEmpty">
                                <h4 class="h4 form-fieldset-title">{{i+1}} : {{terresSedimentsSortants.transporteurs[i].raisonSociale}}</h4>
                            </div>
                            <div style="display:flex;flex-direction:row;">
                                <label class="form-item form-item--3" *ngIf="raisonSocialeTransporteurEmpty">
                                    <span class="form-label">Raison Sociale <span class="red">*</span></span>
                                    <input class="form-input" [(ngModel)]="terresSedimentsSortants.transporteurs[i].raisonSociale" name="transporteurRaisonSociale{{i}}" required 
                                    #transporteurRaisonSociale="ngModel" [ngClass]="{'red-border-class': transporteurRaisonSociale.errors && form.submitted}">
                                </label>
                                <label class="form-item form-item--2">
                                    <span class="form-label">Type d'organisme <span class="red">*</span></span>
                                    <span class="form-select">
                                        <select type="number" required class="form-select-inner" [(ngModel)]="terresSedimentsSortants.transporteurs[i].type" name="transporteurType{{i}}" required #transporteurType="ngModel" [ngClass]="{'red-border-class': transporteurType.errors && form.submitted}">
                                            <option *ngFor="let typePersonne of typesPersonne" [value]="typePersonne.label">{{typePersonne.label}}</option>
                                        </select>
                                    </span>
                                </label>
                                <label class="form-item form-item--3">
                                    <span class="form-label">Numéro d'identification <span class="red">*</span></span>
                                    <input class="form-input" [(ngModel)]="terresSedimentsSortants.transporteurs[i].numeroIdentification" name="transporteurNumeroIdentification{{i}}" required #transporteurNumeroIdentification="ngModel" [ngClass]="{'red-border-class': transporteurNumeroIdentification.errors && form.submitted}">
                                </label>
                            </div>
                            <label class="form-item form-item--6" *ngIf="raisonSocialeTransporteurEmpty">
                                <span class="form-label">Rechercher une adresse</span>
                                <p-autoComplete type="text" name="searchAddressTransporteur" #transporteurAddress="ngModel"
                                    [(ngModel)]="requeteTransporteur" [suggestions]="adressesTransporteur?.features"
                                    (onSelect)="doOnSelectAdresseTransporteur($event)" (completeMethod)="searchAdresseTransporteur($event)"
                                    field="properties.label" [minLength]="3" class="form-search form-search-input">
                                </p-autoComplete>
                            </label>
                            <div style="display:flex;flex-direction:row;" *ngIf="raisonSocialeTransporteurEmpty">
                                <label class="form-item form-item--4">
                                    <span class="form-label">Adresse <span class="red">*</span></span>
                                    <input type="text" name="transporteurAddress" #transporteurAddress="ngModel" required
                                        [(ngModel)]="terresSedimentsSortants.transporteurs[i].adresse.libelle" placeholder="Numéro et nom de la rue"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': transporteurAddress.errors && form.submitted}">
                                </label>
                                <label class="form-item form-item--2">
                                    <span class="form-label">Code postal <span class="red">*</span></span>
                                    <input type="text" name="transporteurPostalCode" #transporteurPostalCode="ngModel" required
                                        [(ngModel)]="terresSedimentsSortants.transporteurs[i].adresse.codePostal" placeholder="Exemple: 75013" pattern="[0-9]+"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': transporteurPostalCode.errors && form.submitted}">
                                    <span class="red"
                                        *ngIf="transporteurPostalCode.status === 'INVALID' && form.submitted">Veuillez saisir un
                                        code postal valide</span>
                                </label>
                                <label class="form-item form-item--2">
                                    <span class="form-label">Ville <span class="red">*</span></span>
                                    <input type="text" name="transporteurCity" #transporteurCity="ngModel" required
                                        [(ngModel)]="terresSedimentsSortants.transporteurs[i].adresse.commune" placeholder="Exemple: Paris"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': transporteurCity.errors && form.submitted}">
                                </label>
                            </div>
                        </div>
                    </div>
            
                    <div class="form-fieldset-header"></div>
            
                    <div class="form-item form-item--end form-item--4">
                        <button type="submit" class="button button--fullwidth button--blue">
                            <span class="button-label">Passer à la confirmation</span>
                        </button>
                    </div>
                    <div *ngIf="form.submitted" class="red">
                        <div *ngIf="form.form.status === 'INVALID'">
                          Veuillez compléter les champs obligatoires avant de continuer<br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
