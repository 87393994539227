<div class="container" *ngIf="site">
    <form name="modifSiteForm" #form="ngForm" (ngSubmit)="updateSite(form)">
        <div class="page-sticky">
            <a [routerLink]="'/sites'" class="button button--outline button--teal">
                <i class="button-icon icon-left" aria-hidden="true"></i>
                <span class="button-label">Retour</span>
            </a>

            <div class="page-header">
                <h1 class="h1">Modification du site</h1>
                <ul class="page-actions">
                    <li class="page-actions-item">
                        <button type="submit" class="button button--green">
                            <i class="button-icon icon-check" aria-hidden="true"></i>
                            <span class="button-label">Mettre à jour</span>
                        </button>
                        <div *ngIf="form.submitted">
                            <div *ngIf="form.status === 'INVALID' && form.submitted" class="red">
                              Veuillez compléter les champs obligatoires avant de continuer
                            </div>
                            <div *ngIf="!isSisCodeLoaded" class="red">
                              Merci d'attendre que les codes SIS soit récupérés avant de continuer
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="details">
            <div class="details-header">
                <h2 class="details-title h1">{{oldSiteName}}</h2>
            </div>

            <div class="details-body">
                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-info" aria-hidden="true"></i>
                        <h3 class="details-section-title" data-counter="01">Informations utilisateur</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-fieldset">
                            <div class="form-group">
                                <label class="form-item form-item--6">
                                    <span class="form-label">Nom usuel du site <span class="red">*</span></span>
                                    <input type="text" name="siteName" #siteName="ngModel" required
                                        [(ngModel)]="site.name" placeholder="Entrer le nom du site" class="form-input"
                                        [ngClass]="{'red-border-class': siteName.errors && form.submitted}">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-pin" aria-hidden="true"></i>
                        <h3 class="details-section-title" data-counter="02">Localisation du site</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-fieldset">
                            <div class="form-fieldset-header">
                                <h3 class="h3 form-fieldset-title">Adresse</h3>
                            </div>

                            <div class="form-group">
                                <label class="form-item form-item--12">
                                    <span class="form-label">Rechercher une adresse</span>
                                    <p-autoComplete type="text" name="siteAddress" #siteAddress="ngModel"
                                    [(ngModel)]="requete" [suggestions]="adresses?.features" (onSelect)="doOnSelect($event)"
                                    (completeMethod)="searchAdresse($event)" field="properties.label" [minLength]="3"
                                    class="form-search form-search-input"></p-autoComplete>

                                </label>

                                <label class="form-item form-item--6">
                                    <span class="form-label">Rue <span class="red">*</span></span>

                                    <input type="text" name="siteNumberStreet" #siteNumberStreet="ngModel" required
                                        [(ngModel)]="site.address.numberStreet" placeholder="Nom et numéro de la rue"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': siteNumberStreet.errors && form.submitted}">
                                </label>

                                <label class="form-item form-item--3">
                                    <span class="form-label">Code postal<span class="red">*</span></span>
                                    <input type="text" name="sitePostalCode" #sitePostalCode="ngModel"
                                        placeholder="Exemple: 95123" required [(ngModel)]="site.address.zipCode"
                                        placeholder="Exemple: 75013" pattern="[0-9]+" class="form-input"
                                        [ngClass]="{'red-border-class': sitePostalCode.errors && form.submitted}">
                                    <span class="red"
                                        *ngIf="sitePostalCode.status === 'INVALID' && form.submitted">Veuillez
                                        saisir un code postal valide</span>
                                </label>

                                <label class="form-item form-item--3">
                                    <span class="form-label">Ville<span class="red">*</span></span>

                                    <input type="text" name="siteCity" #siteCity="ngModel" placeholder="Exemple: Paris"
                                        required [(ngModel)]="site.address.foreignCity" placeholder="Exemple: Paris"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': siteCity.errors && form.submitted}">
                                </label>
                            </div>
                        </div>

                        <hr class="form-separator">

                        <div class="form-fieldset">
                            <div class="form-group">

                                <div class="form-item form-item--12">
                                    <p class="form-message">
                                        Dessinez le polygone sur la carte, grâce à l’outil « ligne » pour définir les
                                        limites de votre site.<br>
                                        <b>Attention : Le site correspond à la parcelle cadastrale du site émetteur ou
                                            receveur et non pas au plan des travaux.</b>
                                    </p>
                                </div>

                                <div class="form-item form-item--12">

                                    <app-map-edit-polygon [site]="site" (onPolygonGeometryChange)="onPolygonGeometryChange($event)"
                                      (onPointCoordinatesChange)="onPointCoordinatesChange($event)" (onSisCodeLoadedChange)="onSisCodeLoadedChange($event)">
                                    </app-map-edit-polygon>
                                    <span class="red"
                                        *ngIf="!site.geometry && form.submitted">Veuillez
                                        dessiner un polygone</span>
                                </div>

                                <!-- Informations complémentaires -->
                                <div class="form-item form-item--12">
                                  <span class="form-label">Informations complémentaires</span>
                                  <textarea class="form-textarea" [(ngModel)]="site.comment" id="comment" name="comment" #comment="ngModel"
                                      placeholder="Commentaires…"
                                      >
                                  </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
