import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BordereauService } from 'src/app/fr/brgm/common/http/bodereau/bordereau.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { Bordereau } from 'src/app/fr/brgm/common/model/bordereau.model';
import { LexObject } from 'src/app/fr/brgm/common/model/lex/lexObject.model';

@Component({
  selector: 'app-modal-bstv-close',
  templateUrl: './modal-bstv-close.component.html',
  styleUrls: ['./modal-bstv-close.component.scss']
})
export class ModalBstvCloseComponent implements OnInit {

  @Input() bordereau: Bordereau;

  verificationCheckbox: boolean = false;
  verificationSSDCheckbox: boolean = false;
  bordereauStatus: LexObject[];
  closingError: string;
  hasSSD: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private bordereauService: BordereauService,
    private router: Router,
    private lexiqueService: LexiqueService,
    private modalService: NgbModal
    ) { }

  ngOnInit() {
    this.hasSSD = this.bordereau.guideDechet != null && this.bordereau.guideDechet != "";
    this.lexiqueService.getLexBordereauStatus().subscribe((res) => this.bordereauStatus = res);
  }

  cancel() {
    this.activeModal.dismiss();
  }

  onSubmit() {
    if(this.bordereau.hasLot){
      if(this.verificationCheckbox && ((this.hasSSD && this.verificationSSDCheckbox) || !this.hasSSD)){
        let tempBod: Bordereau = this.bordereau;
        this.bordereauService.close(tempBod).subscribe(
          (_res) => this.activeModal.close(),
          _err => {
            this.closingError = 'Une erreur s\'est produite lors de la clôture du bordereau.';
            setTimeout(()=> this.closingError = null, 4000);
          }
        )
      }
    }
    else{
      this.closingError = 'Le bordereau ne contient pas de lot.';
      setTimeout(()=> this.closingError = null,4000);
    }
  }

  checkVerificationCheckBox(event) {
    this.verificationCheckbox = event.target.checked;
  }

  checkVerificationSSDCheckBox(event) {
    this.verificationSSDCheckbox = event.target.checked;
  }

}
