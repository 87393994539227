import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { Alert } from 'src/app/fr/brgm/common/model/alert.model';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * An utility class to manage RX events
 */
@Injectable({
    providedIn: 'root'
})
export class MessageService {
    observable: Observable<any>;
    observer: Observer<any>;

    constructor(
        private sanitizer:DomSanitizer
    ) {
        this.observable = Observable.create((observer: Observer<any>) => {
            this.observer = observer;
        }).pipe(share());
    }

    /**
     * Method to broadcast the event to observer
     */
    broadcast(event) {
        if (this.observer != null) {
            this.observer.next(event);
        }
    }

    error(sender, error) {
        let errorData = new Alert();
        errorData.title =  this.sanitizer.bypassSecurityTrustHtml("Une erreur s'est produite : ");
        errorData.text =  this.sanitizer.bypassSecurityTrustHtml(error.message);
        errorData.classType = "message message--error";
        this.broadcast({name: sender, content: errorData});
    }

    success(sender, title, text) {
        let successData = new Alert();
        successData.title = this.sanitizer.bypassSecurityTrustHtml( title);
        successData.text = this.sanitizer.bypassSecurityTrustHtml(text);
        successData.classType = "message message--success";
        this.broadcast({name: sender, content: successData});
    }

    info(sender, title, text) {
        let data = new Alert();
        data.title = this.sanitizer.bypassSecurityTrustHtml(title);
        data.text = this.sanitizer.bypassSecurityTrustHtml(text);
        data.classType = "message";
        this.broadcast({name: sender, content: data});
    }

    /**
     * Method to subscribe to an event with callback
     */
    subscribe(eventName, callback) {
        const subscriber: Subscription = this.observable
            .pipe(
                filter(event => {
                    return event.name === eventName;
                })
            )
            .subscribe(callback);
        return subscriber;
    }

    /**
     * Method to unsubscribe the subscription
     */
    destroy(subscriber: Subscription) {
        subscriber.unsubscribe();
    }
}