import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalForgottenPasswordComponent } from 'src/app/modal/modal-forgotten-password/modal-forgotten-password.component';
import { AuthService } from '../../fr/brgm/common/http/auth/auth.service';
import { UserService } from './../../fr/brgm/common/http/user/user.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  message: string;
  returnUrl: string;

  mailError: boolean;
  passwordError: boolean;
  userStatusError: boolean;
  isSubmitted: boolean;

  userActivationSuccess: boolean;
  userActivationCodeError: boolean;
  userActivationError: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public authService: AuthService,
    private modalService: NgbModal,
    private userService: UserService,
    private route: ActivatedRoute
  )
  {
    this.resetError();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.returnUrl = '/';
    this.authService.logout();

    this.route.queryParams.subscribe(params => {
      if (params['activationCode'] != null && params['activationCode'].trim().length === 20) {
        this.activeUserAccount(params['activationCode']);
      }
    });
  }

  private resetError(): void {
    this.mailError = false;
    this.userStatusError = false;
    this.passwordError = false;

    this.userActivationSuccess = false;
    this.userActivationCodeError = false;
    this.userActivationError = false;
  }

  private activeUserAccount(activationCode: string): void {
    this.resetError();
    this.userService.useActivationCode(activationCode).subscribe(
      _success => this.userActivationSuccess = true,
      err => {
        if (err.status === 404) {
          this.userActivationCodeError = true;
        } else {
          this.userActivationError = true;
        }
      }
    );
  }

  get formControls() { return this.loginForm.controls; }

  seConnecter() {
    this.isSubmitted = true;
    this.resetError();

    if (!this.loginForm.invalid) {
      this.tryToAuthenticate();
    }
  }

  tryToAuthenticate(): void {
    this.userService.getUserExist(this.loginForm.value.email).subscribe(
      (userExist) => {
        if (userExist) {
          this.authenticate();
        } else {
          this.mailError = true;
        }
      }
    );
  }

  authenticate(): void {
    this.authService.authenticate(this.loginForm.value).subscribe(
      (_success) => this.navigateAfterSuccessfulAuthentification(),
      (err) => {
        if (err.status === 403) {
          this.userStatusError = true;
        } else {
          this.passwordError = true;
        }
      }
    );
  }

  private navigateAfterSuccessfulAuthentification(): void {
    this.route.queryParams.subscribe(params => {
      if (params['returnUrl'] != null) {
        this.router.navigate([params['returnUrl']]);
      } else {
        this.router.navigate(['accueil']);
      }
    });
  }



  forgottenPassword() {
    const modalRef = this.modalService.open(ModalForgottenPasswordComponent);
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
  }

  public isInvalid(controlName: string): boolean {
    let control = this.getFormControl(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  public getFormControl(controlName: string): AbstractControl {
    return this.loginForm.get(controlName);
  }
}
