import { LexUserStatus } from "../lex/lexuserstatus.model";
import { User } from "../user.model";

export class UserUtils {

  public static isPending(user: User): boolean {
    return (LexUserStatus.PENDING === user?.lexUserStatus?.label);
  }

  public static isCollaborator(user: User): boolean {
    return (LexUserStatus.COLLABORATOR === user?.lexUserStatus?.label);
  }

  public static isAdmin(user: User): boolean {
    return (LexUserStatus.ADMIN === user?.lexUserStatus?.label);
  }

  public static isSuperAdmin(user: User): boolean {
    return (LexUserStatus.SUPER_ADMIN === user?.lexUserStatus?.label);
  }

  public static isDisabled(user: User): boolean {
    return (LexUserStatus.DISABLED === user?.lexUserStatus?.label);
  }

}
