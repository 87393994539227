import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnouncementService } from 'src/app/fr/brgm/common/http/announcement/announcement.service';
import { AnnouncementConfidentialAccess } from 'src/app/fr/brgm/common/model/announcement/announcement-confidential-access.model';
import { Announcement } from 'src/app/fr/brgm/common/model/announcement/announcement.model';
import { AnnouncementType } from 'src/app/fr/brgm/common/model/enums/announcement-type.enum';

@Component({
  selector: 'app-modal-set-confidential-code',
  templateUrl: './modal-set-confidential-code.component.html',
  styleUrls: ['./modal-set-confidential-code.component.scss']
})
export class ModalSetConfidentialCodeComponent {

  @Input() userId: number;

  confidentialCode: string;

  confidentialAccessNotFound: boolean;
  confidentialAccessError: boolean;
  announcementUrl: string;

  constructor(
    private activeModal: NgbActiveModal,
    private announcementService: AnnouncementService
  ) {
    this.resetSearch();
  }

  onClose(): void {
    this.activeModal.close();
  }

  onSubmit(): void {
    this.resetSearch();
    this.announcementService.getConfidentialAccess(new AnnouncementConfidentialAccess(this.userId, this.confidentialCode)).subscribe(
      (announcement: Announcement) => {
        if (announcement.announcementType === AnnouncementType.Demand) {
          this.announcementUrl = `/demandes/details/${announcement.demand.id}`;
        } else {
          this.announcementUrl = `/offres/details/${announcement.offer.id}`;
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.confidentialAccessNotFound = true;
        } else {
          console.error(err);
          this.confidentialAccessError = true;
        }
      }
    );
  }

  private resetSearch(): void {
    this.confidentialAccessNotFound = false;
    this.confidentialAccessError = false;
    this.announcementUrl = null;
  }

}
