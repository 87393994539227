<div class="container">
  <div class="page-header">
    <h1 class="h1">Mes sites</h1>

    <a routerLink="create" routerLinkActive="active" class="button button--blue">
      <i class="button-icon icon-add" aria-hidden="true"></i>
      <span class="button-label" style="color: #FFF">Ajouter un site</span>
    </a>
  </div>

  <div class="table-wrapper">
    <div class="table-filters">
      <span class="table-filters-results">
        <i class="table-filters-results-icon icon-pin" aria-hidden="true"></i>
        {{totalSites}} Sites
      </span>

      <ul class="table-filters-list">

        <li class="table-filters-item" *ngFor="let status of siteStatus">
          <label class="table-filters-button has-tooltip">
            <input type="checkbox" class="table-filters-input" (change)="addFilterStatus(status)">
            <span class="table-filters-label">{{status.label}}</span>

            <span class="tooltip tooltip--center">Filtrer par sites {{status.label}}</span>
          </label>
        </li>
        <li class="table-filters-item">
          <label class="table-filters-search-item">
            <input type="text" placeholder="Nom ou adresse du site" class="table-filters-search-input"
              [(ngModel)]="filterSearch" (blur)="search()" (keyup.enter)="search()">
            <i class="table-filters-search-icon icon-search" aria-hidden="true"></i>
          </label>
        </li>
      </ul>
    </div>

    <p-table (onLazyLoad)="loadLazy($event)" [paginator]="false" [lazy]="true" [totalRecords]="totalSites" dataKey="id"
      tableStyleClass="table" [resetPageOnSort]="false" [value]="mySites"
      [tableStyle]="{'table-layout':'auto'}">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'id'" class="table-sortable" scope="col">Site</th>
          <th [pSortableColumn]="'name'" class="table-sortable" scope="col">Nom du site</th>
          <th [pSortableColumn]="'address'" class="table-sortable" scope="col">Localisation</th>
          <th [pSortableColumn]="'lexSiteStatus'" class="table-sortable" scope="col">Statut</th>
          <th class="table-center" scope="col">Offres/Demandes</th>
          <th class="table-center" scope="col">Nombre de délégations actives</th>
          <th scope="col">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-site>
        <tr>
          <td>
            <a [routerLink]="['/sites/details/',site.id]" routerLinkActive="active" class="table-link">{{site.id}}</a>
          </td>
          <td>
            <a [routerLink]="['/sites/details/',site.id]" routerLinkActive="active" class="table-link">{{site.name}}</a>
          </td>
          <td>{{site.address.numberStreet}}, {{site.address.zipCode}}, {{site.address.foreignCity}}</td>
          <td>
            <b [class.green]="site.lexSiteStatus.id === 1"
              [class.orange]="site.lexSiteStatus.id === 2">{{site.lexSiteStatus.label}}</b>
          </td>
          <td style="text-align: center;">
            <span *ngIf="site.hasOfferDemand">Oui</span><span *ngIf="!site.hasOfferDemand">Non</span>
          </td>
          <td style="text-align: center;">
            {{ getNumberOfActiveDelegations(site) }}
          </td>
          <td>
            <div class="table-actions">
              <input type="checkbox" id="table-actions-1" class="table-actions-input">
              <label for="table-actions-1" class="table-actions-toggle"></label>
              <ul class="table-actions-list">
                <li class="table-actions-item">
                  <a [routerLink]="['/sites/details/',site.id]" routerLinkActive="active"
                    class="table-actions-link blue">
                    <i class="table-actions-icon icon-eye" aria-hidden="true"></i>
                    <span class="table-actions-label">Consulter</span>
                  </a>
                </li>
                <li class="table-actions-item">
                  <a [routerLink]="['/sites/edit/',site.id]" class="table-actions-link orange"
                    *ngIf="site.lexSiteStatus.label === 'Ouvert' && !site.hasOfferDemandNotClosed && site.currentUserCanUpdate">
                    <i class="table-actions-icon icon-edit" aria-hidden="true"></i>
                    <span class="table-actions-label">Modifier</span>
                  </a>
                </li>
                <li class="table-actions-item">
                  <a [routerLink]="['/sites/delegations/create/', site.id]" class="table-actions-link"
                    *ngIf="site.lexSiteStatus.label === 'Ouvert' && site.currentUserCanDelegate">
                    <i class="table-actions-icon icon-external" aria-hidden="true"></i>
                    <span class="table-actions-label">Mettre en délégation</span>
                  </a>
                </li>
                <li class="table-actions-item">
                  <a class="table-actions-link red"
                    *ngIf="site.lexSiteStatus.label === 'Ouvert' && !site.hasOfferDemandNotClosed && site.currentUserCanCloseOrReopen"
                    (click)="fermer(site)">
                    <i class="table-actions-icon icon-open-folder" aria-hidden="true"></i>
                    <span class="table-actions-label">Fermer</span>
                  </a>
                  <a class="table-actions-link lightblue"
                    *ngIf="site.lexSiteStatus.label === 'Fermé' && site.currentUserCanCloseOrReopen"
                    (click)="rouvrir(site)">
                    <i class="table-actions-icon icon-open-folder" aria-hidden="true"></i>
                    <span class="table-actions-label">Rouvrir</span>
                  </a>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-pagination [length]="totalSites" [index]="page" [size]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (page)="pageChange($event)"></app-pagination>

  </div>
</div>
