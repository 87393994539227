<div class="wrapper">
    <form action="" method="" class="container">
        <div class="page-header">
            <h1 class="h1">Mon profil</h1>
        </div>

        <div class="details">
            <div class="details-header">
                <h2 class="details-title h1">{{currentUser?.firstname}} {{currentUser?.name}}</h2>
            </div>

            <div class="form-fieldset">
                <div class="form-group">
                    <label class="form-item form-item--12">
                        <span class="form-label">Email: {{currentUser.email}}</span>
                    </label>
                    <label class="form-item form-item--disabled form-item--12">
                        <span class="form-label">Raison sociale de l'entreprise: {{currentUser.entityObject.socialReason}}</span>
                    </label>
                    <label class="form-item form-item--disabled form-item--12" *ngIf="currentUser.cleEtablissement">
                        <span class="form-label">Clé RNDTS: **********</span>
                    </label>
                    <label class="form-item form-item--disabled form-item--12" *ngIf="!currentUser.cleEtablissement">
                        <span class="form-label">Clé RNDTS: Aucune clé n'a été entrée</span>
                    </label>
                    <label class="form-item form-item--disabled form-item--12" *ngIf="currentUser.cleEtablissement">
                        <span class="form-label">Clé de l'établissement: {{currentUser.entityObject.siret}}<b>{{currentUser.cleEtablissement}}</b></span>
                    </label>
                    <label class="form-item form-item--disabled form-item--12" *ngIf="!currentUser.cleEtablissement">
                        <span class="form-label">Clé de l'établissement: Aucune clé n'a été entrée</span>
                    </label>
                    <label class="form-item form-item--12">
                        <span class="form-label">Téléphone</span>
                        <div class="form-inline">
                            <input type="tel" #phoneNumber="ngModel" name="phoneNumber"
                                [(ngModel)]="currentUser.phone" placeholder="Numéro de téléphone" pattern="^\+?([0-9]\s*){10,14}$"
                                class="form-input form-control"
                                [ngClass]="{'red-border-class': phoneNumber.errors}" required>
                            <button class="edit-button gg-phone" (click)="updatePhone()" title="Enregistrer le numéro de téléphone"></button>
                        </div>
                    </label>
                    <div class="form-item form-item--end form-item--6">
                        <button type="button" class="button button--fullwidth button--blue" (click)="openPasswordModal()">
                            <i class="button-icon icon-padlock" aria-hidden="true"></i>
                            <span class="button-label">Changer mon mot de passe</span>
                        </button>
                    </div>
                    <div class="form-item form-item--end form-item--6" *ngIf="!currentUser.cleEtablissement">
                        <button type="button" class="button button--fullwidth button--blue" (click)="openRNDTSKeydModal()">
                            <i class="button-icon icon-padlock" aria-hidden="true"></i>
                            <span class="button-label">Saisir ma clé RNDTS et d'établissement</span>
                        </button>
                    </div>
                    <div class="form-item form-item--end form-item--6" *ngIf="currentUser.cleEtablissement">
                        <button type="button" class="button button--fullwidth button--blue" (click)="openRNDTSKeydModal()">
                            <i class="button-icon icon-padlock" aria-hidden="true"></i>
                            <span class="button-label">Modifier ma clé RNDTS et d'établissement</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
