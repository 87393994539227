
import { Component, Input, OnInit } from '@angular/core';

import Group from 'ol/layer/Group';

import { defaults, ScaleLine } from 'ol/control';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import View from 'ol/View';
import { BehaviorSubject } from 'rxjs';
import { Site } from 'src/app/fr/brgm/common/model/site.model';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})

export class MapViewComponent implements OnInit {
  map: Map;
  view: View = new View();
  vectorLayer: VectorLayer<VectorSource>;

  private _site = new BehaviorSubject<Site>(new Site);

  @Input() set site(value){
    this._site.next(value);
  }

  get site(){
    return this._site.getValue();
  }

  constructor() { }

  ngOnInit() {
    this.initMap();
    this._site.subscribe(x => {
      if(this.site && this.site.geometry){
        this.map.removeLayer(this.vectorLayer);
        this._addFeature(this.site);
      }
    });
  }

  initMap(){
    var scale = new ScaleLine();
    //var zslider = new ZoomSlider();

      this.map = new Map({
        view: this.view,
        target: "site_map",
        controls: defaults({
          attribution : true,
        }).extend([
          scale
          //zslider
        ])
      })

      const osmStandardLayer = new TileLayer({
        source: new OSM(),
        visible: true
      })

      const baseLayeGroup = new Group({
        layers: [
          osmStandardLayer
        ]
      })

      this.map.addLayer(baseLayeGroup);
  }

  _addFeature(data: Site){
    var styles = {
      'Polygon': new Style({
        stroke: new Stroke({
          color: 'rgba(237,131,77,1)',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(237,131,77,.5)',
        }),
      })
    };

    var styleFunction = function (feature) {
      return styles[feature.getGeometry().getType()];
    };
    var vectorFeature = new Feature();

    vectorFeature.setGeometry(new GeoJSON().readGeometry(data.geometry).transform('EPSG:4326', 'EPSG:3857'));
    this.view.fit(vectorFeature.getGeometry().getExtent(), {padding: [100, 100, 100, 100]});

    var vectorSource = new VectorSource({
      features: [vectorFeature],
    });

    this.vectorLayer = new VectorLayer({
      source: vectorSource,
      style: styleFunction,
    });

    if(Math.ceil(this.view.getZoom()) != 28){
      this.view.setZoom(Math.ceil(this.view.getZoom()));
    }else{
      this.view.setZoom(15);
    }

    this.map.addLayer(this.vectorLayer);
  }

  _getCenterOfExtent(extent){
    var X = (extent[0] + extent[2])/2;
    var Y = (extent[1] + extent[3])/2;
    return [X, Y];
  }

}
