import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { forkJoin } from "rxjs";
import { AnalysisService } from "src/app/fr/brgm/common/http/analysis/analysis.service";
import { FondPedogeochimiqueService } from "src/app/fr/brgm/common/http/fondpedogeochimique/fondpedogeochimique.service";
import { LexiqueService } from "src/app/fr/brgm/common/http/lex/lexique.service";
import { AnalysisType } from "src/app/fr/brgm/common/model/analysis-type.model";
import { AnalysisDeclaration } from "src/app/fr/brgm/common/model/analysisdeclaration.model";
import { AnalysisElemResult } from "src/app/fr/brgm/common/model/analysiselemresult.model";
import { AnalysisElemResultPK } from "src/app/fr/brgm/common/model/analysiselemresultpk.model";
import { FondPedogeochimiqueAnalysis } from "src/app/fr/brgm/common/model/fondpedogeochimiqueanalysis.model";
import { FondPedogeochimique } from "src/app/fr/brgm/common/model/lex/fondpedogeochimique.model";
import { LexAnalysisType } from "src/app/fr/brgm/common/model/lex/lex-analysis-type.model";
import { LexMolecule } from "src/app/fr/brgm/common/model/lex/lexmolecule.model";
import { ModalAlertMessageComponent } from "../../../modal/modal-alert-message/modal-alert-message.component";
import { ModalSauvegarderFpgComponent } from "../../../modal/modal-sauvegarder-fpg/modal-sauvegarder-fpg.component";
import { MessageService } from "../../message/message.service";
import { Annonce } from "../annonce.model";


@Component({
  selector: "app-analyses",
  templateUrl: "./analyses.component.html",
  styleUrls: ["./analyses.component.scss"],
})
export class AnalysesComponent implements OnInit {

  @ViewChild('divToModalClickMessage') divToModalClickMessage: ElementRef;

  @Input() childIsOffer: boolean;
  @Input() childIsCreation: boolean;
  @Input() annonce: Annonce;
  @Input() parentFormSubmitted: boolean;

  analysis: AnalysisDeclaration;
  defaultAnalysisResults: AnalysisElemResult[];

  molecules: LexMolecule[];
  filteredMolecules: LexMolecule[];
  selectedMoelecule: any;
  masterMoelecule: any;

  analysisAlreadyExist: string;

  lexAnalysisTypes: LexAnalysisType[];
  currentLexAnalysisTypeIdValue = 1;

  fondpedogeochimiques: FondPedogeochimique[];
  idSelectedFond: number = null;

  @Output() analysisElemResultEmitter: EventEmitter<AnalysisElemResult[]> =
    new EventEmitter<AnalysisElemResult[]>();
  @Output() analysisDeclarationEmitter: EventEmitter<AnalysisDeclaration> =
    new EventEmitter<AnalysisDeclaration>();

  typeAnnonce: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private lexiqueService: LexiqueService,
    private analysisService: AnalysisService,
    private fondPedogeochimiqueService: FondPedogeochimiqueService,
    private modalService: NgbModal,
    private messageService: MessageService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.analysisAlreadyExist = null;
    this.analysis = new AnalysisDeclaration();
    this.analysis.analysisType = new AnalysisType();
  }

  ngOnInit() {
    if (this.childIsOffer) {
      this.typeAnnonce = "offer";
    } else {
      this.typeAnnonce = "demand";
    }

    this.analysisService
      .getAllLexAnalysisDetail().subscribe((lexAnalysisTypes) => {
        this.lexAnalysisTypes = lexAnalysisTypes;
        this.currentLexAnalysisTypeIdValue = this.lexAnalysisTypes[0].id;
        this.initAnalysisDeclaration();
      });

    this.lexiqueService.getLexMolecules().subscribe((res: LexMolecule[]) => {
      this.molecules = res;
    });

    this.lexiqueService.getLexFondPedogeochimique().subscribe((res: FondPedogeochimique[]) => {
      this.fondpedogeochimiques = res;
    });
  }

  initAnalysisDeclaration(): void {
    this.defaultAnalysisResults = [];
    if (!this.childIsCreation) {
      this.activatedRoute.params.subscribe((params) => {
        forkJoin([
          this.analysisService.getAnalysis(params.id, this.typeAnnonce),
          this.analysisService.getAnalysisResults(params.id, this.typeAnnonce)
        ]).subscribe(([anaDecla, results]) => {
          if (anaDecla != null) {
            this.analysis = anaDecla;
            this.currentLexAnalysisTypeIdValue = this.analysis.analysisType.lexAnalysisType.id;
            this.lexiqueService.getLexMolecules().subscribe((molecules: LexMolecule[]) => {
                results.forEach((analysisResult) => {
                  analysisResult.lexMolecule = _.find(molecules, ["id", analysisResult.id.moleculeId]);
                });
                this.defaultAnalysisResults = results;
                this.emitAnalysisResults();
            });
            if (anaDecla.analysisType.lexAnalysisType.id === 2) {
              this.idSelectedFond = this.analysis.fondPedogeochimique.id;
            }
          }
          this.emitAnalysisDeclaration();
        });
      });
    } else {
      this.analysis.analysisType.lexAnalysisType = this.getLexAnalysisType(this.currentLexAnalysisTypeIdValue);
      this.getDefaultMolecules();
    }
    this.emitAnalysisDeclaration();
  }

  convertToAnalysisElemResults(elements: FondPedogeochimiqueAnalysis[], idAnaDecla: number): AnalysisElemResult[] {
    let results: AnalysisElemResult[] = [];
    elements.forEach(element => {
      let aer: AnalysisElemResult = new AnalysisElemResult();
      aer.id.analysisDeclId = idAnaDecla;
      aer.id.moleculeId = element.id.moleculeId;
      aer.infQl = element.infQl;
      aer.lexMolecule = element.lexMolecule;
      aer.quantifyLimit = element.quantifyLimit;
      aer.value = element.value;
      results.push(aer);
    })
    return results;
  }

  getDefaultMolecules() {
    this.lexiqueService.getLexMolecules().subscribe((res: LexMolecule[]) => {
      this.molecules.forEach((molecule: LexMolecule) => {
        if (molecule.active) {
          let analysisResult = new AnalysisElemResult();
          analysisResult.lexMolecule = molecule;
          analysisResult.id.moleculeId = molecule.id;
          analysisResult.id.analysisDeclId = this.analysis.id;
          if (molecule.defaultDisplayed) {
            this.defaultAnalysisResults.push(analysisResult);
          }
        }
      });
    });
  }

  emitAnalysisResults() {
    this.analysisElemResultEmitter.emit(this.defaultAnalysisResults);
    this.forceChangesUpdate(); // #181 : permet de forcer l'actualisation des composants angular (notament *ngIf) afin de forcer l'affichage du tableau d'analyse
  }

  emitAnalysisDeclaration() {
    if (!this.childIsOffer) {
      this.updateAnalysisType(); // Le type d'analyse n'est disponible que pour les demandes
      this.analysis.fondPedogeochimique = null;

      if (this.currentLexAnalysisTypeIdValue === 2) {
        if (this.idSelectedFond !== null) {
          let fondPedogeochimique: FondPedogeochimique = this.fondpedogeochimiques.find((lex) => lex.id === this.idSelectedFond);
          this.analysis.fondPedogeochimique = fondPedogeochimique;
          this.defaultAnalysisResults = [];
          this.fondPedogeochimiqueService.getByIdFondPedogeochimique(fondPedogeochimique.id).subscribe(res => {
            this.defaultAnalysisResults = this.convertToAnalysisElemResults(res, this.analysis.id);
            this.emitAnalysisResults();
          });
        }
      }

      if (this.currentLexAnalysisTypeIdValue === 3) {
        this.defaultAnalysisResults = [];
        this.emitAnalysisResults();
      }
    }
    this.analysisDeclarationEmitter.emit(this.analysis);
    this.forceChangesUpdate(); // #181 : permet de forcer l'actualisation des composants angular (notament *ngIf) afin de forcer l'affichage du tableau d'analyse
  }

  updateAnalysisType(): void {
    this.analysis.analysisType.lexAnalysisType = this.getLexAnalysisType(this.currentLexAnalysisTypeIdValue);
  }

  getLexAnalysisType(lexId: number): LexAnalysisType {
    return this.lexAnalysisTypes.find((lex) => lex.id === lexId);
  }

  clickOnInfQ1(infQl, result: AnalysisElemResult) {
    infQl ? (result.quantifyLimit = undefined) : (result.value = undefined);
  }

  analysisExistInAnalysisResults(analysisToTest: AnalysisElemResult): boolean {
    return this.defaultAnalysisResults.some((analysis) =>
        analysis.lexMolecule.name.toLowerCase() === analysisToTest.lexMolecule.name.toLowerCase()
    );
  }

  addAnalysisResult() {
    let analysisResult = new AnalysisElemResult();
    analysisResult.lexMolecule = this.masterMoelecule;
    let analysisElemResultPK: AnalysisElemResultPK = new AnalysisElemResultPK(this.masterMoelecule?.id, this.analysis?.id);
    analysisResult.id = analysisElemResultPK;
    analysisResult.infQl = false;

    if (!this.analysisExistInAnalysisResults(analysisResult)) {
      this.defaultAnalysisResults.push(analysisResult);
    } else {
      this.analysisAlreadyExist = analysisResult.lexMolecule.name;
    }

    this.selectedMoelecule = "";
  }

  doOnSelectMolecule(event) {
    this.masterMoelecule = this.selectedMoelecule;
  }

  filterMolecule(event) {
    this.analysisAlreadyExist = null;

    let filtered: any[] = [];
    let query = event.query;
    for (let mole of this.molecules) {
      if (mole.active && !mole.defaultDisplayed && mole.name.toLowerCase().indexOf(query.toLowerCase()) == 0 ) {
        filtered.push(mole);
      }
    }

    this.filteredMolecules = filtered;
  }
  saveFondPedogeochimiqueAnalysis() {
    const modalRef = this.modalService.open(ModalSauvegarderFpgComponent, { size: 'sm' });
    modalRef.componentInstance.defaultAnalysisResults = this.defaultAnalysisResults;
    modalRef.result.then(
      (data: any) => {
        if (data != null) {
          this.fondpedogeochimiques.push(data);
          this.idSelectedFond = data.id;
          const alertModalRef = this.modalService.open(ModalAlertMessageComponent, { size: 'sm' });
          alertModalRef.componentInstance.message = data.nom;
          this.divToModalClickMessage.nativeElement.click()
          this.emitAnalysisDeclaration();
        }
      }
    );
  }

  private forceChangesUpdate(): void {
    this.changeDetector.detectChanges();
  }

}
