import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { UploadFichierService } from 'src/app/00-shared/upload-file/upload-fichier.service';
import { AnalysisService } from 'src/app/fr/brgm/common/http/analysis/analysis.service';
import { AnnouncementService } from 'src/app/fr/brgm/common/http/announcement/announcement.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { OtvService } from 'src/app/fr/brgm/common/http/otv/otv.service';
import { UrlService } from 'src/app/fr/brgm/common/http/url/url.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { AnalysisDeclaration } from 'src/app/fr/brgm/common/model/analysisdeclaration.model';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { DocumentTerrass } from 'src/app/fr/brgm/common/model/document.model';
import { AnnouncementType } from 'src/app/fr/brgm/common/model/enums/announcement-type.enum';
import { LexOfferStatus } from 'src/app/fr/brgm/common/model/lex/lexofferstatus.model';
import { OTV } from 'src/app/fr/brgm/common/model/otv.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { ModalGetConfidentialCodeComponent } from 'src/app/modal/modal-get-confidential-code/modal-get-confidential-code.component';
import GeoJSON from 'ol/format/GeoJSON';
import { Coordinate } from 'ol/coordinate';



@Component({
  selector: 'app-view-offre-page',
  templateUrl: './view-offre-page.component.html',
  styleUrls: ['./view-offre-page.component.scss']
})
export class ViewOffrePageComponent implements OnInit {

  @ViewChild("divToModalClick") divToModalClick: ElementRef;

  otv: OTV;
  public otvAnalysis: AnalysisDeclaration;
  public otvAnalysisResults: AnalysisElemResult[];
  loaded: boolean;

  offerStatus: LexOfferStatus[];

  docTechniques:DocumentTerrass[] = [];
  bordereauxAnalyses:DocumentTerrass[] = [];
  bordereauxAnalysesSurLuxiviat:DocumentTerrass[] = [];
  planOfferDocs: DocumentTerrass[] = [];
	pointCoordinates: Coordinate;

  user: User;

  previousUrl: string = '';

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private otvService: OtvService,
    private analysisService: AnalysisService,
    private lexiqueService: LexiqueService,
    private uploadFichierService: UploadFichierService,
    private userService: UserService,
    private router: Router,
    private urlService: UrlService,
    private modalService: NgbModal,
    private announcementService: AnnouncementService
  ) {
    this.otv = null;
    this.otvAnalysis = null;
  }

  ngOnInit() {

    this.previousUrl = this.urlService.getPreviousUrl();
    this.loaded = false;

    this.activatedroute.data.subscribe(data => this.titleService.setTitle(data.title));
    this.lexiqueService.getLexOfferStatus().subscribe((res) => this.offerStatus = res);
    this.userService.getCurrentUser().subscribe((res) => this.user = res);

    this.activatedroute.params.subscribe(params => {
      this.otvService.get(Number(params.id)).subscribe((res: OTV) => {
        this.otv = res;
        if(res.documents){
        res.documents.forEach(d => {
          if(d.lexDocumentType.id == 1){
            this.docTechniques.push(d);
          }
          if(d.lexDocumentType.id == 2){
            this.bordereauxAnalyses.push(d);
          }
          if(d.lexDocumentType.id == 3){
            this.bordereauxAnalysesSurLuxiviat.push(d);
          }
          if(d.lexDocumentType.id == 4){
            this.planOfferDocs.push(d);
          }
        });
      }
        this.analysisService.getAnalysis(this.otv.id, "offer").subscribe((res) => {
          this.otvAnalysis = res;
          this.loaded = true;
        });
        this.analysisService.getAnalysisResults(this.otv.id, "offer").subscribe((results) => {
          this.otvAnalysisResults = results;

          this.lexiqueService.getLexMolecules().subscribe((molecules) => {
            this.otvAnalysisResults.forEach((analysisResult) => {
              analysisResult.lexMolecule = _.find(molecules, ["id", analysisResult.id.moleculeId]);
            });
          });
        });

        if (this.otv?.geometry) {
          var geometry = new GeoJSON().readGeometry(this.otv.geometry).transform('EPSG:4326', 'EPSG:3857');
          this.pointCoordinates = this.getCenterOfExtent(geometry.getExtent());
        }
        else if (this.otv?.site?.geometry){
          var geometry = new GeoJSON().readGeometry(this.otv.site.geometry).transform('EPSG:4326', 'EPSG:3857');
          this.pointCoordinates = this.getCenterOfExtent(geometry.getExtent());
        }
      });
    });
  }

  getCenterOfExtent(extent){
		var X = (extent[0] + extent[2])/2;
		var Y = (extent[1] + extent[3])/2;
		return [X, Y];
	}

  closeOTV(otv: OTV) {
    var tempOtv: OTV = otv;
    tempOtv.lexOfferStatus = this.offerStatus.find(offer => offer.label = "Clôturée");
    this.otvService.closeOTV(tempOtv).subscribe((res) => {
      this.otv.isModifiable = false;
    })
  }

  publishOTV(otv: OTV) {
    var tempOtv: OTV = otv;
    tempOtv.lexOfferStatus = this.offerStatus.find(offer => offer.label === "Publiée");
    this.otvService.publishOTV(tempOtv).subscribe((res) => {
      this.otv = res;
    })
  }

  getVolume(qte: number): number {
    return Number((qte / 1.3).toFixed(1));
  }

  isModifiable(offer): boolean {
    return (offer.lexOfferStatus.id != 3) && this.currentUserCanUpdate(offer);
  }

  isPubliable(offer): boolean {
    return (offer.lexOfferStatus.id == 1) && this.currentUserCanUpdate(offer);
  }

  isCloturable(offer): boolean {
    return (offer.lexOfferStatus.id != 3) && this.currentUserCanUpdate(offer);
  }

  downloadFile(fname: string, nomFic: string) {
    this.uploadFichierService.getFile(fname).subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = nomFic;
      link.click();
    });
  }

  @HostListener('window:popstate', ['$event'])
  onBackBrowser(event: PopStateEvent): void {
    this.navigateBack();
  }

  onReturnClick(): void {
    this.navigateBack();
  }

  navigateBack(): void {
    if(this.previousUrl === '/accueil'){
      this.router.navigate(['/accueil']);
    }
    else{
      this.router.navigate(['/offres']);
      sessionStorage.removeItem('CRITERIA');
    }
  }

  isOwner(): boolean {
    return (this.user.id === this.otv.user.id);
  }

  onGenereteConfidentialCodeClick(): void {
    this.announcementService.getConfidentialCode(AnnouncementType.Offer, this.otv.id).subscribe((confidentialCode: string) => {
      const modalRef = this.modalService.open(ModalGetConfidentialCodeComponent, {
        size: "sm",
      });
      modalRef.componentInstance.confidentialCode = confidentialCode;

      this.divToModalClick.nativeElement.click(); // Permet de forcer l'affichage de la modal
    });
  }

  currentUserCanUpdate(offer: OTV): boolean {
    return offer.currentUserCanUpdate;
  }
}
