import { Component, Input, OnInit } from '@angular/core';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { LexMaterialType } from 'src/app/fr/brgm/common/model/lex/lexmaterialtype.model';
import { LexObject } from 'src/app/fr/brgm/common/model/lex/lexObject.model';
import { Annonce } from '../annonce.model';

@Component({
  selector: 'app-caracterisation',
  templateUrl: './caracterisation.component.html',
  styleUrls: ['./caracterisation.component.scss']
})
export class CaracterisationComponent implements OnInit {

  @Input() annonce: Annonce;
  @Input() parentFormSubmitted: boolean;
  @Input() childIsOffer: boolean;

  materialTypes: LexMaterialType[];
  codeDechets: LexObject[];

  constructor(private lexiqueService: LexiqueService) { }

  ngOnInit() {
    this.lexiqueService.getLexMaterialTypes().subscribe((res) => this.materialTypes = res);
    this.lexiqueService.getLexCodeDechets().subscribe((res) => this.codeDechets = res);
  }
}
