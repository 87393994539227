import { Deserializable } from './Deserializable';
import { AnalysisElemResultPK } from './analysiselemresultpk.model';
import { LexMolecule } from './lex/lexmolecule.model';

export class AnalysisElemResult implements Deserializable {
    constructor(
        public id?: AnalysisElemResultPK,
        public infQl?: boolean,
        public quantifyLimit?:number,
        public value?: number,
        public lexMolecule?:LexMolecule
    ) {
        this.infQl = false;
        this.id = new AnalysisElemResultPK();
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}