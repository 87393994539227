<div class="container" *ngIf="transaction">
    <div class="page-sticky">
        <a routerLink="/transactions" routerLinkActive="active" class="button button--outline button--teal">
            <i class="button-icon icon-left" aria-hidden="true"></i>
            <span class="button-label">Retour</span>
        </a>

        <div class="page-header">
            <h1 class="h1">Consultation de la transaction</h1>

            <ul class="page-actions" style="color:white">

                <li class="page-actions-item" *ngIf="(transaction.lexTransactionStatus.id === 1) && transaction.currentUserCanUpdate">
                    <a [routerLink]="'/transactions/edit/'+transaction.id" class="button button--teal">
                        <i class="button-icon icon-check" aria-hidden="true"></i>
                        <span class="button-label">Modifier</span>
                    </a>
                </li>
                <li class="page-actions-item" *ngIf="(transaction.lexTransactionStatus.id === 1) && transaction.currentUserCanCancel">
                    <a class="button button--red" (click)="annulerTransaction(transaction)">
                        <i class="button-icon icon-close" aria-hidden="true"></i>
                        <span class="button-label">Annuler</span>
                    </a>
                </li>
                <li class="page-actions-item" *ngIf="(transaction.lexTransactionStatus.id === 2) && transaction.currentUserCanCreateBordereau">
                    <a class="button button--green" (click)="createBordereau(transaction)">
                        <i class="button-icon icon-add" aria-hidden="true"></i>
                        <span class="button-label">Créer bordereau</span>
                    </a>
                </li>
                <li class="page-actions-item" *ngIf="canAcceptOrRefuseTransaction()">
                    <a class="button button--green" (click)="accepterTransaction(transaction)">
                        <i class="button-icon icon-check" aria-hidden="true"></i>
                        <span class="button-label">Accepter</span>
                    </a>
                </li>
                <li class="page-actions-item" *ngIf="canAcceptOrRefuseTransaction()">
                    <a class="button button--red" (click)="refuserTransaction(transaction)">
                        <i class="button-icon icon-close" aria-hidden="true"></i>
                        <span class="button-label">Refuser</span>
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="details">
        <div class="details-header">
            <span class="details-type" *ngIf="userCreator && transaction">Créée le
                {{transaction?.creationDate | date:'dd/MM/yyyy'}} par {{userCreator?.firstname}} {{userCreator?.name}} |
                {{userCreator?.entityObject?.name}}</span>

            <h2 class="details-title h1">{{transaction?.id}}</h2>
            <span class="details-status details-status--proposed">{{transaction?.lexTransactionStatus?.label}}</span>

            <span class="details-visibility blue">Transaction valide jusqu’au
                {{transaction?.bstrEndDate | date:'dd/MM/yyyy'}}</span>
        </div>
        <div class="details-body">
            <div class="details-section details-section--6 details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-info" aria-hidden="true"></i>
                    <h3 class="details-section-title">Informations sur la transaction</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--6">
                            <p class="details-label">Quantité de terre (t)</p>
                            <p class="details-value">{{transaction?.quantity}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-section details-section--6 details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-box" aria-hidden="true"></i>
                    <h3 class="details-section-title">Logistique</h3>
                </div>
                <div class="details-section-body" *ngIf="transaction.transporters.length > 0">
                    <p class="details-value" *ngFor="let transporter of transaction.transporters">
                        {{transporter?.name}} - {{transporter?.address?.numberStreet}}, {{transporter?.address?.cityInsee}}
                        {{transporter?.address?.foreignCity}}
                    </p>
                </div>
                <div class="details-section-body" *ngIf="transaction.transporters.length === 0">
                    <p class="details-value">Aucun transporteur n’a été défini</p>
                </div>
            </div>

            <div class="details-section details-section--opened" *ngIf="transaction">
                <div class="details-section-header">
                    <i class="details-section-icon icon-folder" aria-hidden="true"></i>
                    <h3 class="details-section-title">Comparaison de l’offre & de la demande</h3>
                </div>
                <div class="details-section-body">
                    <div class="details-comparison">
                        <div class="details-comparison-item details-comparison-item--offer">
                            <div class="form-group">
                                <div class="form-item form-item--12">
                                    <p class="details-label">Offre</p>
                                    <a class="details-link">{{transaction?.offer?.id}}</a>
                                </div>

                                <div class="form-item form-item--12">
                                    <p class="details-label">Période de disponibilité</p>
                                    <p>Du &nbsp; <span
                                            class="details-value">{{transaction?.offer?.availStartDate | date:'dd/MM/yyyy'}}</span>
                                        &nbsp; &nbsp; Au &nbsp; <span
                                            class="details-value">{{transaction?.offer?.availEndDate | date:'dd/MM/yyyy'}}</span>
                                    </p>
                                </div>

                                <div class="form-item form-item--6">
                                    <p class="details-label">Producteur de terres</p>
                                    <div class="details-contact">
                                        <p class="details-contact-title">{{transaction?.offer?.user?.firstname}}
                                            {{transaction?.offer?.user?.name}} |
                                            {{transaction?.offer?.user?.entityObject?.socialReason}} </p>
                                        <p class="details-contact-line blue">{{transaction?.offer?.user?.email}}</p>
                                        <p class="details-contact-line blue">
                                            {{transaction?.offer?.site?.address?.numberStreet}},
                                            {{transaction?.offer?.site?.address?.cityInsee}}
                                            {{transaction?.offer?.site?.address?.foreignCity}}</p>
                                    </div>
                                </div>

                                <div class="form-item form-item--6">
                                    <p class="details-label">N° de SIRET</p>
                                    <p class="details-value">{{transaction?.offer?.user?.entityObject?.siret}}</p>
                                </div>
                            </div>

                            <h3 class="details-subheading">Caractérisation du site producteur</h3>

                            <div class="form-group">
                                <div class="form-item form-item--6">
                                    <p class="details-label">Quantité de terres (t)</p>
                                    <p class="details-value">{{transaction?.offer?.quantity}}</p>
                                </div>

                                <div class="form-item form-item--6">
                                    <p class="details-label">Type de matériau</p>
                                    <p class="details-value">{{transaction?.offer?.lexMaterialType?.label}}</p>
                                </div>

                                <div class="form-item form-item--12">
                                    <p class="details-label">Informations complémentaires</p>
                                    <p class="details-value">{{transaction?.offer?.infos}}</p>
                                </div>
                            </div>

                            <h3 class="details-subheading">Physico chimique des terres</h3>

                            <div class="form-group">
                                <div class="form-item form-item--12">
                                    <p class="details-label">Synthèse des résultats analytiques</p>
                                    <p class="details-value" *ngIf="!otvAnalysisResults">Aucun résultat</p>
                                    <table class="details-table" *ngIf="otvAnalysisResults">
                                        <thead>
                                            <tr>
                                                <th>Molécule</th>
                                                <th class="details-table-centered">Valeur</th>
                                                <th class="details-table-centered">
                                                    < LQ</th>
                                                <th class="details-table-centered">LQ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let result of otvAnalysisResults">
                                                <td *ngIf="result.lexMolecule">
                                                  {{result.lexMolecule.name}}
                                                </td>
                                                <td class="details-table-centered blue" *ngIf="result.infQl">-</td>
                                                <td class="details-table-centered blue" *ngIf="!result.infQl">
                                                  {{result.value}}</td>
                                                <td class="details-table-centered blue"><i class="icon-check"
                                                  *ngIf="result.infQl"></i>
                                                </td>
                                                <td class="details-table-centered blue" *ngIf="!result.infQl">-</td>
                                                <td class="details-table-centered blue" *ngIf="result.infQl">
                                                  {{result.quantifyLimit}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="details-comparison-item details-comparison-item--demand">
                            <div class="form-group">
                                <div class="form-item form-item--12">
                                    <p class="details-label">Demande</p>
                                    <a class="details-link">{{transaction?.demand?.id}}</a>
                                </div>

                                <div class="form-item form-item--12">
                                    <p class="details-label">Période de disponibilité</p>
                                    <p>Du &nbsp; <span class="details-value">{{transaction?.demand?.availStartDate | date:'dd/MM/yyyy'}}</span> &nbsp; &nbsp; Au &nbsp;
                                        <span class="details-value">{{transaction?.demand?.availEndDate | date:'dd/MM/yyyy'}}</span></p>
                                </div>

                                <div class="form-item form-item--6">
                                    <p class="details-label">Demandeur de terres</p>
                                    <div class="details-contact">
                                        <p class="details-contact-title">{{transaction?.demand?.user?.firstname}}
                                            {{transaction?.demand?.user?.name}} |
                                            {{transaction?.demand?.user?.entityObject?.socialReason}} </p>
                                        <p class="details-contact-line blue">{{transaction?.demand?.user?.email}}</p>
                                        <p class="details-contact-line blue">
                                            {{transaction?.demand?.site?.address?.numberStreet}},
                                            {{transaction?.demand?.site?.address?.cityInsee}}
                                            {{transaction?.demand?.site?.address?.foreignCity}}</p>
                                    </div>
                                </div>

                                <div class="form-item form-item--6">
                                    <p class="details-label">N° de SIRET</p>
                                    <p class="details-value">{{transaction?.demand?.user?.entityObject?.siret}}</p>
                                </div>
                            </div>

                            <h3 class="details-subheading">Caractérisation du site receveur</h3>

                            <div class="form-group">
                                <div class="form-item form-item--6">
                                    <p class="details-label">Quantité de terres (t)</p>
                                    <p class="details-value">{{transaction?.demand?.quantity}}</p>
                                </div>

                                <div class="form-item form-item--6">
                                    <p class="details-label">Type de matériau</p>
                                    <p class="details-value">{{transaction?.demand?.lexMaterialType?.label}}</p>
                                </div>

                                <div class="form-item form-item--12">
                                    <p class="details-label">Informations complémentaires</p>
                                    <p class="details-value">{{transaction?.demand?.infos}}</p>
                                </div>
                            </div>

                            <h3 class="details-subheading">Physico chimique du site receveur</h3>

                            <div class="form-group">

                                <div class="form-item form-item--12">
                                    <p class="details-label">Synthèse des résultats analytiques</p>
                                    <p class="details-value" *ngIf="!dtvAnalysisResults">Aucun résultat</p>
                                    <table class="details-table" *ngIf="dtvAnalysisResults">
                                        <thead>
                                            <tr>
                                                <th>Molécule</th>
                                                <th class="details-table-centered">Valeur</th>
                                                <th class="details-table-centered">
                                                    < LQ</th>
                                                <th class="details-table-centered">LQ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let result of dtvAnalysisResults">
                                                <td *ngIf="result.lexMolecule">
                                                    {{result.lexMolecule.name}}
                                                </td>
                                                <td class="details-table-centered blue" *ngIf="result.infQl">-</td>
                                                <td class="details-table-centered blue" *ngIf="!result.infQl">
                                                    {{result.value}}</td>
                                                <td class="details-table-centered blue"><i class="icon-check"
                                                        *ngIf="result.infQl"></i>
                                                </td>
                                                <td class="details-table-centered blue" *ngIf="!result.infQl">-</td>
                                                <td class="details-table-centered blue" *ngIf="result.infQl">
                                                    {{result.quantifyLimit}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details-section details-section--opened" *ngIf="transaction">
                <div class="details-section-header">
                    <i class="details-section-icon icon-folder"></i>
                    <h3 class="details-section-title">Conversation</h3>
                </div>
                <app-chat [transaction]="transaction" [enabled]="transaction.currentUserCanView"></app-chat>
            </div>
        </div>
    </div>
</div>
