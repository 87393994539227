<div class="wrapper">
    <div class="container">
        <div class="page-header">
            <h1 class="h1">Paramètres</h1>
        </div>
    
        <div class="table-wrapper">
            <table class="table">
                <thead>
                    <tr>
                        <th class="table-selection">
                            <label class="form-checkbox">
                                <input type="checkbox" class="form-checkbox-input" [checked]="allChecked" (change)="changeAll()">
                                <span class="form-checkbox-label"></span>
                            </label>
                        </th>
                        <th>Je souhaite recevoir un email quand…</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let param of params">
                        <td class="table-selection">
                            <label class="form-checkbox">
                                <input type="checkbox" class="form-checkbox-input" [checked]="userParams.indexOf(param.id)>-1" (change)="change(param.id)">
                                <span class="form-checkbox-label"></span>
                            </label>
                        </td>
                        <td class="black">{{param.label}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

