<div class="container">
	<div class="page-header">
		<h1 class="h1">Transactions</h1>
	</div>

	<div class="table-wrapper">
		<div class="table-filters">
			<span class="table-filters-results">
				<i class="table-filters-results-icon icon-file" aria-hidden="true"></i>
				{{totalbstv}} Transactions
			</span>

      <!-- Filtres -->

			<ul class="table-filters-list">
				<li class="table-filters-item" *ngFor="let status of transactionStatus">
					<label class="table-filters-button has-tooltip">
						<input type="checkbox" class="table-filters-input" (change)="addFilterStatus(status)">
						<span class="table-filters-label">{{status.label}}</span>

						<span class="tooltip tooltip--center">Filtrer par transactions {{status.label}}</span>
					</label>
				</li>
			</ul>

      <!-- Recherches -->

			<div class="table-filters-search">

				<label class="table-filters-search-item">
					<p-calendar [(ngModel)]="dateDebut" dateFormat="dd/mm/yy"
						[showTransitionOptions]="'5ms'" [hideTransitionOptions]="'5ms'" [showIcon]="true"
						[showButtonBar]="true" (ngModelChange)="handleDate()" styleClass="table-filters-search-input"
						placeholder="Date min" >
					</p-calendar>
				</label>

				<label class="table-filters-search-item">
					<p-calendar [(ngModel)]="dateFin" dateFormat="dd/mm/yy"  [showTransitionOptions]="'5ms'"
						[hideTransitionOptions]="'5ms'" [showIcon]="true" [showButtonBar]="true"
						(ngModelChange)="handleDate()" styleClass="table-filters-search-input" placeholder="Date max"
						>
					</p-calendar>
				</label>

        <label class="table-filters-search-item">
          <input type="text" placeholder="Numéro de transaction"
              class="table-filters-search-input" [(ngModel)]="filterSearch" (blur)="onSearch()" (keyup.enter)="onSearch()">
          <i class="table-filters-search-icon icon-search" aria-hidden="true"></i>
        </label>

			</div>
		</div>

		<app-message component="transaction"></app-message>

		<p-table id="transactionTable" tableStyleClass="table" [resetPageOnSort]="false" [sortOrder]="2" [value]="mybstv" [autoLayout]="true" [scrollable]="false"
			(onLazyLoad)="loadLazy($event)" [paginator]="false" [lazy]="true" [totalRecords]="totalbstv" dataKey="id">
			<ng-template pTemplate="header">
				<tr style="text-align: center;">
					<th [pSortableColumn]="'id'" class="table-sortable" scope="col">Transaction &nbsp;</th>
					<th [pSortableColumn]="'creationDate'" class="table-sortable" scope="col">Date de création &nbsp;</th>
					<th [pSortableColumn]="'quantity'" class="table-sortable" scope="col">Quantité &nbsp;</th>
					<th [pSortableColumn]="'demand'" class="table-sortable" scope="col">DTV &nbsp;</th>
					<th scope="col">Site DTV &nbsp;</th>
					<th [pSortableColumn]="'offer'" class="table-sortable" scope="col">OTV &nbsp;</th>
					<th scope="col">Site OTV &nbsp;</th>
					<th [pSortableColumn]="'lexTransactionStatus'" class="table-sortable" scope="col">Statut &nbsp;</th>
					<th scope="col">Actions &nbsp;</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-transaction let-rowIndex="rowIndex">
				<tr style="text-align: center;">
					<td>
						<a [routerLink]="'/transactions/details/'+transaction.id" routerLinkActive="active" (click)="onTransactionActionClick(rowIndex)"
							class="table-link">{{transaction.id}}</a>&nbsp;
						<span class="beacon beacon--lightblue note" *ngIf="statusChangedNotSeenByCurrentUser(rowIndex)">
							<span class="notetext">Une action a été effectuée par un autre utilisateur sur cette
								transaction</span>
						</span>
					</td>
					<td>{{transaction.creationDate | date: 'dd/MM/yyyy'}}</td>
					<td>{{transaction.quantity}}t</td>
					<td>
						<a [routerLink]="'/demandes/details/'+transaction.demand.id" routerLinkActive="active"
							class="table-link">{{transaction.demand.id}}</a>
					</td>
					<td>
						<h3 class="h3">{{transaction.demand.site.name}}</h3>
						<p>{{transaction.demand.site.address.numberStreet}},
							{{transaction.demand.site.address.postalBox}}
							{{transaction.demand.site.address.foreignCity}}</p>
					</td>
					<td>
						<a [routerLink]="'/offres/details/'+transaction.offer.id" routerLinkActive="active"
							class="table-link">{{transaction.offer.id}}</a>
					</td>
					<td>
						<h3 class="h3">{{transaction.offer.site.name}}</h3>
						<p>{{transaction.offer.site.address.numberStreet}}, {{transaction.offer.site.address.postalBox}}
							{{transaction.offer.site.address.foreignCity}}</p>
					</td>
					<td [ngSwitch]="transaction.lexTransactionStatus.id">
						<strong *ngSwitchCase="1" class="orange">{{transaction.lexTransactionStatus.label}}</strong>
						<strong *ngSwitchCase="2" class="green">{{transaction.lexTransactionStatus.label}}</strong>
						<strong *ngSwitchCase="3" class="black">{{transaction.lexTransactionStatus.label}}</strong>
						<strong *ngSwitchCase="4" class="red">{{transaction.lexTransactionStatus.label}}</strong>
						<strong *ngSwitchCase="5" class="black">{{transaction.lexTransactionStatus.label}}</strong>
					</td>
					<td>
						<div class="table-actions">
							<input type="checkbox" id="table-actions-1" class="table-actions-input">
							<label for="table-actions-1" class="table-actions-toggle"></label>
							<ul class="table-actions-list">
								<li class="table-actions-item">
									<a [routerLink]="'/transactions/details/'+transaction.id" routerLinkActive="active" (click)="onTransactionActionClick(rowIndex)"
										class="table-actions-link blue">
										<i class="table-actions-icon icon-eye" aria-hidden="true"></i>
										<span class="table-actions-label">Consulter</span>
									</a>
								</li>
								<li class="table-actions-item" *ngIf="(transaction.lexTransactionStatus.id === 1) && transaction.currentUserCanUpdate">
									<a [routerLink]="'/transactions/edit/'+transaction.id" (click)="onTransactionActionClick(rowIndex)"
										class="table-actions-link orange">
										<i class="table-actions-icon icon-edit" aria-hidden="true"></i>
										<span class="table-actions-label">Modifier</span>
									</a>
								</li>
								<li class="table-actions-item" *ngIf="(transaction.lexTransactionStatus.id === 2) && transaction.currentUserCanCreateBordereau">
									<a class="table-actions-link green" (click)="createBordereau(transaction, rowIndex)">
										<i class="table-actions-icon" aria-hidden="true"></i>
										<span class="table-actions-label">Créer bordereau</span>
									</a>
								</li>
							</ul>
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="9">
						Aucune transaction
					</td>
				</tr>
			</ng-template>
		</p-table>

		<app-pagination [length]="totalbstv" [index]="page" [size]="itemsPerPage" [pageSizeOptions]="pageSizeOptions"
			(page)="pageChange($event)"></app-pagination>

	</div>
</div>
