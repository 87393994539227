<div class="container" *ngIf="bordereau">
    <form name="createBordereauForm" #form="ngForm" (ngSubmit)="createBordereau(form)">
        <div class="page-sticky">



            <div class="page-header">
                <a routerLink="/transactions/" routerLinkActive="active" class="button button--outline button--teal">
                    <i class="button-icon icon-left" aria-hidden="true"></i>
                    <span class="button-label">Retour</span>
                </a>

                <ul class="page-actions">
                    <li class="page-actions-item">
                        <button class="button button--red" (click)="cancel()">
                            <i class="button-icon icon-cancel" aria-hidden="true"></i>
                            <span class="button-label">Annuler</span>
                        </button>
                    </li>

                    <li class="page-actions-item" *ngIf="!isModification">
                        <button type="submit" class="button button--green">
                            <i class="button-icon icon-check" aria-hidden="true"></i>
                            <span class="button-label">Créer</span>
                        </button>
                    </li>

                    <li class="page-actions-item" *ngIf="isModification">
                        <button type="submit" class="button button--green">
                            <i class="button-icon icon-check" aria-hidden="true"></i>
                            <span class="button-label">Soumettre</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="details">

            <div class="details-header">
                <h2 class="details-title h1">Création d'un bordereau</h2>
            </div>

            <div class="details-body">

                <!-- 01. Localisation du site producteur -->
                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-pin" aria-hidden="true"></i>
                        <h3 class="details-section-title" data-counter="01">Installation producteur</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-fieldset">
                            <div class="form-group">

                                <label class="form-item form-item--6">
                                    <span class="form-label">Raison sociale<span class="red">*</span></span>
                                    <input type="text" class="form-input" [(ngModel)]="bordereau.offreurNomSite"
                                        #offreurNomSite="ngModel" name="offreurNomSite"
                                        [ngClass]="{'red-border-class': offreurNomSite.errors && form.submitted}"
                                        required />

                                    <span *ngIf="offreurNomSite.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">SIRET<span class="red">*</span></span>
                                    <input type="text" class="form-input" [(ngModel)]="bordereau.offreurSiret"
                                        #offreurSiret="ngModel" name="offreurSiret"
                                        [ngClass]="{'red-border-class': offreurSiret.errors && form.submitted}"
                                        required />

                                    <span *ngIf="offreurSiret.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">Rue<span class="red">*</span></span>
                                    <input type="text" name="offreurAdresse" #offreurAdresse="ngModel" required
                                        [(ngModel)]="bordereau.offreurAdresse" placeholder="Nom et numéro de la rue"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': offreurAdresse.errors && form.submitted}"
                                    >

                                    <span *ngIf="offreurAdresse.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">Ville<span class="red">*</span></span>
                                    <input type="text" name="offreurVille" #offreurVille="ngModel"
                                        placeholder="Exemple: Paris" required [(ngModel)]="bordereau.offreurVille"
                                        placeholder="Exemple: Paris" class="form-input"
                                        [ngClass]="{'red-border-class': offreurVille.errors && form.submitted}"
                                    >

                                    <span *ngIf="offreurVille.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">Code postal<span class="red">*</span></span>
                                    <input type="text" name="offreurCodePostal" #offreurCodePostal="ngModel"
                                        placeholder="Exemple: 95123" required [(ngModel)]="bordereau.offreurCodePostal"
                                        placeholder="Exemple: 75013" pattern="[0-9]+" class="form-input"
                                        [ngClass]="{'red-border-class': offreurCodePostal.errors && form.submitted}"
                                    >

                                    <span class="red" *ngIf="offreurCodePostal.status === 'INVALID' && form.submitted">Veuillez
                                        saisir un code postal valide
                                    </span>

                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 02. Localisation du site receveur -->
                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-pin" aria-hidden="true"></i>
                        <h3 class="details-section-title" data-counter="02">Installation demandeur</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-fieldset">
                            <div class="form-group">
                                <label class="form-item form-item--6">
                                    <span class="form-label">Raison sociale<span class="red">*</span></span>
                                    <input type="text" class="form-input" [(ngModel)]="bordereau.demandeurNomSite"
                                        #demandeurNomSite="ngModel" name="demandeurNomSite"
                                        [ngClass]="{'red-border-class': demandeurNomSite.errors && form.submitted}"
                                        required
                                    />

                                    <span *ngIf="demandeurNomSite.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">SIRET<span class="red">*</span></span>
                                    <input type="text" class="form-input" [(ngModel)]="bordereau.demandeurSiret"
                                        #demandeurSiret="ngModel" name="demandeurSiret"
                                        [ngClass]="{'red-border-class': demandeurSiret.errors && form.submitted}"
                                        required
                                    />

                                    <span *ngIf="demandeurSiret.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">Rue<span class="red">*</span></span>
                                    <input type="text" name="demandeurAdresse" #demandeurAdresse="ngModel" required
                                        [(ngModel)]="bordereau.demandeurAdresse" placeholder="Nom et numéro de la rue"
                                        class="form-input"
                                        [ngClass]="{'red-border-class': demandeurAdresse.errors && form.submitted}"
                                      >

                                      <span *ngIf="demandeurAdresse.errors && form.submitted" class="red">
                                        Ce champ est obligatoire
                                      </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">Ville<span class="red">*</span></span>
                                    <input type="text" name="demandeurVille" #demandeurVille="ngModel"
                                        placeholder="Exemple: Paris" required [(ngModel)]="bordereau.demandeurVille"
                                        placeholder="Exemple: Paris" class="form-input"
                                        [ngClass]="{'red-border-class': demandeurVille.errors && form.submitted}"
                                    >

                                    <span class="red" *ngIf="demandeurVille.status === 'INVALID' && form.submitted">
                                      Veuillez saisir un code postal valide
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">Code postal<span class="red">*</span></span>
                                    <input type="text" name="demandeurCodePostal" #demandeurCodePostal="ngModel"
                                        placeholder="Exemple: 95123" required
                                        [(ngModel)]="bordereau.demandeurCodePostal" placeholder="Exemple: 75013"
                                        pattern="[0-9]+" class="form-input"
                                        [ngClass]="{'red-border-class': demandeurCodePostal.errors && form.submitted}"
                                    >

                                    <span class="red" *ngIf="demandeurCodePostal.status === 'INVALID' && form.submitted">
                                      Veuillez saisir un code postal valide
                                    </span>

                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 03. Caractérisation -->

                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-design-tools" aria-hidden="true"></i>
                        <h3 class="details-section-title" data-counter="03">Caractérisation</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-fieldset">
                            <div class="form-group">

                              <label class="form-item form-item--6">
                                <span class="form-label">Quantité en tonne</span>
                                <input type="text" class="form-input" name="quantiteT" value="{{ bordereau.transaction.quantity }}" disabled />
                               </label>

                              <label class="form-item form-item--6">
                                <span class="form-label">Volume en m3</span>
                                <input type="text" class="form-input" name="quantiteM" value="{{ volume }}" disabled />
                              </label>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- 04. Livraison -->

                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-paper-plane" aria-hidden="true"></i>
                        <h3 class="details-section-title" data-counter="04">Livraison</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-fieldset">
                            <div class="form-group">

                                <div class="form-item form-item--6">
                                    <label>Date de livraison <span class="red">*</span></label>
                                    <span class="form-date">
                                        <p-calendar [(ngModel)]="bordereau.dateLivraison" #rDateLivraison="ngModel" name="rDateLivraison"
                                          selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input"
                                          [ngClass]="{'red-border-class': rDateLivraison.errors && form.submitted}"
                                          required>
                                        </p-calendar>
                                    </span>

                                    <span *ngIf="rDateLivraison.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- 05. Signature -->

                <div class="details-section details-section--opened">
                    <div class="details-section-header">
                        <i class="details-section-icon icon-design-tools" aria-hidden="true"></i>
                        <h3 class="details-section-title" data-counter="05">Signature</h3>
                    </div>
                    <div class="details-section-body">
                        <div class="form-fieldset">
                            <div class="form-group">
                                <label class="form-item form-item--6">
                                    <span class="form-label">Nom du représentant <span class="red">*</span></span>
                                    <input type="text" class="form-input" [(ngModel)]="bordereau.nomRepresentant"
                                      #nomRepresentant="ngModel" name="nomRepresentant" placeholder="Saisir le nom du représentant"
                                      [ngClass]="{'red-border-class': nomRepresentant.errors && form.submitted}"
                                      required
                                    />

                                    <span *ngIf="nomRepresentant.errors && form.submitted" class="red">
                                      Ce champ est obligatoire
                                    </span>

                                </label>
                                <label class="form-item form-item--6">
                                    <span class="form-label">E-mail du représentant<span class="red">*</span></span>
                                    <input type="email" #emailRepresentant="ngModel" name="emailRepresentant"
                                      [(ngModel)]="bordereau.emailRepresentant" placeholder="Saisir l'adresse e-mail du représentant" class="form-input"
                                      [ngClass]="{'red-border-class': emailRepresentant.errors && form.submitted}" required
                                      pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    />

                                    <span *ngIf="emailRepresentant.errors && form.submitted" class="red">
                                      Ce champ est obligatoire et doit contenir une adresse mail valide
                                    </span>

                                </label>
                            </div>
                            <br>
                            <div class="form-group">
                                <div class="form-item form-item--12">
                                    <label class="form-checkbox" class="form-checkbox"
                                        [ngClass]="{'red-border-class': !verificationCheckbox && form.submitted}">
                                        <input required name="verificationCheckbox" [(ngModel)]="verificationCheckbox"
                                            type="checkbox" class="form-checkbox-input"
                                            (click)=checkVerificationCheckBox($event)>
                                        <span class="form-checkbox-label">
                                          Je, soussigné, certifie que les renseignements ci-dessus sont exacts et établis de bonne foi
                                        </span>
                                    </label>

                                    <div *ngIf="!verificationCheckbox && form.submitted" class="red">
                                      La validation est obligatoire
                                    </div>

                                </div>
                            </div>
                            <div class="form-group" *ngIf="bordereau.transaction.lexGuideDechet">
                                <div class="form-item form-item--12">
                                    <label class="form-checkbox" class="form-checkbox"
                                        [ngClass]="{'red-border-class': !verificationBCheckbox && form.submitted}">
                                        <input required name="verificationBCheckbox" [(ngModel)]="verificationBCheckbox"
                                            type="checkbox" class="form-checkbox-input"
                                            (click)=checkVerificationBCheckBox($event)>
                                        <span class="form-checkbox-label">
                                            Je, soussigné, certifie que les présents lots de terres
                                            excavées ont été préparés conformément aux exigences définies à l’arrêté ministériel du
                                            04/06/2021 relatif à la sortie de statut de déchet des terres excavées et sédiments ayant
                                            fait l’objet d’une préparation en vue d’une utilisation en génie civil ou en aménagement
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </form>
</div>
