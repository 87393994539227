import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-get-confidential-code',
  templateUrl: './modal-get-confidential-code.component.html',
  styleUrls: ['./modal-get-confidential-code.component.scss']
})
export class ModalGetConfidentialCodeComponent {

  @Input() confidentialCode: string;

  constructor(
    private activeModal: NgbActiveModal,
    private clipboard: Clipboard
  ) { }

  onClose(): void {
    this.activeModal.close();
  }

  onCopy(): void {
    this.clipboard.copy(this.confidentialCode);
  }

}
