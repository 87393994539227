import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from "../fr/brgm/common/guards/is-signed-in.guard";
import { EntityComponent } from "./entity/entity.component";
import { ProfilePageComponent } from "./profile-page/profile-page.component";
import { SettingsPageComponent } from "./settings-page/settings-page.component";
import { UserPasswordChangedComponent } from "./user-password-changed/user-password-changed.component";

const usersRoutes: Routes = [
    {path: 'user/profile', component: ProfilePageComponent, data: {title: APP_PREFIX  + 'Profil'}, canActivate: [ IsSignedInGuard] },
    {path: 'user/entity', component: EntityComponent, data: {title: APP_PREFIX  + 'Entité'}, canActivate: [ IsSignedInGuard] },
    {path: 'user/settings', component: SettingsPageComponent, data: {title: APP_PREFIX  + 'Profil'}, canActivate: [ IsSignedInGuard] },
    {path: 'users/resetpassword', component: UserPasswordChangedComponent, data: {title: APP_PREFIX  + 'Modification de mot de passe'} },
];

@NgModule({
    imports: [
        RouterModule.forChild(usersRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class UsersRoutingModule { }
