import { Transaction } from './transaction.model';
import { Address } from './address.model';
import { EntityObject } from './entityobject.model';
import { LexTransportMode } from './lex/lextransportmode.model';
import { LexTransporterStatus } from './lex/lextransporterstatus.model';
import { Deserializable } from './Deserializable';

export class Transporter implements Deserializable {
    constructor(
        public id?: number,
        public contactPerson?: string,
        public email?: string,
        public faxNumber?: string,
        public name?: string,
        public phoneNumber?: string,
        public siret?: string,
        public transactions?: Transaction[],
        public address?: Address,
        public entityObject?: EntityObject,
        public lexTransportMode?: LexTransportMode,
        public lexTransporterStatus?: LexTransporterStatus
    ) {}
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    // deserialize(input: any) {
    //     Object.assign(this, input);
    //     this.address = new Address().deserialize(input.address);
    //     this.entityObject = new EntityObject().deserialize(input.entityObject);
    //     this.lexTransportMode = new LexTransportMode().deserialize(input.lexTransportMode);
    //     this.lexTransporterStatus = new LexTransporterStatus().deserialize(input.lexTransporterStatus);
    //     for (let i = 0; i < input.transactions.length; i++) {
    //         this.transactions.push(new Transaction().deserialize(input.transactions[i]));
    //     }
    //     return this;
    // }
}