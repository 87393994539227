import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RndtsService } from "src/app/fr/brgm/common/http/rndts/rndts.service";
import { UserService } from "src/app/fr/brgm/common/http/user/user.service";
import { EntityObject } from "src/app/fr/brgm/common/model/entityobject.model";
import { LexUserStatus } from "src/app/fr/brgm/common/model/lex/lexuserstatus.model";
import { User } from "src/app/fr/brgm/common/model/user.model";
import { ModalChangePasswordComponent } from "src/app/modal/modal-change-password/modal-change-password.component";
import { ModalChangeRNDTSKeyComponent } from "src/app/modal/modal-change-rndts-key/modal-change-rndts-key.component";

@Component({
  selector: "app-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent implements OnInit {
  currentUser: User;
  city: string;
  userStatus: number;
  lexUserStatus: LexUserStatus[];

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private rndtsService: RndtsService
  ) {
    this.currentUser = new User();
    this.city = "";
    this.currentUser.entityObject = new EntityObject();
    this.lexUserStatus = [];
  }

  ngOnInit() {
    this.initUser();
  }

  initUser() {
    this.userService.getCurrentUser().subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  openPasswordModal() {
    const modalRef = this.modalService.open(ModalChangePasswordComponent);
    modalRef.result.then((result) => {}).catch((error) => {});
  }

  openRNDTSKeydModal() {
    const modalRef = this.modalService.open(ModalChangeRNDTSKeyComponent);
    modalRef.componentInstance.user = this.currentUser;
    modalRef.result.then((result) => {}).catch((error) => {});
  }

  updatePhone(){
    this.userService.updatePhone(this.currentUser.id, this.currentUser.phone).subscribe(
      _user => {},
      err => {}
    );

  }
}
