<div class="modal"><!-- Ajouter modal--opened pour ouvrir -->
    <form action="" class="modal-inner">
        <button type="button" class="modal-close icon-close" (click)="returnToBSTV()" *ngIf="response.httpCode == 201"></button>

        <div class="modal-body">
            <i class="modal-icon modal-icon--green icon-add"  *ngIf="response.httpCode == 201"></i>
            <i class="modal-icon modal-icon--red icon-info"  *ngIf="!(response.httpCode == 201)"></i>

            <h2 class="h1 modal-title">{{response.message}}</h2>

            <div class="form-item form-item--12" *ngIf="!(response.httpCode == 201)">
                <div style="text-align: center;">
                    <ul class="form-list" *ngFor="let item of response.content | keyvalue">
                        <li class="form-list-item">
                            <label class="form-item">
                                <span class="form-label">- {{item.key}} : {{item.value[0].message}}</span>
                            </label>
                        </li>
                    </ul>
                    <button type="reset" value="0" class="button button--red" (click)="cancel()">  
                            <i class="button-icon icon-close"></i>
                            <span class="button-label">Revenir au formulaire</span>
                    </button>
                </div>
            </div>

            <div class="form-item form-item--12" *ngIf="response.httpCode == 201">
                <div style="text-align: center;">
                    <span class="form-label">Identifiant RNDTS : {{response.id}}</span>
                    <button type="button" value="1" class="button button--green" (click)="returnToBSTV()">
                        <i class="button-icon icon-check"></i>
                        <span class="button-label">Revenir à l'écran BSTV</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>