import { DocumentTerrass } from "./document.model";
import { LexObject } from "./lex/lexObject.model";
import { Transaction } from "./transaction.model";
import { User } from "./user.model";

export class Bordereau {

  constructor(
    public id?: number,
    public dateCreation?: Date,
    public identifiantBstv?: string,
    public lot?: number,
    public transactionId?: number,
    public guideDechet?: string,
    public nomSiteProducteur?: string,
    public nomRepresentant?: string,
    public emailRepresentant?: string,
    public offreurRaisonSociale?: string,
    public offreurNomSite?: string,
    public offreurSiret?: string,
    public offreurAdresse?: string,
    public offreurCodePostal?: string,
    public offreurVille?: string,
    public demandeurRaisonSociale?: string,
    public demandeurNomSite?: string,
    public demandeurSiret?: string,
    public demandeurAdresse?: string,
    public demandeurCodePostal?: string,
    public demandeurVille?: string,
    public transaction?: Transaction,
    public lexBordereauStatus?: LexObject,
    public isCreateurTransaction?: boolean,
    public hasLot?: boolean,
    public dateLivraison?: Date,
    public documents?: DocumentTerrass[],
    public prenomNomCloture?: string,
    public currentUserCanClose?: boolean,
    public currentUserCanDelete?: boolean,
    public currentUserCanAddLot?: boolean,
    public currentUserIsLinkedToOffer?: boolean,
    public currentUserIsLinkedToDemand?: boolean,
    public currentUserCanUpdateAndDeleteLot?: boolean,
    public currentUserCanDeclareToRndtsEntrants?: boolean,
    public currentUserCanDeclareToRndtsSortants?: boolean,
    public dateDeclarationToRndtsEntrants?: Date,
    public dateDeclarationToRndtsSortants?: Date,
    public idRndtsEntrants?: string,
    public idRndtsSortants?: string,
    public userWhoDeclaredRndts?: User,
  ) {}

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
