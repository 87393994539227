import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TransporterService } from 'src/app/00-shared/services/transporter.service';
import { calendar_fr } from 'src/app/app.constants';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { Criteria } from 'src/app/fr/brgm/common/http/model/criteria.model';
import { TransactionService } from 'src/app/fr/brgm/common/http/transaction/transaction.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { LexGuideDechet } from 'src/app/fr/brgm/common/model/lex/lexGuideDechet.model';
import { LexTransactionStatus } from 'src/app/fr/brgm/common/model/lex/lextransactionstatus.model';
import { Transaction } from 'src/app/fr/brgm/common/model/transaction.model';
import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';

@Component({
  selector: 'app-edit-transaction-page',
  templateUrl: './edit-transaction-page.component.html',
  styleUrls: ['./edit-transaction-page.component.scss']
})
export class EditTransactionPageComponent implements OnInit {
  currentUser: User;
  isUserOfTransaction: boolean;
  fr: any = calendar_fr;
  transportersList: Transporter[];

  transaction: Transaction;
  lexTransactionStatus: LexTransactionStatus[];
  userCreator: User;

  bstrStartDate: Date;
  bstrEndDate: Date;

  receptionStartDate: Date;
  receptionEndDate: Date;
  selectedTransporters: Transporter[];
  choixTransporter: boolean;
  choixStatutDechet: boolean;
  lexGuideDechets: LexGuideDechet[];
  selectedGuideId: number;

  transactionDateError: string;
  receptionDateError: string;

  constructor(
    private transactionService: TransactionService,
    private activatedroute: ActivatedRoute,
    private titleService: Title,
    private lexService: LexiqueService,
    private userService: UserService,
    private transporterService: TransporterService,
    private router: Router
  ) {
    this.resetDateError();
   }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    });
    this.lexService.getLexTransactionStatus().subscribe((res) => this.lexTransactionStatus = res);
    this.lexService.getLexGuideDechet().subscribe((res) => this.lexGuideDechets = res);
    this.getTransporters();

    this.choixTransporter = false;

    this.activatedroute.params.subscribe(params => {
      this.transactionService.findById(params.id).subscribe(res => {
        this.transaction = res;

        this.selectedTransporters = res.transporters;

        if (res.bstrStartDate) { this.bstrStartDate = new Date(res.bstrStartDate); }
        if (res.bstrEndDate) { this.bstrEndDate = new Date(res.bstrEndDate); }
        if (res.receptionStart) { this.receptionStartDate = new Date(res.receptionStart); }
        if (res.receptionEnd) { this.receptionEndDate = new Date(res.receptionEnd); }

        if (res.lexGuideDechet){
          this.choixStatutDechet = true;
          this.selectedGuideId = res.lexGuideDechet.id;
        }else{
          this.choixStatutDechet = false;
        }

        this.userService.getCurrentUser().subscribe((res) => {
          this.currentUser = res;
          this.setIsUserOfTransaction();
        });
      });
    });
  }

  setIsUserOfTransaction() {
    this.isUserOfTransaction = this.currentUser.id == this.transaction.offer.user.id
      || this.currentUser.id == this.transaction.demand.user.id ? true : false;
  }

  getTransporters() {
    let criteria: Criteria = new Criteria();
    this.transporterService.getTransporters(criteria, {
      sort: "name,asc"
    }).subscribe(
      (res) => {
        this.transportersList = res.transporter
      }
    );
  }

  getSelectedTransporter(event, transp: Transporter) {
    if (event.target.checked) {
      this.selectedTransporters.push(transp);
    } else {
      this.selectedTransporters = this.selectedTransporters.filter(t => t.id != transp.id);
    }
  }

  isChecked(id: number){
    var test = this.selectedTransporters.find(t => t.id === id);
    if(test){
      return true;
    }else{
      return false;
    }
  }

  updateT(form: NgForm) {
    if (this.isValidDate() && form.form.status == "VALID") {
      var tempTransaction: Transaction = this.transaction;
      tempTransaction.bstrStartDate = this.bstrStartDate;
      tempTransaction.bstrEndDate = this.bstrEndDate;
      tempTransaction.receptionStart = this.receptionStartDate;
      tempTransaction.receptionEnd = this.receptionEndDate;
      tempTransaction.transporters = this.selectedTransporters;

      this.transactionService.update(tempTransaction).subscribe((res) => {
        this.router.navigate(['/transactions/details/' + res.id]);
      })
    }
  }

  checkChoixTransport(event) {
    this.choixTransporter = event.target.checked;
    this.selectedTransporters = [];
  }

  checkChoixStatutDechet(event){
    this.choixStatutDechet = event.target.checked;
    if(!event.target.checked){
      this.transaction.lexGuideDechet = null;
      this.selectedGuideId = null;
    }
  }

  setLexGuideDechet(guide: LexGuideDechet){
    this.transaction.lexGuideDechet = guide;
  }

  resetDateError(): void {
    this.resetTransactionDateError();
    this.resetReceptionDateError();
  }

  resetTransactionDateError(): void {
    this.transactionDateError = null;
  }

  resetReceptionDateError(): void {
    this.receptionDateError = null;
  }

  isValidDate(): boolean {
    this.resetDateError();
    return (this.isValidTransactionDate() && this.isValidReceptionDate());
  }

  isValidTransactionDate(): boolean {
    let transactionDateIsValid = false;

    let now = new Date();
    now.setHours(0, 0, 0, 0);

    if (this.bstrEndDate <= this.bstrStartDate) {
      this.transactionDateError = "La date de fin de transaction doit être postérieure, d’au moins un jour, à la date de début de transaction";
    } else if (this.bstrEndDate < now) {
      this.transactionDateError = "La date de fin de transaction ne peut pas être antérieure à la date du jour";
    } else {
      transactionDateIsValid = true;
    }
    return transactionDateIsValid;
  }

  isValidReceptionDate(): boolean {
    if (this.receptionEndDate <= this.receptionStartDate) {
      this.receptionDateError = "La date de fin de réception doit être postérieure, d’au moins un jour, à la date de début de réception";
      return false;
    }
    return this.isValidReceptionTransactionStartDate();
  }

  isValidReceptionTransactionStartDate(): boolean {
    if (this.receptionStartDate < this.bstrStartDate) {
      this.receptionDateError = "La date de début de réception doit être postérieure ou égale à la date de début de transaction";
      return false;
    }
    return true;
  }

  onTransactionDateSelect() : void {
    this.resetTransactionDateError();
    this.isValidTransactionDate();
  }

  onReceptionDateSelect(): void {
    this.resetReceptionDateError();
    this.isValidReceptionDate();
  }

}
