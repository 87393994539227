import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from 'src/app/00-shared/message/message.component';

@NgModule({
  declarations: [
    MessageComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MessageComponent
  ]
})
export class MessageModule { }
