import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup} from '@angular/forms';
import { RndtsType } from 'src/app/07-bordereaux/rndts-page/rndts-page.component';

@Component({
  selector: 'app-modal-choose-rndts-type',
  templateUrl: './modal-choose-rndts-type.component.html',
  styleUrls: ['./modal-choose-rndts-type.component.scss']
})
export class ModalChooseRNDTSTypeComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  RndtsType = RndtsType;
  typeSelected: RndtsType;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  close() {
    this.activeModal.close(this.typeSelected);
  }
}
