import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalysisDeclaration } from '../../model/analysisdeclaration.model';
import { AnalysisElemResult } from '../../model/analysiselemresult.model';
import { ListOTV } from '../../model/listotv.model';
import { OTV } from '../../model/otv.model';
import { Criteria } from '../model/criteria.model';

@Injectable({
  providedIn: 'root'
})
export class OtvService {

  public otvData = [];
  private resourceUrl = environment.apiUrl + 'offer';
  private analysisResourceUrl = environment.apiUrl + 'analysisDeclaration';
  private analysisElemResultUrl = environment.apiUrl + 'analysisElemResult';
  private SEPARATOR =   '/';
  constructor(private http: HttpClient) { }

  getOtvs(criteria:Criteria, pageable?: any): Observable<ListOTV> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + "findall", criteria, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listOtv = new ListOTV();
      listOtv.otv = this.convertResponse(res.body);
      listOtv.maxOTV = Number(res.headers.get('X-Total-Count'));
      return listOtv;
    }));
  }

  get(id: number): Observable<OTV> {
    return this.http.get( this.resourceUrl + this.SEPARATOR + id).pipe(map((res:OTV)=> res));
  }

  create(otv:OTV):Observable<OTV> {
    return this.http.post(this.resourceUrl + this.SEPARATOR+'create',otv).pipe(map((res:OTV)=> res));
  }

  createAnalysis(idOtv: number, analysisDeclaration: AnalysisDeclaration): Observable<AnalysisDeclaration> {
    return this.http.post(this.analysisResourceUrl + this.SEPARATOR+'create/offer/'+ idOtv, analysisDeclaration).pipe(map((res:AnalysisDeclaration)=> res));
  }

  updateAnalysisDeclarationOnOffer(idOtv: number, analysisDeclaration: AnalysisDeclaration): Observable<AnalysisDeclaration> {
    return this.http.put(this.analysisResourceUrl + this.SEPARATOR+'update/offer/'+ idOtv, analysisDeclaration).pipe(map((res:AnalysisDeclaration)=> res));
  }

  addAnalysisResults(analysisElemResult: AnalysisElemResult[]): Observable<AnalysisElemResult[]> {
    return this.http.post(this.analysisElemResultUrl+this.SEPARATOR+'create', analysisElemResult).pipe(map((res: AnalysisElemResult[])=> res));
  }

  updateAnalysisResults(idOtv: number, analysisElemResult: AnalysisElemResult[]): Observable<AnalysisElemResult[]> {
    return this.http.put(this.analysisElemResultUrl+this.SEPARATOR+'update/offer/'+idOtv, analysisElemResult).pipe(map((res: AnalysisElemResult[])=> res));
  }


  private convertResponse(res): OTV[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new OTV().deserialize(res[i]));
    }
    return result;
  }

  duplicate(id): Observable<OTV> {
    return this.http.get(this.resourceUrl+this.SEPARATOR+'copy'+this.SEPARATOR+id).pipe(map((res: OTV)=> res));
  }

  updateOTV(otv: OTV): Observable<OTV> {
    return this.http.put(this.resourceUrl+this.SEPARATOR+'update', otv).pipe(map((res: OTV)=> res));
  }

  closeOTV(otv: OTV): Observable<OTV> {
    return this.http.put(this.resourceUrl+this.SEPARATOR+'update', otv).pipe(map((res: OTV)=> res));
  }

  publishOTV(otv: OTV): Observable<OTV> {
    return this.http.put(this.resourceUrl+this.SEPARATOR+'update', otv).pipe(map((res: OTV)=> res));
  }

  getBySite(siteId: number, pageable?: any): Observable<ListOTV> {
    return this.http.get( this.resourceUrl + this.SEPARATOR + "findBySite?siteId=" + siteId, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listOtv = new ListOTV();
      listOtv.otv = this.convertResponse(res.body);
      listOtv.maxOTV = Number(res.headers.get('X-Total-Count'));
      return listOtv;
    }));
  }
}
