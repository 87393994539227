import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/00-shared/message/message.service';
import { TransporterService } from 'src/app/00-shared/services/transporter.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { SireneService } from 'src/app/fr/brgm/common/http/sirene/sirene.service';
import { Address } from 'src/app/fr/brgm/common/model/address.model';
import { LexCountry } from 'src/app/fr/brgm/common/model/lex/lexcountry.model';
import { LexTransporterStatus } from 'src/app/fr/brgm/common/model/lex/lextransporterstatus.model';
import { LexTransportMode } from 'src/app/fr/brgm/common/model/lex/lextransportmode.model';
import { SiretAddress } from 'src/app/fr/brgm/common/model/siret-address';
import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';
import { UrlService } from 'src/app/fr/brgm/common/http/url/url.service';

@Component({
  selector: 'app-create-carrier-page',
  templateUrl: './create-carrier-page.component.html',
  styleUrls: ['./create-carrier-page.component.scss']
})
export class CreateCarrierPageComponent implements OnInit {

  @ViewChild('form', { static: true })
  createTransporterForm: NgForm;

  currentStep: number;
  progress: number;
  transporter: Transporter = new Transporter();
  transporterStatus: LexTransporterStatus[];
  transporterCountry: LexCountry[];
  transportMode: LexTransportMode[];
  selectedCountryId: number;
  selectedTransportModeId: number;
  addressSiret: Address;
  previousUrl: string = '';
  siretAddress: SiretAddress;

  invalidSiret: boolean;

  constructor(
    private transporterService: TransporterService,
    private lexService: LexiqueService,
    private sireneService: SireneService,
    private router: Router,
    private urlService: UrlService,
    private messageService: MessageService
    ) {
      this.invalidSiret = false;
      this.siretAddress = null;
    }

  ngOnInit() {
    this.previousUrl = this.urlService.getPreviousUrl();

    this.transporter.address = new Address();
    this.transporter.address.lexCountry = new LexCountry();
    this.progress = 0
    this.currentStep = 1;
    this.lexService.getLexTransporterStatus().subscribe((res) => this.transporterStatus = res);
    this.lexService.getLexTransportMode().subscribe((res) => this.transportMode = res);
    this.lexService.getLexCountry().subscribe((res) => this.transporterCountry = res);
    this.selectedCountryId = 1;
    this.selectedTransportModeId = 1;
  }

  onSiretChange(event){
    this.transporter.siret = event.target.value.replace(/\s/g, "");
    if(this.transporter.siret != null && this.transporter.siret.length === 14){
      this.invalidSiret = true;
      this.setAddress();
    }else{
      this.siretAddress = null;
    }
  }

  setAddress(): void {
    this.sireneService.getSiretAddress(this.transporter.siret).subscribe(
      (siretAddress) => {
        if (siretAddress == null) {
          this.siretAddress = null;
          this.invalidSiret = true;
        } else {
          this.siretAddress = siretAddress;
          this.invalidSiret = false;
        }
      },
      (err) => this.invalidSiret = true
    );
  }

  suivant() {
    if (this.currentStep < 2 && !this.isInvalidSiret() && this.siretAddress != null) {
      this.currentStep++;
      this.progress = 50;
    } else if (this.createTransporterForm.form.status == "VALID" && !this.invalidSiret) {
      this.transporter.address = this.siretAddress.address;
      this.transporter.address.lexCountry = this.transporterCountry[this.selectedCountryId - 1];
      this.transporter.lexTransportMode = this.transportMode[this.selectedTransportModeId - 1];
      this.transporterService.create(this.transporter).subscribe(
        (res) => this.router.navigate([this.previousUrl]),
        error => this.messageService.error('createTransporter', error)
      );
    }
  }

  getModeTransportId(event, id){
    this.selectedTransportModeId = id;
  }

  getSiretFormControl(): AbstractControl {
    return this.createTransporterForm.controls['transporterSiret'];
  }

  isInvalidSiret(): boolean {
    let control = this.getSiretFormControl();
    if (control != null) {
      return (control.invalid || this.invalidSiret)
          && (this.createTransporterForm.submitted || control.dirty || control.touched);
    }
    return false;
  }

}
