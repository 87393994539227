import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TransporterService } from 'src/app/00-shared/services/transporter.service';
import { calendar_fr } from 'src/app/app.constants';
import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';
import { Lot } from '../model/lot.model';
import { LotService } from '../services/lot.service';
import { siteExitDateValidator } from './site-exit-date-validator';

@Component({
  selector: 'app-lot-form',
  templateUrl: './lot-form.component.html',
  styleUrls: ['./lot-form.component.scss']
})
export class LotFormComponent implements OnInit {

  fr: any = calendar_fr;

  lotFormGroup: FormGroup;
  idBord: number;
  isCreateForm: boolean;
  transporters: Transporter[];
  siteExitDate: Date;
  isSubmited: boolean = false;

  @Input() set lot(value: any) {
    this._lot = value;
  }

  private _lot: Lot = new Lot();

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private lotService: LotService,
    private transporterService: TransporterService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.getTransporters();
    this.activatedroute.data.subscribe(data => this.titleService.setTitle(data.title));
    this.activatedroute.params.subscribe( params => this.idBord = params.idBordereau);

    this.isCreateForm = this.router.url.includes('create');

    this.lotFormGroup = this.fb.group ({
      lot: [this._lot?.lot || null, {validators: Validators.required}],
      quantite: [this._lot?.quantite || null, {validators: [Validators.required], updateOn: 'blur'}],
      volume: [this._lot?.volume || null, {validators: [Validators.required], updateOn: 'blur'}],
      transporteur: [this._lot?.transporteur?.id || null, {validators: [Validators.required]}],
      siteExitDate: ['', {validators: Validators.required}],
    });

    this.siteExitDate = new Date(this._lot.siteExitDate);

    if (this.isCreateForm) {
      this.lotFormGroup.get('siteExitDate').addValidators(siteExitDateValidator);
      this.lotFormGroup.get('siteExitDate').setValue(new Date());
    } else {
      this.lotFormGroup.get('siteExitDate').setValue(this.siteExitDate);
      if(this._lot.nomTransporteur === 'Transport interne au site') {
        this.lotFormGroup.get('transporteur').setValue(-1);
      }
    }

    this.lotFormGroup.get('quantite').valueChanges.subscribe((qte) => {
      this.setVolume(qte);
    });

    this.lotFormGroup.get('volume').valueChanges.subscribe((vlm) => {
      this.setQuantite(vlm);
    });
  }

  getTransporters() {
    this.transporterService.getBordereauTransporters(this._lot.bordereauId).subscribe((transporteurs) => {
        this.transporters = transporteurs;
        const trp = new Transporter();
        trp.id = -1;
        trp.name = 'Transport interne au site';
        trp.siret = '';
        this.transporters.push(trp);
    });
  }

  onSubmitLot(){
    this.isSubmited = true;
    if (this.lotFormGroup.valid) {
      this._lot.siteExitDate = this.lotFormGroup.get('siteExitDate').value;
      this._lot.quantite = this.lotFormGroup.get('quantite').value;
      this._lot.volume = this.lotFormGroup.get('volume').value;
      const idSelectedTransporteur = this.lotFormGroup.get('transporteur').value;
      const selectedTransporteur = this.transporters.find(trp => trp.id === idSelectedTransporteur);
      if(selectedTransporteur.id != -1) {
        this._lot.transporteur = selectedTransporteur;
      } else{
        this._lot.transporteur = null;
      }
      this._lot.nomTransporteur = selectedTransporteur.name;
      this._lot.siretTransporteur = selectedTransporteur.siret;

      if(this.isCreateForm){
        this.createLot();
      }else{
        this.updateLot();
      }
    }
  }

  createLot(): void {
    this.lotService.create(this._lot).subscribe((lot: Lot) => {
      this._lot = lot;
      this.goBack();
    });
  }

  updateLot(): void {
    this.lotService.updateLot(this._lot).subscribe((lot: Lot) => {
      this._lot = lot;
      this.goBack()
    });
  }

  setQuantite(val: number){
    this.lotFormGroup.get('quantite').setValue(Number((val * 1.3).toFixed(1)), {emitEvent: false});
  }

  setVolume(val: number){
    this.lotFormGroup.get('volume').setValue(Number((val / 1.3).toFixed(1)), {emitEvent: false});
  }

  goBack(): void {
		let link = ['/bordereaux/details', this.idBord];
		this.router.navigate(link);
	}
}
