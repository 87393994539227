import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EntityObjectService } from 'src/app/00-shared/services/entity-object.service';
import { calendar_fr } from 'src/app/app.constants';
import { UrlService } from 'src/app/fr/brgm/common/http/url/url.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { Delegation } from 'src/app/fr/brgm/common/model/delegation.model';
import { EntityObject } from 'src/app/fr/brgm/common/model/entityobject.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { DelegationService } from '../../services/delegation.service';
import { dateDebutGelValidator } from './date-debut-gel-validator';
import { dateFinGelValidator } from './date-fin-gel-validator';

@Component({
  selector: 'app-delegation-form',
  templateUrl: './delegation-form.component.html',
  styleUrls: ['./delegation-form.component.scss']
})
export class DelegationFormComponent implements OnInit {

  @Input() set delegation(value: any) {
    this._delegation = value;
  }
  _delegation: Delegation;

  delegationFormGroup: FormGroup;

  users: User[];
  selectedUsers: User[] = [];

  fr: any = calendar_fr;

  entities: EntityObject[];
  selectedEntity: EntityObject;
  currentUserSiren: string;

  sirenError: string;

  isCreation: boolean;
  allChecked: boolean = false;
  disableAllCheck: boolean = true;

  frostDatesEmptiesError = false;
  frostStartDateError = false;

  constructor(
    private entityObjectService: EntityObjectService,
    private delegationService: DelegationService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private urlService: UrlService
  ) { }

  ngOnInit(): void {
    this.isCreation = this._delegation.id === null;
    this.selectedEntity = this._delegation.entityObject;
    this.userService.getCurrentUser().subscribe(user => this.currentUserSiren = user.entityObject.siret);

    this.delegationFormGroup = this.fb.group({
      startDate: [null, {validators: Validators.required}],
      expirationDate: [null, {validators: Validators.required}],
      dateDebutGel: [null],
      dateFinGel: [null],
      entity: [this._delegation.entityObject || null, {validators: Validators.required}]
    },{
      validators: [dateFinGelValidator('expirationDate','dateFinGel'),
      dateDebutGelValidator('dateFinGel', 'dateDebutGel')]
    });


    if (this._delegation.id === null) {
      this.delegationFormGroup.get('startDate').setValue(new Date());
      const today = new Date();
      this.delegationFormGroup.get('expirationDate').setValue(new Date(today.setMonth(today.getMonth()+3)));
    } else {
      let startDate = new Date(this._delegation.startDate);
      let expirationDate = new Date(this._delegation.expirationDate);
      this.delegationFormGroup.get('startDate').setValue(startDate);
      this.delegationFormGroup.get('expirationDate').setValue(expirationDate);

      if (this._delegation?.dateDebutGel != null && this._delegation?.dateFinGel != null) {
        let dateDebutGel = new Date(this._delegation.dateDebutGel);
        let dateFinGel = new Date(this._delegation.dateFinGel);
        this.delegationFormGroup.get('dateDebutGel').setValue(dateDebutGel);
        this.delegationFormGroup.get('dateFinGel').setValue(dateFinGel);
      }

      this.getUsersForEntity(this._delegation.entityObject.id);
    }
  }

  getSelectedUsers(event, u: User) {
    if (event.target.checked) {
      this.selectedUsers.push(u);
    } else {
      this.selectedUsers = this.selectedUsers.filter(elmt => elmt.id != u.id);
    }
  }

  changeAll() {
    this.allChecked = !this.allChecked;
    this.selectedUsers = [];
    if(this.allChecked) {
      this.users.forEach(u => this.selectedUsers.push(u));
    }
  }

  onSubmitDelegation() {
    if (this.delegationFormGroup.valid && this.selectedUsers.length > 0 && this.isValidFrostDates()) {
      if (this._delegation.id === null) {
        let delegationsToCreate: Delegation[] = [];
        this.selectedUsers.forEach(handler => {
          let deleg = new Delegation();
          deleg.id = this._delegation.id;
          deleg.site = this._delegation.site;
          deleg.entityObject = this.selectedEntity;
          deleg.startDate = this.delegationFormGroup.get('startDate').value;
          deleg.expirationDate = this.delegationFormGroup.get('expirationDate').value;
          deleg.dateDebutGel = this.delegationFormGroup.get('dateDebutGel').value;
          deleg.dateFinGel = this.delegationFormGroup.get('dateFinGel').value;
          deleg.handlerId = handler.id;
          deleg.refDelegation = handler.name + ' ' +handler.firstname;
          delegationsToCreate.push(deleg);
        });
        this.delegationService.createDelegations(delegationsToCreate).subscribe(res => {
          this.goBack()
        });
      } else {
        let deleg = new Delegation();
        deleg = this._delegation;
        deleg.startDate = this.delegationFormGroup.get('startDate').value;
        deleg.expirationDate = this.delegationFormGroup.get('expirationDate').value;
        deleg.dateDebutGel = this.delegationFormGroup.get('dateDebutGel').value;
        deleg.dateFinGel = this.delegationFormGroup.get('dateFinGel').value;
        this.delegationService.update(deleg).subscribe(res => {
          this.goBack()
        });
      }
    }
  }

  isValidFrostDates(): boolean {
    let startFrostDate = this.delegationFormGroup.get('dateDebutGel').value;
    let endFrostDate = this.delegationFormGroup.get('dateFinGel').value;

    this.frostDatesEmptiesError = ((startFrostDate != null && endFrostDate == null) || (startFrostDate == null && endFrostDate != null));
    this.setFrostStartDateError();

    return !(this.frostDatesEmptiesError || this.frostStartDateError);
  }

  searchEntities(event) {
    let sirenToComplete = event.query.replace(/\s/g, '');
    this.entityObjectService.getBySiretStartsWith(sirenToComplete).subscribe((res => this.entities = res));
  }

  doOnSelect(event: EntityObject) {
    this.delegationFormGroup.get('entity').setValue(event);
    this.selectedEntity = event;
    if (event.siret !== this.currentUserSiren) {
      this.getUsersForEntity(event.id);
    } else {
      this.sirenError = "Vous ne pouvez pas vous déléguer vos propres sites";
    }
  }

  getUsersForEntity(id: number) {
    this.userService.getUsersByEntity(id).subscribe((res: User[]) => {
      if (this._delegation.handlerId) {
        this.selectedUsers.push(res.find(u => u.id === this._delegation.handlerId));
      }
      this.disableAllCheck = false;
      this.users = res;
    });
  }

  isChecked(id: number) {
    return this.selectedUsers.find(u => u.id === id);
  }

  sirenOnChange(): void {
    this.selectedEntity = new EntityObject();
    this.sirenError = null;
  }


  goBack(): void {
    let link: String[];
    let previousUrl = this.urlService.getPreviousUrl();
    if (previousUrl === "/sites") {
      link = ['/sites'];
    } else {
      link = ['/sites/details/', this._delegation?.site?.id.toString()];
    }
		this.router.navigate(link);
	}

  onReturnClick(): void {
    this.goBack();
  }

  onClickStartDate() {
    document.getElementById("idStartDate").style.zIndex = "10";
    document.getElementById("idDateDebutGel").style.zIndex = "1";
  }

  onClickExpirationDate() {
    document.getElementById("idExpirationDate").style.zIndex = "10";
    document.getElementById("idDateFinGel").style.zIndex = "1";
  }

  onClickDateDebutGel() {
    document.getElementById("idDateDebutGel").style.zIndex = "10";
    document.getElementById("idStartDate").style.zIndex = "1";

    this.frostDatesEmptiesError = false;
  }

  onClickDateFinGel() {
    document.getElementById("idDateFinGel").style.zIndex = "10";
    document.getElementById("idExpirationDate").style.zIndex = "1";

    this.frostDatesEmptiesError = false;
  }

  onFrostStartDateChange(): void {
    this.setFrostStartDateError();
  }

  private setFrostStartDateError(): void {
    let frostStartDate: Date;
    if (this.delegationFormGroup.get('dateDebutGel').value != null) {
      frostStartDate = new Date(this.delegationFormGroup.get('dateDebutGel').value);
      frostStartDate.setHours(0, 0, 0, 0);
    } else {
      frostStartDate = null;
    }

    let delegationStartDate = new Date(this.delegationFormGroup.get("startDate").value);
    delegationStartDate.setHours(0, 0, 0, 0);

    this.frostStartDateError = (frostStartDate != null) && (frostStartDate < delegationStartDate);
  }
}
