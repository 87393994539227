import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserInfos } from './user-infos';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private resourceUrl = environment.apiUrl + 'auth';

  constructor(private http: HttpClient) { }

  getHeaders(): HttpHeaders{
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json')
    headers = headers.set('Accept', 'application/json'),
    headers = headers.set('Access-Control-Allow-Origin', '*'),
    headers = headers.set('Access-Control-Allow-Methods', 'DELETE, POST, GET, OPTIONS'),
    headers = headers.set('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With')
    return headers;
  }

  public authenticate(userInfo: UserInfos) {
    const userCredential = { username: userInfo.email, password: userInfo.password };
    return this.http.post(this.resourceUrl, userCredential, {headers: this.getHeaders(), responseType: 'text'}).pipe(map((token:string)=> {
        localStorage.setItem('Bearer', token);
        return true;
    }));
  }

  public login(userInfo: UserInfos){
    const userCredential = { username: userInfo.email, password: userInfo.password };
    return this.http.post(this.resourceUrl, userCredential, {headers: this.getHeaders(), responseType: 'text'}).pipe(map((token:string)=> {
      localStorage.setItem('Bearer', token);
      return true;
    }));
  }

  public logout(): void {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('authUser');
    localStorage.removeItem('Bearer');
  }

}
