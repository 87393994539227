import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityObject } from '../../model/entityobject.model';
import { ListUser } from '../../model/listuser.model';
import { Password } from '../../model/password.model';
import { User } from '../../model/user.model';
import { UserCreation } from '../../model/userCreation.model';
import { Criteria } from '../model/criteria.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userApiUrl = environment.apiUrl + 'user';

  constructor(private http: HttpClient) {}

  get(id:number):Observable<User> {
    return this.http.get( this.userApiUrl + "/" + id).pipe(map((res:User) => res));
  }

  getAll(criteria:Criteria, pageable: any): Observable<ListUser> {
    return this.http.post(this.userApiUrl + "/findall", criteria, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listSite = new ListUser();
      listSite.user = this.convertResponse(res.body);
      listSite.maxUser = Number(res.headers.get('X-Total-Count'));
      return listSite;
    }));
  }

  getUserEntity(userId: number): Observable<EntityObject> {
    return this.http.get(`${this.userApiUrl}/${userId}/entity`).pipe(map((entity: EntityObject) => entity));
  }

  getUsersByEntity(entityId: number): Observable<User[]> {
    return this.http.get(`${this.userApiUrl}/entity/${entityId}`).pipe(map((users: User[]) => users));
  }

  getCurrentUser():Observable<User> {
    return this.http.get(this.userApiUrl+'/current').pipe(map((res:User) => {
      return res;
    }));
  }

  getUserExist(email:string):Observable<Boolean> {
    return this.http.get(this.userApiUrl+'/email/'+email).pipe(map((res:Boolean) => {
      return res;
    }));
  }

  lostPassword(email:string):Observable<Boolean> {
    return this.http.get(environment.apiUrl + '/mailpwd?email=' + email).pipe(map((res:Boolean) => {
      return res;
    }));
  }

  resetPassword(token:string, password:string):Observable<Boolean> {
    return this.http.get(environment.apiUrl + '/resetpwd?token=' + token + "&password=" + password).pipe(map((res:Boolean) => {
      return res;
    }));
  }

  create(user:UserCreation):Observable<User> {
    return this.http.post(this.userApiUrl + '/create',user).pipe(map((res:User)=> res));
  }

  useActivationCode(activationCode: string): Observable<void> {
    return this.http.put(`${this.userApiUrl}/activation-code?activationCode=${activationCode}`, null).pipe(map(() => null));
  }

  updatePassword(password:string):Observable<Boolean> {
    let pwd:Password = new Password(password);
    return this.http.put(this.userApiUrl + '/password', pwd).pipe(map((res:Boolean) => {
      return res;
    }));
  }

  updateRndts(user:User):Observable<Boolean> {
    return this.http.put(this.userApiUrl + '/rndts', user).pipe(map((res:Boolean) => {
      return res;
    }));
  }

  update(user:User): Observable<User> {
    return this.http.put(this.userApiUrl+'/update', user).pipe(map((res: User)=> res));
  }

  validUserToCollaborator(userId: number): Observable<void> {
    return this.http.put(`${this.userApiUrl}/${userId}/valid`, null).pipe(map(() => null));
  }

  updateToAdministrator(userId: number): Observable<User> {
    return this.http.put(`${this.userApiUrl}/${userId}/update/status/to-administrator`, null).pipe(map((user: User) => user));
  }

  disable(userId: number): Observable<User> {
    return this.http.put(`${this.userApiUrl}/${userId}/disable`, null).pipe(map((user: User) => user));
  }

  refuseAndDelete(userId: number): Observable<void> {
    return this.http.delete(`${this.userApiUrl}/${userId}/refuse`).pipe(map(() => null));
  }

  getUserParams():Observable<number[]> {
    return this.http.get(this.userApiUrl+'Param').pipe(map((res:number[]) => {
      return res;
    }));
  }

  createUserParams(ids:number[]):Observable<number[]> {
    return this.http.post(this.userApiUrl+'Param',ids).pipe(map((res:number[]) => {
      return res;
    }));
  }

  updatePhone(userId: number, phoneNumber: string): Observable<User> {
    return this.http.put(`${this.userApiUrl}/${userId}/update/phone?phoneNumber=${phoneNumber}`, null).pipe(map((user: User) => user));
  }

  private convertResponse(res): User[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new User().deserialize(res[i]));
    }
    return result;
  }
}
