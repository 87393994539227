import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListSite } from '../../model/listsite.model';
import { CriteriaHome } from '../model/criteria-home.model';
import { Criteria } from '../model/criteria.model';
import { Site } from './../../model/site.model';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  public siteData = [];
  private resourceUrl =  environment.apiUrl + 'site';
  private SEPARATOR =   '/';

  constructor(private http: HttpClient) { }

  getAll(criteria:Criteria, pageable: any): Observable<ListSite> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + "findall", criteria, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listSite = new ListSite();
      listSite.site = this.convertResponse(res.body);
      listSite.maxSite = Number(res.headers.get('X-Total-Count'));
      return listSite;
    }));
  }

  get(id: number): Observable<Site> {
    return this.http.get( this.resourceUrl + this.SEPARATOR + id).pipe(map((res:Site) => res));
  }

  update(id:number, site: Site): Observable<Site> {
    return this.http.put( this.resourceUrl + this.SEPARATOR + 'update', site).pipe(map((res:Site) => res));
  }

  create(site: Site): Observable<Site> {
    return this.http.post(this.resourceUrl + this.SEPARATOR+'create',site).pipe(map((res:Site)=> res));
  }

  findByLocalisation(criteria: CriteriaHome): Observable<Site[]> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + "findbylocalisation", criteria).pipe(map((res:Site[]) =>  res))
  }

  private convertResponse(res): Site[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new Site().deserialize(res[i]));
    }
    return result;
  }

}
