import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UploadFichierService } from "src/app/00-shared/upload-file/upload-fichier.service";
import { BordereauService } from "src/app/fr/brgm/common/http/bodereau/bordereau.service";
import { LexiqueService } from "src/app/fr/brgm/common/http/lex/lexique.service";
import { UserService } from "src/app/fr/brgm/common/http/user/user.service";
import { Bordereau } from "src/app/fr/brgm/common/model/bordereau.model";
import { DocumentTerrass } from "src/app/fr/brgm/common/model/document.model";
import { LexDocumentType } from "src/app/fr/brgm/common/model/lex/lexdocumenttype.model";
import { LexObject } from "src/app/fr/brgm/common/model/lex/lexObject.model";
import { User } from "src/app/fr/brgm/common/model/user.model";
import { ModalBstvCloseComponent } from "src/app/modal/modal-bstv-close/modal-bstv-close.component";
import { ModalBstvDeleteComponent } from "src/app/modal/modal-bstv-delete/modal-bstv-delete.component";
import { v4 as uuidv4 } from "uuid";
import { Lot } from "../model/lot.model";
import { LotService } from "../services/lot.service";

@Component({
  selector: "app-view-bstv-page",
  templateUrl: "./view-bstv-page.component.html",
  styleUrls: ["./view-bstv-page.component.scss"],
})
export class ViewBstvPageComponent implements OnInit {
  bordereau: Bordereau;
  lots: Lot[];
  bordereauStatus: LexObject[];
  files: File[];
  private myDocs: DocumentTerrass[] = [];
  fieldsetTitle = "Documents";
  uploadFileId = "uploadFileId";
  documentError: string;
  documentSuccess: string;
  user: User;

  volume = 0.0;
  deliveredQuantity = 0.0;
  deltaQuantity = 0.0;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private bordereauService: BordereauService,
    private lotService: LotService,
    private lexiqueService: LexiqueService,
    private uploadFichierService: UploadFichierService,
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.activatedroute.data.subscribe((data) =>
      this.titleService.setTitle(data.title)
    );

    this.activatedroute.params.subscribe((params) => {
      this.bordereauService.get(Number(params.id)).subscribe((bordereau) => {
        this.bordereau = bordereau;
        this.updateVolume();

        this.lotService
          .getByBordereauId(Number(params.id))
          .subscribe((lots) => {
            this.lots = lots;
            this.updateDeliveredQuantity();
          });
      });
    });

    this.lexiqueService
      .getLexBordereauStatus()
      .subscribe((lex) => (this.bordereauStatus = lex));
    this.userService.getCurrentUser().subscribe((user) => (this.user = user));
  }

  telechargerPdf(id: number) {
    this.bordereauService.getPdfBordereau(id).subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.href = downloadURL;
      link.download = "Bordereau" + id + ".pdf";
      link.click();
    });
  }

  closeBstv(bod: Bordereau) {
    bod.prenomNomCloture = `${this.user.firstname} ${this.user.name}`;

    const modalRef = this.modalService.open(ModalBstvCloseComponent, {
      size: "sm",
    });
    modalRef.componentInstance.bordereau = bod;
    modalRef.result
      .then((_result) => {
        this.router.navigate([`/bordereaux/details/${this.bordereau.id}`]);
        location.reload(); // #222 : on force le rafraîchissement de la page après la clôture d'un BSTV
      });
  }

  deleteLot(lob: Lot) {
    this.lotService.delete(lob).subscribe((res) => this.getLots());
  }

  getLots() {
    this.lotService.getByBordereauId(this.bordereau.id).subscribe((lots) => {
      this.lots = lots;
      this.bordereau.hasLot = lots.length > 0;
      this.updateDeliveredQuantity();
    });
  }

  getFiles(event) {
    this.files = event;
  }

  saveDocuments() {
    for (let file of this.files) {
      const nfic: string = uuidv4();

      let d: DocumentTerrass = new DocumentTerrass();
      d.filename = nfic;
      d.originalFilename = file.name;
      let typeDoc: LexDocumentType = new LexDocumentType();
      typeDoc.id = 1;
      d.lexDocumentType = typeDoc;
      this.myDocs.push(d);
      this.uploadFichierService.sendFile(file, nfic).subscribe(
        (res: string) => {},
        (err) => {
          this.documentError =
            "Une erreur s'est produite lors de l'enregistrement des documents";
          setTimeout(() => (this.documentError = null), 2500);
        }
      );
    }
  }

  enregistrerDocuments() {
    if (this.files && this.files.length != 0) {
      this.saveDocuments();
      this.bordereau.documents = this.myDocs;
      this.bordereauService.update(this.bordereau).subscribe(
        (res: Bordereau) => {
          this.documentError = null;
          this.documentSuccess =
            "Les documents ont été enregistrés avec succès";
          setTimeout(() => (this.documentSuccess = null), 4000);
        },
        (err) => {
          this.documentError =
            "Une erreur s'est produite lors de l'enregistrement des documents";
          setTimeout(() => (this.documentError = null), 4000);
        }
      );
    }
  }

  openDeleteModal(bordereau: Bordereau) {
    const modalRef = this.modalService.open(ModalBstvDeleteComponent, {
      size: "sm",
    });
    modalRef.componentInstance.bordereau = bordereau;
    modalRef.result
      .then((result) => {
        this.router.navigate(["/bordereaux"]);
      })
      .catch((error) => {});
  }

  updateVolume(): void {
    this.volume = Number(
      (this.bordereau.transaction.quantity / 1.3).toFixed(1)
    );
  }

  updateDeliveredQuantity(): void {
    this.deliveredQuantity = 0.0;
    this.lots.forEach((lot) => (this.deliveredQuantity += lot.quantite));
    this.deltaQuantity =
      this.deliveredQuantity - this.bordereau.transaction.quantity;
  }

  currentUserIsClaimerOrSupplier(): boolean {
    return (
      this.bordereau.transaction.demand.user.id === this.user.id ||
      this.bordereau.transaction.offer.user.id === this.user.id
    );
  }

  currentUserCanClosed(): boolean {
    return this.bordereau.transaction.demand.user.id === this.user.id;
  }

  currentUserCanRemove(): boolean {
    return this.bordereau.transaction.offer.user.id === this.user.id;
  }

  getSiteExiteDate(lot: Lot): Date {
    let exiteDate = new Date(lot.siteExitDate);
    exiteDate.setHours(exiteDate.getHours() + 1); // Problème de timezone lors de la conversion js/java/postgre

    return exiteDate;
  }

  declarerAuRndts() {
    this.router.navigate(["/bordereaux/rndts/" + this.bordereau.id]);
  }
}
