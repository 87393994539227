import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MapEvent, Overlay } from 'ol';
import { defaults, ScaleLine } from 'ol/control';
import { boundingExtent } from 'ol/extent';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry, MultiPoint, Point, Polygon } from 'ol/geom';
import { circular } from 'ol/geom/Polygon';
import { Image, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import Group from 'ol/layer/Group';
import Map from 'ol/Map';
import { Projection, transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { Cluster, ImageWMS, OSM, Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import View from 'ol/View';
import proj4 from 'proj4';
import { BehaviorSubject } from 'rxjs';
import { GeojsonService } from 'src/app/fr/brgm/common/http/geojson/geojson.service';
import { HomeOtvDtv } from 'src/app/fr/brgm/common/model/home-otv-dtv.model';
import { Site } from 'src/app/fr/brgm/common/model/site.model';

@Component({
  selector: 'app-map-view-points',
  templateUrl: './map-view-points.component.html',
  styleUrls: ['./map-view-points.component.scss'],
  providers: [DatePipe]
})
export class MapViewPointsComponent implements OnInit {

  private _sites = new BehaviorSubject<Site[]>([]);

  map: Map;
  view: View = new View({
    extent: new View().getProjection().getExtent()
  });
  clusters = new VectorLayer();
  geochimieLayer: BaseLayer;
  selectedOtvDtvList?: HomeOtvDtv[];
  selectedFeature?: Feature<Geometry>;
  container: HTMLElement;
  content: HTMLElement;
  closer: HTMLElement;
  isGeochimieLayerVisible: boolean = true;
  isOtvDtvLayerVisible: boolean = true;

  martiniqueLayer: BaseLayer;
  isMartiniqueLayerVisible: boolean = false;

  reunionPedologieLayer: BaseLayer;
  reunionPedologieNonSolLayer: BaseLayer;
  isReunionPedologieLayerVisible: boolean = true;

  reunionSecteurGeogLayer: BaseLayer;
  isReunionSecteurGeogLayerVisible: boolean = false;

  reunionEnjeuInvasionLayer: BaseLayer;
  isReunionEnjeuInvasionLayerVisible: boolean = false;

  soilSiteNaturelUniqueLayer: BaseLayer;
  isSoilSiteNaturelUniqueLayerVisible: boolean = false;

  soilSiteRemblaisLayer: BaseLayer;
  isSoilSiteRemblaisLayerVisible: boolean = false;

  RAYON_5KM: number = 5000;
  RAYON_30KM: number = 30000;
  geochimieCircleLayer = new VectorLayer();
  circleFeatures;
  selectedSiteCircleLayer = new VectorLayer();
  showLayers: boolean = true;

  isSearchClick: boolean = false;

  @Output() selectedOtvDtv = new EventEmitter<any>();
  @Output() onZoomChangeEventEmitter = new EventEmitter<any>();
  @Input()
  set sites(value) {
    this._sites.next(value);
  }

  get sites() {
    return this._sites.getValue();
  }

  constructor(private datePipe: DatePipe, private geojsonService: GeojsonService) { }

  ngOnInit() {
    this.container = document.getElementById('popup');
    this.content = document.getElementById('popup-content');
    this.closer = document.getElementById('popup-closer');

    this.initMap();

    this._sites.subscribe(_x => {
      this.onCloserclick();
      this.map.removeLayer(this.clusters);

      if (this.sites && this.sites.length != 0) {
        this._addFeatures(this.sites);
      }

      this.refreshCircleLayerVisibility();

    });
  }

  afficherCouches() {
    this.showLayers = !this.showLayers;
  }

  initMap() {
    var scale = new ScaleLine();
    this.view.setCenter([194455, 5916837]);
    this.view.setZoom(6);

    this.map = new Map({
      view: this.view,
      target: "sites_map",
      controls: defaults({
        attribution: true,
      }).extend([
        scale
        //zslider
      ])
    });

    const osmStandardLayer = new TileLayer({
      source: new OSM({
        wrapX: false
      }),
      visible: true
    });

    const anomalieGeochimique = new Image({
      source: new ImageWMS({ params: {}, url: 'https://mapsref.brgm.fr/wxs/infoterre/anomalie_geochimique?LAYERS=ANO_GEOCHIMIK_TERRASS_2' }),
      visible: true,
      opacity: 0.5
    });

    proj4.defs(
      'EPSG:2975',
      '+proj=utm +zone=40 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 ' +
      '+units=m +no_defs'

    );

    proj4.defs(
      'EPSG:2154',
      '+proj=lcc +lat_0=46.5 +lon_0=3 +lat_1=49 +lat_2=44 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 ' +
      '+units=m +no_defs +type=crs'
    );

    proj4.defs("EPSG:32620", "+proj=utm +zone=20 +datum=WGS84 +units=m +no_defs +type=crs");

    register(proj4);

    const proj2975 = new Projection({
      code: 'EPSG:2975'
    });

    const proj2154 = new Projection({
      code: 'EPSG:2154'
    });

    const proj32620 = new Projection({
      code: 'EPSG:32620'
    });

    const reunionEnjeuInvasion = new Image({
      source: new ImageWMS({
        params: {
          LAYERS: 'layer193',
          version: '1.1.1',
          SRS: proj2975.getCode()
        },
        url: 'https://ws.carmen.developpement-durable.gouv.fr/cgi-bin/mapserv?map=/mnt/data_carmen/REU/Publication/DEAL_REUNION_2020.map&ISBASELAYER=false&TRANSPARENT=true&SERVICE=WMS&REQUEST=GetMap',
        projection: proj2975.getCode()
      }),
      visible: this.isReunionEnjeuInvasionLayerVisible
    });

    const soilSiteNaturelUnique = new Image({
      source: new ImageWMS({
        params: {
          LAYERS: 'SOIL_SITE_NATUREL_UNIQUE',
          version: '1.1.1',
          SRS: proj2154.getCode()
        },
        url: 'http://mapsref.brgm.fr/wxs/soil_site/soil_site?TRANSPARENT=TRUE&SERVICE=WMS&REQUEST=GetMap',
        projection: proj2154.getCode()
      }),
      visible: this.isSoilSiteNaturelUniqueLayerVisible
    });

    const soilSiteRemblais = new Image({
      source: new ImageWMS({
        params: {
          LAYERS: 'SOIL_SITE_REMBLAIS',
          version: '1.1.1',
          SRS: proj2154.getCode()
        },
        url: 'http://mapsref.brgm.fr/wxs/soil_site/soil_site?TRANSPARENT=TRUE&SERVICE=WMS&REQUEST=GetMap',
        projection: proj2154.getCode()
      }),
      visible: this.isSoilSiteRemblaisLayerVisible
    });

    const martiniqueChlordecone = new Image({
      source: new ImageWMS({
        params: {
          LAYERS: 'layer8',
          SRS: proj32620.getCode()
        },
        url: 'https://datacarto.geomartinique.fr/map/layers/pref_chlordecone_analyse_sol_s_972?TRANSPARENT=true',
        projection: proj32620.getCode()
      }),
      visible: this.isMartiniqueLayerVisible
    });

    const stylesMultiPolygon = {
      'MultiPolygon1': new Style({
        fill: new Fill({
          color: '#f29639'
        })
      }),
      'MultiPolygon2': new Style({
        fill: new Fill({
          color: '#9f5219'
        })
      }),
      'MultiPolygon3': new Style({
        fill: new Fill({
          color: '#fff99d'
        })
      }),
      'MultiPolygon4': new Style({
        fill: new Fill({
          color: '#fbcfa1'
        })
      }),
      'MultiPolygon5': new Style({
        fill: new Fill({
          color: '#ed7e81'
        })
      }),
      'MultiPolygon6': new Style({
        fill: new Fill({
          color: '#99d5ad'
        })
      }),
      'MultiPolygon7': new Style({
        fill: new Fill({
          color: '#b9a75f'
        })
      }),
      'MultiPolygon8': new Style({
        fill: new Fill({
          color: '#d0d0d0'
        })
      }),
      'MultiPolygon9': new Style({
        fill: new Fill({
          color: '#fce2ee'
        })
      }),
      'MultiPolygon10': new Style({
        fill: new Fill({
          color: '#307b1b'
        })
      }),
      'MultiPolygon11': new Style({
        fill: new Fill({
          color: '#f9ffe5'
        })
      }),
      'MultiPolygon12': new Style({
        stroke: new Stroke({
          color: 'black',
          width: 0.5,
        }),
        fill: new Fill({
          color: '#ffffff'
        })
      })
    };

    const styleFunctionMultipolygon = function(feature) {
      return stylesMultiPolygon[feature.getGeometry().getType() + feature.get('id')];
    };

    let sourceReunionPedologieSol = new VectorSource();
    this.geojsonService.getReunionPedologieSol().subscribe(res => {
      sourceReunionPedologieSol.addFeatures(new GeoJSON().readFeatures(res));
    });
    const vectorReunionPedologie = new VectorLayer({
      source: sourceReunionPedologieSol,
      visible: this.isReunionPedologieLayerVisible,
      style: styleFunctionMultipolygon
    });

    let sourceReunionPedologieNonSol = new VectorSource();
    this.geojsonService.getReunionPedologieNonSol().subscribe(res => {
      sourceReunionPedologieNonSol.addFeatures(new GeoJSON().readFeatures(res));
    });
    const vectorReunionPedologieNonSol = new VectorLayer({
      source: sourceReunionPedologieNonSol,
      visible: this.isReunionPedologieLayerVisible,
      style: styleFunctionMultipolygon
    });

    let sourceReunionSecteurGeog = new VectorSource();
    this.geojsonService.getReunionSecteurGeographique().subscribe(res => {
      sourceReunionSecteurGeog.addFeatures(new GeoJSON().readFeatures(res));
    });
    const vectorReunionSecteurGeog = new VectorLayer({
      source: sourceReunionSecteurGeog,
      visible: this.isReunionSecteurGeogLayerVisible,
      style: new Style({
        stroke: new Stroke({
          color: 'red',
          width: 2,
        }),
      })
    });

    const baseLayeGroup = new Group({
      layers: [
        osmStandardLayer, anomalieGeochimique, martiniqueChlordecone, vectorReunionPedologieNonSol, vectorReunionPedologie,
        vectorReunionSecteurGeog, reunionEnjeuInvasion, soilSiteNaturelUnique, soilSiteRemblais
      ]
    });

    this.geochimieLayer = anomalieGeochimique;
    this.martiniqueLayer = martiniqueChlordecone;
    this.reunionPedologieLayer = vectorReunionPedologie;
    this.reunionPedologieNonSolLayer = vectorReunionPedologieNonSol;
    this.reunionSecteurGeogLayer = vectorReunionSecteurGeog;
    this.reunionEnjeuInvasionLayer = reunionEnjeuInvasion;
    this.soilSiteNaturelUniqueLayer = soilSiteNaturelUnique;
    this.soilSiteRemblaisLayer = soilSiteRemblais;
    this.map.addLayer(baseLayeGroup);
    this.map.addLayer(this.geochimieCircleLayer);
    this.selectedSiteCircleLayer.setVisible(false);
    const style = new Style({
      stroke: new Stroke({
        color: '#000000',
        width: 1
      })
    });
    this.selectedSiteCircleLayer.setStyle(style);
    this.map.addLayer(this.selectedSiteCircleLayer);
    this.map.on('moveend', event => this.onZoomChange(event));
  }

  onZoomChange(event: MapEvent): void {
    let geom3857 = new Polygon(this.toCoordinates(this.map.getView().calculateExtent(event.map.getSize())));
    let geom4326 = geom3857.transform("EPSG:3857", "EPSG:4326");
    let theGeoJSON = new GeoJSON().writeGeometryObject(geom4326);
    this.onZoomChangeEventEmitter.emit(theGeoJSON);
  }

  toCoordinates(bbox: number[]): any {
    let coordinates = [];
    let datas = [];
    datas.push([bbox[0], bbox[1]]);
    datas.push([bbox[2], bbox[1]]);
    datas.push([bbox[2], bbox[3]]);
    datas.push([bbox[0], bbox[3]]);
    datas.push([bbox[0], bbox[1]]);
    coordinates.push(datas);
    return coordinates;
  }

  _getCenterOfExtent(extent) {
    var X = (extent[0] + extent[2]) / 2;
    var Y = (extent[1] + extent[3]) / 2;
    return [X, Y];
  }

  onCloserclick() {
    this.container.style.display = "none";
    if (this.selectedFeature) {
      this.selectedFeature.setStyle(new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            color: 'rgba(237,131,77,1)',
          }),
          fill: new Fill({
            color: 'rgba(237,131,77,1)',
          }),
        }),
      }));
    }
    this.selectedOtvDtv.emit([]);
    this.selectedSiteCircleLayer.setVisible(false);
  }

  applySelectedStyle(res) {
    this.selectedFeature = res[0];
    res[0].setStyle(new Style({
      image: new CircleStyle({
        radius: 10,
        stroke: new Stroke({
          color: 'red',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(237,131,77,1)',
        }),
      }),
    }));
  }

  onMapClick(evt) {
    this.container.style.display = "none";
    if (this.selectedSiteCircleLayer.getSource()) {
      this.selectedSiteCircleLayer.getSource().clear();
    }

    if (this.selectedFeature) {
      this.selectedFeature.setStyle(new Style({
        image: new CircleStyle({
          radius: 10,
          stroke: new Stroke({
            color: 'rgba(237,131,77,1)',
          }),
          fill: new Fill({
            color: 'rgba(237,131,77,1)',
          }),
        }),
      }));
    }

    this.selectedOtvDtv.emit([]);

    let selectedCircleSource = new VectorSource();
    let selectedFeatureCircles = new Array<Feature>();

    this.clusters.getFeatures(evt.pixel).then((clickedFeatures) => {
      if (clickedFeatures.length) {
        // Get clustered Coordinates
        const features = clickedFeatures[0].get('features');
        if (features.length > 1) {
          const extent = boundingExtent(
            features.map((r) => r.getGeometry().getCoordinates())
          );
          this.map.getView().fit(extent, { duration: 1000, padding: [100, 100, 100, 100] });
        }
        else if (features.length == 1) {

          this.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
            selectedFeatureCircles.push(this.getCircleFeature([feature.getGeometry().getExtent()[0], feature.getGeometry().getExtent()[1]], this.RAYON_5KM));
            selectedFeatureCircles.push(this.getCircleFeature([feature.getGeometry().getExtent()[0], feature.getGeometry().getExtent()[1]], this.RAYON_30KM));
            var selectedFeatures = layer.getFeatures(evt.pixel);
            selectedFeatures.then(res => this.applySelectedStyle(res));
            this.selectedOtvDtvList = features[0].getProperties()[2].value;
            this.selectedOtvDtv.emit(this.selectedOtvDtvList.map(o => o.id));
            this.container.style.display = "block";
          })

          selectedCircleSource.addFeatures(selectedFeatureCircles);
          this.selectedSiteCircleLayer.setSource(selectedCircleSource);
          this.selectedSiteCircleLayer.setVisible(true);
        }
      }
    });
  }

  _addFeatures(data: Site[]) {
    var featureList = new Array<Feature>();
    var points = new MultiPoint([]);
    this.circleFeatures = new Array<Feature>();

    data.forEach(site => {
      if (site.geometry) {
        var feature = new Feature();
        var properties: { key: string, value: any }[] = [
          { key: "id", value: site.id },
          { key: "name", value: site.name },
          { key: "otvDtvList", value: site.otvDtvList }
        ]
        feature.setProperties(properties);

        var polygGeom: Geometry = new GeoJSON().readGeometry(site.geometry).transform('EPSG:4326', 'EPSG:3857');
        var pointGeom = new Point(this._getCenterOfExtent(polygGeom.getExtent()));
        feature.setGeometry(pointGeom);
        featureList.push(feature);

        points.appendPoint(pointGeom);

        this.circleFeatures.push(this.getCircleFeature(pointGeom.getCoordinates(), this.RAYON_5KM));
        this.circleFeatures.push(this.getCircleFeature(pointGeom.getCoordinates(), this.RAYON_30KM));
      }
    });

    this.addCircleFeatures();

    var pointsSource = new VectorSource({
      features: featureList,
      wrapX: false
    });

    var clusterSource = new Cluster({
      distance: 10,
      source: pointsSource,
      wrapX: false,
    });

    var styleCache = {};
    this.clusters = new VectorLayer({
      source: clusterSource,
      style: function (feature) {
        var size = feature.get('features').length;
        var style = styleCache[size];
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: 10,
              stroke: new Stroke({
                color: 'rgba(237,131,77,1)',
              }),
              fill: new Fill({
                color: 'rgba(237,131,77,1)',
              })
            }),
            text: new Text({
              text: size > 1 ? size.toString() : '',
              fill: new Fill({
                color: '#fff',
              }),
            }),
          });
          styleCache[size] = style;
        }
        return style;
      },
    });

    this.map.addLayer(this.clusters);

    /** Center and zoom on cluster */
    if(this.isSearchClick){
      this.map.getView().fit(pointsSource.getExtent(), {duration: 1000, size:this.map.getSize(), maxZoom:12, padding:[100,100,100,100]});
      this.isSearchClick = false;
    }

    /**
     * Create an overlay to anchor the popup to the map.
     */
    const overlay = new Overlay(this.container);
    this.map.addOverlay(overlay);

    /**
     * Add a click handler to hide the popup.
     * @return {boolean} Don't follow the href.
     */
    this.closer.addEventListener("click", this.onCloserclick.bind(this), false);

    this.map.addEventListener("click", this.onMapClick.bind(this));

    this.clusters.setVisible(this.isOtvDtvLayerVisible);
  }

  /**
   * Construct link to offer consultation
   * @param annonce
   * @returns
   */
  getConsultationLink(annonce: HomeOtvDtv): Object[] {
    let url = (annonce.type == 'O') ? "/offres/details" : "/demandes/details";
    return [url, annonce.id];
  }

  /**
   * Find a feature in the cluster by an offer id
   * @param otvDtv
   * @returns Feature
   */
  findSite(otvDtv: HomeOtvDtv): Feature {
    if (this.clusters) {
      let clusterFeatures = this.clusters.getSource().getFeatures();
      for (let i = 0; i < clusterFeatures.length; i++) {
        let features = clusterFeatures[i].getProperties()['features'];
        for (let j = 0; j < features.length; j++) {
          var siteId = features[j].getProperties()[0].value;
          var otvDtvList = features[j].getProperties()[2].value;
          for (let k = 0; k < otvDtvList.length; k++) {
            if (otvDtvList[k].id == otvDtv.id) {
              return features[j];
            }
          }
        }
      }
    }

    return null;
  }

  /**
   * method call from landing page when clicking on row in offers table
   * @param otvDtv
   */
  selectOtvDtv(otvDtv: HomeOtvDtv) {
    var siteFeature = this.findSite(otvDtv);
    this.view.fit(siteFeature.getGeometry().getExtent());
    this.view.setZoom(20);
  }

  private refreshCircleLayerVisibility(): void {
    this.geochimieCircleLayer?.setVisible(
      this.isOtvDtvLayerVisible && this.isGeochimieLayerVisible && this.sites && (this.sites.length != 0) // On n'affiche pas les cercles si pas de site à afficher sur la carte
    );
  }

  onGeochimieLayerCheckboxClicked() {
    this.geochimieLayer.setVisible(this.isGeochimieLayerVisible);
    this.refreshCircleLayerVisibility();
  }

  onOtvDtvLayerCheckboxClicked() {
    this.clusters.setVisible(this.isOtvDtvLayerVisible);
    this.refreshCircleLayerVisibility();
  }

  onMartiniqueCheckboxClicked() {
    this.martiniqueLayer.setVisible(this.isMartiniqueLayerVisible);
  }

  onReunionPedologieCheckboxClicked() {
    this.reunionPedologieLayer.setVisible(this.isReunionPedologieLayerVisible);
    this.reunionPedologieNonSolLayer.setVisible(this.isReunionPedologieLayerVisible);
  }

  onReunionSecteurGeogCheckboxClicked() {
    this.reunionSecteurGeogLayer.setVisible(this.isReunionSecteurGeogLayerVisible);
  }

  onReunionEnjeuInvasionCheckboxClicked() {
    this.reunionEnjeuInvasionLayer.setVisible(this.isReunionEnjeuInvasionLayerVisible);
  }


  onSoilSiteNaturelUniqueCheckboxClicked() {
    this.soilSiteNaturelUniqueLayer.setVisible(this.isSoilSiteNaturelUniqueLayerVisible);
  }

  onSoilSiteRemblaisCheckboxClicked() {
    this.soilSiteRemblaisLayer.setVisible(this.isSoilSiteRemblaisLayerVisible);
  }

  getCircleFeature(center, radius): Feature {
    const projection = this.map.getView().getProjection();
    const mycircle = circular(
      transform(center, projection, 'EPSG:4326'),
      radius,
      128
    );
    mycircle.transform('EPSG:4326', projection);
    return new Feature(mycircle);
  }

  addCircleFeatures() {
    let circleVectorSource = new VectorSource({
      features: this.circleFeatures
    });

    const style = new Style({
      stroke: new Stroke({
        color: '#000000',
        width: 1
      })
    });

    this.geochimieCircleLayer.setSource(circleVectorSource);
    this.geochimieCircleLayer.setStyle(style);
  }
}
