<div class="modal">
    <div class="modal-inner">
        <button type="button" class="modal-close icon-close" (click)="onClose()"></button>
        <div class="modal-body">
            <h1 class="h1 modal-title">Sauvegarde du fond pédogéochimique</h1>

            <div class="form-group">
                <label class="form-item form-item--12">
                    <span class="form-label">Nom du fond pédogéochimique<span class="red">*</span></span>
                    <span>
                        <input class="form-input" type="text" [(ngModel)]="nomFondPedogeochimique" id="idNomFondPedogeochimique" required
                        name="nomfondpedo" #nomfondpedo="ngModel" [ngClass]="{'red-border-class': nomfondpedo.errors && (nomfondpedo.touched || erreurSubmettedFondpedo)}">
                    </span>
                </label>
            </div>

            <div class="form-footer">


                <button type="submit" value="1" class="button button--green" (click)="onSave()">
                    <i class="button-icon icon-check"></i>
                    <span class="button-label">Enregistrer</span>
                </button>

                <button type="submit" value="0" class="button button--red" (click)="onClose()">
                    <i class="button-icon icon-close"></i>
                    <span class="button-label">Annuler</span>
                </button>
            </div>

        </div>
    </div>
</div>