<div class="process-body container" #divToModalClick>
  <div class="page-header">
    <h1 class="h1">Créer une annonce</h1>
  </div>
  <div class="process-wrapper">

    <nav class="process-nav">
      <ol id="progressbar" class="process-nav-list">
        <li class="process-nav-item" [class.process-nav-item--selected]="etapeActive === 1">
          <a href="#01" class="process-nav-link" >
            <i class="process-nav-icon icon-add" aria-hidden="true"></i>
            <span class="process-nav-label">Informations générales</span>
          </a>
        </li>
        <li class="process-nav-item" [class.process-nav-item--selected]="etapeActive === 2">
          <a href="#02" class="process-nav-link" >
            <i class="process-nav-icon icon-pin" aria-hidden="true"></i>
            <span class="process-nav-label">Localisation du site</span>
          </a>
        </li>
        <li class="process-nav-item" [class.process-nav-item--selected]="etapeActive === 3">
          <a href="#03" class="process-nav-link">
            <i class="process-nav-icon icon-design-tools" aria-hidden="true"></i>
            <span class="process-nav-label">Caractérisation</span>
          </a>
        </li>
        <li class="process-nav-item" [class.process-nav-item--selected]="etapeActive === 4">
          <a href="#04" class="process-nav-link">
            <i class="process-nav-icon icon-flasks" aria-hidden="true"></i>
            <span class="process-nav-label">Déclaration d'analyse</span>
          </a>
        </li>
        <li class="process-nav-item" [class.process-nav-item--selected]="etapeActive === 5">
          <a href="#05" class="process-nav-link">
            <i class="process-nav-icon icon-check" aria-hidden="true"></i>
            <span class="process-nav-label">Validation</span>
          </a>
        </li>
      </ol>
    </nav>

    <div class="process-main">

      <form *ngIf="annonce" (ngSubmit)="onSubmit(annonceForm)" #annonceForm="ngForm">

        <fieldset id="01" class="process-section">
          <legend class="process-section-header">
            <i class="process-section-icon icon-add" aria-hidden="true"></i>
            <span class="process-section-title" data-counter="01">Informations générales</span>
          </legend>
          <app-infos-generales [annonce]="annonce" [isCreation]="isCreation" [childIsOffer]="_isOffer" (typeChange)="typeChangeInChild($event)"
            [parentFormSubmitted]="annonceForm.submitted" [form]="annonceForm" [childIsDuplication]="isDuplication">
          </app-infos-generales>
        </fieldset>

        <fieldset id="02" class="process-section" [disabled]="(etapeActive < 2) || (annonce.isLinkToTransaction && !isDuplication)">
          <legend class="process-section-header">
            <i class="process-section-icon icon-pin" aria-hidden="true"></i>
            <span class="process-section-title" data-counter="02">Localisation du site</span>
          </legend>
          <div class="process-section-body">
            <app-localisation [annonce]="annonce" [parentFormSubmitted]="annonceForm.submitted" (onSiteChange)="onSiteChange($event)"></app-localisation>

            <div class="form-fieldset" *ngIf="annonce && pointCoordinates">
              <br>
              <h3 class="h4 form-fieldset-title" *ngIf="_isOffer">Localisez la ou les zones d'extraction des terres ou la localisation de l'andain</h3>
              <h3 class="h4 form-fieldset-title" *ngIf="!_isOffer">Localisez la ou les zones de valorisation des terres</h3>
              <app-map-edit-multipolygon [mapcenter]="pointCoordinates" [annonce]="annonce"></app-map-edit-multipolygon>
              <!-- <span class="red" *ngIf="!annonce.geometry && annonceForm.submitted">Veuillez dessiner un polygone</span>-->
            </div>

            <app-upload-fichier *ngIf="_isOffer" [fieldsetTitle]="fieldsetTitle4" [uploadFileId]="uploadFilePlanOfferId4" [documents]="annonce.documents" (fileListEmitter)="getPlanOfferFiles($event)"></app-upload-fichier>
            <app-upload-fichier *ngIf="!_isOffer" [fieldsetTitle]="fieldsetTitle5" [uploadFileId]="uploadFilePlanDemandId5" [documents]="annonce.documents" (fileListEmitter)="getPlanDemandFiles($event)"></app-upload-fichier>
          </div>
        </fieldset>

        <fieldset id="03" class="process-section" [disabled]="(etapeActive < 3) || (annonce.isLinkToTransaction && !isDuplication)">
          <legend class="process-section-header">
            <i class="process-section-icon icon-design-tools" aria-hidden="true"></i>
            <span class="process-section-title" data-counter="03">Caractérisation</span>
          </legend>
          <div class="process-section-body">
            <app-caracterisation [annonce]="annonce" [childIsOffer]="_isOffer" [parentFormSubmitted]="annonceForm.submitted">
            </app-caracterisation>
            <app-upload-fichier [fieldsetTitle]="fieldsetTitle1" [uploadFileId]="uploadFileTechId1" [documents]="annonce.documents" (fileListEmitter)="getTechniquesFiles($event)"></app-upload-fichier>
          </div>
        </fieldset>

        <fieldset id="04" class="process-section" [disabled]="(etapeActive < 4) || (annonce.isLinkToTransaction && !isDuplication)">
          <legend class="process-section-header">
            <i class="process-section-icon icon-flasks" aria-hidden="true"></i>
            <span class="process-section-title" data-counter="04">Déclaration d'analyse</span>
          </legend>
          <div class="process-section-body">
          <app-analyses [annonce]="annonce" [childIsCreation]="isCreation" [childIsOffer]="_isOffer" (analysisElemResultEmitter)="getAnalysisElemResult($event)"
            (analysisDeclarationEmitter)="getAnalysisDeclaration($event)" [parentFormSubmitted]="annonceForm.submitted">
          </app-analyses>
          <app-upload-fichier [disabled]="analysis?.analysisType?.lexAnalysisType?.id === 3" [fieldsetTitle]="fieldsetTitle2" [uploadFileId]="uploadFileAnasId2" [documents]="annonce.documents" (fileListEmitter)="getAnalysesFiles($event)"></app-upload-fichier>
          <app-upload-fichier [disabled]="analysis?.analysisType?.lexAnalysisType?.id === 3" [fieldsetTitle]="fieldsetTitle3" [uploadFileId]="uploadFileLixiId3" [documents]="annonce.documents" (fileListEmitter)="getAnalysesSurLixiviatFiles($event)"></app-upload-fichier>
        </div>
        </fieldset>

        <fieldset id="05" class="process-section" [disabled]="(etapeActive < 5) && (!annonce.isLinkToTransaction || isDuplication)">
          <legend class="process-section-header">
            <i class="process-section-icon icon-check" aria-hidden="true"></i>
            <span class="process-section-title" data-counter="05">Validation</span>
          </legend>
          <div style="text-align: center;">
            <p>
              Vous êtes sur le point de {{isCreation ? 'créer':'modifier' }} une annonce de type : <strong>{{_isOffer? 'offre':'demande ' }}</strong>.<br>
              <ng-container *ngIf="isCreation">
                Cette annonce sera sauvegardée sous le statut : <strong>en brouillon</strong>.<br>
                Vous devez la publier pour qu'elle soit visible par tous les utilisateurs de TERRASS
              </ng-container>
            </p>

            <div *ngIf="annonceForm.submitted && annonceForm.form.status === 'INVALID'" class="red">

              <p *ngIf="annonceForm.form.controls['availStartDate'].status === 'INVALID' || annonceForm.form.controls['availEndDate'].status === 'INVALID'; else otherError">
                Merci de vérifier les dates de disponibilités de l'annonce
              </p>

              <ng-template #otherError>
                <p>
                  Merci de compléter les champs obligatoires
                </p>
              </ng-template>

            </div>
            <button type="submit" class="button button--green">
              <span class="button-label">Valider</span>
              <i class="button-icon icon-right" aria-hidden="true"></i>
            </button>
          </div>
        </fieldset>

      </form>

    </div>
  </div>
</div>

<div class="process-footer" *ngIf="!annonce.isLinkToTransaction || isDuplication">
  <div class="container">
    <div class="process-controls">
      <div>

        <button type="button" class="button button--teal button--outline" *ngIf="etapeActive > 1"
          (click)="updateEtapePrecedent()">
          <i class="button-icon icon-left" aria-hidden="true"></i>
          <span class="button-label">Précédent</span>
        </button>
      </div>

      <div class="process-steps">
        <span class="process-steps-current">Étape <span
            class="process-steps-current-number">{{etapeActive}}</span></span>
        <span class="process-steps-total">/ 5</span>

        <h3 class="process-steps-title">Informations générales</h3>
      </div>

      <div>
        <button type="button" class="button button--teal" *ngIf="etapeActive < 5" (click)="updateEtapeSuivant()">
          <span class="button-label">Suivant</span>
          <i class="button-icon icon-right" aria-hidden="true"></i>
        </button>
      </div>

    </div>
  </div>

  <div class="process-progress">
    <div class="process-progress-bar" [style.width]="progression + '%'"></div>
    <span class="process-progress-label">{{progression}}%</span>
  </div>

</div>
