<div class="container" *ngIf="loaded">
    <div class="page-sticky">
        <a (click)="onReturnClick()" routerLinkActive="active" class="button button--outline button--teal">
            <i class="button-icon icon-left" aria-hidden="true"></i>
            <span class="button-label">Retour</span>
        </a>

        <div class="page-header">
            <h1 class="h1">Consultation de la demande</h1>

            <ul class="page-actions">
                <li *ngIf="isCloturable(dtv)" class="page-actions-item">
                    <a class="button button--black" (click)="closeDTV(dtv)">
                        <i class="button-icon icon-open-folder" aria-hidden="true"></i>
                        <span class="button-label">Clôturer</span>
                    </a>
                </li>
                <li *ngIf="isModifiable(dtv)" class="page-actions-item">
                    <a routerLink="/demandes/edit/{{dtv.id}}" routerLinkActive="active" class="button button--orange">
                        <i class="button-icon icon-edit" aria-hidden="true"></i>
                        <span class="button-label">Modifier</span>
                    </a>
                </li>
                <li *ngIf="isPubliable(dtv)" class="page-actions-item">
                    <a routerLink="close" routerLinkActive="active" class="button button--green" (click)="publishDTV(dtv)">
                        <i class="button-icon icon-check" aria-hidden="true"></i>
                        <span class="button-label">Publier</span>
                    </a>
                </li>
                <li *ngIf="dtv.currentUserCanDuplicate" class="page-actions-item">
                    <a routerLink="/demandes/duplicate/{{dtv.id}}" routerLinkActive="active" class="button button--orange">
                        <i class="button-icon icon-edit" aria-hidden="true"></i>
                        <span class="button-label">Dupliquer</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="details" *ngIf="dtv" #divToModalClick>

        <div class="details-header">
            <span class="details-type lightblue">Demande</span>

            <h2 class="details-title h1">{{dtv.id}}</h2>
            <span class="details-status" [ngClass]="{
                'details-status--draft': dtv.lexDemandStatus?.label.toLowerCase() === 'en brouillon',
                'details-status--proposed': dtv.lexDemandStatus?.label.toLowerCase() === 'publiée',
                'details-status--pending': dtv.lexDemandStatus?.label.toLowerCase() === 'échue',
                'details-status--archived': dtv.lexDemandStatus?.label.toLowerCase() === 'clôturée'
            }">{{dtv.lexDemandStatus?.label}}</span>

            <span *ngIf="!dtv.public_" class="details-visibility">
              <span *ngIf="isOwner()">
                <a id="confidential-code" class="black" (click)="onGenereteConfidentialCodeClick()">Générer code de partage</a>
              </span>
              <span class="red">
                <i class="icon-padlock" aria-hidden="true"></i> Confidentielle
              </span>
            </span>
            <span *ngIf="dtv.public_" class="details-visibility green"><i class="icon-open-padlock" aria-hidden="true"></i>Publique</span>
        </div>

        <div class="details-body">
            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-info" aria-hidden="true"></i>
                    <h3 class="details-section-title">Informations générales</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--6">
                            <p class="details-label">Période de disponibilité</p>
                            <p>Du &nbsp; <span class="details-value">{{dtv.availStartDate | date: 'dd/MM/yyyy'}}</span>
                                &nbsp; &nbsp; Au &nbsp; <span
                                    class="details-value">{{dtv.availEndDate | date: 'dd/MM/yyyy'}}</span></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-pin" aria-hidden="true"></i>
                    <h3 class="details-section-title">Localisation du site</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--12">
                            <div class="details-contact">
                                <h3 class="details-contact-title">{{dtv.site.name}}</h3>

                                <p class="details-contact-line">{{dtv.site.address.numberStreet}},
                                    {{dtv.site.address.zipCode}} {{dtv.site.address.foreignCity}}</p>

                                <em class="teal">{{dtv.site.lexSiteType?.label}}</em>
                            </div>
                        </div>

                        <div class="form-item form-item--12" *ngIf="dtv.site">
                            <app-map-edit-multipolygon [mapcenter]="pointCoordinates" [annonce]="dtv" [editMode]="false"></app-map-edit-multipolygon>
                        </div>
                    </div>
                    <hr class="form-separator">

                    <div class="form-group" *ngIf="planDemandDocs.length > 0">
                        <div class="form-item form-item--12">
                            <p class="details-label">Plan de la zone d'utilisation des terres</p>

                            <div class="form-group">
                                <div *ngFor="let doc of planDemandDocs" class="form-item form-item--3">
                                    <a [routerLink]="" (click)="downloadFile(doc.filename, doc.originalFilename)" download class="details-value"><i class="icon-pdf"></i>
                                        {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-design-tools" aria-hidden="true"></i>
                    <h3 class="details-section-title">Caractérisation</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">
                        <div class="form-item form-item--3">
                            <p class="details-label">Quantité à valoriser (t)</p>
                            <p class="details-value">{{dtv.quantity}}</p>
                        </div>
                        <div class="form-item form-item--3">
                            <p class="details-label">Volume à valoriser (m3)</p>
                            <p class="details-value">{{getVolume(dtv.quantity)}}</p>
                        </div>
                        <div class="form-item form-item--3">
                            <p class="details-label">Type de matériau (classement GTR)</p>
                            <p class="details-value">{{dtv.lexMaterialType?.label}}</p>
                        </div>

                        <div class="form-item form-item--9">
                            <p class="details-label">Informations complémentaires</p>
                            <p class="details-value">{{dtv.infos}}</p>
                        </div>
                    </div>

                    <hr class="form-separator">

                    <div class="form-group">
                        <div class="form-item form-item--12" *ngIf="docTechniques.length > 0">
                            <p class="details-label">Documents techniques</p>

                            <div class="form-group">
                                <div *ngFor="let doc of docTechniques" class="form-item form-item--3">
                                    <a *ngIf="doc.lexDocumentType.id === 1" [routerLink]=""
                                        (click)="downloadFile(doc.filename, doc.originalFilename)" download
                                        class="details-value"><i class="icon-pdf" aria-hidden="true"></i> {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-flasks" aria-hidden="true"></i>
                    <h3 class="details-section-title">Déclaration d’analyse</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-group">

                      <div class="form-item form-item--12">
                        <ul class="form-list">
                          <li class="form-list-item" *ngFor="let lexAnalysisType of lexAnalysisTypes">
                            <label class="form-radio">
                              <input type="radio" [value]="lexAnalysisType.id" name="analysisType" [(ngModel)]="dtvAnalysis.analysisType.lexAnalysisType.id" class="form-radio-input" disabled>
                              <span class="form-radio-label">{{ lexAnalysisType.label }}</span>
                            </label>
                          </li>
                        </ul>
                      </div>

                      <!-- Valeurs de fond pédo-géochimique local disponibles -->
                      <div class="form-item form-item--9" *ngIf="dtvAnalysis.analysisType.lexAnalysisType.id === 2">
                        <p class="details-label">Base de fond pédo-géochimique locale utilisée</p>
                        <p class="details-value">{{ dtvAnalysis.fondPedogeochimique? dtvAnalysis.fondPedogeochimique?.nom : dtvAnalysis.analysisType.detail}}</p>
                      </div>

                      <div class="form-item form-item--12">
                          <p *ngIf="!dtvAnalysis && !dtvAnalysisResults && dtvAnalysisResults.length === 0" class="black">Aucune analyse n’a été déclarée.</p>

                          <div *ngIf="dtvAnalysis != null && dtvAnalysisResults != null && dtvAnalysisResults.length > 0">
                            <p class="details-label">Tableau de substances</p>

                            <table class="details-table">
                                <thead>
                                    <tr>
                                        <th>Substance (analyse en contenu total)</th>
                                        <th class="details-table-centered">Valeur</th>
                                        <th class="details-table-centered">
                                            < LQ</th>
                                        <th class="details-table-centered">LQ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let elemResult of dtvAnalysisResults">
                                        <td>{{elemResult.lexMolecule?elemResult.lexMolecule.name:''}}</td>
                                        <td class="details-table-centered blue">
                                            {{elemResult.infQl?'-':elemResult.value}}</td>
                                        <td class="details-table-centered blue"><i class="icon-check"
                                                *ngIf="elemResult.infQl"></i></td>
                                        <td class="details-table-centered blue">{{elemResult.quantifyLimit}}</td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>

                      </div>

                      <div class="form-item form-item--9" *ngIf="dtvAnalysis && dtvAnalysis.infos">
                          <p class="details-label">Informations complémentaires</p>
                          <p class="details-value">{{dtvAnalysis.infos}}</p>
                      </div>
                    </div>

                    <hr class="form-separator">

                    <div class="form-group">
                        <div class="form-item form-item--12" *ngIf="bordereauxAnalyses.length > 0">
                            <p class="details-label">Bordereau(x) d'analyses</p>

                            <div class="form-group">
                                <div *ngFor="let doc of bordereauxAnalyses" class="form-item form-item--3">
                                    <a *ngIf="doc.lexDocumentType.id === 2" [routerLink]=""
                                        (click)="downloadFile(doc.filename, doc.originalFilename)" download
                                        class="details-value"><i class="icon-pdf"></i> {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-item form-item--12" *ngIf="bordereauxAnalysesSurLuxiviat.length > 0">
                            <p class="details-label">Bordereau(x) d'analyses sur lixiviat</p>

                            <div class="form-group">
                                <div *ngFor="let doc of bordereauxAnalysesSurLuxiviat" class="form-item form-item--3">
                                    <a *ngIf="doc.lexDocumentType.id === 3" [routerLink]=""
                                        (click)="downloadFile(doc.filename, doc.originalFilename)" download
                                        class="details-value"><i class="icon-pdf"></i> {{doc.originalFilename}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
