import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RndtsResponse } from '../../model/rndts/rndtsResponse.model';
import { TerresSedimentsEntrants } from '../../model/rndts/terresSedimentsEntrants.model';
import { TerresSedimentsSortants } from '../../model/rndts/terresSedimentsSortants.model';

@Injectable({
  providedIn: 'root'
})
export class RndtsService {

  private rndtsApiUrl = environment.apiUrl + 'rndts';

  constructor(private http: HttpClient) {}

  getEntrantsPreFilled(bordereauId: number):Observable<RndtsResponse> {
    return this.http.get( this.rndtsApiUrl + "/entrants/" + bordereauId).pipe(map((res:RndtsResponse) => res));
  }

  getSortantsPreFilled(bordereauId: number):Observable<RndtsResponse> {
    return this.http.get( this.rndtsApiUrl + "/sortants/" + bordereauId).pipe(map((res:RndtsResponse) => res));
  }

  sendEntrants(terresSedimentsEntrants: TerresSedimentsEntrants):Observable<RndtsResponse> {
    return this.http.post( this.rndtsApiUrl + "/entrants", terresSedimentsEntrants).pipe(map((res:RndtsResponse) => res));
  }

  sendSortants(terresSedimentsSortants: TerresSedimentsSortants):Observable<RndtsResponse> {
    return this.http.post( this.rndtsApiUrl + "/sortants", terresSedimentsSortants).pipe(map((res:RndtsResponse) => res));
  }

}
