import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Annonce } from 'src/app/00-shared/annonce-form/annonce.model';
import { calendar_fr } from 'src/app/app.constants';
import { DtvService } from 'src/app/fr/brgm/common/http/dtv/dtv.service';

@Component({
  selector: 'app-edit-demande-page',
  templateUrl: './edit-demande-page.component.html',
  styleUrls: ['./edit-demande-page.component.scss']
})
export class EditDemandePageComponent implements OnInit {

  fr: any = calendar_fr;
  availStartDate: Date;
  availEndDate: Date;
  annonce: Annonce = null;
  isCreation: boolean;
  isOffer: boolean;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private dtvService: DtvService
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    });

    this.isCreation = false;
    this.isOffer = false;
    this.activatedRoute.params.subscribe(params => {
      this.dtvService.get(Number(params.id)).subscribe(dtv => {

        this.annonce = new Annonce();
        this.annonce.id = dtv.id;
        this.annonce.availStartDate =  new Date(dtv.availStartDate);
        this.annonce.availEndDate =  new Date(dtv.availEndDate);
        this.annonce.bstvIcpe = dtv.bstvIcpe;
        this.annonce.infos = dtv.infos;
        this.annonce.lexMaterialType = dtv.lexMaterialType;
        this.annonce.public_ = dtv.public_;
        this.annonce.quantity = dtv.quantity;
        this.annonce.site = dtv.site;
        this.annonce.user = dtv.user;
        this.annonce.lexDemandStatus = dtv.lexDemandStatus;
        this.annonce.documents = dtv.documents;
        this.annonce.isLinkToTransaction = dtv.isLinkToTransaction;
        this.annonce.geometry = dtv.geometry;
      });
    });
  }
}
