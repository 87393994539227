<div class="modal">
    <form action="" #form="ngForm" class="modal-inner" (ngSubmit)="onSubmit()">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">

            <h2 class="h1 modal-title">Accepter la transaction ?</h2>

            <div *ngIf="error" class="row alert alert-danger">
                {{ error }}
            </div>

            <div class="form-group">
                <div class="form-item form-item--12">
                    <span class="form-label">
                        Attention ! L’adéquation entre le site producteur et le site receveur n’est pas
                        vérifiée par TERRASS.<br>
                        Il est de votre responsabilité de vérifier la conformité entre les terres
                        d’apport et leur usage futur.
                    </span>
                    <br>
                    <label class="form-checkbox" [ngClass]="{'red-border-class': !verificationCheckBox && form.submitted}">
                        <input required [(ngModel)]="verificationCheckBox" name="verificationCheckBox" type="checkbox" class="form-checkbox-input">
                        <span class="form-checkbox-label">J’ai vérifié la compatibilité entre le site
                            producteur et le site receveur</span>
                    </label>
                    <div *ngIf="!verificationCheckBox && form.submitted">
                        <span class="red">Veuillez vérifier la compatibilité puis cocher la case</span>
                    </div>
                </div>
            </div>

            <div class="form-footer">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="submit" value="1" class="button button--green">
                    <i class="button-icon icon-check"></i>
                    <span class="button-label">Confirmer</span>
                </button>
            </div>
        </div>
    </form>
</div>
