<div class="wrapper">
  <div class="container" *ngIf="bordereau">
    <div class="page-sticky">

      <a routerLink="/bordereaux" routerLinkActive="active" class="button button--outline button--teal">
        <i class="button-icon icon-left" aria-hidden="true"></i>
        <span class="button-label">Retour</span>
      </a>

      <div class="page-header">
        <h1 class="h1">Consultation du bordereau #{{bordereau.id}}</h1>

        <ul class="page-actions">
          <li class="page-actions-item" *ngIf="user.cleEtablissement && (bordereau?.currentUserCanDeclareToRndtsEntrants || bordereau?.currentUserCanDeclareToRndtsSortants)">
            <button class="button button--blue" title="Utiliser ce BSTV pour réaliser votre déclaration de flux de terres et sédiments au RNDTS">
              <i class="button-icon icon-external" aria-hidden="true"></i>
              <span class="button-label" (click)="declarerAuRndts()">Déclaration au RNDTS</span>
            </button>
          </li>
          <li class="page-actions-item" *ngIf="!user.cleEtablissement && (bordereau?.currentUserCanDeclareToRndtsEntrants || bordereau?.currentUserCanDeclareToRndtsSortants)">
            <i class="button-icon icon-external" aria-hidden="true" style="display: inline-block;"></i>
            <span class="button-label" style="display: inline-block;" title="Pour activer cette fonction, merci de déclarer une clé RNDTS dans votre profil">Déclaration au RDNTS</span>
          </li>
          <li class="page-actions-item" *ngIf="bordereau?.lexBordereauStatus?.id === 2">
            <a (click)="telechargerPdf(bordereau?.id)" download class="details-value">
              <i class="icon-pdf" aria-hidden="true"></i>
              Télécharger</a>
          </li>
          <li class="page-actions-item"
            *ngIf="(bordereau?.lexBordereauStatus?.id === 1) && bordereau?.currentUserCanClose">
            <a (click)="closeBstv(bordereau)" class="button button--orange">
              <i class="button-icon icon-open-folder" aria-hidden="true"></i>
              <span class="button-label">Clôturer</span>
            </a>
          </li>
          <li class="page-actions-item"
            *ngIf="(bordereau?.lexBordereauStatus?.id === 1) && bordereau?.currentUserCanDelete">
            <a class="button button--red" (click)="openDeleteModal(bordereau)">
              <i class="button-icon icon-cancel" aria-hidden="true"></i>
              <span class="button-label">Supprimer</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="details">

      <div class="details-body">
        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-pin" aria-hidden="true"></i>
            <h3 class="details-section-title">Site producteur</h3>
          </div>
          <div class="details-section-body">
            <div class="form-group">
              <div class="form-item form-item--12">
                <p class="details-label">Raison sociale</p>
                <p class="details-value">{{bordereau?.offreurRaisonSociale}}</p>
              </div>
              <div class="form-item form-item--12">
                <p class="details-label">N° de SIRET</p>
                <p class="details-value">{{bordereau?.offreurSiret}}</p>
              </div>
              <div class="form-item form-item--6">
                <p class="details-label">Adresse</p>
                <p class="details-value">{{bordereau?.offreurAdresse}}</p>
              </div>
              <div class="form-item form-item--6">
                <p class="details-label">Ville</p>
                <p class="details-value">{{bordereau?.offreurVille}}</p>
              </div>
              <div class="form-item form-item--12">
                <p class="details-label">Code postal</p>
                <p class="details-value">{{bordereau?.offreurCodePostal}}</p>
              </div>

              <div class="form-item form-item--12" *ngIf="bordereau.transaction.offer.site.parcelles.length !== 0">
                <p class="form-label">Parcelles intersectées par ce site</p>
                <table class="table">
                  <caption>Tableau des parcelles intersectées par le site producteur</caption>
                  <thead>
                    <th scope="col">ID</th>
                    <th scope="col">Depart.</th>
                    <th scope="col">Code</th>
                    <th scope="col">Ville</th>
                    <th scope="col">Section</th>
                    <th scope="col">Numero</th>
                    <th scope="col">Feuille</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let parcel of bordereau.transaction.offer.site.parcelles">
                      <td>{{parcel.identifiant}}</td>
                      <td>{{parcel.codeDepartement}}</td>
                      <td>{{parcel.codeCommune}}</td>
                      <td>{{parcel.nomCommune}}</td>
                      <td>{{parcel.section}}</td>
                      <td>{{parcel.numero}}</td>
                      <td>{{parcel.feuille}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="form-item form-item--12" *ngIf="bordereau.transaction.offer.site.sisIntersectes.length != 0">
                <p class="form-label">SIS intersectés par ce site</p>
                <table class="table">
                  <caption>Tableau des SIS intersectés par le site producteur</caption>
                  <thead>
                    <th scope="col">Code</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let sis of bordereau.transaction.offer.site.sisIntersectes">
                      <td>{{sis.code}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-pin" aria-hidden="true"></i>
            <h3 class="details-section-title">Site receveur</h3>
          </div>
          <div class="details-section-body">
            <div class="form-group">
              <div class="form-item form-item--12">
                <p class="details-label">Raison sociale</p>
                <p class="details-value">{{bordereau?.demandeurRaisonSociale}}</p>
              </div>
              <div class="form-item form-item--12">
                <p class="details-label">N° de SIRET</p>
                <p class="details-value">{{bordereau?.demandeurSiret}}</p>
              </div>
              <div class="form-item form-item--6">
                <p class="details-label">Adresse</p>
                <p class="details-value">{{bordereau?.demandeurAdresse}}</p>
              </div>
              <div class="form-item form-item--6">
                <p class="details-label">Ville</p>
                <p class="details-value">{{bordereau?.demandeurVille}}</p>
              </div>
              <div class="form-item form-item--12">
                <p class="details-label">Code postal</p>
                <p class="details-value">{{bordereau?.demandeurCodePostal}}</p>
              </div>

              <div class="form-item form-item--12" *ngIf="bordereau.transaction.demand.site.parcelles.length !== 0">
                <p class="form-label">Parcelles intersectées par ce site</p>
                <table class="table">
                  <caption>Tableau des parcelles intersectées par le site receveur</caption>
                  <thead>
                    <th scope="col">ID</th>
                    <th scope="col">Depart.</th>
                    <th scope="col">Code</th>
                    <th scope="col">Ville</th>
                    <th scope="col">Section</th>
                    <th scope="col">Numero</th>
                    <th scope="col">Feuille</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let parcel of bordereau.transaction.demand.site.parcelles">
                      <td>{{parcel.identifiant}}</td>
                      <td>{{parcel.codeDepartement}}</td>
                      <td>{{parcel.codeCommune}}</td>
                      <td>{{parcel.nomCommune}}</td>
                      <td>{{parcel.section}}</td>
                      <td>{{parcel.numero}}</td>
                      <td>{{parcel.feuille}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="form-item form-item--12" *ngIf="bordereau.transaction.demand.site.sisIntersectes.length != 0">
                <p class="form-label">SIS intersectés par ce site</p>
                <table class="table">
                  <caption>Tableau des SIS intersectés par le site receveur</caption>
                  <thead>
                    <th scope="col">Code</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let sis of bordereau.transaction.demand.site.sisIntersectes">
                      <td>{{sis.code}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-paper-plane" aria-hidden="true"></i>
            <h3 class="details-section-title">Livraison</h3>
          </div>
          <div class="details-section-body">

            <div class="form-group">
              <div class="form-item form-item--6">
                <p class="details-label">Date de livraison</p>
                <p class="details-value">{{bordereau?.dateLivraison|date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-design-tools" aria-hidden="true"></i>
            <h3 class="details-section-title">Caractérisation</h3>
          </div>
          <div class="details-section-body">

            <div class="form-group">
              <div class="form-item form-item--6">
                <p class="details-label">Quantité en tonne</p>
                <p class="details-value">{{bordereau.transaction.quantity}}</p>
              </div>

              <div class="form-item form-item--6">
                <p class="details-label">Volume en m3</p>
                <p class="details-value">{{volume}}</p>
              </div>

            </div>
          </div>
        </div>

        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-info" aria-hidden="true"></i>
            <h3 class="details-section-title">Liste de bons de transport</h3>
          </div>
          <div class="details-section-body">
            <div *ngIf="(bordereau?.lexBordereauStatus?.id === 1) && bordereau?.currentUserCanAddLot" class="form-group">
              <div class="form-item form-item--12">
                <a [routerLink]="'/bordereaux/lot/create/'+bordereau.id" class="button button--green">
                  <i class="button-icon icon-add" aria-hidden="true"></i>
                  <span class="button-label">Ajouter un lot au BSTV</span>
                </a>
              </div>
            </div>
            <div class="form-group" *ngIf="lots">

              <div class="form-item form-item--12">

                <br>

                <div *ngIf="lots.length === 0">
                  <p>Aucun bon de transport n'est liée à ce bordereau.</p>
                </div>

                <div *ngIf="lots.length !== 0">
                  <table class="table">

                    <thead>
                      <tr>
                        <th scope="col">Date de sortie du site</th>
                        <th scope="col">Quantité (t)</th>
                        <th scope="col">Volume (m3)</th>
                        <th scope="col" *ngIf="bordereau?.currentUserCanUpdateAndDeleteLot">Éditer</th>
                        <th scope="col" *ngIf="bordereau?.currentUserCanUpdateAndDeleteLot">Supprimer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lot of lots">

                        <td>{{ getSiteExiteDate(lot) | date:'dd/MM/yyyy \'à\' HH:mm' }}</td>
                        <td>{{ lot?.quantite }}</td>
                        <td>{{ lot?.volume }}</td>
                        <td *ngIf="bordereau?.currentUserCanUpdateAndDeleteLot">
                          <a [ngClass]="{'isDisabled':bordereau?.lexBordereauStatus?.id === 2}"
                            [routerLink]="'/bordereaux/lot/edit/'+bordereau?.id+'/'+lot?.id">
                            <i class="table-actions-icon icon-edit" aria-hidden="true"></i>
                          </a>
                        </td>
                        <td *ngIf="bordereau?.currentUserCanUpdateAndDeleteLot">
                          <a [ngClass]="{'isDisabled':bordereau?.lexBordereauStatus?.id === 2}" [routerLink]=""
                            (click)="deleteLot(lot)">
                            <i class="table-actions-icon icon-cancel" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

            </div>

            <div class="form-group">
              <div class="form-item form-item--6">
                <p class="details-label">Nom du représentant</p>
                <p class="details-value">{{bordereau?.nomRepresentant}}</p>
              </div>
              <div class="form-item form-item--6">
                <p class="details-label">Quantité livrée par les lots</p>
                <p class="details-value">{{ deliveredQuantity }} t</p>
              </div>
            </div>

            <div class="form-group">
              <div class="form-item form-item--6">
                <p class="details-label">E-mail du représentant</p>
                <p class="details-value">{{bordereau?.emailRepresentant}}</p>
              </div>
              <div class="form-item form-item--6">
                <p class="details-label">Différence entre la quantité de la transaction et la quantité livrée</p>
                <p class="details-value"
                  [ngClass]="{'number-zero': deltaQuantity === 0, 'number-positive': deltaQuantity > 0, 'number-negative': deltaQuantity < 0}">
                  {{ deltaQuantity }} t
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="details-section details-section--opened">
          <div class="details-section-header">
            <i class="details-section-icon icon-folder" aria-hidden="true"></i>
            <h3 class="details-section-title">Documents</h3>
          </div>
          <div *ngIf="documentSuccess" class="row alert alert-success">
            {{ documentSuccess }}
          </div>
          <div *ngIf="documentError" class="row alert alert-danger">
            {{ documentError }}
          </div>
          <div class="details-section-body">
            <app-upload-fichier [fieldsetTitle]="fieldsetTitle" [uploadFileId]="uploadFileId"
              [documents]="bordereau.documents" [readOnly]="!currentUserIsClaimerOrSupplier()"
              (fileListEmitter)="getFiles($event)">
            </app-upload-fichier>
            <br>
            <div *ngIf="currentUserIsClaimerOrSupplier()" class="form-group">
              <div class="form-item form-item--12">
                <a [routerLink]="" (click)="enregistrerDocuments()" class="button button--green">
                  <i class="button-icon icon-check" aria-hidden="true"></i>
                  <span class="button-label">Enregistrer les documents</span>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
