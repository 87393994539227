import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityObjectService } from 'src/app/00-shared/services/entity-object.service';
import { EntityObject } from 'src/app/fr/brgm/common/model/entityobject.model';

@Component({
  selector: 'app-modal-edit-social-reason',
  templateUrl: './modal-edit-social-reason.component.html',
  styleUrls: ['./modal-edit-social-reason.component.scss']
})
export class ModalEditSocialReasonComponent {

  @Input() entity: EntityObject;

  internalError = false;

  constructor(
    private activeModal: NgbActiveModal,
    private entityService: EntityObjectService
  ) { }

  onClose(): void {
    this.activeModal.close();
  }

  onSubmit(): void {
    this.internalError = false;

    this.entityService.updateEntityObject(this.entity).subscribe(
      _entity => this.activeModal.close(),
      _err => this.internalError = true
    );
  }

}
