import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BordereauService } from 'src/app/fr/brgm/common/http/bodereau/bordereau.service';
import { Lot } from '../model/lot.model';
import { LotService } from '../services/lot.service';

@Component({
  selector: 'app-lot-create',
  templateUrl: './lot-create.component.html',
  styleUrls: ['./lot-create.component.scss']
})
export class LotCreateComponent implements OnInit {

  lot: Lot = null;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private lotService: LotService,
    private bordereauService: BordereauService
  ) { }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => this.titleService.setTitle(data.title));
    this.activatedroute.params.subscribe( params => {

      this.bordereauService.get(Number(params.idBordereau)).subscribe((bord) => {
        const bordereau = bord;

        this.lotService.getByBordereauId(Number(params.idBordereau)).subscribe((lots) => {
            this.lot = new Lot();
            this.lot.lot = lots.length+1;
            this.lot.bordereauId = bordereau.id;
            if(bordereau?.transaction?.transporters?.length > 0) {
              this.lot.transporteur = bordereau?.transaction?.transporters?.[0];
            }
          });
      });
    });
  }
}
