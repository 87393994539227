import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { filter, map } from 'rxjs/operators';
declare const statistic: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  primeNgFrTranslation = {
    "startsWith": "Commence avec",
    "contains": "Contient",
    "notContains": "Ne contient pas",
    "endsWith": "Se termine par",
    "equals": "Équivaut à",
    "notEquals": "Pas égal",
    "noFilter": "Pas de filtre",
    "lt": "Moins que",
    "lte": "Inférieur ou égal à",
    "gt": "Supérieur à",
    "gte": "Supérieur ou égal à",
    "is": "Est",
    "isNot": "N'est pas",
    "before": "Avant",
    "after": "Après",
    "dateIs": "La date est",
    "dateIsNot": "La date n'est pas",
    "dateBefore": "La date est antérieure",
    "dateAfter": "La date est postérieure",
    "clear": "Vider",
    "apply": "Appliquer",
    "matchAll": "Tout correspondre",
    "matchAny": "Correspondre à n'importe quel",
    "addRule": "Ajouter une règle",
    "removeRule": "Supprimer un règle",
    "accept": "Oui",
    "reject": "Non",
    "choose": "Choisir",
    "upload": "Télécharger",
    "cancel": "Annuler",
    "dayNames": ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    "dayNamesShort": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    "dayNamesMin": ["Di","Lu","Ma","Me","Je","Ve","Sa"],
    "monthNames": ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
    "monthNamesShort": ["Janv", "Févr", "Mars", "Avr", "Mai", "Juin","Juill", "Août", "Sept", "Oct", "Nov", "Déc"],
    "dateFormat": "dd/mm/yyyy",
    "firstDayOfWeek": 1,
    "today": "Aujourd'hui",
    "weekHeader": "Sem",
    "weak": "Faible",
    "medium": "Moyen",
    "strong": "Fort",
    "passwordPrompt": "Entrer un mot de passe",
    "emptyMessage": "Aucun résultat trouvé",
    "emptyFilterMessage": "Aucun résultat trouvé"
  };

  isHomePage = false;

  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private primeNGConfig: PrimeNGConfig
  ) {

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        statistic(window.location.pathname);
      }
    });

  }

  ngOnInit() {

    /**
     * Depuis les dernières versions de PrimeNG, il n'est plus possible d'utiliser [locale]=fr sur un composant PrimeNG.
     * Dans notre cas, l'application utilise uniquement la langue française, on fournit donc directement à la création de l'application
     * les traductions françaises de l'ensemble des composants PrimeNG.
     */
    this.primeNGConfig.setTranslation(this.primeNgFrTranslation);

    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map((navigation: NavigationEnd) => {
          this.isHomePage = navigation.url.includes("accueil");
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

}
