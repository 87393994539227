import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityObjectService } from 'src/app/00-shared/services/entity-object.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { UserCreation } from 'src/app/fr/brgm/common/model/userCreation.model';
import { ModalRegisterConfirmationComponent } from 'src/app/modal/modal-register-confirmation/modal-register-confirmation.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @ViewChild('divToModalClick') divToModalClick: ElementRef;

  unknownSiren = "inconnue";
  sirenValue: string;

  user: UserCreation;

  //entityNatures:LexEntityNature[];

  registerForm: FormGroup;
  submitted = false;

  checkCondition = false;
  checkData = false;

  raisonSociale: string;

  checkSiren = false;
  checkEmail = false;
  checkGlobalError = false;

  invalidSiren: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userService: UserService,
    private entityObjectService: EntityObjectService
  )
    {
      this.user = new UserCreation();
      this.invalidSiren = false;
      this.raisonSociale = this.unknownSiren;
      //this.entityNatures = [];
    }

  ngOnInit() {
    //this.entityNatures.push(new LexEntityNature(2,"Collectivité"));
    //this.entityNatures.push(new LexEntityNature(3,"Entreprise"));
    this.registerForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        //nature: [this.entityNatures[1], Validators.required],
        isPrincipal: [true, Validators.required],
        mailPrincipal: ['',],
        siren: ['', [Validators.required, Validators.pattern('^[0-9]{9}$')]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[\\d])(?=.*[^a-zA-Z\\d\\s]).{8,}')]],
        confirmPassword: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
        acceptCharte: [false, Validators.requiredTrue],
        acceptData: [false, Validators.requiredTrue]
      },
      {
        validator: this.mustMatch('password', 'confirmPassword')
      }
    );

    this.registerForm.get('isPrincipal').valueChanges
      .subscribe(value => {
        if (value) {
          this.registerForm.get('mailPrincipal').setValidators(Validators.required)
        } else {
          this.registerForm.get('mailPrincipal').clearValidators();
        }
      });
  }

  onSubmit() {
    this.checkSiren = false;
    this.checkEmail = false;
    this.checkGlobalError = false;

    this.userService.getUserExist(this.registerForm.value.email).subscribe((result) => {
      if(!result){

        this.user.email = this.registerForm.value.email;
        this.user.firstname = this.registerForm.value.firstName;
        this.user.lastName = this.registerForm.value.lastName;
        this.user.lexEntityNature = this.registerForm.value.nature;
        this.user.password = this.registerForm.value.password;
        this.user.siren = this.registerForm.value.siren;
        this.user.isPrincipal = this.registerForm.value.isPrincipal;
        this.user.mailPrincipal = this.registerForm.value.mailPrincipal;

        if (this.raisonSociale != this.unknownSiren && !this.invalidSiren) {
          this.userService.create(this.user).subscribe(
            (res) => {
              const modalRef = this.modalService.open(ModalRegisterConfirmationComponent);
              modalRef.componentInstance.firstSubscriber = res.firstSubscriber;
              this.divToModalClick.nativeElement.click(); // Forcer un click permet d'ouvrir instantanément la modal (bug : la modal ne s'ouvre pas sans click)
            },
            (err) => this.checkGlobalError = true
          );
        } else {
          this.checkSiren = true;
        }

      }else{
        this.checkEmail = true;
      }
    })

  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }


  getSiren(e) {
    this.sirenValue = this.sirenValue.replace(/\s/g, '');
    if (this.sirenValue.length == 9) {
      this.entityObjectService.getEntityObject(this.sirenValue).subscribe(
        res => {
          this.raisonSociale = res.socialReason;
          this.invalidSiren = false;
        },
        err => {
          this.raisonSociale = this.unknownSiren;
          this.invalidSiren = true;
        }
      );
    }
  }

  get lastName() {
    return this.registerForm.get("lastName");
  }

  public isInvalid(controlName: string): boolean {
    let control = this.getFormControl(controlName);
    return control.invalid && (control.dirty || control.touched);
  }

  public getFormControl(controlName: string): AbstractControl {
    return this.registerForm.get(controlName);
  }

}
