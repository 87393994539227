import { Deserializable } from './Deserializable';
import { Site } from './site.model';

export class HomeOtvDtv implements Deserializable{
    constructor(
        public id?: number,
        public denomination?: string,
        public statusId?: number,
        public site?: Site,
        public availStartDate?: Date,
        public availStartEnd?: Date,
        public creationDate?: Date,
        public quantity?: number,
        public public_?: boolean,
        public availEndDate?: string,
        public type?: string
    ) {
        //console.log("OTVDTV",this)
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
