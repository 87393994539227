export const APP_NAME = 'Terrass';
export const APP_PREFIX = 'Terrass - ';

export const calendar_fr = {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu","Ma","Me","Je","Ve","Sa"],
    monthNames: [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre" ],
    monthNamesShort: [ "Jan", "Fév", "Mar", "Avr", "Mai", "Juin","Juil", "Aoû", "Sep", "Oct", "Nov", "Déc" ],
    today: 'Aujourd\'hui',
    clear: 'vider',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
};
