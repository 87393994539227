<div class="modal">
    <form action="" class="modal-inner" (ngSubmit)="onSubmit()">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">
            <h2 class="h1 modal-title">Souhaitez vous supprimer ce bordereau ?</h2>

            <div class="form-footer mt-5">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="submit" value="1" class="button button--green">
                    <i class="button-icon icon-check"></i>
                    <span class="button-label">Confirmer</span>
                </button>
            </div>
        </div>
    </form>
</div>