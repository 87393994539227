import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { MessageService } from "src/app/00-shared/message/message.service";
import { calendar_fr } from "src/app/app.constants";
import { BordereauService } from "src/app/fr/brgm/common/http/bodereau/bordereau.service";
import { LexiqueService } from "src/app/fr/brgm/common/http/lex/lexique.service";
import { Criteria } from "src/app/fr/brgm/common/http/model/criteria.model";
import { UserService } from "src/app/fr/brgm/common/http/user/user.service";
import { Bordereau } from "src/app/fr/brgm/common/model/bordereau.model";
import { LexObject } from "src/app/fr/brgm/common/model/lex/lexObject.model";
import { LexTransactionStatus } from "src/app/fr/brgm/common/model/lex/lextransactionstatus.model";
import { User } from "src/app/fr/brgm/common/model/user.model";
import { ModalBstvCloseComponent } from "src/app/modal/modal-bstv-close/modal-bstv-close.component";
import { ModalBstvDeleteComponent } from "src/app/modal/modal-bstv-delete/modal-bstv-delete.component";

@Component({
  selector: "app-bstv-page",
  templateUrl: "./bstv-page.component.html",
  styleUrls: ["./bstv-page.component.scss"],
})
export class BstvPageComponent implements OnInit {
  bordereauStatus: LexObject[];

  itemsPerPage: number;
  page: any;
  rowsPerPage: number[] = [10, 20, 30, 40, 50];
  sortColumn: string;
  sortOrder: string;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];

  filterStatusInProgress: boolean;
  filterStatusClotured: boolean;
  filterStatusCanceled: boolean;
  filterStatus: any[];
  filterStatusExpired: boolean;
  filterDate: Date[];
  filterSearch: string;

  filterMyOffers: boolean;
  filterMyRequests: boolean;

  fr: any = calendar_fr;
  lexTransactionStatus: LexTransactionStatus[];

  bordereaux: Bordereau[];
  totalbordereaux: number;

  user: User;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private bordereauService: BordereauService,
    private messageService: MessageService,
    private lexiqueService: LexiqueService,
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService
  ) {
    this.totalbordereaux = 0;
    this.page = 0;
    this.itemsPerPage = 10;
    this.sortColumn = "dateCreation";
    this.filterSearch = "";
    this.filterStatus = [];
  }

  ngOnInit() {
    this.activatedroute.data.subscribe((data) =>
      this.titleService.setTitle(data.title)
    );
    this.lexiqueService
      .getLexTransactionStatus()
      .subscribe((res) => (this.lexTransactionStatus = res));
    this.lexiqueService
      .getLexBordereauStatus()
      .subscribe((res) => (this.bordereauStatus = res));
    this.userService.getCurrentUser().subscribe((res) => (this.user = res));
  }

  changeRowsperPage(event) {
    this.itemsPerPage = event.target.value;
  }

  loadLazy(event) {
    this.page = event.first / this.itemsPerPage;
    if (event.sortField) this.sortColumn = event.sortField;
    if (event.sortOrder == 1) {
      this.sortOrder = "ASC";
      this.page = 0;
    } else {
      this.sortOrder = "DESC";
      this.page = 0;
    }
    this.search();
  }

  onSearch(): void {
    this.page = 0; // lors d'une recherche par numéro de bordereau, on replace l'utilisateur sur la page 1 (commence à 0)
    this.search();
  }

  search() {
    let criteria: Criteria = new Criteria();
    criteria.queryItem = this.filterSearch;
    criteria.statusIds = this.filterStatus;
    this.bordereauService
      .getForUser(criteria, {
        page: this.page,
        size: this.itemsPerPage,
        sort: this.sortColumn + "," + this.sortOrder,
      })
      .subscribe(
        (res) => this.onSuccess(res),
        (res) => this.onError(res)
      );
  }

  private onSuccess(data) {
    this.bordereaux = data.bordereaux;
    this.totalbordereaux = data.maxBordereau;
  }
  private onError(error: any) {
    this.messageService.error("bordereaux", error);
  }

  pageChange(event) {
    this.page = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.search();
  }

  telechargerPdf(id: number) {
    this.bordereauService.getPdfBordereau(id).subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.href = downloadURL;
      link.download = "Bordereau" + id + ".pdf";
      link.click();
    });
  }

  declarerAuRndts(bordereauId: number) {
    this.router.navigate(["/bordereaux/rndts/" + bordereauId]);
  }

  closeBstv(bod: Bordereau) {
    bod.prenomNomCloture = `${this.user.firstname} ${this.user.name}`;

    const modalRef = this.modalService.open(ModalBstvCloseComponent, {
      size: "sm",
    });
    modalRef.componentInstance.bordereau = bod;
    modalRef.result
      .then((_result) => {
        this.router.navigate(["/bordereaux"]);
        location.reload(); // #222 : on force le rafraîchissement de la page après la clôture d'un BSTV
      });
  }

  addFilterStatus(status: LexObject) {
    if (!_.includes(this.filterStatus, status.id)) {
      this.filterStatus.push(status.id);
    } else {
      let index = _.indexOf(this.filterStatus, status.id);
      if (index != -1) {
        this.filterStatus.splice(index, 1);
      }
    }
    this.page = 0;
    this.search();
  }

  openDeleteModal(bordereau: Bordereau) {
    const modalRef = this.modalService.open(ModalBstvDeleteComponent, {
      size: "sm",
    });
    modalRef.componentInstance.bordereau = bordereau;
    modalRef.result
      .then((result) => {
        this.search();
      })
      .catch((error) => {});
  }
}
