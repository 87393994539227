import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListBSTV } from '../../model/listbstv.model';
import { Message } from '../../model/message.model';
import { Transaction } from '../../model/transaction.model';
import { TransactionTrace } from '../../model/transactiontrace.model';
import { Criteria } from '../model/criteria.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private resourceUrl = environment.apiUrl + 'transaction';
  private resourceUrlMessage = environment.apiUrl + 'message';
  private resourceUrlTrace = environment.apiUrl + 'transactionTrace';
  private SEPARATOR = '/';
  constructor(private http: HttpClient) { }

  query(criteria: Criteria, isBSTV: string, pageable?: any): Observable<ListBSTV> {
    // let listBstv = new ListBSTV();
    // listBstv.bstv = (bstvDataMock as any).default;
    // listBstv.maxBSTV = 1;
    // return of(listBstv);
    //console.log("TRANSACTION : " + this.resourceUrl);
    //console.log("criteria "+ JSON.stringify(criteria))
    return this.http.post(this.resourceUrl+ "/findall?isBSTV="+ isBSTV, criteria, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listBstv = new ListBSTV();
      listBstv.bstv = this.convertResponse(res.body);
      listBstv.maxBSTV = Number(res.headers.get('X-Total-Count'));
      //console.log("max BSTV", res.headers)
      return listBstv;
    }));
  }

  findById(id: number): Observable<Transaction> {
    return this.http.get(this.resourceUrl+this.SEPARATOR+id).pipe(map((res: Transaction) => res));
  }

  findTraceById(id: number): Observable<TransactionTrace[]> {
    return this.http.get(this.resourceUrlTrace + this.SEPARATOR + "findByTransaction" + this.SEPARATOR + id).pipe(map((res: TransactionTrace[]) => res));
  }

  findLastTraceById(id: number): Observable<TransactionTrace> {
    return this.http.get(this.resourceUrlTrace + this.SEPARATOR + "findByTransaction" + this.SEPARATOR + 'last').pipe(map((res: TransactionTrace) => res));
  }

  findLastStatusChangeByTransactionId(transactionId: number): Observable<TransactionTrace> {
    return this.http.get(`${this.resourceUrlTrace}/transaction/${transactionId}/last-status-change`).pipe(map((transactionTrace: TransactionTrace) => transactionTrace));
  }

  addUserView(transactionTraceId: number, userId: number): void {
    this.http.put(`${this.resourceUrlTrace}/${transactionTraceId}/view-by/${userId}`, null).subscribe();
  }

  findMessageByTransactionId(id: number): Observable<Message[]> {
    return this.http.get(this.resourceUrlMessage+this.SEPARATOR+'findall'+this.SEPARATOR+id).pipe(map((res: Message[]) => res));
  }

  createMessage(message: Message): Observable<Message> {
    return this.http.post(this.resourceUrlMessage+this.SEPARATOR+'create', message).pipe(map((res: Message) => res));
  }

  create(transaction: Transaction): Observable<Transaction>{
    return this.http.post(this.resourceUrl+ this.SEPARATOR+ "create",transaction).pipe(map((res: Transaction) => res ))
  }

  update(transaction: Transaction): Observable<Transaction>{
    return this.http.put(this.resourceUrl+this.SEPARATOR+"update", transaction).pipe(map((res: Transaction) => res));
  }

  uploadFile(file: File): Observable<Transaction> {
    const endpoint = this.resourceUrl + '/uploadfile';
    const formData: FormData = new FormData();
    formData.append('fileKey', file, file.name);
    return this.http.post(endpoint, formData).pipe(map((res) => { return new Transaction().deserialize(res); }));
  }

  downloadFile(id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.http.get(`${this.resourceUrl}/${id}/download`, { headers: headers, responseType: 'blob' });
  }

  private convertResponse(res): Transaction[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
      result.push(new Transaction().deserialize(res[i]));
    }
    return result;
  }

  getBySite(criteria: Criteria, pageable?: any): Observable<ListBSTV> {
    return this.http.post(this.resourceUrl+ "/findBySite?", criteria, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listBstv = new ListBSTV();
      listBstv.bstv = this.convertResponse(res.body);
      listBstv.maxBSTV = Number(res.headers.get('X-Total-Count'));
      return listBstv;
    }));
  }
}
