import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionService } from 'src/app/fr/brgm/common/http/transaction/transaction.service';
import { Message } from 'src/app/fr/brgm/common/model/message.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { Transaction } from 'src/app/fr/brgm/common/model/transaction.model';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnChanges {

  @Input() transaction:Transaction;

  @Input() enabled:Boolean;

  messages:Message[];
  newMessage:Message;
  currentUser:User;

  constructor(public activeModal: NgbActiveModal,
    public transactionService:TransactionService,
    public userService:UserService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {  
      if(propName == 'transaction')
      {
        if(this.transaction) {
          this.transactionService.findMessageByTransactionId(this.transaction.id).subscribe((res) => {
            this.messages = res;
          });
        }
      }
    }
  }

  /*closeModal() {
    this.activeModal.close('Modal Closed');
  }*/

  ngOnInit() {
    this.newMessage = new Message();
    this.userService.getCurrentUser().subscribe((user:User) => {
      this.currentUser = user;
    });
  }

  sendMessage() {
    if(this.newMessage.body) {
      this.newMessage.transaction = this.transaction;
      this.transactionService.createMessage(this.newMessage).subscribe((res) => {
        this.messages.push(res);
        this.newMessage = new Message();
      });
    }
  }

}
