import { Deserializable } from './Deserializable';
import { LexCountry } from './lex/lexcountry.model';

export class Address implements Deserializable {
    constructor(
        public addressId?: number,
        public cedex?:string,
        public cityInsee?:string,
        public foreignCity?:string,
        public numberStreet?:string,
        public postalBox?:string,
        public zipCode?:string,
        public lexCountry?:LexCountry,
    ) {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}