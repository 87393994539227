import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, NgForm } from "@angular/forms";
import { calendar_fr } from "src/app/app.constants";
import { OTV } from "src/app/fr/brgm/common/model/otv.model";

@Component({
  selector: "app-infos-generales",
  templateUrl: "./infos-generales.component.html",
  styleUrls: ["./infos-generales.component.scss"],
})
export class InfosGeneralesComponent implements OnInit {

  @Input() isCreation: boolean;
  @Input() annonce: OTV;
  @Input() childIsOffer: boolean; // Bizarrement, même si on le défini en tant que boolean, l'input est récupéré en tant que string...
  @Input() parentFormSubmitted: boolean;
  @Input() form: NgForm;
  @Input() childIsDuplication: boolean;

  @Output() typeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  fr: any = calendar_fr;
  now: Date;

  constructor() {
    this.now = new Date();
    this.now.setHours(0, 0, 0, 0);
  }

  ngOnInit(): void {
    this.childIsOffer = (this.childIsOffer.toString() === 'true'); // Pour une raison inconnu, c'est un string, donc on le converti en boolean
    this.emitTypeChange();

    if (!this.isCreation) {
      setTimeout(() => {
        this.checkDateValidation();
      }, 2500);
    }
  }

  onTypeChange(isOffer: boolean): void {
    this.childIsOffer = isOffer;
    this.emitTypeChange();
  }

  emitTypeChange() {
    this.typeChange.emit(this.childIsOffer);
  }

  onDateSelect(): void {
    this.checkDateValidation();
  }

  checkDateValidation(): void {
    this.checkStartDateError();
    this.checkEndDateError();
  }

  checkStartDateError(): void {
    if (this.annonce.availStartDate == null) {
      this.getStartDate().setErrors({"required": true});
    } else if (this.annonce.availStartDate < this.now) {
      this.getStartDate().setErrors({"now-inferior": true});
    } else if (this.annonce.availStartDate >= this.annonce.availEndDate) {
      this.getStartDate().setErrors({"superior-end": true});
    } else {
      this.resetStartDateErrors();
    }
  }

  checkEndDateError(): void {
    if (this.annonce.availEndDate == null) {
      this.getEndDate().setErrors({"required": true});
    } else if (this.annonce.availEndDate <= this.annonce.availStartDate) {
      this.getEndDate().setErrors({"inferior-start": true});
    } else {
      this.resetEndDateErrors();
    }
  }

  /* Reset errors */

  resetEndDateErrors(): void {
    this.getEndDate().setErrors(null);
  }

  resetStartDateErrors(): void {
    this.getStartDate().setErrors(null);
  }

  /* Get control */

  getStartDate(): AbstractControl {
    return this.getFormControl("availStartDate");
  }

  getEndDate(): AbstractControl {
    return this.getFormControl("availEndDate");
  }

  getFormControl(controlName: string): AbstractControl {
    return this.form.form.controls[controlName];
  }
}
