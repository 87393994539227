import { Deserializable } from './Deserializable';

export class AnalysisElemResultPK implements Deserializable {
    constructor(public moleculeId?: number,
        public analysisDeclId?: number) {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}