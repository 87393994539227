import { Address } from "./address.model";
import { Deserializable } from "./Deserializable";
import { EntityObject } from "./entityobject.model";
import { LexUserParam } from "./lex/lexuserparam.model";
import { LexUserStatus } from "./lex/lexuserstatus.model";

export class User implements Deserializable {

  constructor(
    public id?: number,
    public commentStatus?: string,
    public createDate?: Date,
    public email?: string,
    public fax?: string,
    public firstname?: string,
    public jobDescription?: string,
    public name?: string,
    public natureId?: number,
    public phone?: string,
    public address?: Address,
    public entityObject?: EntityObject,
    public lexUserStatus?: LexUserStatus,
    public lexUserParams?: LexUserParam[],
    public firstSubscriber?: boolean,
    public cleRndts?: string,
    public cleEtablissement?: string
  ) {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }

}
