<form name="createTransactionForm" #form="ngForm">
    <div class="process-body container">
        <div class="page-header">
            <h1 class="h1">Créer une transaction</h1>
        </div>
        <div class="process-wrapper">
            <nav class="process-nav">
                <ol class="process-nav-list">
                    <li class="process-nav-item" [class.process-nav-item--selected]="currentStep === 1">
                        <a href="#01" class="process-nav-link">
                            <i class="process-nav-icon icon-info" aria-hidden="true"></i>
                            <span class="process-nav-label">Informations générales</span>
                        </a>
                    </li>
                    <li class="process-nav-item" [class.process-nav-item--selected]="currentStep === 2">
                        <a href="#02" class="process-nav-link">
                            <i class="process-nav-icon icon-box" aria-hidden="true"></i>
                            <span class="process-nav-label">Logistique</span>
                        </a>
                    </li>
                    <li class="process-nav-item" [class.process-nav-item--selected]="currentStep === 3">
                        <a href="#03" class="process-nav-link">
                            <i class="process-nav-icon icon-check" aria-hidden="true"></i>
                            <span class="process-nav-label">Validation</span>
                        </a>
                    </li>
                </ol>
            </nav>

            <div class="process-main">
                <fieldset id="01" class="process-section">
                    <legend class="process-section-header">
                        <i class="process-section-icon icon-info" aria-hidden="true"></i>
                        <span class="process-section-title" data-counter="01">Informations générales</span>
                    </legend>
                    <div class="process-section-body">
                        <div class="form-fieldset">
                            <span class="form-label">Période de validité de la transaction</span>
                            <div class="form-group">
                                <div class="form-item form-item--3 transaction-date">
                                    <label>Date de début <span class="red">*</span></label>
                                    <span class="form-date">
                                        <p-calendar [(ngModel)]="bstrStartDate" #startDate="ngModel" name="startDate"
                                          selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                          [ngClass]="{'red-border-class': (startDate.errors && form.submitted) || transactionDateError}"
                                          (onSelect)="onTransactionDateSelect()" required>
                                        </p-calendar>
                                    </span>
                                </div>

                                <div class="form-item form-item--3 transaction-date">
                                    <label>Date de fin <span class="red">*</span></label>
                                    <span class="form-date">
                                        <p-calendar [(ngModel)]="bstrEndDate" #endDate="ngModel" name="endDate"
                                          selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                          [ngClass]="{'red-border-class': (endDate.errors && form.submitted) || transactionDateError}"
                                          (onSelect)="onTransactionDateSelect()" required>
                                        </p-calendar>
                                    </span>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="transactionDateError" class="alert alert-danger">
                          {{ transactionDateError }}
                        </div>

                        <div class="form-fieldset">
                            <div class="form-group">
                                <label class="form-item form-item--6" *ngIf="baseType === 'd'">
                                    <div *ngIf="otvList && otvList.length !== 0">
                                        <span class="form-label">Choisissez l'offre que vous souhaitez lier à cette demande
                                            <span class="red">*</span></span>
                                        <span class="form-select">
                                            <select name="selectOffre" class="form-select-inner" [(ngModel)]="baseOtv"
                                                (change)='onOfferSelected(baseOtv)'>
                                                <option *ngFor="let otv of otvList" [ngValue]="otv">{{otv.id}} |
                                                    {{otv.site.name}}</option>
                                            </select>
                                        </span>
                                    </div>
                                </label>

                                <label class="form-item form-item--6" *ngIf="baseType === 'o'">
                                    <span class="form-label">Choisissez la demande que vous souhaitez lier à cette
                                        offre
                                        <span class="red">*</span></span>
                                    <span class="form-select">
                                        <select name="selectOffre" class="form-select-inner" [(ngModel)]="baseDtv"
                                            (change)='onDemandSelected(baseDtv)'>
                                            <option *ngFor="let dtv of dtvList" [ngValue]="dtv">{{dtv.id}} |
                                                {{dtv.site.name}}</option>
                                        </select>
                                    </span>
                                </label>

                                <div class="form-item form-item--12">

                                    <div class="details-comparison">
                                        <div class="details-comparison-item details-comparison-item--offer"
                                            *ngIf="baseOtv">
                                            <div class="form-group">
                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Offre</p>
                                                    <a class="details-link">{{baseOtv.id}}</a>
                                                </div>

                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Période de disponibilité</p>
                                                    <p>Du &nbsp; <span
                                                            class="details-value">{{baseOtv.availStartDate|date:'dd/MM/yyyy'}}</span>
                                                        &nbsp; &nbsp; Au &nbsp; <span
                                                            class="details-value">{{baseOtv.availEndDate|date:'dd/MM/yyyy'}}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <h3 class="details-subheading">Caractérisation du site producteur</h3>

                                            <div class="form-group">
                                                <div class="form-item form-item--6">
                                                    <p class="details-label">Quantité de terres (t)</p>
                                                    <p class="details-value">{{baseOtv.quantity}}</p>
                                                </div>

                                                <div class="form-item form-item--6">
                                                    <p class="details-label">Type de matériau</p>
                                                    <p class="details-value" *ngIf="baseOtv.lexMaterialType">
                                                        {{baseOtv.lexMaterialType.label}}</p>
                                                </div>

                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Informations complémentaires</p>
                                                    <p class="details-value">{{baseOtv.infos}}</p>
                                                </div>
                                            </div>

                                            <h3 class="details-subheading">Physico chimique des terres</h3>

                                            <div class="form-group">

                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Synthèse des résultats analytiques</p>

                                                    <table class="details-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Molécule</th>
                                                                <th class="details-table-centered">Valeur</th>
                                                                <th class="details-table-centered">
                                                                    < LQ</th>
                                                                <th class="details-table-centered">LQ</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngIf="otvAnalysisResults">
                                                            <tr *ngFor="let result of otvAnalysisResults">
                                                                <td *ngIf="result.lexMolecule">
                                                                    {{result.lexMolecule.name}}
                                                                </td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="result.infQl">-</td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="!result.infQl">{{result.value}}</td>
                                                                <td class="details-table-centered blue"><i
                                                                        class="icon-check" *ngIf="result.infQl"></i>
                                                                </td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="!result.infQl">-</td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="result.infQl">{{result.quantifyLimit}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-comparison-item details-comparison-item--offer"
                                            style="text-align: center;" *ngIf="!baseOtv">
                                            <h3 class="details-subheading">Veuillez sélectionner une offre dans la liste
                                                ci-dessus</h3>
                                        </div>

                                        <div class="details-comparison-item details-comparison-item--demand"
                                            *ngIf="baseDtv">
                                            <div class="form-group">
                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Demande</p>
                                                    <a class="details-link">{{baseDtv.id}}</a>
                                                </div>

                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Période de disponibilité</p>
                                                    <p>Du &nbsp; <span
                                                            class="details-value">{{baseDtv.availStartDate|date:'dd/MM/yyyy'}}</span>
                                                        &nbsp; &nbsp;
                                                        Au &nbsp; <span
                                                            class="details-value">{{baseDtv.availEndDate|date:'dd/MM/yyyy'}}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <h3 class="details-subheading">Caractérisation du site receveur</h3>

                                            <div class="form-group">
                                                <div class="form-item form-item--6">
                                                    <p class="details-label">Quantité de terres (t)</p>
                                                    <p class="details-value">{{baseDtv.quantity}}</p>
                                                </div>

                                                <div class="form-item form-item--6">
                                                    <p class="details-label">Type de matériau</p>
                                                    <p class="details-value" *ngIf="baseDtv.lexMaterialType">
                                                        {{baseDtv.lexMaterialType.label}}</p>
                                                </div>

                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Informations complémentaires</p>
                                                    <p class="details-value">{{baseDtv.infos}}</p>
                                                </div>
                                            </div>

                                            <h3 class="details-subheading">Physico chimique du site receveur</h3>

                                            <div class="form-group">

                                                <div class="form-item form-item--12">
                                                    <p class="details-label">Synthèse des résultats analytiques</p>

                                                    <table class="details-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Molécule</th>
                                                                <th class="details-table-centered">Valeur</th>
                                                                <th class="details-table-centered">
                                                                    < LQ</th>
                                                                <th class="details-table-centered">LQ</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngIf="dtvAnalysisResults">
                                                            <tr *ngFor="let result of dtvAnalysisResults">
                                                                <td *ngIf="result.lexMolecule">
                                                                    {{result.lexMolecule.name}}
                                                                </td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="result.infQl">-</td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="!result.infQl">{{result.value}}</td>
                                                                <td class="details-table-centered blue"><i
                                                                        class="icon-check" *ngIf="result.infQl"></i>
                                                                </td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="!result.infQl">-</td>
                                                                <td class="details-table-centered blue"
                                                                    *ngIf="result.infQl">{{result.quantifyLimit}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-comparison-item details-comparison-item--demand"
                                            style="text-align: center;" *ngIf="!baseDtv">
                                            <h3 class="details-subheading">Veuillez sélectionner une demande dans la
                                                liste
                                                ci-dessus</h3>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-item form-item--12">
                                    <span class="form-label">
                                        <b>Attention, l’adéquation entre le site producteur et le site receveur n’est pas
                                        vérifié par TERRASS.<br>
                                        Il est de votre responsabilité de vérifier la conformité entre les terres
                                        d’apport
                                        et leur usage futur.</b>
                                    </span>
                                    <label class="form-checkbox" [ngClass]="{'red-border-class': !verificationCheckBox && form.submitted}">
                                        <input required [(ngModel)]="verificationCheckBox" name="verificationCheckbox" type="checkbox" class="form-checkbox-input">
                                        <span class="form-checkbox-label"><b>J’ai vérifié la compatibilité entre le site
                                            producteur et le site receveur.</b></span> <span class="red">*</span>
                                    </label>
                                    <div *ngIf="!verificationCheckBox && form.submitted">
                                        <span class="red">Veuillez vérifier la compatibilité puis cocher la case</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <fieldset id="02" class="process-section" [disabled]="currentStep < 2">
                    <legend class="process-section-header">
                        <i class="process-section-icon icon-box"></i>
                        <span class="process-section-title" data-counter="02">Logistique</span>
                    </legend>
                    <div class="process-section-body padding-for-calendar">
                        <div class="form-fieldset">
                            <div class="form-fieldset-header">
                                <h3 class="h3 form-fieldset-title">Transporteur</h3>
                            </div>
                            <div class="form-group">
                                <div class="form-item form-item--end form-item--12">
                                    <label class="form-checkbox form-checkbox--margin">
                                        <input type="checkbox" class="form-checkbox-input" [ngModelOptions]="{standalone: true}" [(ngModel)]="choixTransporter" (click)="checkChoixTransport($event)">
                                        <span class="form-checkbox-label">Je ne souhaite pas prendre en charge le
                                            transport.</span>
                                    </label>
                                </div>
                                <div class="form-item form-item--12" *ngIf="!choixTransporter">
                                    <span class="form-label">Choisissez un ou plusieurs transporteurs</span>
                                    <div *ngIf="transportersList" style="width: 100%; overflow-y: auto; height: 150px; border: 2px solid lightgrey;">
                                        <div *ngFor="let t of transportersList" >
                                            <label class="form-checkbox form-checkbox--margin">
                                            <input type="checkbox" class="form-checkbox-input" name="t.id" [value]="t.id" [checked]="isChecked(t.id)" (change)="getSelectedTransporter($event, t)">
                                            <span class="form-checkbox-label">{{t.name}}, {{t.address.numberStreet}}, {{t.address.zipCode}}
                                                {{t.address.foreignCity}}</span>
                                        </label>
                                        </div>
                                    </div>
                                </div>

                                <label class="form-item form-item--6">
                                    <span class="form-label">Quantité à transporter (t) <span class="red">*</span></span>

                                    <span class="form-item-inner">
                                        <span class="form-unit">t</span>
                                        <input name="quantity" [(ngModel)]="transaction.quantity" type="text" class="form-input" required
                                        #quantite="ngModel" name="quantite" [ngClass]="{'red-border-class': quantite.errors && form.submitted}">
                                    </span>
                                </label>
                            </div>
                            <div class="form-group">
                                <div class="form-item form-item--3 transaction-date">
                                    <label>Date de début réception <span class="red">*</span></label>
                                    <span class="form-date">
                                        <p-calendar [(ngModel)]="receptionStartDate" #rStartDate="ngModel" name="rStartDate"
                                          selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                          [ngClass]="{'red-border-class': (rStartDate.errors && form.submitted) || receptionDateError}"
                                          (onSelect)="onReceptionDateSelect()" required>
                                        </p-calendar>
                                    </span>
                                </div>

                                <div class="form-item form-item--3 transaction-date">
                                    <label>Date de fin réception <span class="red">*</span></label>
                                    <span class="form-date">
                                        <p-calendar [(ngModel)]="receptionEndDate" #rEndDate="ngModel" name="rEndDate"
                                          selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                          [ngClass]="{'red-border-class': (rEndDate.errors && form.submitted) || receptionDateError}"
                                          (onSelect)="onReceptionDateSelect()" required>
                                        </p-calendar>
                                    </span>
                                </div>

                                <div *ngIf="receptionDateError" class="alert alert-danger">
                                  {{ receptionDateError }}
                                </div>

                            </div>
                        </div>

                        <hr class="form-separator">


                        <div class="form-fieldset">
                            <div class="form-fieldset-header">
                                <h3 class="h3 form-fieldset-title">Statut de déchets</h3>
                            </div>

                            <div class="form-group">
                                <div class="form-item form-item--12">
                                    <label class="form-checkbox">
                                        <input type="checkbox" class="form-checkbox-input" [ngModelOptions]="{standalone: true}" [(ngModel)]="choixStatutDechet" (change)="checkChoixStatutDechet($event)">
                                        <span class="form-checkbox-label">
                                          Je souhaite réaliser une sortie de statut de déchets pour les terres excavées,
                                          en conformité avec l'arrêté du 4 juin 2021 fixant les critères de sortie du statut de déchets
                                          pour les terres excavées et sédiments ayant fait l'objet d'une préparation en vue d'une utilisation en génie civil ou
                                          en aménagement
                                        </span>
                                    </label>
                                </div>

                                <div class="form-item form-item--12" *ngIf="choixStatutDechet">
                                    <span class="form-label">Quel guide appliquez-vous pour la sortie de statut de déchets ?</span>
                                    <ul class="form-list" *ngFor="let guide of lexGuideDechets">
                                        <li class="form-list-item">
                                            <label class="form-radio">
                                                <input type="radio" name="guide.id" [value]="guide.id" class="form-radio-input"
                                                [(ngModel)]="selectedGuideId" (change)="setLexGuideDechet(guide)">
                                                <span class="form-radio-label">{{guide.label}}</span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </fieldset>

                <fieldset id="03" class="process-section" [disabled]="currentStep < 3">
                    <legend class="process-section-header">
                        <i class="process-section-icon icon-check"></i>
                        <span class="process-section-title" data-counter="03">Validation</span>
                    </legend>
                    <div class="process-section-body">
                        <div class="form-fielset">
                            <div class="form-group">
                                <label class="form-item form-item--12">
                                    <span class="form-label">Ajouter un message lors de l’envoi de la proposition de
                                        transaction</span>
                                    <textarea #recommendations=ngModel [(ngModel)]="message.body" name="recommendations"
                                        placeholder="Bonjour, je suis intéressé par votre offre…"
                                        class="form-textarea"></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>

    <div class="process-footer">
        <div class="container">
            <div class="process-controls">
                <button type="button" class="button button--teal button--outline">
                    <i class="button-icon icon-left"></i>
                    <span class="button-label">Précédent</span>
                </button>

                <div class="process-steps">
                    <span class="process-steps-current">Étape <span class="process-steps-current-number">{{currentStep}}</span></span>
                    <span class="process-steps-total">/ 3</span>

                    <h3 class="process-steps-title">Informations générales</h3>
                </div>

                <button type="button" class="button button--teal" (click)="createTransaction(form)" *ngIf="currentStep < 3">
                    <span class="button-label">Suivant</span>
                    <i class="button-icon icon-right"></i>
                </button>
                <button type="submit" class="button button--teal" (click)="createTransaction(form)" *ngIf="currentStep === 3">
                    <span class="button-label" *ngIf="currentStep === 3">Créer transaction</span>
                    <i class="button-icon icon-right"></i>
                </button>
                <div *ngIf="form.submitted" class="red">
                  <div *ngIf="form.form.status === 'INVALID'">
                    Veuillez compléter les champs obligatoires avant de continuer<br>
                  </div>
                  <div *ngIf="transactionDateError">
                    Veuillez vérifier les dates de transaction avant de continuer<br>
                  </div>
                  <div *ngIf="receptionDateError">
                    Veuillez vérifier les dates de réception avant de continuer<br>
                  </div>
                </div>
            </div>
        </div>

        <div class="process-progress">
            <div class="process-progress-bar" [style.width]="progression+'%'"></div>
            <span class="process-progress-label">{{progression}}%</span>
        </div>
    </div>
</form>
