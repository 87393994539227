import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { AnalysisService } from 'src/app/fr/brgm/common/http/analysis/analysis.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { TransactionService } from 'src/app/fr/brgm/common/http/transaction/transaction.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { LexTransactionStatus } from 'src/app/fr/brgm/common/model/lex/lextransactionstatus.model';
import { Transaction } from 'src/app/fr/brgm/common/model/transaction.model';
import { TransactionTrace } from 'src/app/fr/brgm/common/model/transactiontrace.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { ModalTransactionAcceptComponent } from 'src/app/modal/modal-transaction-accept/modal-transaction-accept.component';

@Component({
  selector: 'app-view-transaction-page',
  templateUrl: './view-transaction-page.component.html',
  styleUrls: ['./view-transaction-page.component.scss']
})
export class ViewTransactionPageComponent implements OnInit {

  transaction: Transaction;
  transactionTrace: TransactionTrace[];
  lexTransactionStatus: LexTransactionStatus[];
  userCreator: User;
  currentUser: User;
  otvAnalysisResults: AnalysisElemResult[];
  dtvAnalysisResults: AnalysisElemResult[];
  isAwaitedForAction: boolean;
  isUserOfTransaction: boolean;

  constructor(private transactionService: TransactionService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private lexService: LexiqueService,
    private analysisService: AnalysisService,
    private userService: UserService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    });
    this.lexService.getLexTransactionStatus().subscribe((res) => this.lexTransactionStatus = res);
    this.activatedroute.params.subscribe(params => {

      this.transactionService.findById(params.id).subscribe(res => {
        this.transaction = res;
        this.userService.getCurrentUser().subscribe((res) => {
          this.currentUser = res;
          this.setIsAwaitedForAction();
          this.setIsUserOfTransaction();
      });
        this.getAnalysisForOffer(this.transaction.offer.id);
        this.getAnalysisForDemand(this.transaction.demand.id);
      });
      this.transactionService.findTraceById(params.id).subscribe(res => {
        this.transactionTrace = res;

        let creationTrace = this.transactionTrace[this.transactionTrace.length - 1];
        if (creationTrace.userId == creationTrace.transaction.offer.user.id) {
          this.userCreator = creationTrace.transaction.offer.user;
        } else if (creationTrace.userId == creationTrace.transaction.demand.user.id) {
          this.userCreator = creationTrace.transaction.demand.user;
        }
      });
    });
  }

  getAnalysisForOffer(id: number) {
    this.analysisService.getAnalysisResults(id, "demand").subscribe((results) => {
      this.dtvAnalysisResults = results;
      if (this.dtvAnalysisResults) {
      this.lexService.getLexMolecules().subscribe((molecules) => {
        this.dtvAnalysisResults.forEach((analysisResult) => {
          analysisResult.lexMolecule = _.find(molecules, ["id", analysisResult.id.moleculeId]);
        });
      });
    }
    });
  }

  getAnalysisForDemand(id: number) {
    this.analysisService.getAnalysisResults(id, "offer").subscribe((results) => {
      this.otvAnalysisResults = results;
      if (this.otvAnalysisResults) {

        this.lexService.getLexMolecules().subscribe((molecules) => {
          this.otvAnalysisResults.forEach((analysisResult) => {
            analysisResult.lexMolecule = _.find(molecules, ["id", analysisResult.id.moleculeId]);
        });
      });
    }
    });
  }

  setIsAwaitedForAction() {
    if(this.transactionTrace) {
      this.isAwaitedForAction = this.currentUser.id == this.transactionTrace[0].userId ? false: true;
    }
  }

  setIsUserOfTransaction(){
    this.isUserOfTransaction = this.currentUser.id == this.transaction.offer.user.id
    || this.currentUser.id == this.transaction.demand.user.id ? true: false;
  }

  accepterTransaction(transac: Transaction){
    const modalRef = this.modalService.open(ModalTransactionAcceptComponent, { size: 'sm' });
    modalRef.componentInstance.transaction = this.transaction;
    modalRef.componentInstance.lexTransactionStatus = this.lexTransactionStatus;
    modalRef.componentInstance.currentUser = this.currentUser;

    modalRef.result.then((res) => {
      //Si l'utilisateur courant est le producteur on le redirige vers la création du BSTV
      if(this.transaction.offer.user.id == this.currentUser.id){
        this.router.navigate(['/bordereaux/create/'+res.id]);
      }
      else{
        this.router.navigateByUrl('/transactions');
      }
    }).catch((error) => {
    });
  }

  annulerTransaction(transaction: Transaction) {
    this.updateTransactionStatus(transaction, this.lexTransactionStatus.find(ts => ts.id === 3));
  }

  refuserTransaction(transaction: Transaction) {
    this.updateTransactionStatus(transaction, this.lexTransactionStatus.find(ts => ts.id === 4));
  }

  private updateTransactionStatus(transaction: Transaction, status: LexTransactionStatus) {
    let tempTransaction: Transaction = transaction;
    tempTransaction.lexTransactionStatus = status;
    this.transactionService.update(tempTransaction).subscribe((res) => {
      this.router.navigateByUrl('/transactions/details/'+res.id);
    })
  }

  createBordereau(transac: Transaction) {
    this.router.navigate(['/bordereaux/create/'+transac.id]);
  }

  canAcceptOrRefuseTransaction(): boolean {
    return (this.transaction.lexTransactionStatus.id === 1) && this.transaction.currentUserCanAccepteOrRefuse;
  }

  currentUserIsClaimerOrSupplier(): boolean {
    return (this.transaction.demand.user.id === this.currentUser.id || this.transaction.offer.user.id === this.currentUser.id);
  }

}
