import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';
import { Deserializable } from '../model/Deserializable';

export class Lot implements Deserializable {

    constructor(
        public id?: number,
        public siteExitDate?:Date,
        public lot?:number,
        public quantite?:number,
        public volume?:number,
        public siretTransporteur?:string,
        public nomTransporteur?:string,
        public immatriculationTransporteur?:string,
        public bordereauId?:number,
        public transporteur?:Transporter
    ) {}

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}
