import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BordereauService } from 'src/app/fr/brgm/common/http/bodereau/bordereau.service';
import { Bordereau } from 'src/app/fr/brgm/common/model/bordereau.model';

@Component({
  selector: 'app-modal-bstv-delete',
  templateUrl: './modal-bstv-delete.component.html',
  styleUrls: ['./modal-bstv-delete.component.scss']
})
export class ModalBstvDeleteComponent implements OnInit {

  @Input() bordereau: Bordereau;

  constructor(
    public activeModal: NgbActiveModal,
    private bordereauService: BordereauService,
    private router: Router
    ) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  onSubmit() {
      this.bordereauService.delete(this.bordereau).subscribe((res) => this.activeModal.close());
  }

}
