import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';
import { APP_PREFIX } from '../app.constants';
import { IsSignedInGuard } from '../fr/brgm/common/guards/is-signed-in.guard';
import { DtvPageComponent } from './dtv-page/dtv-page.component';
import { DuplicateDemandePageComponent } from "./duplicate-demande-page/duplicate-demande-page.component";
import { EditDemandePageComponent } from './edit-demande-page/edit-demande-page.component';
import { ViewDemandePageComponent } from './view-demande-page/view-demande-page.component';

const demandesRoutes: Routes = [
    {path: 'demandes', component: DtvPageComponent, data: {title: APP_PREFIX  + 'Demandes'}, canActivate: [ IsSignedInGuard] },
    {path: 'demandes/details/:id', component: ViewDemandePageComponent, data: {title: APP_PREFIX  + 'Demande'}, canActivate: [ IsSignedInGuard] },
    {path: 'demandes/edit/:id', component: EditDemandePageComponent, data: {title: APP_PREFIX  + 'Modification de demande'}, canActivate: [ IsSignedInGuard] },
    {path: 'demandes/duplicate/:id', component: DuplicateDemandePageComponent, data: {title: APP_PREFIX + 'Duplication de demande'}, canActivate: [ IsSignedInGuard]}
  ];

@NgModule({
    imports: [
        RouterModule.forChild(demandesRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class DemandesRoutingModule { }