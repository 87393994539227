import { Deserializable } from './Deserializable';
import { FondPedogeochimiqueAnalysisPK } from './fondpedogeochimiqueanalysispk.model';
import { LexMolecule } from './lex/lexmolecule.model';

export class FondPedogeochimiqueAnalysis implements Deserializable {
    constructor(
        public id?: FondPedogeochimiqueAnalysisPK,
        public infQl?: boolean,
        public quantifyLimit?:number,
        public value?: number,
        public lexMolecule?:LexMolecule
    ) {
        this.infQl = false;
        this.id = new FondPedogeochimiqueAnalysisPK();
    }

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}