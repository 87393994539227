<div class="wrapper">

    <div class="container">
        <div class="page-header">
            <h1 class="h1">Transporteurs</h1>
            <a routerLink="/carriers/create" routerLinkActive="active" class="button button--blue">
                <i class="button-icon icon-add"></i>
                <span class="button-label" style="color: #FFF">Ajouter un transporteur</span>
            </a>
        </div>
    
        <div class="table-wrapper">
            <div class="table-filters">
                <span class="table-filters-results">
                    <i class="table-filters-results-icon icon-box"></i>
                    {{totalTransporters}} Transporteurs
                </span>
                <ul class="table-filters-list">
                    <li class="table-filters-item" *ngFor="let status of transporterStatus">
                        <label class="table-filters-button has-tooltip">
                            <input type="checkbox" class="table-filters-input" (change)="addFilterStatus(status)">
                            <span class="table-filters-label">{{status.label}}</span>
    
                            <span class="tooltip tooltip--center">Filtrer par transporteurs {{status.label}}</span>
                        </label>
                    </li>
                    <li class="table-filters-item">
                        <label class="table-filters-search-item">
                            <input type="text" placeholder="Nom du transporteur"
                                class="table-filters-search-input" [(ngModel)]="filterSearch" (blur)="search()"
                                (keyup.enter)="search()">
                            <i class="table-filters-search-icon icon-search"></i>
                        </label>  
                    </li>
                </ul>
            </div>

            <p-table tableStyleClass="table" [resetPageOnSort]="false" [sortOrder]="1" [value]="myTransporters" (onLazyLoad)="loadLazy($event)" [paginator]="false" [lazy]="true" [totalRecords]="totalTransporters" dataKey="id" [tableStyle]="{'table-layout':'auto'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'name'" class="table-sortable">Nom du transporteur</th>
                        <th [pSortableColumn]="'lexTransportMode.label'" class="table-sortable">Mode de transport</th>
                        <th [pSortableColumn]="'address.numberStreet'" class="table-sortable">Localisation</th>
                        <th [pSortableColumn]="'email'" class="table-sortable">Contact</th>
                        <th [pSortableColumn]="'lexTransporterStatus.label'" class="table-sortable">Statut</th>
                        <th>Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-transporter>
                    <tr>
                        <td>
                            {{transporter.name}}
                        </td>
                        <td>
                            <span class="table-tag" [ngClass]="[transporter.lexTransportMode?.id == 1 ? 'table-tag--teal' : 'table-tag--orange' ]">{{transporter.lexTransportMode?.label}}</span>
                        </td>
                        <td>{{transporter.address?.numberStreet}}, {{transporter.address?.zipCode}} {{transporter.address?.foreignCity}}</td>
                        <td class="black">
                            {{transporter.email}}<br>
                            {{transporter.phoneNumber}}
                        </td>
                        <td>
                            <b [class.green]="transporter.lexTransporterStatus?.id == 1"
                            [class.orange]="transporter.lexTransporterStatus?.id == 2">
                            {{transporter.lexTransporterStatus?.label}}</b>
                        </td>
                        <td>
                            <div class="table-actions">
                                <button type="button" class="table-actions-toggle"></button>
                                <ul class="table-actions-list">
                                    <li class="table-actions-item">
                                        <a [routerLink]="['/carriers/details',transporter.id]" routerLinkActive="active" class="table-actions-link blue">
                                            <i class="table-actions-icon icon-eye"></i>
                                            <span class="table-actions-label">Consulter</span>
                                        </a>
                                    </li>
                                    <li class="table-actions-item">
                                        <a [routerLink]="['/carriers/edit',transporter.id]" routerLinkActive="active" class="table-actions-link orange" *ngIf="transporter.lexTransporterStatus?.label != 'Archivé'">
                                            <i class="table-actions-icon icon-edit"></i>
                                            <span class="table-actions-label">Modifier</span>
                                        </a>
                                    </li>
                                    <li class="table-actions-item">
                                        <a class="table-actions-link lightblue" *ngIf="transporter.lexTransporterStatus?.label === 'Actif'" (click)="archiver(transporter)">
                                            <i class="table-actions-icon icon-open-folder"></i>
                                            <span class="table-actions-label">Archiver</span>
                                        </a>
                                        <a class="table-actions-link lightblue" *ngIf="transporter.lexTransporterStatus?.label === 'Archivé'" (click)="activer(transporter)">
                                            <i class="table-actions-icon icon-open-folder"></i>
                                            <span class="table-actions-label">Activer</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">
                            Aucun transporteur.
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <app-pagination [length]="totalTransporters" [index]="page" [size]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (page)="pageChange($event)"></app-pagination>

        </div>
    </div>
</div>
