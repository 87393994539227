import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-create-offre-page',
  templateUrl: './create-offre-page.component.html',
  styleUrls: ['./create-offre-page.component.scss']
})
export class CreateOffrePageComponent implements OnInit {

	isOffer = true;
	isCreation: boolean;

	constructor(
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params: Params) => this.isOffer = params['offer']);
  }

	ngOnInit(): void {
		this.titleService.setTitle('Terrass - Création d\'annonce');
		this.isCreation = true;
	}

}
