import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SiretAddress } from '../../model/siret-address';

@Injectable({
  providedIn: 'root'
})
export class SireneService {

  private resourceUrl = environment.apiUrl + 'sirene';
  private SEPARATOR = '/';
  constructor(private http: HttpClient) { }

  getSiretAddress(siret: string): Observable<SiretAddress> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + siret).pipe(map((siretAddress: SiretAddress) => siretAddress));
  }
}
