import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Featurecollection } from '../../model/featurecollection.model';

@Injectable({
  providedIn: 'root'
})
export class ApiAdresseService {

  private resourceUrl = environment.apiUrl + 'apiAdresse';
  private SEPARATOR = '/';
  constructor(private http: HttpClient) { }

  getAdresseByTexte(q: string): Observable<Featurecollection> {
    return this.http.get(this.resourceUrl + this.SEPARATOR + q).pipe(map((res:Featurecollection) => res));
  }
}
