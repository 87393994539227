<div class="modal">
  <div class="modal-inner">

    <button type="button" class="modal-close icon-close" (click)="onClose()"></button>

    <div class="modal-body">

      <h2 class="h1 modal-title">Statut de {{ isOffer ? "l'offre" : "la demande" }}</h2>

      <p class="modal-text">Quel statut voulez-vous attribuer à cette {{ isOffer ? "offre" : "demande" }} ?</p>

      <div class="form-footer">
        <button type="button" class="button button--grey" (click)="onDraftClick()">
          <i class="button-icon icon-edit" aria-hidden="true"></i>
          <span class="button-label">Brouillon</span>
        </button>
        <button type="button" class="button button--green" (click)="onPublishedClick()">
          <i class="button-icon icon-check" aria-hidden="true"></i>
          <span class="button-label">Publiée</span>
        </button>
      </div>
    </div>

  </div>
</div>
