import { Deserializable } from "./Deserializable";
import { LexAnalysisType } from "./lex/lex-analysis-type.model";

export class AnalysisType implements Deserializable {

  constructor(
    public lexAnalysisType?: LexAnalysisType,
    public detail?: string
  ) {}

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
}
}
