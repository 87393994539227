import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';
import { Criteria } from '../../fr/brgm/common/http/model/criteria.model';
import { ListTransporter } from '../../fr/brgm/common/model/listtransporter';
import { Transporter } from '../../fr/brgm/common/model/transporter.model';

@Injectable({
  providedIn: 'root'
})
export class TransporterService {

  public transporterData = [];
  private transporterUrl =  environment.apiUrl + 'transporter';
  private SEPARATOR =   '/';
  constructor(private http: HttpClient) { }

  getTransporters(criteria:Criteria, pageable?: any): Observable<ListTransporter> {
    return this.http.post(this.transporterUrl + this.SEPARATOR + "findall", criteria, {params: pageable, observe: 'response'}).pipe(map((res) => {
      // console.log("res", JSON.stringify(res.body))
      let listTransporter= new ListTransporter();
      listTransporter.transporter = this.convertResponse(res.body);
      listTransporter.maxTransporter = Number(res.headers.get('X-Total-Count'));
      //console.log("TOTAL COUNT "+ JSON.stringify(res.headers))
      return listTransporter;
    }));
  }

  getBordereauTransporters(bordereauId: number): Observable<Transporter[]> {
    return this.http.get(`${this.transporterUrl}/bordereau/${bordereauId}`).pipe(map((transporteurs: Transporter[]) => transporteurs));
  }

  get(id: number): Observable<Transporter> {
    return this.http.get( this.transporterUrl + this.SEPARATOR + id).pipe(map((res:Transporter)=> res));
  }

  create(transporter:Transporter):Observable<Transporter> {
    return this.http.post(this.transporterUrl + this.SEPARATOR+'create',transporter).pipe(map((res:Transporter)=> res));
  }

  update(transporter:Transporter): Observable<Transporter> {
    return this.http.put(this.transporterUrl+this.SEPARATOR+'update', transporter).pipe(map((res: Transporter)=> res));
  }

  private convertResponse(res): Transporter[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new Transporter().deserialize(res[i]));
    }
    return result;
  }
}
