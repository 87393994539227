import { Component, OnInit } from '@angular/core';
import { Criteria } from 'src/app/fr/brgm/common/http/model/criteria.model';
import * as _ from 'lodash';  
import { LexTransporterStatus } from 'src/app/fr/brgm/common/model/lex/lextransporterstatus.model';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { MessageService } from 'src/app/00-shared/message/message.service';
import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';
import { TransporterService } from 'src/app/00-shared/services/transporter.service';

@Component({
  selector: 'app-carriers-page',
  templateUrl: './carriers-page.component.html',
  styleUrls: ['./carriers-page.component.scss']
})
export class CarriersPageComponent implements OnInit {

  myTransporters:Transporter[];

  transporterStatus: LexTransporterStatus[];

  totalTransporters:number;
  itemsPerPage:number;
  page:any;
  pageSizeOptions = [5, 10, 15, 20];
  sortColumn:string;
  sortOrder:string;

  filterStatusExpired:boolean;
  filterStatus:any[];
  filterDate:Date[];
  filterSearch:string;

  constructor(private titleService: Title,
    private activatedroute: ActivatedRoute,
    private lexiqueService: LexiqueService,
    private transporterService: TransporterService,
    private messageService: MessageService) { 
      this.totalTransporters = 0;
      this.page = 0;
      this.itemsPerPage = this.pageSizeOptions[0];
      this.sortColumn = "name";
      this.transporterStatus = [];
      // this.filterStatusExpired = false;
      this.filterSearch = "";
      this.filterStatus = [];
    }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    });
    this.lexiqueService.getLexTransporterStatus().subscribe((res) => this.transporterStatus = res);
  }

  addFilterStatus(status:LexTransporterStatus) {
    if(!_.includes(this.filterStatus, status.id)) { 
      this.filterStatus.push(status.id);
    } else {
      let index = _.indexOf(this.filterStatus, status.id);
      if(index != -1) {
        this.filterStatus.splice(index,1);
      }
    }
    this.page = 0;
    this.search();
  }

  addExpired() {
    this.search();
  }

  loadLazy(event) {
    this.page = event.first/ this.itemsPerPage;
    if(event.sortField)
      this.sortColumn = event.sortField;
    if (event.sortOrder == 1)
    {
      this.sortOrder = "ASC";
      this.page = 0;
    }
    else {
      this.sortOrder = "DESC";
      this.page = 0;
    }
    this.search();
  }

  search() {
    let criteria:Criteria = new Criteria();
    criteria.queryItem = this.filterSearch;
    criteria.statusIds = this.filterStatus;
    // criteria.date = this.filterDate;
    // criteria.isExpired = this.filterStatusExpired;
    this.transporterService.getTransporters(criteria, {
      page: this.page,
      size: this.itemsPerPage,
      sort: this.sortColumn + "," + this.sortOrder,
    }).subscribe(
      (res) => this.onSuccess(res),
      (res) => this.onError(res)
    );
  }

  private onSuccess(data) {
    this.myTransporters = data.transporter;
    this.totalTransporters = data.maxTransporter;
  }
  private onError(error:any) {
    this.messageService.error('dtv', error);
  }

  pageChange(event) {
    this.page = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.search();
    //console.log(event);
  }

  archiver(transporter: Transporter) {
    let tempTransporter = transporter;
    tempTransporter.lexTransporterStatus = this.transporterStatus.find(status => status.label.toLowerCase() === "archivé");
    this.transporterService.update(tempTransporter).subscribe((res) => { this.search() });
  }

  activer(transporter:Transporter){
    let tempTransporter = transporter;
    tempTransporter.lexTransporterStatus = this.transporterStatus.find(status => status.label.toLowerCase() === "actif");
    this.transporterService.update(tempTransporter).subscribe((res) => { this.search() });
  }

}
