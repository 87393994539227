import { Component, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChatComponent } from "src/app/00-shared/chat/chat.component";
import { Criteria } from "src/app/fr/brgm/common/http/model/criteria.model";
import { SiteService } from "src/app/fr/brgm/common/http/site/site.service";
import { ListSite } from "src/app/fr/brgm/common/model/listsite.model";
import { User } from "src/app/fr/brgm/common/model/user.model";
import { ModalSetConfidentialCodeComponent } from "src/app/modal/modal-set-confidential-code/modal-set-confidential-code.component";
import { environment } from "../../../environments/environment";
import { AuthService } from "./../../fr/brgm/common/http/auth/auth.service";
import { UserService } from "./../../fr/brgm/common/http/user/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {

  homeEndpoint = "/accueil";
  offerEndpoint = "/offres";
  demandEndpoint = "/demandes";

  currentUser: User;
  isProd: any = environment.production;
  createOffer: boolean;

  texteRecette =
    "Il s’agit ici d’un site de recette utilisé pour réaliser des tests avec une communauté d’utilisateurs identifiée. Il ne s’agit pas d’une application en production.";
  texteProduction =
    "Cette application est en phase Bêta, elle est actuellement en cours de tests par une communauté d’utilisateurs et est susceptible d’évoluer dans les semaines à venir";

  noSite: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
    private siteService: SiteService
  ) {
    this.currentUser = null;
  }

  ngOnInit(): void {
    this.setCreateOffer();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.updateNoSite();
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setCreateOffer();
        this.noSite = false;
      }
    });


  }

  showConfidentialCodeButton(): boolean {
    return [this.homeEndpoint, this.offerEndpoint, this.demandEndpoint].includes(this.router.url);
  }

  private setCreateOffer() {
    this.createOffer = !this.router.url.includes(this.demandEndpoint);
  }

  private updateNoSite() {
    let criteria: Criteria = new Criteria();

    this.siteService.getAll(criteria,
      {
        page: 0,
        size: 10,
        sort: ['lastModification,DESC', 'id,DESC']
      }
    ).subscribe((res: ListSite) => {
      if (res.maxSite === 0) {
        this.noSite = true;
      }
    });
  }

  logout() {
    this.authService.logout();
    this.currentUser = null;
    this.router.navigate(["login"]);
  }

  displayLoggedInMenu() {
    let connected = localStorage.getItem("Bearer") === null ? false : true;
    if (connected && this.currentUser == null) {
      this.userService.getCurrentUser().subscribe(
        (user: User) => (this.currentUser = user),
        (err) => this.logout()
      );
    }
    return connected;
  }

  openMessagerieModal() {
    const modalRef = this.modalService.open(ChatComponent);
    modalRef.componentInstance.id = 10; // should be the id
    modalRef.result
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  getUserFullName(): string {
    return (this.currentUser == null)
      ? ""
      : `${this.currentUser.firstname} ${this.currentUser.name}`;
  }

  onConfidentialCodeClick() {
    if (this.currentUser == null) {
      this.userService.getCurrentUser().subscribe(
        (user: User) => this.openConfidentialCodeModal(user),
        (err) => {
          console.error(err);
          this.logout();
        }
      );
    } else {
      this.openConfidentialCodeModal(this.currentUser);
    }
  }

  private openConfidentialCodeModal(user: User) {
    const modalRef = this.modalService.open(ModalSetConfidentialCodeComponent);
    modalRef.componentInstance.userId = user.id;
  }

}
