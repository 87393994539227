import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TransporterService } from 'src/app/00-shared/services/transporter.service';
import { ChatModule } from './00-shared/chat/chat.module';
import { FooterComponent } from './00-shared/footer/footer.component';
import { HeaderComponent } from './00-shared/header/header.component';
import { MapModule } from './00-shared/map/map.module';
import { MessageModule } from './00-shared/message/message.module';
import { NotificationsPageComponent } from './00-shared/notifications-page/notifications-page.component';
import { PaginationModule } from './00-shared/pagination/pagination.module';
import { PrimengModule } from './00-shared/primeng/primeng.module';
import { SitesModule } from './01-sites/sites.module';
import { TransporteursModule } from './02-transporteurs/transporteurs.module';
import { AccueilModule } from './03-accueil/accueil.module';
import { OffresModule } from './04-offres/offres.module';
import { DemandesModule } from './05-demandes/demandes.module';
import { TransactionsModule } from './06-transactions/transactions.module';
import { BordereauxModule } from './07-bordereaux/bordereaux.module';
import { UsersModule } from './10-users/users.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DtvService } from './fr/brgm/common/http/dtv/dtv.service';
import { HomeService } from './fr/brgm/common/http/home/home.service';
import { JwtInterceptor } from './fr/brgm/common/http/jwt.interceptor';
import { LexiqueService } from './fr/brgm/common/http/lex/lexique.service';
import { OtvService } from './fr/brgm/common/http/otv/otv.service';
import { TransactionService } from './fr/brgm/common/http/transaction/transaction.service';
import { UrlService } from './fr/brgm/common/http/url/url.service';
import { ModalAddResultsComponent } from './modal/modal-add-results/modal-add-results.component';
import { ModalAddSubstanceComponent } from './modal/modal-add-substance/modal-add-substance.component';
import { ModalAnnouncementStatusChoiceComponent } from './modal/modal-announcement-status-choice/modal-announcement-status-choice.component';
import { ModalBstvCancelComponent } from './modal/modal-bstv-cancel/modal-bstv-cancel.component';
import { ModalBstvCloseCompleteRequestComponent } from './modal/modal-bstv-close-complete-request/modal-bstv-close-complete-request.component';
import { ModalBstvCloseDepletedOfferComponent } from './modal/modal-bstv-close-depleted-offer/modal-bstv-close-depleted-offer.component';
import { ModalBstvCloseLeftoverOfferComponent } from './modal/modal-bstv-close-leftover-offer/modal-bstv-close-leftover-offer.component';
import { ModalBstvCloseOfferAndRequestComponent } from './modal/modal-bstv-close-offer-and-request/modal-bstv-close-offer-and-request.component';
import { ModalBstvCloseUpdatedOfferAndRequestComponent } from './modal/modal-bstv-close-updated-offer-and-request/modal-bstv-close-updated-offer-and-request.component';
import { ModalBstvCloseComponent } from './modal/modal-bstv-close/modal-bstv-close.component';
import { ModalBstvDeleteComponent } from './modal/modal-bstv-delete/modal-bstv-delete.component';
import { ModalCarrierArchiveComponent } from './modal/modal-carrier-archive/modal-carrier-archive.component';
import { ModalCarrierEditComponent } from './modal/modal-carrier-edit/modal-carrier-edit.component';
import { ModalChangePasswordComponent } from './modal/modal-change-password/modal-change-password.component';
import { ModalChangeRNDTSKeyComponent } from './modal/modal-change-rndts-key/modal-change-rndts-key.component';
import { ModalChooseRNDTSTypeComponent } from './modal/modal-choose-rndts-type/modal-choose-rndts-type.component';
import { ModalContactComponent } from './modal/modal-contact/modal-contact.component';
import { ModalConvertUnitComponent } from './modal/modal-convert-unit/modal-convert-unit.component';
import { ModalEditSocialReasonComponent } from './modal/modal-edit-social-reason/modal-edit-social-reason.component';
import { ModalForgottenPasswordComponent } from './modal/modal-forgotten-password/modal-forgotten-password.component';
import { ModalGetConfidentialCodeComponent } from './modal/modal-get-confidential-code/modal-get-confidential-code.component';
import { ModalImportFileComponent } from './modal/modal-import-file/modal-import-file.component';
import { ModalOfferCloseComponent } from './modal/modal-offer-close/modal-offer-close.component';
import { ModalOfferDuplicateComponent } from './modal/modal-offer-duplicate/modal-offer-duplicate.component';
import { ModalOfferEditComponent } from './modal/modal-offer-edit/modal-offer-edit.component';
import { ModalRegisterConfirmationComponent } from './modal/modal-register-confirmation/modal-register-confirmation.component';
import { ModalRegistrationPendingSuperComponent } from './modal/modal-registration-pending-super/modal-registration-pending-super.component';
import { ModalRegistrationPendingComponent } from './modal/modal-registration-pending/modal-registration-pending.component';
import { ModalRequestCloseComponent } from './modal/modal-request-close/modal-request-close.component';
import { ModalRequestDuplicateComponent } from './modal/modal-request-duplicate/modal-request-duplicate.component';
import { ModalRequestEditComponent } from './modal/modal-request-edit/modal-request-edit.component';
import { ModalRNDTSResponseComponent } from './modal/modal-rndts-response/modal-rndts-response.component';
import { ModalSetConfidentialCodeComponent } from './modal/modal-set-confidential-code/modal-set-confidential-code.component';
import { ModalSiteArchiveComponent } from './modal/modal-site-archive/modal-site-archive.component';
import { ModalSiteDelegateComponent } from './modal/modal-site-delegate/modal-site-delegate.component';
import { ModalSiteEditComponent } from './modal/modal-site-edit/modal-site-edit.component';
import { ModalSiteInvalidParcelsComponent } from './modal/modal-site-invalid-parcels/modal-site-invalid-parcels.component';
import { ModalTransactionAcceptComponent } from './modal/modal-transaction-accept/modal-transaction-accept.component';
import { ModalTransactionEditComponent } from './modal/modal-transaction-edit/modal-transaction-edit.component';
import { ModalUserArchiveComponent } from './modal/modal-user-archive/modal-user-archive.component';
import { ModalUserCreateComponent } from './modal/modal-user-create/modal-user-create.component';
import { ModalUserEditComponent } from './modal/modal-user-edit/modal-user-edit.component';
import { ModalUserReactivateComponent } from './modal/modal-user-reactivate/modal-user-reactivate.component';
import { LoginPageComponent } from './public/login-page/login-page.component';
import { RegisterComponent } from './public/register/register.component';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    NotificationsPageComponent,
    LoginPageComponent,
    RegisterComponent,
    ModalUserEditComponent,
    ModalAddResultsComponent,
    ModalAddSubstanceComponent,
    ModalBstvCancelComponent,
    ModalBstvCloseCompleteRequestComponent,
    ModalBstvCloseDepletedOfferComponent,
    ModalBstvCloseLeftoverOfferComponent,
    ModalBstvCloseOfferAndRequestComponent,
    ModalBstvCloseUpdatedOfferAndRequestComponent,
    ModalCarrierArchiveComponent,
    ModalCarrierEditComponent,
    ModalChangePasswordComponent,
    ModalChangeRNDTSKeyComponent,
    ModalChooseRNDTSTypeComponent,
    ModalRNDTSResponseComponent,
    ModalContactComponent,
    ModalConvertUnitComponent,
    ModalForgottenPasswordComponent,
    ModalImportFileComponent,
    ModalOfferCloseComponent,
    ModalOfferDuplicateComponent,
    ModalOfferEditComponent,
    ModalRegisterConfirmationComponent,
    ModalRegistrationPendingComponent,
    ModalRegistrationPendingSuperComponent,
    ModalRequestCloseComponent,
    ModalRequestDuplicateComponent,
    ModalRequestEditComponent,
    ModalSiteArchiveComponent,
    ModalSiteDelegateComponent,
    ModalSiteEditComponent,
    ModalTransactionEditComponent,
    ModalUserArchiveComponent,
    ModalUserCreateComponent,
    ModalUserReactivateComponent,
    ModalBstvDeleteComponent,
    ModalBstvCloseComponent,
    ModalSiteInvalidParcelsComponent,
    ModalTransactionAcceptComponent,
    ModalAnnouncementStatusChoiceComponent,
    ModalEditSocialReasonComponent,
    ModalGetConfidentialCodeComponent,
    ModalSetConfidentialCodeComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    PaginationModule,
    MapModule,
    PrimengModule,
    MessageModule,
    ChatModule,
    SitesModule,
    TransporteursModule,
    AccueilModule,
    OffresModule,
    DemandesModule,
    TransactionsModule,
    BordereauxModule,
    AppRoutingModule,
    UsersModule
  ],
  providers: [
    Title,
    TransactionService,
    OtvService,
    DtvService,
    TransporterService,
    LexiqueService,
    HomeService,
    NgbActiveModal,
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ModalUserEditComponent,
    ModalAddResultsComponent,
    ModalAddSubstanceComponent,
    ModalBstvCancelComponent,
    ModalBstvCloseCompleteRequestComponent,
    ModalBstvCloseDepletedOfferComponent,
    ModalBstvCloseLeftoverOfferComponent,
    ModalBstvCloseOfferAndRequestComponent,
    ModalBstvCloseUpdatedOfferAndRequestComponent,
    ModalBstvDeleteComponent,
    ModalCarrierArchiveComponent,
    ModalCarrierEditComponent,
    ModalChangePasswordComponent,
    ModalChangeRNDTSKeyComponent,
    ModalChooseRNDTSTypeComponent,
    ModalRNDTSResponseComponent,
    ModalContactComponent,
    ModalConvertUnitComponent,
    ModalForgottenPasswordComponent,
    ModalImportFileComponent,
    ModalOfferCloseComponent,
    ModalOfferDuplicateComponent,
    ModalOfferEditComponent,
    ModalRegisterConfirmationComponent,
    ModalRegistrationPendingComponent,
    ModalRegistrationPendingSuperComponent,
    ModalRequestCloseComponent,
    ModalRequestDuplicateComponent,
    ModalRequestEditComponent,
    ModalSiteArchiveComponent,
    ModalSiteDelegateComponent,
    ModalSiteEditComponent,
    ModalTransactionEditComponent,
    ModalUserArchiveComponent,
    ModalUserCreateComponent,
    ModalUserReactivateComponent,
    ModalBstvCloseComponent,
    ModalSiteInvalidParcelsComponent,
    ModalTransactionAcceptComponent,
    ModalAnnouncementStatusChoiceComponent,
    ModalEditSocialReasonComponent,
    ModalGetConfidentialCodeComponent,
    ModalSetConfidentialCodeComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private urlService: UrlService){}
}
