<div class="details-map map">
    <div id="site_edit_map" class="map-container"></div>

    <label
        class="map-overlay map-overlay--horizontal map-overlay--bottom map-overlay--right button button--blue col-xs-12">
        <i class="button-icon" aria-hidden="true"></i>
        <span class="button-label">Réinitialiser la carte</span>
        <input type="button" (click)="resetDraw()">
    </label>
</div>

<div *ngIf="site.parcelles.length > 0">
    <p class="form-label">Parcelles intersectées par ce site</p>
    <table class="table">
        <thead>
            <th>ID</th>
            <th>Depart.</th>
            <th>Code</th>
            <th>Ville</th>
            <th>Section</th>
            <th>Numero</th>
            <th>Feuille</th>
            <th>Supprimer</th>
        </thead>
        <tbody>
            <tr *ngFor="let parcel of site.parcelles">
                <td>{{parcel.identifiant}}</td>
                <td>{{parcel.codeDepartement}}</td>
                <td>{{parcel.codeCommune}}</td>
                <td>{{parcel.nomCommune}}</td>
                <td>{{parcel.section}}</td>
                <td>{{parcel.numero}}</td>
                <td>{{parcel.feuille}}</td>
                <td>
                    <a (click)="deleteParcelle(parcel)">
                        <i class="table-actions-icon icon-cancel" aria-hidden="true"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="isSisCodeLoaded && (site.sisIntersectes.length > 0)">
    <p class="form-label">SIS intersectés par ce site</p>
    <table class="table">
        <thead>
            <th>Code</th>
            <th>Supprimer</th>
        </thead>
        <tbody>
            <tr *ngFor="let sis of site.sisIntersectes">
                <td>{{sis.code}}</td>
                <td>
                    <a [routerLink]="" (click)="deleteSisIntersectes(sis)">
                        <i class="table-actions-icon icon-cancel"  aria-hidden="true"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>

    <p class="form-message"> Attention, sur un terrain répertorié sur un SIS,
        le maître d’ouvrage doit fournir dans le dossier de demande de permis de
        construire ou d’aménager une attestation,
        réalisée par un bureau d’étude certifié dans le domaine des sites et sols
        pollués ou équivalent,
        garantissant la réalisation d’une étude des sols et de sa prise en compte dans
        la conception du projet de construction ou de lotissement (cf. L.556-2 du code
        de l’environnement).
        Cette attestation garantit la réalisation d’une étude de sol ainsi que la prise
        en compte des préconisations
        de cette étude pour assurer la compatibilité entre l'état des sols et l'usage
        futur du site dans la conception du projet d'aménagement.</p>
</div>

<div *ngIf="isSisCodeLoaded && (!site.sisIntersectes || (site.sisIntersectes.length === 0))">
    <p class="form-message">Aucun SIS intersecté par le polygone</p>
</div>

<div *ngIf="!isSisCodeLoaded">
  <p class="form-message">Récupération des codes SIS en cours, merci de patienter...</p>
</div>

