import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '../00-shared/primeng/primeng.module';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { MapModule } from '../00-shared/map/map.module';
import { MessageModule } from '../00-shared/message/message.module';
import { TransactionsPageComponent } from './transactions-page/transactions-page.component';
import { CreateTransactionPageComponent } from './create-transaction-page/create-transaction-page.component';
import { ViewTransactionPageComponent } from './view-transaction-page/view-transaction-page.component';
import { EditTransactionPageComponent } from './edit-transaction-page/edit-transaction-page.component';
import { ChatModule } from '../00-shared/chat/chat.module';

@NgModule({
  declarations: [
    TransactionsPageComponent,
    CreateTransactionPageComponent,
    ViewTransactionPageComponent,
    EditTransactionPageComponent
  ],
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    FormsModule,
    PrimengModule,
    PaginationModule,
    MapModule,
    MessageModule,
    ChatModule
  ],
  providers: []
})
export class TransactionsModule { }
