import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    TableModule,
    CalendarModule,
    DropdownModule,
    AutoCompleteModule
  ]
})
export class PrimengModule { }
