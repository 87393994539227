import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BordereauService } from 'src/app/fr/brgm/common/http/bodereau/bordereau.service';
import { DtvService } from 'src/app/fr/brgm/common/http/dtv/dtv.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { Criteria } from 'src/app/fr/brgm/common/http/model/criteria.model';
import { OtvService } from 'src/app/fr/brgm/common/http/otv/otv.service';
import { SiteService } from 'src/app/fr/brgm/common/http/site/site.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { Bordereau } from 'src/app/fr/brgm/common/model/bordereau.model';
import { Delegation } from 'src/app/fr/brgm/common/model/delegation.model';
import { LexObject } from 'src/app/fr/brgm/common/model/lex/lexObject.model';
import { LexSiteStatus } from 'src/app/fr/brgm/common/model/lex/lexsitestatus.model';
import { ListDTV } from 'src/app/fr/brgm/common/model/listdtv.model';
import { ListOTV } from 'src/app/fr/brgm/common/model/listotv.model';
import { Site } from 'src/app/fr/brgm/common/model/site.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { DelegationService } from '../services/delegation.service';

@Component({
  selector: 'app-sites-details',
  templateUrl: './sites-details.component.html',
  styleUrls: ['./sites-details.component.scss']
})
export class SitesDetailsComponent implements OnInit {

  site: Site;
  siteStatus: LexSiteStatus[];
  otvs: ListOTV;
  dtvs: ListDTV;
  bstvs: Bordereau[];
  delegations: Delegation[];

  currentUser: User;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private siteService: SiteService,
    private lexService: LexiqueService,
    private otvService: OtvService,
    private dtvService: DtvService,
    private bodereauService: BordereauService,
    private delegationService: DelegationService,
    private userService: UserService
  )
  {
    this.site = new Site();
    this.currentUser = new User();
   }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    });
    this.lexService.getLexSiteStatus().subscribe((res) => this.siteStatus = res);
    this.activatedroute.params.subscribe(params => {
      this.getSite(params.id);
      this.getOTVs(params.id);
      this.getDTVs(params.id);
      this.getBSTVs(params.id);
      this.getDelegations(params.id);
    });

    this.userService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  getDelegations(id) {
    this.delegationService.getBySite(id).subscribe((res: Delegation[]) => {
      this.delegations = res;
    })
  }

  getSite(id) {
    this.siteService.get(Number(id)).subscribe((res: Site) => {
      this.site = res;
    });
  }

  getOTVs(id) {
    this.otvService.getBySite(id).subscribe((res: ListOTV) => {
      this.otvs = res;
    })
  }

  getDTVs(id) {
    this.dtvService.getBySite(id).subscribe((res: ListDTV) => {
      this.dtvs = res;
    })
  }

  getBSTVs(id) {
    let criteria:Criteria = new Criteria();
    criteria.queryItem = "";
    criteria.isExpired = true;
    criteria.siteId = id;
    this.bodereauService.getBstvs(id).subscribe((res: Bordereau[]) => {
      this.bstvs = res;
    })
  }

  rouvrir(site: Site) {
    let tempSite = site;
    tempSite.lexSiteStatus = this.siteStatus.find(status => status.label.toLowerCase() === "ouvert");
    this.siteService.update(tempSite.id, tempSite).subscribe((res) => {
    });
  }

  fermer(site: Site) {
    let tempSite = site;
    tempSite.lexSiteStatus = this.siteStatus.find(status => status.label.toLowerCase() === "fermé");
    this.siteService.update(tempSite.id, tempSite).subscribe();
  }

  hasDelegateActions(): boolean {
    if (this.site.user == null) {
      return false;
    }
    return (this.site.user.id === this.currentUser.id)
            && (this.site.delegations.some(delegation => (delegation.lexDelegationStatus.id === 1 || delegation.lexDelegationStatus.id === 2)));
  }

  closeDelegation(delegation: Delegation): void {
    delegation.lexDelegationStatus = new LexObject(3);
    this.delegationService.update(delegation).subscribe(res => delegation.lexDelegationStatus = res.lexDelegationStatus);
  }
}
