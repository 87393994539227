import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFichierService {

  private resourceUrl = environment.apiUrl + 'document';
  private SEPARATOR = '/';
  constructor(private http: HttpClient) { }

  sendFile(file: File, fname: string): Observable<string> {
    const url = this.resourceUrl + this.SEPARATOR + 'saveFile';
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('technicalFileName', fname);

    return this.http.post(url, formData, {responseType: 'text'}).pipe(map((res: string) => res));
  }

  getFile(fname: string) {
    const url = this.resourceUrl + this.SEPARATOR + 'file' + this.SEPARATOR + fname;
    return this.http.get(url, { responseType: 'blob' });
  }
}
