import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FondPedogeochimiqueService } from 'src/app/fr/brgm/common/http/fondpedogeochimique/fondpedogeochimique.service';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { FondPedogeochimiqueAnalysis } from 'src/app/fr/brgm/common/model/fondpedogeochimiqueanalysis.model';
import { FondPedogeochimique } from 'src/app/fr/brgm/common/model/lex/fondpedogeochimique.model';

@Component({
  selector: 'app-modal-sauvegarder-fpg',
  templateUrl: './modal-sauvegarder-fpg.component.html',
  styleUrls: ['./modal-sauvegarder-fpg.component.scss']
})
export class ModalSauvegarderFpgComponent implements OnInit {

  @Input() defaultAnalysisResults: AnalysisElemResult[];

  nomFondPedogeochimique: string;
  erreurSubmettedFondpedo = false;
  reponse: any = null;

  constructor(
    private activeModal: NgbActiveModal,
    private fondPedogeochimiqueService: FondPedogeochimiqueService
  ) { }

  ngOnInit(): void {
    this.nomFondPedogeochimique = null;
  }

  onClose(): void {
    this.activeModal.close(this.reponse);
  }

  onSave(): void {
    if (this.nomFondPedogeochimique !== undefined && this.nomFondPedogeochimique !== null) {

      let fpg: FondPedogeochimique = new FondPedogeochimique();
      fpg.id = null;
      fpg.nom = this.nomFondPedogeochimique;
  
      this.fondPedogeochimiqueService.createFondPedogeochimique(fpg).subscribe(createdFPG => {
  
        //this.fondpedogeochimiques.push(createdFPG);
        //this.idSelectedFond = createdFPG.id;
      
        var tempFondPedogeochimiqueAnalysis: FondPedogeochimiqueAnalysis[] = [];
        this.defaultAnalysisResults.forEach(element => {
          if (element.quantifyLimit || element.value) {
            let fond: FondPedogeochimiqueAnalysis = new FondPedogeochimiqueAnalysis();
            fond.id.fondPedogeochimiqueId = createdFPG.id;
            fond.id.moleculeId = element.lexMolecule.id;
            fond.infQl = element.infQl;
            fond.lexMolecule = element.lexMolecule;
            fond.quantifyLimit = element.quantifyLimit;
            fond.value = element.value;
            tempFondPedogeochimiqueAnalysis.push(fond);
          }
        });
  
        this.fondPedogeochimiqueService.createFondPedogeochimiqueAnalysis(tempFondPedogeochimiqueAnalysis).subscribe((createdFPGAnalysis) => {
          //this.defaultAnalysisResults = created;
          //this.router.navigate(['/demandes/details/' + createdDemand.id]);
          this.reponse = createdFPG;
          this.onClose();
        });
  
      });

    } else {
      this.erreurSubmettedFondpedo = true;
    }
  }

}
