<div class="modal"><!-- Ajouter modal--opened pour ouvrir -->
    <form action="" class="modal-inner" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <button type="button" class="modal-close icon-close" (click)="cancel()"></button>

        <div class="modal-body">
            <i class="modal-icon modal-icon--blue icon-padlock"></i>

            <h2 class="h1 modal-title">Changer mon mot de passe</h2>

            <div class="form-group">
                <label class="form-item form-item--12">
                    <span class="form-label">Nouveau mot de passe <span class="form-tooltip has-tooltip" ngbTooltip="Votre mot de passe doit contenir 8 caractères minimum, au moins 1 Majuscule, 1 chiffre & 1 caractère spécial @& »’=:,…"></span></span>
                    <span class="form-password">
                        <input type="password" formControlName="password" placeholder="Entrez votre mot de passe" class="form-password-input">
                        <span class="form-password-toggle"></span>
                    </span>
                </label>
                
                <label class="form-item form-item--12">
                    <span class="form-label">Confirmation du mot de passe</span>
                    <span class="form-password">
                        <input type="password" formControlName="confirmPassword" placeholder="Entrez votre mot de passe" class="form-password-input">
                        <span class="form-password-toggle"></span>
                    </span>
                </label>
            </div>

            <div class="form-footer">
                <button type="reset" value="0" class="button button--red" (click)="cancel()">
                    <i class="button-icon icon-close"></i>
                    <span class="button-label">Annuler</span>
                </button>

                <button type="submit" value="1" class="button button--green" [disabled]="registerForm.invalid">
                    <i class="button-icon icon-check"></i>
                    <span class="button-label">Confirmer</span>
                </button>
            </div>
        </div>
    </form>
</div>