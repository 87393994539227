import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditOffrePageComponent } from './edit-offre-page/edit-offre-page.component';
import { OtvPageComponent } from './otv-page/otv-page.component';
import { ViewOffrePageComponent } from './view-offre-page/view-offre-page.component';
import { OffresRoutingModule } from './offres-routing.module';
import { FormsModule } from '@angular/forms';
import { PrimengModule } from '../00-shared/primeng/primeng.module';
import { PaginationModule } from '../00-shared/pagination/pagination.module';
import { MapModule } from '../00-shared/map/map.module';
import { MessageModule } from '../00-shared/message/message.module';
import { CreateOffrePageComponent } from './create-offre-page/create-offre-page.component';
import { AnnonceFormModule } from '../00-shared/annonce-form/annonce-form.module';
import { DuplicateOffrePageComponent } from './duplicate-offre-page/duplicate-offre-page.component';

@NgModule({
  declarations: [
    EditOffrePageComponent,
    OtvPageComponent,
    ViewOffrePageComponent,
    CreateOffrePageComponent,
    DuplicateOffrePageComponent
  ],
  imports: [
    CommonModule,
    OffresRoutingModule,
    FormsModule,
    PrimengModule,
    PaginationModule,
    MapModule,
    MessageModule,
    AnnonceFormModule
  ],
  providers: []
})
export class OffresModule { }
