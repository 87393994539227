import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFichierComponent } from './upload-fichier/upload-fichier.component';


@NgModule({
  declarations: [
    UploadFichierComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UploadFichierComponent
  ],
  providers: []
})
export class UploadFichierModule { }
