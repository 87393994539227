import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalysisDeclaration } from '../../model/analysisdeclaration.model';
import { AnalysisElemResult } from '../../model/analysiselemresult.model';
import { DTV } from '../../model/dtv.model';
import { ListDTV } from '../../model/listdtv.model';
import { Criteria } from '../model/criteria.model';

@Injectable({
  providedIn: 'root'
})
export class DtvService {

  private resourceUrl =  environment.apiUrl + 'demand';
  private analysisResourceUrl = environment.apiUrl + 'analysisDeclaration';
  private analysisElemResult = environment.apiUrl + 'analysisElemResult';
  private SEPARATOR =   '/';

  constructor(private http: HttpClient,) { }

  query(criteria:Criteria, pageable?: any):Observable<ListDTV> {
    return this.http.post(this.resourceUrl + this.SEPARATOR + "findall", criteria, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listDtv = new ListDTV();
      listDtv.dtv = this.convertResponse(res.body);
      listDtv.maxDTV = Number(res.headers.get('X-Total-Count'));
      return listDtv;
    }));
  }

  get(id: number): Observable<DTV> {
    return this.http.get( this.resourceUrl + this.SEPARATOR + id).pipe(map((res:DTV)=> res));
  }

  create(otv:DTV):Observable<DTV> {
    return this.http.post(this.resourceUrl+this.SEPARATOR+'create',otv).pipe(map((res:DTV)=> res));
  }

  createAnalysis(idDtv: number, analysisDeclaration: AnalysisDeclaration): Observable<AnalysisDeclaration> {
    return this.http.post(this.analysisResourceUrl + this.SEPARATOR+'create/demand/'+ idDtv, analysisDeclaration).pipe(map((res:AnalysisDeclaration)=> res));
  }

  updateAnalysisDeclarationOnDemand(idDtv: number, analysisDeclaration: AnalysisDeclaration): Observable<AnalysisDeclaration> {
    return this.http.put(this.analysisResourceUrl + this.SEPARATOR+'update/demand/'+ idDtv, analysisDeclaration).pipe(map((res:AnalysisDeclaration)=> res));
  }

  addAnalysisResults(analysisElemResult: AnalysisElemResult[]): Observable<AnalysisElemResult[]> {
    return this.http.post(this.analysisElemResult+this.SEPARATOR+'create', analysisElemResult).pipe(map((res: AnalysisElemResult[])=> res));
  }

  updateAnalysisResults(idDtv: number, analysisElemResult: AnalysisElemResult[]): Observable<AnalysisElemResult[]> {
    return this.http.put(this.analysisElemResult+this.SEPARATOR+'update/demand/'+idDtv, analysisElemResult).pipe(map((res: AnalysisElemResult[])=> res));
  }

  private convertResponse(res): DTV[] {
    const result = [];
    for (let i = 0; i < res.length; i++) {
        result.push(new DTV().deserialize(res[i]));
    }
    return result;
  }

  duplicate(id): Observable<DTV> {
    return this.http.get(this.resourceUrl+this.SEPARATOR+'copy'+this.SEPARATOR+id).pipe(map((res: DTV)=> res));
  }

  updateDTV(dtv: DTV): Observable<DTV> {
    return this.http.put(this.resourceUrl+this.SEPARATOR+'update', dtv).pipe(map((res: DTV)=> res));
  }

  publishDTV(otv: DTV): Observable<DTV> {
    return this.http.put(this.resourceUrl+this.SEPARATOR+'update', otv).pipe(map((res: DTV)=> res));
  }

  closeDTV(dtv: DTV): Observable<DTV> {
    return this.http.put(this.resourceUrl+this.SEPARATOR+'update', dtv).pipe(map((res: DTV)=> res));
  }

  getBySite(siteId: number, pageable?: any): Observable<ListDTV> {
    return this.http.get( this.resourceUrl + this.SEPARATOR + "findBySite?siteId=" + siteId, {params: pageable, observe: 'response'}).pipe(map((res) => {
      let listDtv = new ListDTV();
      listDtv.dtv = this.convertResponse(res.body);
      listDtv.maxDTV = Number(res.headers.get('X-Total-Count'));
      return listDtv;
    }));
  }
}
