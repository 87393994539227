import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from "lodash";
import { TransporterService } from 'src/app/00-shared/services/transporter.service';
import { calendar_fr } from 'src/app/app.constants';
import { AnalysisService } from 'src/app/fr/brgm/common/http/analysis/analysis.service';
import { DtvService } from 'src/app/fr/brgm/common/http/dtv/dtv.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { Criteria } from 'src/app/fr/brgm/common/http/model/criteria.model';
import { OtvService } from 'src/app/fr/brgm/common/http/otv/otv.service';
import { TransactionService } from 'src/app/fr/brgm/common/http/transaction/transaction.service';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { DTV } from 'src/app/fr/brgm/common/model/dtv.model';
import { LexDemandStatus } from 'src/app/fr/brgm/common/model/lex/lexdemandstatus.model';
import { LexGuideDechet } from 'src/app/fr/brgm/common/model/lex/lexGuideDechet.model';
import { LexOfferStatus } from 'src/app/fr/brgm/common/model/lex/lexofferstatus.model';
import { Message } from 'src/app/fr/brgm/common/model/message.model';
import { OTV } from 'src/app/fr/brgm/common/model/otv.model';
import { Transaction } from 'src/app/fr/brgm/common/model/transaction.model';
import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';

@Component({
  selector: 'app-create-transaction-page',
  templateUrl: './create-transaction-page.component.html',
  styleUrls: ['./create-transaction-page.component.scss']
})
export class CreateTransactionPageComponent implements OnInit {
  // Un des deux sera utilisé selon le paramètre d'url (O ou D)
  baseOtv: OTV;
  baseDtv: DTV;
  otvAnalysisResults: AnalysisElemResult[];
  dtvAnalysisResults: AnalysisElemResult[];
  // Une des deux listes sera en opposition au paramètre d'url
  otvList: OTV[];
  dtvList: DTV[];
  // OTV ou DTV choisi pour compléter la transaction
  offerStatus: LexOfferStatus[];
  demandeStatus: LexDemandStatus[];
  baseType: string;
  transaction: Transaction;
  transportersList: Transporter[];

  verificationCheckBox = false;
  message:Message;
  fr: any = calendar_fr;
  currentStep: number;
  progression: number;

  bstrStartDate: Date;
  bstrEndDate: Date;

  receptionStartDate: Date;
  receptionEndDate: Date;
  selectedTransporters: Transporter[] = [];
  choixTransporter: boolean;
  choixStatutDechet: boolean;
  lexGuideDechets: LexGuideDechet[];
  selectedGuideId: number;

  transactionDateError: string;
  receptionDateError: string;

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private otvService: OtvService,
    private dtvService: DtvService,
    private lexiqueService: LexiqueService,
    private analysisService: AnalysisService,
    private transporterService: TransporterService,
    private transactionService: TransactionService,
    private router: Router
    ) {
      this.resetDateError();
     }

  ngOnInit() {
    this.progression = 0;
    this.currentStep = 1;
    this.transaction = new Transaction();
    this.message = new Message();
    this.transaction.transporters = [];
    this.activatedroute.data.subscribe(data => {
      this.titleService.setTitle(data.title);
    });
    this.lexiqueService.getLexGuideDechet().subscribe((res) => this.lexGuideDechets = res);
    this.getTransporters();
    this.lexiqueService.getLexOfferStatus().subscribe((res) => this.offerStatus = res);
    this.activatedroute.params.subscribe(params => {
      this.baseType = String(params.type).toLowerCase();
      let criteria: Criteria = new Criteria();
      criteria.isExpired = false;
      criteria.statusIds = [2]; //Get only published announcements
      criteria.canCreateTransaction = true;
      if (this.baseType == "d") {
        // La transaction est à créer à partir d'une DEMANDE sélectionnée
        // Récupération DTV & analyse
        this.dtvService.get(params.id).subscribe((res) => {
          this.baseDtv = res;
          this.message.userTo = this.baseDtv.user;
          this.onDemandSelected(this.baseDtv);
        });

        // Récupération de la liste des OTV pour
        this.otvService.getOtvs(criteria, {
          sort: 'id,DESC'
        }).subscribe(
          (res) => {
            this.otvList = res.otv;
          }
        );
      } else if (this.baseType == "o") {
        this.otvService.get(params.id).subscribe((res) => {
          this.baseOtv = res;
          this.message.userTo = this.baseOtv.user;
          this.onOfferSelected(this.baseOtv);
        });

        // Récupération de la liste des OTV pour
        this.dtvService.query(criteria, {
          sort: 'id,DESC'
        }).subscribe(
          (res) => {
            this.dtvList = res.dtv;
          }
        );
      }

    });
  }

  onOfferSelected(otv: OTV) {
    this.analysisService.getAnalysisResults(otv.id, "offer").subscribe((results) => {
      this.otvAnalysisResults = results;
      this.lexiqueService.getLexMolecules().subscribe((molecules) => {
        this.otvAnalysisResults.forEach((analysisResult) => {
          analysisResult.lexMolecule = _.find(molecules, ["id", analysisResult.id.moleculeId]);
        });
      });
    });
  }

  onDemandSelected(dtv: DTV) {
    this.analysisService.getAnalysisResults(dtv.id, "demand").subscribe((results) => {
      this.dtvAnalysisResults = results;
      this.lexiqueService.getLexMolecules().subscribe((molecules) => {
        this.dtvAnalysisResults.forEach((analysisResult) => {
          analysisResult.lexMolecule = _.find(molecules, ["id", analysisResult.id.moleculeId]);
        });
      });
    });
  }

  createTransaction(form: NgForm) {
    if (this.currentStep == 3) {

      if (this.isValidDate() && form.form.status == "VALID" && this.baseOtv && this.baseDtv && this.verificationCheckBox) {

          this.transaction.bstrStartDate = this.bstrStartDate;
          this.transaction.bstrEndDate = this.bstrEndDate;
          this.transaction.demand = this.baseDtv;
          this.transaction.offer = this.baseOtv;

          this.transaction.receptionStart = this.receptionStartDate;
          this.transaction.receptionEnd = this.receptionEndDate;
          this.transaction.transporters = this.selectedTransporters;

          this.transactionService.create(this.transaction).subscribe((res) => {
            if(this.message.body) {
              this.message.transaction = res;
              this.transactionService.createMessage(this.message).subscribe((res) => { });
            }
              this.router.navigate(['/transactions/details/'+res.id]);
          })
      }
    } else {
      this.currentStep++;
      if (this.currentStep == 2) {
        this.progression = 33;
      } else if (this.currentStep == 3) {
        this.progression = 66;
      }
    }

  }

  getTransporters() {
    let criteria: Criteria = new Criteria();
    this.transporterService.getTransporters(criteria, {
      sort: "name,asc"
    }).subscribe(
      (res) => {
        this.transportersList = res.transporter
      }
    );
  }

  getSelectedTransporter(event, transp: Transporter) {
    if (event.target.checked) {
      this.selectedTransporters.push(transp);
    } else {
      this.selectedTransporters = this.selectedTransporters.filter(t => t.id != transp.id);
    }
  }

  isChecked(id: number){
    var test = this.selectedTransporters.find(t => t.id === id);
    if(test){
      return true;
    }else{
      return false;
    }
  }

  checkChoixTransport(event) {
    this.choixTransporter = event.target.checked;
    this.selectedTransporters = [];
  }

  checkChoixStatutDechet(event){
    this.choixStatutDechet = event.target.checked;
    if(!event.target.checked){
      this.transaction.lexGuideDechet = null;
      this.selectedGuideId = null;
    }
  }

  setLexGuideDechet(guide: LexGuideDechet){
    this.transaction.lexGuideDechet = guide;
  }

  resetDateError(): void {
    this.resetTransactionDateError();
    this.resetReceptionDateError();
  }

  resetTransactionDateError(): void {
    this.transactionDateError = null;
  }

  resetReceptionDateError(): void {
    this.receptionDateError = null;
  }

  isValidDate(): boolean {
    this.resetDateError();
    return (this.isValidTransactionDate() && this.isValidReceptionDate());
  }

  isValidTransactionDate(): boolean {
    let transactionDateIsValid = false;

    let now = new Date();
    now.setHours(0, 0, 0, 0);

    if (this.bstrEndDate <= this.bstrStartDate) {
      this.transactionDateError = "La date de fin de transaction doit être postérieure, d’au moins un jour, à la date de début de transaction";
    } else if (this.bstrEndDate < now) {
      this.transactionDateError = "La date de fin de transaction ne peut pas être antérieure à la date du jour";
    } else {
      transactionDateIsValid = true;
    }
    return transactionDateIsValid;
  }

  isValidReceptionDate(): boolean {
    if (this.receptionEndDate <= this.receptionStartDate) {
      this.receptionDateError = "La date de fin de réception doit être postérieure, d’au moins un jour, à la date de début de réception";
      return false;
    }
    return this.isValidReceptionTransactionStartDate();
  }

  isValidReceptionTransactionStartDate(): boolean {
    if (this.receptionStartDate < this.bstrStartDate) {
      this.receptionDateError = "La date de début de réception doit être postérieure ou égale à la date de début de transaction";
      return false;
    }
    return true;
  }

  onTransactionDateSelect() : void {
    this.resetTransactionDateError();
    this.isValidTransactionDate();
  }

  onReceptionDateSelect(): void {
    this.resetReceptionDateError();
    this.isValidReceptionDate();
  }

}
