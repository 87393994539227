import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { EntityObject } from 'src/app/fr/brgm/common/model/entityobject.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { UserUtils } from 'src/app/fr/brgm/common/model/utils/user-utils';
import { ModalEditSocialReasonComponent } from 'src/app/modal/modal-edit-social-reason/modal-edit-social-reason.component';

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit {

  currentEntityUser: EntityObject; // #193 : permet d'éviter un problème de rafraîchissement lors de la modification de la raison social de l'entité
  currentUser: User;
  entityUsers: User[];

  toManyAdminError = false;

  constructor(
    private userService: UserService,
    private modalService: NgbModal,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      this.userService.getUserEntity(this.currentUser.id).subscribe(entity => this.currentEntityUser = entity);

      this.loadEntityUsers();
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  onSocialReasonEdit(): void {
    const modalRef = this.modalService.open(ModalEditSocialReasonComponent, {
      size: "sm"
    });
    modalRef.componentInstance.entity = this.currentEntityUser;

    modalRef.result.then(_success => this.refreshCurrentPageByNavigation());
  }

  currentUserIsAdmin(): boolean {
    return this.isAdmin(this.currentUser);
  }

  isAdmin(user: User): boolean {
    return UserUtils.isAdmin(user);
  }

  displayValidateRequest(user: User): boolean {
    return UserUtils.isPending(user);
  }

  onValideRequestClick(user: User): void {
    this.userService.validUserToCollaborator(user.id).subscribe(
      _user => this.refreshCurrentPageByNavigation()
    );
  }

  displayRefuseRequest(user: User): boolean {
    return UserUtils.isPending(user);
  }

  onRefuseRequestClick(user: User): void {
    this.userService.refuseAndDelete(user.id).subscribe(
      _success => this.refreshCurrentPageByNavigation()
    );
  }

  displayAppointAdmin(user: User): boolean {
    return UserUtils.isCollaborator(user);
  }

  onAppointAdminClick(user: User): void {
    this.userService.updateToAdministrator(user.id).subscribe(
      _user => this.refreshCurrentPageByNavigation(),
      err => {
        if (err.status === 403) {
          this.toManyAdminError = true;
        }
      }
    );
  }

  displayDisableUser(user: User): boolean {
    return !(UserUtils.isPending(user) || UserUtils.isDisabled(user));
  }

  onDisableUserClick(user: User): void {
    this.userService.disable(user.id).subscribe(
      _user => this.refreshCurrentPageByNavigation()
    );
  }


  private loadEntityUsers(): void {
    if (this.currentUserIsAdmin()) {
      this.userService.getUsersByEntity(this.currentUser.entityObject.id).subscribe(users => this.entityUsers = users);
    }
  }

  private refreshCurrentPageByNavigation(): void {
    this.router.navigate(["/user/entity"]);
  }

}
