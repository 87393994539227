import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { saveAs } from "file-saver";
import * as _ from 'lodash';
import { MessageService } from 'src/app/00-shared/message/message.service';
import { calendar_fr } from 'src/app/app.constants';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { Criteria } from 'src/app/fr/brgm/common/http/model/criteria.model';
import { TransactionService } from 'src/app/fr/brgm/common/http/transaction/transaction.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { LexTransactionStatus } from 'src/app/fr/brgm/common/model/lex/lextransactionstatus.model';
import { Transaction } from 'src/app/fr/brgm/common/model/transaction.model';
import { TransactionTrace } from 'src/app/fr/brgm/common/model/transactiontrace.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';

@Component({
  selector: 'app-transactions-page',
  templateUrl: './transactions-page.component.html',
  styleUrls: ['./transactions-page.component.scss']
})
export class TransactionsPageComponent implements OnInit {

  mybstv: Transaction[];
  bstvTrace: TransactionTrace[];
  totalbstv: number;
  itemsPerPage: number;
  page: any;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  sortColumn: string;
  sortOrder: string;

  filterStatus:any[];
  filterStatusExpired:boolean;
  filterDate:Date[];
  dateDebut:Date;
  dateFin:Date;
  filterSearch:string;

  fr: any = calendar_fr;
  currentUser: User;

  transactionStatus: LexTransactionStatus[] = [];

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private messageService: MessageService,
    private transactionService: TransactionService,
    private userService: UserService,
    private lexiqueService: LexiqueService,
    private router: Router) {

    this.totalbstv = 0;
    this.page = 0;
    this.itemsPerPage = this.pageSizeOptions[0];
    this.sortColumn = "creationDate";

    this.filterSearch = "";
    this.filterStatusExpired = false;

    this.filterStatus = [];
    this.bstvTrace = [];

  }

  ngOnInit() {
    this.activatedroute.data.subscribe(data => this.titleService.setTitle(data.title));
    this.userService.getCurrentUser().subscribe((res) => this.currentUser = res);
    this.lexiqueService.getLexTransactionStatus().subscribe((res) => this.transactionStatus = res);
  }

  addFilterStatus(status:LexTransactionStatus) {
    if(!_.includes(this.filterStatus, status.id)) {
      this.filterStatus.push(status.id);
    } else {
      let index = _.indexOf(this.filterStatus, status.id);
      if(index != -1) {
        this.filterStatus.splice(index,1);
      }
    }
    this.search();
  }

  loadLazy(event) {
    this.page = event.first / this.itemsPerPage;
    if (event.sortField)
      this.sortColumn = event.sortField;
    if (event.sortOrder == 1) {
      this.sortOrder = "ASC";
      this.page = 0;
    }
    else {
      this.sortOrder = "DESC";
      this.page = 0;
    }
    this.search();
  }

  onSearch(): void {
    this.page = 0; // #170 : lors d'une recherche par numéro de transaction, on replace l'utilisateur sur la page 1 (commence à 0)
    this.search();
  }

  search() {
    let criteria:Criteria = new Criteria();
    criteria.queryItem = this.filterSearch;
    criteria.statusIds = this.filterStatus;
    criteria.date = this.filterDate;
    criteria.isExpired = this.filterStatusExpired;
    this.transactionService.query(criteria, "false", {
      page: this.page,
      size: this.itemsPerPage,
      sort: this.sortColumn + "," + this.sortOrder,
    }).subscribe(
      (res) => this.onSuccess(res),
      (res) => this.onError(res)
    );
  }

  private onSuccess(data) {
    this.mybstv = data.bstv;
    this.totalbstv = data.maxBSTV;
    this.bstvTrace = [];
    this.mybstv.forEach((bstv,index) => {
      this.transactionService.findLastStatusChangeByTransactionId(bstv.id).subscribe(
        trace => this.bstvTrace[index] = trace,
        err => {
          if (err.status === 404) {
            this.bstvTrace[index] = null;
          }
        }
      )
    });
  }
  private onError(error: any) {
    this.messageService.error('bstv', error);
  }

  getStatus(rowIndex: number){
      if(this.bstvTrace[rowIndex]){
        return this.bstvTrace[rowIndex].userId == this.currentUser.id ? true: false;
    } else {
      return false;
    }
  }

  statusChangedNotSeenByCurrentUser(rowIndex: number): boolean {
    let transactionTrace = this.bstvTrace[rowIndex];
    if (transactionTrace != null) {
      return (transactionTrace.userId != this.currentUser.id && this.currentUserNotSeenStatusChanged(transactionTrace));
    }
    return false;
  }

  private currentUserNotSeenStatusChanged(transactionTrace: TransactionTrace): boolean {
    return (transactionTrace.seenBy == null || !transactionTrace.seenBy.includes(`;${this.currentUser.id};`));
  }

  onTransactionActionClick(rowIndex): void {
    if (this.statusChangedNotSeenByCurrentUser(rowIndex)) {
      this.transactionService.addUserView(this.bstvTrace[rowIndex].id, this.currentUser.id);
    }
  }

  reload(event) {
    if(this.page != Number(event)-1) {
      this.page = Number(event) - 1;
      this.search();
    }
  }

  download(bstvId) {
    this.transactionService.downloadFile(bstvId).subscribe(res => {
      var newBlob = new Blob([res], { type: "application/pdf" });
      saveAs(newBlob, bstvId + ".pdf");
    });
  }

  importDocket(files: FileList) {
    this.transactionService.uploadFile(files.item(0)).subscribe(data => {
      this.messageService.success('bstv', "Votre bordereau <button name='" + data.id + "' class='message-link'>" + data.id + "</button> a bien été importé", "<button name='" + data.id + "' class='message-link'>Télécharger mon bordereau</button>");
      this.search();
    }, error => {
      this.messageService.error('bstv', error);
    });
  }

  pageChange(event) {
    this.page = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.search();
  }

  closeTransaction(transac: Transaction) {
    var tempTransac: Transaction = transac;
    tempTransac.lexTransactionStatus = this.transactionStatus.find(ts => ts.id = 5);
    this.transactionService.update(tempTransac).subscribe((res) => {
      this.search();
    })
  }

  createBordereau(transac: Transaction, rowIndex: number) {
    this.onTransactionActionClick(rowIndex);
    this.router.navigate(['/bordereaux/create/'+transac.id]);
  }

  handleDate(){
    if(this.dateDebut && this.dateFin){
      this.filterDate = [this.dateDebut, this.dateFin];
    }
    if(this.dateDebut && !this.dateFin){
      this.filterDate = [this.dateDebut, new Date()];
    }
    if(!this.dateDebut && this.dateFin){
      this.filterDate = [new Date('01/01/1970'), this.dateFin];
    }
    this.search();
  }


}
