import { Deserializable } from './Deserializable';
import { Address } from './address.model';
import { LexUserStatus } from './lex/lexuserstatus.model';
import { LexUserParam } from './lex/lexuserparam.model';
import { EntityObject } from './entityobject.model';
import { LexEntityNature } from './lex/lexentitynature.model';

export class UserCreation implements Deserializable {
    constructor(
        public isPrincipal?:boolean,
        public mailPrincipal?:string,
        public email?: string,
        public siren?: string,
        public entityName?:string,
        public firstname?: string,
        public lastName?: string,
        public lexEntityNature?: LexEntityNature,
        public password?:string
    ) {}
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}