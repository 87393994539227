import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { Site } from 'src/app/fr/brgm/common/model/site.model';
import { environment } from 'src/environments/environment';
import { Parcelle } from './parcelle.model';
import { Sis } from './sis.model';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private resourceUrl =  environment.apiUrl;
  private SEPARATOR =   '/';

  constructor(private http: HttpClient) { }

  getParcelles(geom: any): Observable<Parcelle[]> {
    return this.http.post(this.resourceUrl + 'ign' + this.SEPARATOR + 'parcelles', geom).pipe(map((res:Parcelle[])=> res));
  }

  getSis(site: Site): Observable<Sis[]>{
    return this.http.post(this.resourceUrl + 'georisques/site/sis', site).pipe(map((sisArray: Sis[]) => sisArray));
  }
}
