import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-alert-message',
  templateUrl: './modal-alert-message.component.html',
  styleUrls: ['./modal-alert-message.component.scss']
})
export class ModalAlertMessageComponent implements OnInit {

  @Input() message: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.activeModal.close();
  }

}
