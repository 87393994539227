import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';

@Component({
  selector: 'app-modal-forgotten-password',
  templateUrl: './modal-forgotten-password.component.html',
  styleUrls: ['./modal-forgotten-password.component.scss']
})
export class ModalForgottenPasswordComponent implements OnInit {

  email:string;

  constructor(public activeModal: NgbActiveModal,
    private userService:UserService) { }

  ngOnInit() {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  validate() {
    this.userService.lostPassword(this.email).subscribe((res) => this.activeModal.close());
  }

}
