import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { UploadFichierService } from 'src/app/00-shared/upload-file/upload-fichier.service';
import { AnalysisService } from 'src/app/fr/brgm/common/http/analysis/analysis.service';
import { AnnouncementService } from 'src/app/fr/brgm/common/http/announcement/announcement.service';
import { DtvService } from 'src/app/fr/brgm/common/http/dtv/dtv.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { UrlService } from 'src/app/fr/brgm/common/http/url/url.service';
import { UserService } from 'src/app/fr/brgm/common/http/user/user.service';
import { AnalysisDeclaration } from 'src/app/fr/brgm/common/model/analysisdeclaration.model';
import { AnalysisElemResult } from 'src/app/fr/brgm/common/model/analysiselemresult.model';
import { DocumentTerrass } from 'src/app/fr/brgm/common/model/document.model';
import { DTV } from 'src/app/fr/brgm/common/model/dtv.model';
import { AnnouncementType } from 'src/app/fr/brgm/common/model/enums/announcement-type.enum';
import { LexAnalysisType } from 'src/app/fr/brgm/common/model/lex/lex-analysis-type.model';
import { LexDemandStatus } from 'src/app/fr/brgm/common/model/lex/lexdemandstatus.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';
import { ModalGetConfidentialCodeComponent } from 'src/app/modal/modal-get-confidential-code/modal-get-confidential-code.component';
import GeoJSON from 'ol/format/GeoJSON';
import { Coordinate } from 'ol/coordinate';



@Component({
  selector: 'app-view-demande-page',
  templateUrl: './view-demande-page.component.html',
  styleUrls: ['./view-demande-page.component.scss']
})
export class ViewDemandePageComponent implements OnInit {

  @ViewChild("divToModalClick") divToModalClick: ElementRef;

  public dtv: DTV;
  public dtvAnalysis:AnalysisDeclaration;
  public dtvAnalysisResults:AnalysisElemResult[];
  loaded = false;

  demandStatus:LexDemandStatus[];
  lexAnalysisTypes: LexAnalysisType[];

  docTechniques:DocumentTerrass[] = [];
  bordereauxAnalyses:DocumentTerrass[] = [];
  bordereauxAnalysesSurLuxiviat:DocumentTerrass[] = [];
  planDemandDocs: DocumentTerrass[] = [];
	pointCoordinates: Coordinate;

  user: User;

  previousUrl: string = '';

  constructor(
    private titleService: Title,
    private activatedroute: ActivatedRoute,
    private dtvService:DtvService,
    private analysisService:AnalysisService,
    private lexiqueService:LexiqueService,
    private uploadFichierService: UploadFichierService,
    private router: Router,
    private userService: UserService,
    private urlService: UrlService,
    private modalService: NgbModal,
    private announcementService: AnnouncementService
  ) {
    this.user = new User();
    this.dtv = null;
    this.dtvAnalysis = null;
  }

  ngOnInit() {

    this.previousUrl = this.urlService.getPreviousUrl();
    this.loaded = false;

    this.activatedroute.data.subscribe( data => this.titleService.setTitle(data.title));
    this.lexiqueService.getLexDemandStatus().subscribe((res) => this.demandStatus = res);
    this.userService.getCurrentUser().subscribe((res) => this.user = res);

    this.activatedroute.params.subscribe( params => {

      this.dtvService.get(Number(params.id)).subscribe(res => {
        this.dtv = res;

        res.documents.forEach(d => {
          if(d.lexDocumentType.id == 1){
            this.docTechniques.push(d);
          }
          if(d.lexDocumentType.id == 2){
            this.bordereauxAnalyses.push(d);
          }
          if(d.lexDocumentType.id == 3){
            this.bordereauxAnalysesSurLuxiviat.push(d);
          }
          if(d.lexDocumentType.id == 5){
            this.planDemandDocs.push(d);
          }
        });

        this.analysisService.getAnalysis(this.dtv.id, "demand").subscribe((res) => {
          this.dtvAnalysis = res;
        });

        this.analysisService.getAnalysisResults(this.dtv.id, "demand").subscribe((results) => {
          this.dtvAnalysisResults = results;

          this.lexiqueService.getLexMolecules().subscribe((molecules) => {
            this.dtvAnalysisResults.forEach((analysisResult) => {
              analysisResult.lexMolecule = _.find(molecules, ["id", analysisResult.id.moleculeId]);
            });

          });

          this.loaded = true;
        });

        if (this.dtv?.geometry) {
          var geometry = new GeoJSON().readGeometry(this.dtv.geometry).transform('EPSG:4326', 'EPSG:3857');
          this.pointCoordinates = this.getCenterOfExtent(geometry.getExtent());
        }
        else if (this.dtv?.site?.geometry) {
          var geometry = new GeoJSON().readGeometry(this.dtv.site.geometry).transform('EPSG:4326', 'EPSG:3857');
          this.pointCoordinates = this.getCenterOfExtent(geometry.getExtent());
        }

      });

    });

    this.analysisService.getAllLexAnalysisDetail().subscribe(lexAnalysisTypes => this.lexAnalysisTypes = lexAnalysisTypes);
  }

  getCenterOfExtent(extent){
		var X = (extent[0] + extent[2])/2;
		var Y = (extent[1] + extent[3])/2;
		return [X, Y];
	}

  closeDTV(dtv: DTV) {
    var tempDtv: DTV = dtv;
    tempDtv.lexDemandStatus = this.demandStatus.find(offer => offer.label = "Clôturée");
    this.dtvService.closeDTV(tempDtv).subscribe((res) => {
      this.dtv.isModifiable = false;
      this.router.navigate(['/demandes/details/' + res.id]);
    })
  }

  isPubliable(demand: DTV): boolean {
    return (demand.lexDemandStatus.id == 1) && this.currentUserCanUpdate(demand);
  }

  isModifiable(demand: DTV): boolean {
    return (demand.lexDemandStatus.id != 3) && demand.isModifiable && this.currentUserCanUpdate(demand);
  }

  isCloturable(demand: DTV): boolean {
    return (demand.lexDemandStatus.id != 3) && this.currentUserCanUpdate(demand);
  }

  publishDTV(dtv: DTV) {
    var tempDtv: DTV = dtv;
    tempDtv.lexDemandStatus = this.demandStatus.find(demand => demand.label === "Publiée");
    this.dtvService.publishDTV(tempDtv).subscribe();
  }

  getVolume(qte: number): number{
    return Number((qte / 1.3).toFixed(1));
  }

  downloadFile(fname: string, nomFic: string) {
    this.uploadFichierService.getFile(fname).subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = nomFic;
      link.click();
    });
  }

  @HostListener('window:popstate', ['$event'])
  onBackBrowser(event: PopStateEvent): void {
    this.navigateBack();
  }

  onReturnClick(): void {
    this.navigateBack();
  }

  navigateBack(): void {
    if(this.previousUrl === '/accueil'){
      this.router.navigate(['/accueil']);
    }
    else{
      this.router.navigate(['/demandes']);
    }
  }

  isOwner(): boolean {
    return this.dtv.user.id === this.user.id;
  }

  onGenereteConfidentialCodeClick(): void {
    this.announcementService.getConfidentialCode(AnnouncementType.Demand, this.dtv.id).subscribe((confidentialCode: string) => {
      const modalRef = this.modalService.open(ModalGetConfidentialCodeComponent, {
        size: "sm",
      });
      modalRef.componentInstance.confidentialCode = confidentialCode;

      this.divToModalClick.nativeElement.click(); // Permet de forcer l'affichage de la modal
    });
  }

  currentUserCanUpdate(demand: DTV): boolean {
    return demand.currentUserCanUpdate;
  }

}
