import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MapViewPointsComponent } from 'src/app/00-shared/map/map-view-points/map-view-points.component';
import { calendar_fr } from 'src/app/app.constants';
import { ApiAdresseService } from 'src/app/fr/brgm/common/http/api-adresse/api-adresse.service';
import { HomeService } from 'src/app/fr/brgm/common/http/home/home.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { CriteriaHome } from 'src/app/fr/brgm/common/http/model/criteria-home.model';
import { UrlService } from 'src/app/fr/brgm/common/http/url/url.service';
import { Feature } from 'src/app/fr/brgm/common/model/feature.model';
import { Featurecollection } from 'src/app/fr/brgm/common/model/featurecollection.model';
import { HomeOtvDtv } from 'src/app/fr/brgm/common/model/home-otv-dtv.model';
import { LexMaterialType } from 'src/app/fr/brgm/common/model/lex/lexmaterialtype.model';
import { Site } from 'src/app/fr/brgm/common/model/site.model';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  fr: any = calendar_fr;

  sites: Site[];

  itemsPerPage: number;
  page: any;
  sortColumn: string;
  sortOrder: string;
  //pageSizeOptions: number[] = [10, 20, 30, 40, 50];

  localisation: any;
  queryItem = "";
  rayon = 10;
  annonceTypeSearch = "";
  quantity: number;

  otvDtvList: HomeOtvDtv[];
  otvDtvListForSite: HomeOtvDtv[];

  selectedSite: Site;
  selectedOtvDtvIds = [];

  totalOtvDtv: number;

  adresses: Featurecollection;
  requete: string;

  availStartDate: Date;
  availEndDate: Date;

  previousUrl: string = '';
  criteria: CriteriaHome;

  materialTypes: LexMaterialType[];
  idSelectedMaterialType: number = null;

  mapBboxGeometry: any;

  @ViewChild(MapViewPointsComponent, {static: false}) mapViewPoints: MapViewPointsComponent;

  constructor(
    private homeService: HomeService,
    private apiAdresseService: ApiAdresseService,
    private router: Router,
    private urlService: UrlService,
    private lexiqueService: LexiqueService
  ) {
    this.totalOtvDtv = 0;
    this.page = 0;
    this.itemsPerPage = 100000;
    this.sortColumn = "creationDate";
    this.sortOrder = "ASC";
  }

  ngOnInit() {
    this.lexiqueService.getLexMaterialTypes().subscribe((res: LexMaterialType[]) => {
      this.materialTypes = res;
    });
    this.previousUrl = this.urlService.getPreviousUrl();

    this.criteria = JSON.parse(sessionStorage.getItem("CRITERIA"));
    sessionStorage.removeItem('CRITERIA');

    if(this.previousUrl && (this.previousUrl.startsWith('/offres/details') || this.previousUrl.startsWith('/demandes/details'))  && this.criteria){
      this.localisation = this.criteria.localisation ? this.criteria.localisation : null;
      this.rayon = this.criteria.rayon ? this.criteria.rayon : 10;
      this.queryItem = this.criteria.queryItem ? this.criteria.queryItem : "";
      this.annonceTypeSearch = this.criteria.type ? this.criteria.type : "";
      this.quantity = this.criteria.quantity ? +this.criteria.quantity : null;
      this.availStartDate = this.criteria.availStartDate ? new Date(this.criteria.availStartDate) : null;
      this.availEndDate = this.criteria.availEndDate ? new Date(this.criteria.availEndDate) : null;
      this.idSelectedMaterialType = this.criteria.materialTypeId ? this.criteria.materialTypeId : null;
    }

    this.search(false);
  }

  pageChange(event) {
    this.page = event.pageIndex;
    this.itemsPerPage = event.pageSize;
    this.search(false);
  }

  onZoomChangeInChild(event) {
    this.mapBboxGeometry = event;
    this.search(false);
  }

  search(isSearchClick: boolean) {
    let criteria: CriteriaHome = new CriteriaHome();
    if (this.localisation) {
      criteria.localisation = this.localisation;
      criteria.rayon = this.rayon;
    } else {
      criteria.queryItem = this.queryItem;
    }
    if (this.annonceTypeSearch) {
      criteria.type = this.annonceTypeSearch;
    }
    if (this.quantity) {
      criteria.quantity = this.quantity;
    }
    if (this.availStartDate){
      criteria.availStartDate = this.availStartDate;
    }
    if (this.availEndDate){
      criteria.availEndDate = this.availEndDate;
    }
    if (this.idSelectedMaterialType) {
      criteria.materialTypeId = this.idSelectedMaterialType;
    }

    if (!isSearchClick && this.mapBboxGeometry) {
      let feature = new Feature();
      feature.geometry = this.mapBboxGeometry;
      criteria.bboxFeature = feature;
    }

    sessionStorage.setItem("CRITERIA", JSON.stringify(criteria));

    this.homeService.findAll(criteria, {
      page: 0,
      size: 100000,
      sort: this.sortColumn + "," + this.sortOrder
    }).subscribe((res => {
      this.mapViewPoints.isSearchClick = isSearchClick;

      this.otvDtvList = res.odtv;
      this.totalOtvDtv = res.maxODTV;
      this.sites = new Array<Site>();
      res.odtv.forEach(otvdtv => {
        if(this.sites.some(s => s.id === otvdtv.site.id)){
          this.sites.find(s => s.id === otvdtv.site.id).otvDtvList.push(otvdtv);
        }
        else{
          otvdtv.site.otvDtvList = [otvdtv];
          this.sites.push(otvdtv.site);
        }
      });

    }))
  }

  reinitialiser() {

    sessionStorage.removeItem('CRITERIA');

    let criteria: CriteriaHome = new CriteriaHome();
    this.requete = "";
    this.localisation = "";
    this.queryItem = "";
    this.rayon = 10;
    this.annonceTypeSearch = "";
    this.quantity = undefined;
    this.availStartDate = undefined;
    this.availEndDate = undefined;
    this.idSelectedMaterialType = null;

    this.homeService.findAll(criteria).subscribe((res => {
      this.mapViewPoints.isSearchClick = true;
      this.otvDtvList = res.odtv;
      this.totalOtvDtv = res.maxODTV;
      this.sites = new Array<Site>();
      res.odtv.forEach(otvdtv => {
        if(this.sites.some(s => s.id === otvdtv.site.id)){
          this.sites.find(s => s.id === otvdtv.site.id).otvDtvList.push(otvdtv);
        }
        else{
          otvdtv.site.otvDtvList = [otvdtv];
          this.sites.push(otvdtv.site);
        }
      });
    }))
  }

  createTransaction(type: string, id: number) {
    this.router.navigate(['/transactions/create', type, id])
  }

  /*
  onSelectPoint(event) {
    if (event.selected[0]) {
      this.selectedSite = event.selected[0].values_.site;
      let criteria: CriteriaHome = new CriteriaHome();
      criteria.siteId = this.selectedSite.id;
      this.homeService.findAll(criteria).subscribe((res => {
        this.otvDtvListForSite = res.odtv;
        //console.log("otv/dtv for site", this.otvDtvListForSite);
      }))
    } else {
      this.selectedSite = undefined;
    }
  }*/

  getNumberOfResults() {
    return this.otvDtvListForSite ? this.otvDtvListForSite.length : 0;
  }

  searchAdresse(event) {
    this.apiAdresseService.getAdresseByTexte(event.query).subscribe((res => this.adresses = res));
  }

  doOnSelect(event){
    this.localisation = event;
  }

  selectedOtvDtvChange(event) {
    this.selectedOtvDtvIds = event;
    if(this.otvDtvList !== undefined){
      this.otvDtvList.sort(this.sortBySelectedThenDate());
    }
  }

  sortBySelectedThenDate() {
    return (a: any, b: any) => {
      if(this.selectedOtvDtvIds.includes(a['id']) && !this.selectedOtvDtvIds.includes(b['id'])){
        return -1;
      }

      if(!this.selectedOtvDtvIds.includes(a['id']) && this.selectedOtvDtvIds.includes(b['id'])){
        return 1;
      }

      if (a['creationDate'] < b['creationDate']) {
        return 1;
      }
      if (a['creationDate'] > b['creationDate']) {
        return -1;
      }

      return 0;
    }
  }

  showOtvDtv(otvDtv: HomeOtvDtv){

    if(otvDtv.type === 'O'){
      this.router.navigate(['/offres/details', otvDtv.id]);
    }
    else if(otvDtv.type === 'D'){
      this.router.navigate(['/demandes/details', otvDtv.id]);
    }
  }

  showOtvDtvOnMap(otvDtv: HomeOtvDtv){
    this.selectedOtvDtvIds = [otvDtv.id];
    this.mapViewPoints.selectOtvDtv(otvDtv);
  }
}
