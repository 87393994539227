import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-register-confirmation',
  templateUrl: './modal-register-confirmation.component.html',
  styleUrls: ['./modal-register-confirmation.component.scss']
})
export class ModalRegisterConfirmationComponent {

  @Input() public firstSubscriber;

  constructor(public activeModal: NgbActiveModal, private router: Router) { }

  private navigateToLoginPage(): void {
    this.activeModal.dismiss();
    this.router.navigate(['login']);
  }

  onClose(): void {
    this.navigateToLoginPage();
  }

  onSubmit(): void {
    this.navigateToLoginPage();
  }

}
