import { Component, OnInit, Input, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { Alert } from 'src/app/fr/brgm/common/model/alert.model';
import { MessageService } from './message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  @Input() component: string;
  @Output() action = new EventEmitter<any>();

  alerts: Alert[] = [];
  subscription: Subscription;

  constructor(
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.subscription = this.messageService.subscribe(this.component, (message) => this.setAlerts(message));
  }

  ngOnDestroy() {
    this.alerts = [];
    this.subscription.unsubscribe();
  }

  setAlerts(message) {
    this.alerts.push(message.content);
  }

  deleteAlert(message: Alert) {
    this.alerts.splice(this.alerts.indexOf(message), 1);
  }

  @HostListener('click', ['$event']) onClick(event: Event) {
    let el: HTMLElement = event.target as HTMLElement;
    if(el.tagName === 'BUTTON') {
      let name = el.getAttribute('name');
      if(name != null) this.action.emit(name);
    }
  }
}
