import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalAlertMessageComponent } from '../../modal/modal-alert-message/modal-alert-message.component';
import { ModalSauvegarderFpgComponent } from '../../modal/modal-sauvegarder-fpg/modal-sauvegarder-fpg.component';
import { MapModule } from '../map/map.module';
import { PrimengModule } from '../primeng/primeng.module';
import { UploadFichierModule } from '../upload-file/upload-fichier.module';
import { AnalysesComponent } from './analyses/analyses.component';
import { AnnonceFormComponent } from './annonce-form.component';
import { CaracterisationComponent } from './caracterisation/caracterisation.component';
import { InfosGeneralesComponent } from './infos-generales/infos-generales.component';
import { LocalisationComponent } from './localisation/localisation.component';
import { UseParentFormDirective } from './use-parent-form-directive';


@NgModule({
  declarations: [
    AnnonceFormComponent,
    CaracterisationComponent,
    LocalisationComponent,
    InfosGeneralesComponent,
    UseParentFormDirective,
    AnalysesComponent,
    ModalSauvegarderFpgComponent,
    ModalAlertMessageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PrimengModule,
    UploadFichierModule,
    MapModule
  ],
  exports: [
    AnnonceFormComponent
  ],
  providers: []
})
export class AnnonceFormModule { }
