<header class="header">
  <p *ngIf="!isProd" style="text-align: center; color:red; padding-top: 8px;">{{texteRecette}}</p>

  <div class="header-menu" *ngIf="displayLoggedInMenu()">
    <ul class="header-menu-list">
      <li class="header-menu-item">
        <a [routerLink]="'/sites'" routerLinkActive="active" class="header-menu-link header-menu-link--uppercase">
          <i class="header-menu-icon icon-pin" aria-hidden="true"></i>
          <span class="header-menu-label">Mes sites</span>
        </a>
      </li>
      <li class="header-menu-item">
        <a [routerLink]="'/carriers'" routerLinkActive="active" class="header-menu-link header-menu-link--uppercase">
          <i class="header-menu-icon icon-box" aria-hidden="true"></i>
          <span class="header-menu-label">Transporteurs</span>
        </a>
      </li>
    </ul>
    <ul class="header-menu-list">
      <li class="header-menu-item">
        <a target="_blank" href="https://assistance.brgm.fr/formulaire/posez-votre-question?tools=TERRASS"
          class="footer-nav-link">Besoin d’aide ?</a>
      </li>
    </ul>

    <div class="header-menu-account">
      <button type="button" class="header-menu-account-button">
        <i class="header-menu-icon icon-silhouette" aria-hidden="true"></i>
        <span class="header-menu-label" [textContent]="getUserFullName()"></span>
        <i class="header-menu-icon icon-down" aria-hidden="true"></i>
      </button>
      <ul class="header-menu-account-nav">
        <li class="header-menu-account-nav-item">
          <a [routerLink]="'/user/profile'" routerLinkActive="active" class="header-menu-account-nav-link">
            <i class="header-menu-account-nav-icon icon-silhouette" aria-hidden="true"></i>
            <span class="header-menu-account-nav-label">Profil</span>
          </a>
        </li>

        <li class="header-menu-account-nav-item">
          <a [routerLink]="'/user/entity'" routerLinkActive="active" class="header-menu-account-nav-link">
            <i class="header-menu-account-nav-icon icon-folder" aria-hidden="true"></i>
            <span class="header-menu-account-nav-label">Entité</span>
          </a>
        </li>

        <li class="header-menu-account-nav-item">
          <a [routerLink]="'/user/settings'" routerLinkActive="active" class="header-menu-account-nav-link">
            <i class="header-menu-account-nav-icon icon-settings" aria-hidden="true"></i>
            <span class="header-menu-account-nav-label">Paramètres</span>
          </a>
        </li>
        <li class="header-menu-account-nav-item">
          <a (click)="logout()" class="header-menu-account-nav-link-red">
            <i class="header-menu-account-nav-icon icon-logout" aria-hidden="true"></i>
            <span class="header-menu-account-nav-label">Déconnexion</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- personal menu -->
  <!-- div header-bottom -->
  <div class="header-bottom">
    <!-- terrass logo app -->
    <a [routerLink]="'/accueil'" class="header-logo">
      <img src="assets/images/logos/terrass.png" alt="Terrass - Site web de gestion des terres excavées"
        class="header-logo-sprite">
    </a>
    <!-- terrass logo app -->
    <!-- nav header-nav -->
    <nav class="header-nav" *ngIf="displayLoggedInMenu()">
      <ul class="header-nav-list">
        <li class="header-nav-item">
          <a [routerLink]="'/accueil'" routerLinkActive="header-nav-link--current" class="header-nav-link">Accueil</a>
        </li>
        <li class="header-nav-item">
          <a [routerLink]="'/offres'" routerLinkActive="header-nav-link--current" class="header-nav-link has-tooltip">
            OTV
            <span class="tooltip">Accéder à mes Offres de Terres Valorisables</span>
          </a>
        </li>
        <li class="header-nav-item">
          <a [routerLink]="'/demandes'" routerLinkActive="header-nav-link--current" class="header-nav-link has-tooltip">
            DTV
            <span class="tooltip">Accéder à mes Demandes de Terres Valorisables</span>
          </a>
        </li>
        <li class="header-nav-item">
          <a [routerLink]="'/transactions'" routerLinkActive="header-nav-link--current"
            class="header-nav-link">Transactions</a>
        </li>
        <li class="header-nav-item">
          <a [routerLink]="'/bordereaux'" routerLinkActive="header-nav-link--current"
            class="header-nav-link has-tooltip">
            BSTV
            <span class="tooltip">Accéder à mes Bordereaux de Suivi de Terres Valorisables</span>
          </a>
        </li>
      </ul>
    </nav>
    <!-- nav header-nav -->

    <a *ngIf="showConfidentialCodeButton()" class="nav-link grey" (click)="onConfidentialCodeClick()">Code de partage d'une annonce</a>

    <!-- contextual create link -->

    <a *ngIf="noSite" title="Cliquer sur cet avertissement pour créer un site" [routerLink]="'/sites/create'" class="nav-link orange">
      Aucun site créé, veuillez ajouter un site pour pouvoir créer une annonce.
    </a>
    <button *ngIf="displayLoggedInMenu()" [routerLink]="'/annonce/create'" [queryParams]="{offer: createOffer}"
        class="button has-tooltip" [ngClass]="{'button--grey': noSite, 'button--orange': !noSite}" [disabled]="noSite"
    >
      <i class="button-icon icon-add" aria-hidden="true"></i>
      <span class="button-label">Créer une annonce</span>

      <span class="tooltip tooltip--center">Création d’une OTV ou d’une DTV</span>
    </button>

    <!-- contextual create link -->
  </div>
  <!-- div header-bottom -->
</header>
