import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentTerrass } from 'src/app/fr/brgm/common/model/document.model';
import { UploadFichierService } from '../upload-fichier.service';

@Component({
  selector: 'app-upload-fichier',
  templateUrl: './upload-fichier.component.html',
  styleUrls: ['./upload-fichier.component.scss']
})
export class UploadFichierComponent implements OnInit {

  @Input() fieldsetTitle: string;
  @Input() uploadFileId: string;
  @Input() documents: DocumentTerrass[];
  @Input() readOnly = false;
  @Input() disabled: boolean;
  @Input() fileLimit = 4;

  @Output() fileListEmitter: EventEmitter<File[]> = new EventEmitter<File[]>();

  _files: File[] = [];

  fileTypeErrors: string[];
  fileSizeErrors: string[];

  constructor(private uploadFichierService: UploadFichierService) {
    this.resetFileError();
  }

  ngOnInit() {
    if (this.uploadFileId === 'uploadFileTechId1') {
      this.documents.forEach( doc => {
        if(doc.lexDocumentType.id == 1){
          this.getFile(doc.filename, doc.originalFilename);
        }
      });
    }

    else if (this.uploadFileId === 'uploadFileAnasId2') {
      this.documents.forEach( doc => {
        if(doc.lexDocumentType.id == 2){
          this.getFile(doc.filename, doc.originalFilename);
        }
      });
    }

    else if (this.uploadFileId === 'uploadFileLixiId3') {
      this.documents.forEach( doc => {
        if(doc.lexDocumentType.id == 3){
          this.getFile(doc.filename, doc.originalFilename);
        }
      });
    }

    else if (this.uploadFileId === 'uploadFilePlanOfferId4') {
      this.documents.forEach( doc => {
        if(doc.lexDocumentType.id == 4){
          this.getFile(doc.filename, doc.originalFilename);
        }
      });
    }

    else if (this.uploadFileId === 'uploadFilePlanDemandId5') {
      this.documents.forEach( doc => {
        if(doc.lexDocumentType.id == 5){
          this.getFile(doc.filename, doc.originalFilename);
        }
      });
    }

    else{
      this.documents.forEach( doc => {
          this.getFile(doc.filename, doc.originalFilename);
      });
    }
  }

  resetFileError(): void {
    this.fileSizeErrors = [];
    this.fileTypeErrors = [];
  }

  fileIsValid(file: File): boolean {
    if (/(pdf)|(jpe?g)|(bmp)|(png)/.test(file.type) ) {
      if (file.size <= 10485760) {
        return true;
      } else {
        this.fileSizeErrors.push(file.name);
      }
    } else {
      this.fileTypeErrors.push(file.name);
    }
    return false;
  }

  selectFile(fics: FileList) {
    this.resetFileError();

    let file: File;
    for (let i = 0; i < fics.length; i++) {
      file = fics.item(i);
      if (this.fileIsValid(file) && (this._files.length < this.fileLimit)) {
        this._files.push(file);
      }
    }
    this.fileListEmitter.emit(this._files);
    (<HTMLInputElement>document.getElementById(this.uploadFileId)).value = "";
  }

  removeFile(index: number) {
    if (index != -1) {
      this._files.splice(index, 1);
    }
    this.fileListEmitter.emit(this._files);
  }

  getFile(fname: string, nomFic: string) {
    this.uploadFichierService.getFile(fname).subscribe((blob) => {
      let file = new File([blob], nomFic);
      this._files.push(file);
      this.fileListEmitter.emit(this._files);
    });
  }

  downloadFile(nomFic: string) {
    this._files.forEach( file => {
      if(file.name === nomFic){
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = nomFic;
        link.click();
      }
    })
  }
}
