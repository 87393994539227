import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const dateFinGelValidator = (dateFinD: string, dateFinG: string): ValidatorFn =>

(control: AbstractControl): ValidationErrors | null => {
  
  if (control.get(dateFinD).value && control.get(dateFinG).value) {
    const dateFD: Date = new Date(control.get(dateFinD).value);
    dateFD.setHours(0,0,0,0);
    const dateFG: Date = new Date(control.get(dateFinG).value);
    dateFG.setHours(0,0,0,0);

    if(dateFG > dateFD) {
        return {dateFinGelInvalide: true};
    }
  }
  return null;
};