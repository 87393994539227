import { Deserializable } from '../../model/Deserializable';

export class CriteriaHome implements Deserializable{ 
    constructor(
        public queryItem?: string,
        public type?: string,
        public date?: Date[],
        public availStartDate?: Date,
        public availEndDate?: Date,
        public quantity?: number,
        public localisation?: string,
        public rayon?: number,
        public siteId?: number,
        public materialTypeId?: number,
        public bboxFeature?: any
    ) {}

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}