import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TransporterService } from 'src/app/00-shared/services/transporter.service';
import { LexiqueService } from 'src/app/fr/brgm/common/http/lex/lexique.service';
import { LexTransporterStatus } from 'src/app/fr/brgm/common/model/lex/lextransporterstatus.model';
import { Transporter } from 'src/app/fr/brgm/common/model/transporter.model';

@Component({
  selector: 'app-view-carrier-page',
  templateUrl: './view-carrier-page.component.html',
  styleUrls: ['./view-carrier-page.component.scss']
})
export class ViewCarrierPageComponent implements OnInit {

  transporter: Transporter;
  loaded: Boolean;
  transporterStatus: LexTransporterStatus[];

  constructor( private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private transporterService: TransporterService,
    private lexiqueService: LexiqueService
    ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe( data => {
      this.titleService.setTitle(data.title);
    });
    this.activatedRoute.params.subscribe( params => {
      this.transporterService.get(Number(params.id)).subscribe(res => {
        this.transporter = res;
        this.loaded = true;
      });
    });
    this.lexiqueService.getLexTransporterStatus().subscribe((res) => this.transporterStatus = res);
  }


  archiver() {
    let tempTransporter = this.transporter;
    tempTransporter.lexTransporterStatus = this.transporterStatus.find(status => status.label.toLowerCase() === "archivé");
    this.transporterService.update(tempTransporter).subscribe((res) => { this.transporter = res });
  }

  activer(){
    let tempTransporter = this.transporter;
    tempTransporter.lexTransporterStatus = this.transporterStatus.find(status => status.label.toLowerCase() === "actif");
    this.transporterService.update(tempTransporter).subscribe((res) => { this.transporter = res });
  }

}
