<div class="wrapper entry" #divToModalClick>
    <div id="register-block" class="entry-content">
        <form action="" class="entry-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="entry-form-header">
                <h1 class="h1 entry-form-title">S’inscrire</h1>
                <p>
                Bienvenue sur l’outil TERRASS.<br>
                Créez et suivez vos transactions de terres, inscrivez-vous.<br>
                <em>Tous les champs sont obligatoires.</em>
                </p>
            </div>

            <div class="entry-form-body">

                <div class="form-fieldset">
                    <div class="form-fieldset-header">
                        <h3 class="h4 black form-fieldset-title">Identification</h3>
                        <hr class="form-fieldset-line">
                    </div>

                    <div class="form-group">
                        <label class="form-item form-item--12">
                            <span class="form-label">Numéro de SIREN</span>
                            <input type="text" formControlName="siren" placeholder="123456789" class="form-input" (change)="getSiren($event)" [ngClass]="{'invalid-input' : isInvalid('siren') || invalidSiren}" [(ngModel)]="sirenValue">
                            <div *ngIf="isInvalid('siren') || invalidSiren" class="alert alert-danger">
                              <div *ngIf="getFormControl('siren').errors?.required">
                                Numéro SIREN requis
                              </div>
                              <div *ngIf="getFormControl('siren').errors?.pattern">
                                Merci de renseigner un numéro de SIREN valide (9 chiffres)
                              </div>
                              <div *ngIf="invalidSiren">
                                Le numéro de SIREN renseigné est introuvable
                              </div>
                            </div>
                        </label>
                        <label class="form-item form-item--12">
                            <span class="form-label">Raison sociale : {{raisonSociale}}</span>
                        </label>
                    </div>
                </div>

                <div class="form-fieldset">
                    <div class="form-fieldset-header">
                        <h3 class="h4 black form-fieldset-title">Coordonnées</h3>
                        <hr class="form-fieldset-line">
                    </div>

                    <div class="form-group">
                        <label class="form-item form-item--6">
                            <span class="form-label">Nom</span>
                            <input type="text" formControlName="lastName" placeholder="Doe" class="form-input" [ngClass]="{'invalid-input' : isInvalid('lastName')}">
                            <div *ngIf="isInvalid('lastName')" class="alert alert-danger">
                              Nom requis
                            </div>
                        </label>

                        <label class="form-item form-item--6">
                            <span class="form-label">Prénom</span>
                            <input type="text" formControlName="firstName" placeholder="John" class="form-input" [ngClass]="{'invalid-input' : isInvalid('firstName')}">
                            <div *ngIf="isInvalid('firstName')" class="alert alert-danger">
                              Prénom requis
                            </div>
                        </label>

                        <label class="form-item form-item--12">
                            <span class="form-label">Email</span>
                            <input type="email" formControlName="email" placeholder="Entrez votre email" class="form-input" [ngClass]="{'invalid-input' : isInvalid('email') || checkEmail}">
                            <div *ngIf="isInvalid('email') || checkEmail" class="alert alert-danger">
                              <div *ngIf="getFormControl('email').errors?.required">
                                Adresse mail requise
                              </div>
                              <div *ngIf="getFormControl('email').errors?.email">
                                Merci de renseigner une adresse mail valide
                              </div>
                              <div *ngIf="checkEmail">
                                Cette adresse mail est déjà utilisée
                              </div>
                            </div>
                        </label>

                        <label class="form-item form-item--12">
                            <span class="form-label">Mot de passe <br>Votre mot de passe doit contenir 8 caractères minimum, au moins 1 majuscule, 1 chiffre et 1 caractère spécial </span>
                            <span class="form-password">
                                <input type="password" formControlName="password" placeholder="Entrez votre mot de passe" class="form-password-input" [ngClass]="{'invalid-input' : isInvalid('password')}">
                                <span class="form-password-toggle"></span>
                            </span>
                            <div *ngIf="isInvalid('password')" class="alert alert-danger">
                              <div *ngIf="getFormControl('password').errors?.required">
                                Le mot de passe ne peut pas être vide
                              </div>
                              <div *ngIf="getFormControl('password').errors?.minlength">
                                Le mot de passe doit contenir au moins 8 caractères
                              </div>
                              <div *ngIf="getFormControl('password').errors?.pattern && !getFormControl('password').errors?.minlength">
                                Votre mot de passe doit contenir au moins 1 majuscule, 1 chiffre et 1 caractère spécial
                              </div>
                            </div>
                        </label>

                        <label class="form-item form-item--12">
                            <span class="form-label">Confirmation du mot de passe</span>
                            <span class="form-password">
                                <input type="password" formControlName="confirmPassword" placeholder="Entrez votre mot de passe" class="form-password-input" [ngClass]="{'invalid-input' : isInvalid('confirmPassword')}">
                                <span class="form-password-toggle"></span>
                            </span>
                            <div *ngIf="isInvalid('confirmPassword')" class="alert alert-danger">
                              <div *ngIf="getFormControl('confirmPassword').errors?.required">
                                Merci de confirmer votre mot de passe
                              </div>
                              <div *ngIf="!getFormControl('confirmPassword').errors?.required">
                                Les mots de passe ne sont pas identiques
                              </div>
                            </div>
                        </label>

                        <div class="form-item form-item--12">
                            <label class="form-checkbox">
                                <input type="checkbox" formControlName="acceptTerms" class="form-checkbox-input">
                                <span class="form-checkbox-label">J’accepte les &nbsp;</span>
                                <span><a  target="_blank" href="assets/pdf/CGU_TERRASS_vf.pdf">Conditions d’utilisation</a></span>
                            </label>
                        </div>
                        <div class="form-item form-item--12">
                          <label class="form-checkbox">
                              <input type="checkbox" formControlName="acceptCharte" class="form-checkbox-input">
                              <span class="form-checkbox-label">J’accepte la &nbsp;</span>
                              <span><a  target="_blank" href="assets/pdf/charte-bonnes-pratiques.pdf">Charte des bonnes pratiques</a></span>
                          </label>
                      </div>
                        <div class="form-item form-item--12">
                            <label class="form-checkbox">
                                <input type="checkbox" formControlName="acceptData" class="form-checkbox-input">
                                <span class="form-checkbox-label">En remplissant le formulaire, vous consentez à ce que vos données personnelles soient exploitées dans le cadre de votre activité sur l’outil TERRASS.</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="entry-form-footer">
                <button type="submit" class="button button--fullwidth button--blue" [disabled]="registerForm.invalid">
                    <i class="button-icon icon-silhouette"></i>
                    <span class="button-label">S'inscrire</span>
                </button>
            </div>
        </form>

        <div *ngIf="checkSiren" class="red">Votre compte n'a pas été créer. Veuillez vérifier votre numéro de siren</div>
        <div *ngIf="checkEmail" class="red">Un compte existe déjà avec cette adresse mail</div>
        <div *ngIf="checkGlobalError" class="red">Une erreur interne est survenue, merci de réessayer</div>

        <div class="entry-footer">
            <p>
                Déjà un compte ? <a [routerLink]="'/login'" routerLinkActive="active" class="entry-link">Connectez-vous</a>
            </p>
        </div>
    </div>

    <img src="assets/images/placeholders/login.png" alt="" class="entry-image">
</div>
