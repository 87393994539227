<div class="wrapper">
    <div class="container">
        <div class="page-header">
            <h1 class="h1">Mes Bordereaux de Suivi de Terres Valorisables</h1>
        </div>

        <div class="table-wrapper">

            <div class="table-filters">
                <span class="table-filters-results">
                    <i class="table-filters-results-icon icon-file" aria-hidden="true"></i>
                    {{totalbordereaux}} Bordereaux
                </span>

                <!-- Filtres -->

                <ul class="table-filters-list">
                    <li class="table-filters-item" *ngFor="let status of bordereauStatus">
                        <label class="table-filters-button has-tooltip">
                            <input type="checkbox" class="table-filters-input" (change)="addFilterStatus(status)">
                            <span class="table-filters-label">{{status.label}}</span>

                            <span class="tooltip tooltip--center">Filtrer par bordereau {{status.label}}</span>
                        </label>
                    </li>
                </ul>

                <!-- Recherche -->

                <div class="table-filters-search">
                  <label class="table-filters-search-item">
                    <input type="text" placeholder="Numéro de bordereau"
                        class="table-filters-search-input" [(ngModel)]="filterSearch" (blur)="onSearch()" (keyup.enter)="onSearch()">
                    <i class="table-filters-search-icon icon-search" aria-hidden="true"></i>
                  </label>
                </div>

            </div>

            <p-table tableStyleClass="table" [resetPageOnSort]="false" [sortOrder]="2" [value]="bordereaux" (onLazyLoad)="loadLazy($event)" [paginator]="false" [lazy]="true" [totalRecords]="totalbordereaux" dataKey="id">
                <ng-template pTemplate="header">
                    <tr style="text-align: center;">
                        <th scope="col" [pSortableColumn]="'id'" class="table-sortable">Bordereau</th>
                        <th scope="col" [pSortableColumn]="'dateCreation'" class="table-sortable">Date de création</th>
                        <th scope="col" [pSortableColumn]="'offreurNomSite'" class="table-sortable">Producteur</th>
                        <th scope="col" [pSortableColumn]="'demandeurNomSite'" class="table-sortable">Receveur</th>
                        <th scope="col" [pSortableColumn]="'lexBordereauStatus'" class="table-sortable">Statut</th>
                        <th scope="col">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bstv>
                    <tr style="text-align: center;">
                        <td>
                            <a [routerLink]="'/bordereaux/details/'+bstv.id" routerLinkActive="active" class="table-link">{{bstv?.id}}</a>
                        </td>
                        <td>{{bstv?.dateCreation | date: 'dd/MM/yyyy'}}</td>
                        <td>
                            <h3 class="h3">{{bstv?.offreurNomSite}}</h3>
                        </td>
                        <td>
                            <h3 class="h3">{{bstv?.demandeurNomSite}}</h3>
                        </td>
                        <td [ngSwitch]="bstv.lexBordereauStatus.id">
                            <b *ngSwitchCase="1" class="green">{{bstv?.lexBordereauStatus.label}}</b>
                            <b *ngSwitchCase="2" class="black">{{bstv?.lexBordereauStatus.label}}</b>
                        </td>
                        <td>
                            <div class="table-actions">
                                <input type="checkbox" id="table-actions-1" class="table-actions-input">
                                <label for="table-actions-1" class="table-actions-toggle"></label>
                                <ul class="table-actions-list">

                                    <li class="table-actions-item">
                                        <a [routerLink]="'/bordereaux/details/'+bstv.id"
                                        routerLinkActive="active" class="table-actions-link blue">
                                            <i class="table-actions-icon icon-eye" aria-hidden="true"></i>
                                            <span class="table-actions-label">Consulter</span>
                                        </a>
                                    </li>

                                    <li class="table-actions-item" *ngIf="(bstv?.lexBordereauStatus?.id === 1) && bstv?.currentUserCanAddLot">
                                        <a [routerLink]="'/bordereaux/lot/create/'+bstv.id" class="table-actions-link green">
                                            <i class="table-actions-icon icon-add" aria-hidden="true" style="color: green;"></i>
                                            <span class="table-actions-label">Ajouter un lot</span>
                                        </a>
                                    </li>

                                    <li class="table-actions-item" *ngIf="(bstv?.lexBordereauStatus?.id === 1) && bstv?.currentUserCanClose">
                                        <a class="table-actions-link orange" (click)="closeBstv(bstv)">
                                            <i class="table-actions-icon icon-open-folder" aria-hidden="true"></i>
                                            <span class="table-actions-label">Clôturer</span>
                                        </a>
                                    </li>

                                    <li class="table-actions-item" *ngIf="(bstv?.lexBordereauStatus?.id === 1) && bstv?.currentUserCanDelete">
                                        <a class="table-actions-link red" (click)="openDeleteModal(bstv)">
                                            <i class="table-actions-icon icon-cancel" aria-hidden="true"></i>
                                            <span class="table-actions-label">Supprimer</span>
                                        </a>
                                    </li>

                                    <li class="table-actions-item" *ngIf="(bstv?.lexBordereauStatus?.id === 2)">
                                        <a class="table-actions-link blue" (click)="telechargerPdf(bstv?.id)"
                                        download>
                                        <i class="table-actions-icon icon-pdf" aria-hidden="true"></i>
                                        <span class="table-actions-label">Télécharger</span>
                                        </a>
                                    </li>

                                    <li class="table-actions-item" *ngIf="user.cleEtablissement && (bstv?.currentUserCanDeclareToRndtsEntrants || bstv?.currentUserCanDeclareToRndtsSortants)">
                                        <a class="table-actions-link blue" (click)="declarerAuRndts(bstv?.id)"
                                        download>
                                        <i class="table-actions-icon icon-external" aria-hidden="true"></i>
                                        <span class="table-actions-label" title="Utiliser ce BSTV pour réaliser votre déclaration de flux de terres et sédiments au RNDTS">Déclarer au RNDTS</span>
                                        </a>
                                    </li>

                                    <li class="table-actions-item" *ngIf="!user.cleEtablissement && (bstv?.currentUserCanDeclareToRndtsEntrants || bstv?.currentUserCanDeclareToRndtsSortants)">
                                        <a class="table-actions-link grey" download>
                                        <i class="table-actions-icon icon-external" aria-hidden="true"></i>
                                        <span class="table-actions-label" title="Pour activer cette fonction, merci de déclarer une clé RNDTS dans votre profil">Déclarer au RNDTS</span>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">
                            Aucun bordereau.
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <app-pagination [length]="totalbordereaux" [index]="page" [size]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (page)="pageChange($event)"></app-pagination>

        </div>
    </div>
</div>
