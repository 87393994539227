import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Delegation } from 'src/app/fr/brgm/common/model/delegation.model';
import { DelegationService } from '../../services/delegation.service';

@Component({
  selector: 'app-delegation-update-page',
  templateUrl: './delegation-update-page.component.html',
  styleUrls: ['./delegation-update-page.component.scss']
})
export class DelegationUpdatePageComponent implements OnInit {

  delegation: Delegation;

  constructor(
    private activatedroute: ActivatedRoute,
    private delegationService: DelegationService
  ) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => this.getDelegation(params.delegationId));
  }


  getDelegation(id) {
    this.delegationService.get(Number(id)).subscribe((res: Delegation) => {
      this.delegation = res;
    });
  }

}
