import { Deserializable } from './Deserializable';
import { Featureproperty } from './featureproperty.model';

export class Feature implements Deserializable {
    constructor(
        public geometry?: any,
        public properties?: Featureproperty
    ) {}
    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
