import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionService } from 'src/app/fr/brgm/common/http/transaction/transaction.service';
import { LexTransactionStatus } from 'src/app/fr/brgm/common/model/lex/lextransactionstatus.model';
import { Transaction } from 'src/app/fr/brgm/common/model/transaction.model';
import { User } from 'src/app/fr/brgm/common/model/user.model';

@Component({
  selector: 'app-modal-transaction-accept',
  templateUrl: './modal-transaction-accept.component.html',
  styleUrls: ['./modal-transaction-accept.component.scss']
})
export class ModalTransactionAcceptComponent implements OnInit {

  @Input() transaction: Transaction;
  @Input() lexTransactionStatus: LexTransactionStatus[];
  @Input() currentUser: User;
  verificationCheckBox: boolean = false;

  error: string;

  constructor(
    public activeModal: NgbActiveModal,
    private transactionService: TransactionService,
    private router: Router,
    private modalService: NgbModal
    ) { }

  ngOnInit() {}

  cancel() {
    this.activeModal.dismiss();
  }

  onSubmit() {      
    if(this.verificationCheckBox){
      this.transaction.lexTransactionStatus = this.lexTransactionStatus.find(ts => ts.id === 2);
      this.transactionService.update(this.transaction).subscribe(
        (res) => {
          this.activeModal.dismiss();
          //Si l'utilisateur courant est le producteur on le redirige vers la création du BSTV
          if(this.transaction.offer.user.id == this.currentUser.id){
            this.router.navigate(['/bordereaux/create/'+res.id]);
          }
          else{
            this.router.navigateByUrl('/transactions');
          }
        },
        err => {
          this.error = 'Une erreur s\'est produite lors de l\'acceptation de la transaction.';
          setTimeout(()=> this.error = null,4000);
        }
      );
    }   
  }


}
