<div class="container" *ngIf="transaction && transaction.currentUserCanUpdate">
<form action="mofiTransactionForm" #form="ngForm" (ngSubmit)="updateT(form)">
    <div class="page-sticky">
        <a class="button button--outline button--teal"
        routerLink="/transactions/details/{{transaction.id}}"
        routerLinkActive="active">
            <i class="button-icon icon-left" aria-hidden="true"></i>
            <span class="button-label">Retour</span>
        </a>

        <div class="page-header">
            <h1 class="h1">Modification de la transaction</h1>

            <ul class="page-actions">
                <li class="page-actions-item">
                    <button type="submit" class="button button--green" (click)="updateT(form)">
                        <i class="button-icon icon-check" aria-hidden="true"></i>
                        <span class="button-label">Mettre à jour</span>
                    </button>
                </li>
            </ul>
            <div *ngIf="form.submitted" class="red">
              <div *ngIf="form.form.status === 'INVALID'">
                Veuillez compléter les champs obligatoires avant de continuer<br>
              </div>
              <div *ngIf="transactionDateError">
                Veuillez vérifier les dates de transaction avant de continuer<br>
              </div>
              <div *ngIf="receptionDateError">
                Veuillez vérifier les dates de réception avant de continuer<br>
              </div>
            </div>
        </div>
    </div>
    <div class="details">
        <div class="details-header">
            <span class="details-type">Créée le
                {{transaction.creationDate | date:'dd/MM/yyyy'}} par {{transaction.user.firstname}} {{transaction.user.name}} |
                {{transaction.user.entityObject.socialReason}}</span>

            <h2 class="details-title h1">{{transaction.id}}</h2>

            <span class="details-visibility blue">Transaction valide jusqu’au {{transaction.bstrEndDate | date:'dd/MM/yyyy'}}</span>
        </div>

        <div class="details-body">
            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-add" aria-hidden="true"></i>
                    <h3 class="details-section-title" data-counter="01">Informations générales</h3>
                </div>
                <div class="details-section-body">
                    <div class="form-fieldset">
                        <span class="form-label">Période de validité de la transaction</span>
                        <div class="form-group">
                            <div class="form-item form-item--3 transaction-date">
                                <label>Date de début<span class="red">*</span></label>
                                <span class="form-date">
                                    <p-calendar [(ngModel)]="bstrStartDate" #startDate="ngModel" name="startDate"
                                      selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                      [ngClass]="{'red-border-class': (startDate.errors && form.submitted) || transactionDateError}"
                                      (onSelect)="onTransactionDateSelect()" required>
                                    </p-calendar>
                                </span>
                            </div>

                            <div class="form-item form-item--3 transaction-date">
                                <label>Date de fin<span class="red">*</span></label>
                                <span class="form-date">
                                    <p-calendar [(ngModel)]="bstrEndDate" #endDate="ngModel" name="endDate"
                                      selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                      [ngClass]="{'red-border-class': (endDate.errors && form.submitted) || transactionDateError}"
                                      (onSelect)="onTransactionDateSelect()" required>
                                    </p-calendar>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="transactionDateError" class="alert alert-danger">
                      {{ transactionDateError }}
                    </div>

                </div>
            </div>

            <div class="details-section details-section--opened">
                <div class="details-section-header">
                    <i class="details-section-icon icon-box" aria-hidden="true"></i>
                    <h3 class="details-section-title" data-counter="02">Logistique</h3>
                </div>
                <div class="details-section-body padding-for-calendar">
                    <div class="form-fieldset">
                        <div class="form-fieldset-header">
                            <h3 class="h3 form-fieldset-title">Transporteur</h3>
                        </div>
                        <div class="form-group">
                            <div class="form-item form-item--end form-item--12">
                                <label class="form-checkbox form-checkbox--margin">
                                    <input type="checkbox" class="form-checkbox-input" [ngModelOptions]="{standalone: true}" [(ngModel)]="choixTransporter" (click)="checkChoixTransport($event)">
                                    <span class="form-checkbox-label">Je ne souhaite pas prendre en charge le
                                        transport.</span>
                                </label>
                            </div>
                            <div class="form-item form-item--12" *ngIf="!choixTransporter">
                                <span class="form-label">Choisissez un ou plusieurs transporteurs</span>
                                <div *ngIf="transportersList" style="width: 100%; overflow-y: auto; height: 150px; border: 2px solid lightgrey;">
                                    <div *ngFor="let t of transportersList" >
                                        <label class="form-checkbox form-checkbox--margin">
                                        <input type="checkbox" class="form-checkbox-input" name="t.id" [value]="t.id" [checked]="isChecked(t.id)" (change)="getSelectedTransporter($event, t)">
                                        <span class="form-checkbox-label">{{t.name}}, {{t.address.numberStreet}}, {{t.address.zipCode}}
                                            {{t.address.foreignCity}}</span>
                                    </label>
                                    </div>
                                </div>
                            </div>

                            <label class="form-item form-item--6">
                                <span class="form-label">Quantité à transporter (t) <span class="red">*</span></span>

                                <span class="form-item-inner">
                                    <span class="form-unit">t</span>
                                    <input name="quantity" [(ngModel)]="transaction.quantity" type="text" class="form-input" required
                                    #quantite="ngModel" name="quantite" [ngClass]="{'red-border-class': quantite.errors && form.submitted}">
                                </span>
                            </label>
                        </div>

                            <div class="form-group">
                                <div class="form-item form-item--3 transaction-date">
                                    <label>Date de début réception<span class="red">*</span></label>
                                    <span class="form-date">
                                        <p-calendar [(ngModel)]="receptionStartDate" #rStartDate="ngModel" name="rStartDate"
                                          selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                          [ngClass]="{'red-border-class': (rStartDate.errors && form.submitted) || receptionDateError}"
                                          (onSelect)="onReceptionDateSelect()" required>
                                        </p-calendar>
                                    </span>
                                </div>

                                <div class="form-item form-item--3 transaction-date">
                                    <label>Date de fin réception<span class="red">*</span></label>
                                    <span class="form-date">
                                        <p-calendar [(ngModel)]="receptionEndDate" #rEndDate="ngModel" name="rEndDate"
                                          selectOtherMonths="true" dateFormat="dd/mm/yy"  class="form-input" [showButtonBar]="true"
                                          [ngClass]="{'red-border-class': (rEndDate.errors && form.submitted) || receptionDateError}"
                                          (onSelect)="onReceptionDateSelect()" required>
                                        </p-calendar>
                                    </span>
                                </div>

                            </div>
                    </div>

                    <div *ngIf="receptionDateError" class="alert alert-danger">
                      {{ receptionDateError }}
                    </div>

                    <hr class="form-separator">

                    <div class="form-fieldset">
                        <div class="form-fieldset-header">
                            <h3 class="h3 form-fieldset-title">Statut de déchets</h3>
                        </div>

                        <div class="form-group">
                            <div class="form-item form-item--12">
                                <label class="form-checkbox">
                                    <input type="checkbox" class="form-checkbox-input" [ngModelOptions]="{standalone: true}" [(ngModel)]="choixStatutDechet" (change)="checkChoixStatutDechet($event)">
                                    <span class="form-checkbox-label">
                                      Je souhaite réaliser une sortie de statut de déchets pour les terres excavées,
                                      en conformité avec l'arrêté du 4 juin 2021 fixant les critères de sortie du statut de déchets pour les terres excavées et sédiments ayant fait l'objet
                                      d'une préparation en vue d'une utilisation en génie civil ou en aménagement
                                    </span>
                                </label>
                            </div>

                            <div class="form-item form-item--12" *ngIf="choixStatutDechet">
                                <span class="form-label">Quel guide appliquez-vous pour la sortie de statut de déchets ?</span>
                                <ul class="form-list" *ngFor="let guide of lexGuideDechets">
                                    <li class="form-list-item">
                                        <label class="form-radio">
                                            <input type="radio" name="guide.id" [value]="guide.id" class="form-radio-input"
                                            [(ngModel)]="selectedGuideId" (change)="setLexGuideDechet(guide)">
                                            <span class="form-radio-label">{{guide.label}}</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</div>
